import React, { useState, useEffect } from "react";
import { collection, getDocs, addDoc } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { db, storage } from "../../../firebase";
import CloseIcon from "@mui/icons-material/Close";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import PersonIcon from "@mui/icons-material/Person";
import SaveIcon from "@mui/icons-material/Save";
import PlaceIcon from "@mui/icons-material/Place";
import LocalPoliceIcon from "@mui/icons-material/LocalPolice";
import PhoneIcon from "@mui/icons-material/Phone";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";

const NuevoOficial = ({ open, handleClose, onOfficialAdded }) => {
  const [nombre, setNombre] = useState("");
  const [zona, setZona] = useState("");
  const [tipo, setTipo] = useState("preventiva");
  const [celular, setCelular] = useState("");
  const [foto, setFoto] = useState(null);
  const [fotoPreview, setFotoPreview] = useState(null);
  const [zonas, setZonas] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [loadingZonas, setLoadingZonas] = useState(true);

  useEffect(() => {
    const fetchZonas = async () => {
      setLoadingZonas(true);
      try {
        const querySnapshot = await getDocs(collection(db, "unidades"));
        const zonasSet = new Set(
          querySnapshot.docs.map((doc) => doc.data().Zona).filter(Boolean)
        );
        setZonas(Array.from(zonasSet).sort());
      } catch (error) {
        console.error("Error al cargar zonas:", error);
      } finally {
        setLoadingZonas(false);
      }
    };

    if (open) {
      fetchZonas();
    }
  }, [open]);

  const validateForm = () => {
    const newErrors = {};

    if (!nombre.trim()) {
      newErrors.nombre = "El nombre es obligatorio";
    }

    if (!zona) {
      newErrors.zona = "Debe seleccionar una zona";
    }

    if (!celular.trim()) {
      newErrors.celular = "El número de celular es obligatorio";
    } else if (!/^\d{10}$/.test(celular.replace(/\D/g, ""))) {
      newErrors.celular = "El número debe tener 10 dígitos";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleUploadPhoto = async () => {
    if (!foto) return null;

    // Crear un nombre de archivo único basado en timestamp
    const timestamp = Date.now();
    const fileExtension = foto.name.split(".").pop();
    const fileName = `oficial_${timestamp}.${fileExtension}`;

    const storageRef = ref(storage, `oficiales/${fileName}`);
    const uploadTask = await uploadBytes(storageRef, foto);
    const downloadURL = await getDownloadURL(uploadTask.ref);
    return downloadURL;
  };

  const handleSubmit = async () => {
    if (!validateForm()) return;

    setLoading(true);
    try {
      const fotoURL = await handleUploadPhoto();
      const docRef = await addDoc(collection(db, "oficiales"), {
        nombre,
        zona,
        tipo,
        celular,
        fotoURL,
        fechaCreacion: new Date(),
      });

      onOfficialAdded({
        id: docRef.id,
        nombre,
        zona,
        tipo,
        celular,
        fotoURL,
      });

      resetForm();
      handleClose();
    } catch (error) {
      console.error("Error al crear oficial:", error);
      setErrors({
        ...errors,
        submit: "Error al crear el oficial. Intente nuevamente.",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleFotoChange = (e) => {
    const file = e.target.files[0];
    if (!file) return;

    // Validar tipo de archivo
    if (!file.type.match("image.*")) {
      setErrors({ ...errors, foto: "El archivo debe ser una imagen" });
      return;
    }

    // Validar tamaño (máximo 5MB)
    if (file.size > 5 * 1024 * 1024) {
      setErrors({ ...errors, foto: "La imagen no debe superar los 5MB" });
      return;
    }

    setFoto(file);
    setFotoPreview(URL.createObjectURL(file));
    // Limpiar error de foto si existe
    if (errors.foto) {
      const { foto, ...restErrors } = errors;
      setErrors(restErrors);
    }
  };

  const resetForm = () => {
    setNombre("");
    setZona("");
    setTipo("preventiva");
    setCelular("");
    setFoto(null);
    setFotoPreview(null);
    setErrors({});
  };

  if (!open) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
      <div className="bg-white rounded-lg shadow-xl max-w-md w-full max-h-[90vh] overflow-auto">
        {/* Header */}
        <div className="flex justify-between items-center p-4 border-b border-gray-200">
          <h2 className="text-xl font-semibold text-gray-800 flex items-center">
            <PersonIcon className="mr-2 text-indigo-600" />
            Añadir Nuevo Oficial
          </h2>
          <button
            onClick={handleClose}
            className="text-gray-500 hover:text-gray-700 transition-colors"
          >
            <CloseIcon />
          </button>
        </div>

        {/* Content */}
        <div className="p-6">
          {/* Nombre del oficial */}
          <div className="mb-4">
            <label
              className="block text-sm font-medium text-gray-700 mb-1"
              htmlFor="nombre"
            >
              Nombre completo
            </label>
            <div className="relative">
              <input
                id="nombre"
                type="text"
                value={nombre}
                onChange={(e) => setNombre(e.target.value)}
                className={`w-full px-3 py-2 border ${
                  errors.nombre ? "border-red-500" : "border-gray-300"
                } rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 pl-10`}
                placeholder="Ingrese el nombre completo"
              />
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <PersonIcon className="h-5 w-5 text-gray-400" />
              </div>
            </div>
            {errors.nombre && (
              <p className="mt-1 text-sm text-red-600">{errors.nombre}</p>
            )}
          </div>

          {/* Zona */}
          <div className="mb-4">
            <label
              className="block text-sm font-medium text-gray-700 mb-1"
              htmlFor="zona"
            >
              Zona asignada
            </label>
            <div className="relative">
              <select
                id="zona"
                value={zona}
                onChange={(e) => setZona(e.target.value)}
                className={`w-full px-3 py-2 border ${
                  errors.zona ? "border-red-500" : "border-gray-300"
                } rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 pl-10 bg-white appearance-none`}
                disabled={loadingZonas}
              >
                <option value="">Seleccione una zona</option>
                {zonas.map((zonaNombre) => (
                  <option key={zonaNombre} value={zonaNombre}>
                    {zonaNombre}
                  </option>
                ))}
              </select>
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <PlaceIcon className="h-5 w-5 text-gray-400" />
              </div>
              <div className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                <svg
                  className="h-5 w-5 text-gray-400"
                  fill="none"
                  viewBox="0 0 20 20"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                    d="M7 7l3-3 3 3m0 6l-3 3-3-3"
                  />
                </svg>
              </div>
            </div>
            {loadingZonas && (
              <p className="mt-1 text-sm text-gray-500">Cargando zonas...</p>
            )}
            {errors.zona && (
              <p className="mt-1 text-sm text-red-600">{errors.zona}</p>
            )}
          </div>

          {/* Tipo de oficial */}
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Tipo de oficial
            </label>
            <div className="grid grid-cols-2 gap-3">
              <button
                type="button"
                onClick={() => setTipo("preventiva")}
                className={`flex items-center justify-center p-3 rounded-md border ${
                  tipo === "preventiva"
                    ? "bg-indigo-50 border-indigo-200 text-indigo-800"
                    : "border-gray-300 text-gray-700 hover:bg-gray-50"
                }`}
              >
                <div
                  className={`mr-2 ${
                    tipo === "preventiva" ? "text-indigo-600" : "text-gray-400"
                  }`}
                >
                  {tipo === "preventiva" ? (
                    <RadioButtonCheckedIcon />
                  ) : (
                    <RadioButtonUncheckedIcon />
                  )}
                </div>
                <div className="text-left">
                  <div className="font-medium">Preventiva</div>
                  <div className="text-xs text-gray-500">
                    Oficial preventivo
                  </div>
                </div>
              </button>

              <button
                type="button"
                onClick={() => setTipo("transito")}
                className={`flex items-center justify-center p-3 rounded-md border ${
                  tipo === "transito"
                    ? "bg-indigo-50 border-indigo-200 text-indigo-800"
                    : "border-gray-300 text-gray-700 hover:bg-gray-50"
                }`}
              >
                <div
                  className={`mr-2 ${
                    tipo === "transito" ? "text-indigo-600" : "text-gray-400"
                  }`}
                >
                  {tipo === "transito" ? (
                    <RadioButtonCheckedIcon />
                  ) : (
                    <RadioButtonUncheckedIcon />
                  )}
                </div>
                <div className="text-left">
                  <div className="font-medium">Tránsito</div>
                  <div className="text-xs text-gray-500">
                    Oficial de tránsito
                  </div>
                </div>
              </button>
            </div>
          </div>

          {/* Celular */}
          <div className="mb-4">
            <label
              className="block text-sm font-medium text-gray-700 mb-1"
              htmlFor="celular"
            >
              Número de celular
            </label>
            <div className="relative">
              <input
                id="celular"
                type="tel"
                value={celular}
                onChange={(e) => setCelular(e.target.value)}
                className={`w-full px-3 py-2 border ${
                  errors.celular ? "border-red-500" : "border-gray-300"
                } rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 pl-10`}
                placeholder="Ingrese el número de celular"
                maxLength="10"
              />
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <PhoneIcon className="h-5 w-5 text-gray-400" />
              </div>
            </div>
            {errors.celular && (
              <p className="mt-1 text-sm text-red-600">{errors.celular}</p>
            )}
          </div>

          {/* Foto */}
          <div className="mb-6">
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Fotografía
            </label>
            <div className="flex items-center">
              <div className="mr-4">
                {fotoPreview ? (
                  <div className="relative">
                    <img
                      src={fotoPreview}
                      alt="Vista previa"
                      className="h-20 w-20 object-cover rounded-full border-2 border-indigo-200"
                    />
                    <div className="absolute -bottom-2 -right-2 bg-indigo-100 rounded-full p-1">
                      <CheckCircleIcon className="text-indigo-600 text-base" />
                    </div>
                  </div>
                ) : (
                  <div className="h-20 w-20 rounded-full bg-gray-200 flex items-center justify-center">
                    <CameraAltIcon className="text-gray-400 text-xl" />
                  </div>
                )}
              </div>

              <div className="flex-grow">
                <label className="cursor-pointer">
                  <div
                    className={`flex items-center justify-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium ${
                      fotoPreview
                        ? "text-indigo-700 bg-indigo-50 hover:bg-indigo-100"
                        : "text-gray-700 bg-white hover:bg-gray-50"
                    }`}
                  >
                    <CloudUploadIcon className="mr-2" />
                    {fotoPreview ? "Cambiar foto" : "Subir foto"}
                  </div>
                  <input
                    type="file"
                    className="hidden"
                    onChange={handleFotoChange}
                    accept="image/*"
                  />
                </label>
                <p className="mt-1 text-xs text-gray-500">
                  JPG, PNG o GIF. Máx. 5MB
                </p>
              </div>
            </div>
            {errors.foto && (
              <p className="mt-1 text-sm text-red-600">{errors.foto}</p>
            )}
          </div>

          {/* Error general */}
          {errors.submit && (
            <div className="mb-4 p-3 bg-red-50 border border-red-200 rounded-md">
              <p className="text-sm text-red-600">{errors.submit}</p>
            </div>
          )}

          {/* Botones de acción */}
          <div className="flex justify-end space-x-3">
            <button
              type="button"
              onClick={handleClose}
              className="px-4 py-2 border border-gray-300 rounded-md text-sm font-medium text-gray-700 hover:bg-gray-50"
              disabled={loading}
            >
              Cancelar
            </button>

            <button
              type="button"
              onClick={handleSubmit}
              disabled={loading}
              className={`px-4 py-2 rounded-md text-sm font-medium text-white ${
                loading
                  ? "bg-indigo-400 cursor-not-allowed"
                  : "bg-indigo-600 hover:bg-indigo-700"
              } flex items-center`}
            >
              {loading ? (
                <>
                  <div className="w-4 h-4 border-2 border-white border-t-transparent rounded-full animate-spin mr-2"></div>
                  Guardando...
                </>
              ) : (
                <>
                  <SaveIcon className="mr-1" fontSize="small" />
                  Crear Oficial
                </>
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NuevoOficial;
