import React, { useState, useEffect } from "react";
import {
  collection,
  addDoc,
  getDocs,
  doc,
  updateDoc,
  getDoc,
  Timestamp,
} from "firebase/firestore";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { db, storage } from "../../firebase";
import { useUserAuth } from "../../context/userAuthContext";
import { useParams, useNavigate } from "react-router-dom";
import Step1Unidad from "./Steps/Step1Unidad";
import Step2Fotos from "./Steps/Step2Fotos";
import Step3EstadoComentarios from "./Steps/Step3EstadoComentarios";
import Step4OficialFirma from "./Steps/Step4OficialFirma";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import InfoIcon from "@mui/icons-material/Info";
import WarningIcon from "@mui/icons-material/Warning";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import EditIcon from "@mui/icons-material/Edit";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CloseIcon from "@mui/icons-material/Close";
import HomeIcon from "@mui/icons-material/Home";
import AssignmentIcon from "@mui/icons-material/Assignment";
import SaveIcon from "@mui/icons-material/Save";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";

const NuevaInspeccion = () => {
  const { uid } = useParams();
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(0);
  const [unidades, setUnidades] = useState([]);
  const [oficiales, setOficiales] = useState([]);
  const [selectedUnidad, setSelectedUnidad] = useState("");
  const [selectedOficial, setSelectedOficial] = useState("");
  const [comentario, setComentario] = useState("");
  const [status, setStatus] = useState("Correcto");
  const [fotos, setFotos] = useState({});
  const [firma, setFirma] = useState(null);
  const [generalState, setGeneralState] = useState({
    Bocina: "Incorrecto",
    Radio: "Desprogramado",
    Torreta: "Incorrecto",
    DVR: "Incorrecto",
  });
  const [fusibles, setFusibles] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [message, setMessage] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarType, setSnackbarType] = useState("success");
  const [timing, setTiming] = useState({ Start: "", End: "" });
  const [inspectionId, setInspectionId] = useState(uid || "");
  const [isEditing, setIsEditing] = useState(!!uid);
  const [loadingData, setLoadingData] = useState(true);

  const { user } = useUserAuth();

  useEffect(() => {
    const fetchData = async () => {
      setLoadingData(true);
      try {
        await Promise.all([
          fetchUnidades(),
          fetchOficiales(),
          uid ? fetchInspectionData(uid) : Promise.resolve(),
        ]);
      } catch (error) {
        console.error("Error al cargar datos iniciales:", error);
      } finally {
        setLoadingData(false);
      }
    };

    fetchData();
  }, [uid]);

  const fetchUnidades = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, "unidades"));
      const unidadesData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setUnidades(
        unidadesData.sort((a, b) => a.Unidad.localeCompare(b.Unidad))
      );
      return unidadesData;
    } catch (error) {
      console.error("Error al obtener las unidades:", error);
      showMessage("Error al cargar las unidades", "error");
      return [];
    }
  };

  const fetchOficiales = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, "oficiales"));
      const oficialesData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        nombre: doc.data().nombre,
      }));
      setOficiales(
        oficialesData.sort((a, b) => a.nombre.localeCompare(b.nombre))
      );
      return oficialesData;
    } catch (error) {
      console.error("Error al obtener los oficiales:", error);
      showMessage("Error al cargar los oficiales", "error");
      return [];
    }
  };

  const fetchInspectionData = async (uid) => {
    try {
      const inspectionDoc = await getDoc(doc(db, "inspecciones", uid));
      if (inspectionDoc.exists()) {
        const data = inspectionDoc.data();
        setSelectedUnidad(data.Unidad);
        setSelectedOficial(data.Nombre);
        setComentario(data.Comentario || "");
        setStatus(data.Status || "Correcto");
        setGeneralState(
          data.EstadoGeneral || {
            Bocina: "Incorrecto",
            Radio: "Desprogramado",
            Torreta: "Incorrecto",
            DVR: "Incorrecto",
          }
        );
        setFusibles(data.FusiblesReemplazados || []);

        // Convertir las URLs de fotos a objetos con previewURL para mostrarlas
        const fotosObj = {};
        if (data.fotos) {
          Object.entries(data.fotos).forEach(([key, url]) => {
            fotosObj[key] = { previewURL: url };
          });
        }
        setFotos(fotosObj);

        setFirma(data.fotos?.Firma || null);
        setTiming(data.Timing || { Start: "", End: "" });
        setIsEditing(true);
        return data;
      } else {
        console.error("No se encontró la inspección con el UID proporcionado.");
        showMessage("No se encontró la inspección", "error");
        return null;
      }
    } catch (error) {
      console.error("Error al obtener la inspección:", error);
      showMessage("Error al cargar los datos de la inspección", "error");
      return null;
    }
  };

  const showMessage = (msg, type = "success") => {
    setMessage(msg);
    setSnackbarType(type);
    setOpenSnackbar(true);

    // Auto hide after 5 seconds
    setTimeout(() => {
      setOpenSnackbar(false);
    }, 5000);
  };

  const handleImageChange = (label, file) => {
    if (
      file &&
      file instanceof Blob &&
      file.size > 0 &&
      file.type.startsWith("image/")
    ) {
      try {
        const previewURL = URL.createObjectURL(file);
        setFotos((prev) => ({
          ...prev,
          [label]: { file, previewURL },
        }));
      } catch (error) {
        console.error("Error al generar la URL de previsualización:", error);
        showMessage("Error al procesar la imagen", "error");
      }
    } else if (file === null) {
      // Si se elimina la imagen
      setFotos((prev) => {
        const newFotos = { ...prev };
        delete newFotos[label];
        return newFotos;
      });
    } else {
      console.error("El archivo seleccionado no es válido.");
      showMessage("El archivo seleccionado no es válido", "error");
    }
  };

  const handleFirmaPad = (signaturePad) => {
    const dataUrl = signaturePad.toDataURL("image/png");
    setFirma(dataUrl);
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    // Scroll to top when changing steps
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    // Scroll to top when changing steps
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleSubmit = async () => {
    if (!selectedUnidad || !selectedOficial) {
      showMessage("Por favor, selecciona una unidad y un oficial.", "error");
      return;
    }

    setUploading(true);

    try {
      let newInspectionRef;
      if (inspectionId) {
        newInspectionRef = doc(db, "inspecciones", inspectionId);
      } else {
        newInspectionRef = await addDoc(collection(db, "inspecciones"), {
          Comentario: comentario,
          Date: Timestamp.now(),
          "Inspection ID": "",
          Nombre: selectedOficial,
          Pago: "Por cobrar",
          Status: status,
          Unidad: selectedUnidad,
          Usuario: user.email,
          EstadoGeneral: generalState,
          FusiblesReemplazados: fusibles,
          fotos: {},
          Timing: { Start: Timestamp.now(), End: "" },
          Active: true,
        });
        setInspectionId(newInspectionRef.id);
      }

      const validFotos = Object.entries(fotos).filter(
        ([key, value]) => value && (value.file || value.previewURL)
      );

      const uploadPromises = validFotos
        .filter(([key, value]) => value.file)
        .map(([key, { file }]) => {
          const storageRef = ref(
            storage,
            `inspecciones/${inspectionId || newInspectionRef.id}/${key}.jpg`
          );
          const uploadTask = uploadBytesResumable(storageRef, file);

          return new Promise((resolve, reject) => {
            uploadTask.on(
              "state_changed",
              (snapshot) => {
                const progress =
                  (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                console.log(`Subiendo imagen ${key}: ${progress}% hecho`);
              },
              (error) => {
                console.error("Error al subir la imagen:", error);
                reject(error);
              },
              async () => {
                const downloadURL = await getDownloadURL(
                  uploadTask.snapshot.ref
                );
                resolve({ name: key, url: downloadURL });
              }
            );
          });
        });

      // Mantener fotos existentes que no se están actualizando
      const existingFotos = validFotos
        .filter(([key, value]) => !value.file && value.previewURL)
        .reduce((acc, [key, value]) => {
          acc[key] = value.previewURL;
          return acc;
        }, {});

      // Subir firma si existe y ha cambiado
      if (firma && (!fotos.Firma || fotos.Firma.previewURL !== firma)) {
        const firmaBlob = await (await fetch(firma)).blob();
        const firmaRef = ref(
          storage,
          `inspecciones/${inspectionId || newInspectionRef.id}/Firma.png`
        );
        const uploadTask = uploadBytesResumable(firmaRef, firmaBlob);

        const firmaURL = await new Promise((resolve, reject) => {
          uploadTask.on(
            "state_changed",
            (snapshot) => {
              const progress =
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              console.log(`Subiendo firma: ${progress}% hecho`);
            },
            (error) => {
              console.error("Error al subir la firma:", error);
              reject(error);
            },
            async () => {
              const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
              resolve(downloadURL);
            }
          );
        });

        existingFotos.Firma = firmaURL;
      }

      const uploadedImages = await Promise.all(uploadPromises);
      const uploadedFotos = uploadedImages.reduce((acc, img) => {
        if (img) {
          acc[img.name] = img.url;
        }
        return acc;
      }, {});

      // Combinar fotos existentes y nuevas
      const updatedFotos = {
        ...existingFotos,
        ...uploadedFotos,
      };

      await updateDoc(newInspectionRef, {
        "Inspection ID": inspectionId || newInspectionRef.id,
        fotos: updatedFotos,
        Nombre: selectedOficial,
        Comentario: comentario,
        Status: status,
        EstadoGeneral: generalState,
        FusiblesReemplazados: fusibles,
        Timing: { ...timing, End: Timestamp.now() },
      });

      showMessage(
        isEditing
          ? "Inspección actualizada con éxito."
          : "Inspección creada con éxito.",
        "success"
      );
      setTimeout(() => {
        navigate(`/inspeccion/${newInspectionRef.id}`);
      }, 1500);
    } catch (error) {
      console.error("Error al guardar la inspección:", error);
      showMessage(
        "Error al guardar la inspección. Intenta nuevamente.",
        "error"
      );
    } finally {
      setUploading(false);
    }
  };

  const resetForm = () => {
    setComentario("");
    setSelectedUnidad("");
    setSelectedOficial("");
    setFotos({});
    setFirma(null);
    setGeneralState({
      Bocina: "Incorrecto",
      Radio: "Desprogramado",
      Torreta: "Incorrecto",
      DVR: "Incorrecto",
    });
    setFusibles([]);
    setActiveStep(0);
    setTiming({ Start: "", End: "" });
    setInspectionId("");
    setIsEditing(false);
  };

  const steps = [
    "Seleccionar Unidad",
    "Subir Fotografías",
    "Estado y Comentarios",
    "Oficial y Firma",
  ];

  // Componente Snackbar usando Tailwind
  const SnackbarAlert = () => {
    if (!openSnackbar) return null;

    const bgColors = {
      success: "bg-emerald-600",
      error: "bg-red-600",
      info: "bg-blue-600",
      warning: "bg-amber-600",
    };

    const icons = {
      success: <CheckCircleIcon className="mr-2 h-5 w-5" />,
      error: <ErrorIcon className="mr-2 h-5 w-5" />,
      info: <InfoIcon className="mr-2 h-5 w-5" />,
      warning: <WarningIcon className="mr-2 h-5 w-5" />,
    };

    return (
      <div className="fixed bottom-4 left-1/2 transform -translate-x-1/2 z-50 transition-opacity animate-fade-in">
        <div
          className={`flex items-center p-4 rounded-md shadow-lg text-white ${bgColors[snackbarType]}`}
        >
          {icons[snackbarType]}
          <span className="font-medium">{message}</span>
          <button
            onClick={() => setOpenSnackbar(false)}
            className="ml-4 text-white hover:text-gray-200 focus:outline-none"
          >
            <CloseIcon className="h-5 w-5" />
          </button>
        </div>
      </div>
    );
  };

  // Componente de Stepper personalizado con Tailwind
  const CustomStepper = () => {
    return (
      <div className="w-full py-6 mb-8 overflow-x-auto">
        <div className="flex justify-between min-w-max md:min-w-0">
          {steps.map((step, index) => (
            <div
              key={index}
              className="flex flex-col items-center relative px-4"
            >
              <div
                className={`
                flex items-center justify-center w-10 h-10 rounded-full border-2 
                transition-colors duration-200
                ${
                  activeStep >= index
                    ? "border-indigo-700 bg-indigo-700 text-white"
                    : "border-gray-300 bg-white text-gray-500"
                }
                z-10
              `}
              >
                {index + 1}
              </div>

              <div
                className={`
                text-xs mt-2 text-center font-medium w-24 sm:w-32
                ${activeStep >= index ? "text-indigo-700" : "text-gray-500"}
              `}
              >
                {step}
              </div>

              {index < steps.length - 1 && (
                <div
                  className={`
                  absolute top-5 w-full h-0.5 left-1/2 
                  ${activeStep > index ? "bg-indigo-700" : "bg-gray-300"}
                  transition-colors duration-200
                `}
                ></div>
              )}
            </div>
          ))}
        </div>
      </div>
    );
  };

  // Barra de progreso para carga
  const ProgressBar = ({ isLoading }) => {
    return (
      <div className="h-1 w-full bg-gray-100 overflow-hidden">
        {isLoading && (
          <div className="h-1 bg-indigo-600 animate-progress-indeterminate"></div>
        )}
      </div>
    );
  };

  return (
    <div className="flex flex-col items-center justify-center py-8 px-4 bg-gray-50 min-h-screen">
      {/* Breadcrumbs */}
      <div className="w-full max-w-4xl mb-4 flex items-center text-sm text-gray-600">
        <button
          onClick={() => navigate("/")}
          className="flex items-center hover:text-indigo-700 transition-colors"
        >
          <HomeIcon className="h-4 w-4 mr-1" />
          Inicio
        </button>
        <span className="mx-2">/</span>
        <button
          onClick={() => navigate("/buscarinspeccion")}
          className="flex items-center hover:text-indigo-700 transition-colors"
        >
          <AssignmentIcon className="h-4 w-4 mr-1" />
          Inspecciones
        </button>
        <span className="mx-2">/</span>
        <span className="text-gray-900 font-medium flex items-center">
          {isEditing ? (
            <EditIcon className="h-4 w-4 mr-1" />
          ) : (
            <ContentPasteIcon className="h-4 w-4 mr-1" />
          )}
          {isEditing ? "Editar Inspección" : "Nueva Inspección"}
        </span>
      </div>

      <div className="w-full max-w-4xl">
        <div className="bg-white rounded-md shadow-md overflow-hidden">
          {/* Cabecera */}
          <div className="bg-indigo-700 p-6">
            <div className="flex justify-between items-center">
              <button
                onClick={() => navigate(-1)}
                className="text-white p-2 rounded-full hover:bg-indigo-600 transition-colors"
              >
                <ArrowBackIcon />
              </button>
              <h1 className="text-2xl md:text-3xl font-semibold text-white text-center flex-1">
                {isEditing ? "Editar Inspección" : "Nueva Inspección"}
              </h1>
              <div className="w-8"></div>{" "}
              {/* Espaciador para centrar el título */}
            </div>
          </div>

          {/* Barra de progreso */}
          <ProgressBar isLoading={uploading || loadingData} />

          {/* Contenido principal */}
          <div className="p-6 md:p-8">
            <CustomStepper />

            {loadingData ? (
              <div className="flex flex-col items-center justify-center py-12">
                <div className="w-12 h-12 border-4 border-indigo-200 border-t-indigo-700 rounded-full animate-spin"></div>
                <p className="mt-4 text-gray-600">Cargando datos...</p>
              </div>
            ) : (
              <div className="mt-4">
                {activeStep === 0 && (
                  <Step1Unidad
                    unidades={unidades}
                    selectedUnidad={selectedUnidad}
                    setSelectedUnidad={setSelectedUnidad}
                    handleNext={handleNext}
                    setUnidades={setUnidades}
                    setInspectionId={setInspectionId}
                    isEditing={isEditing}
                  />
                )}

                {activeStep === 1 && (
                  <Step2Fotos
                    existingFotos={fotos}
                    handleImageChange={handleImageChange}
                    handleNext={handleNext}
                    handleBack={handleBack}
                    inspectionId={inspectionId}
                    isEditing={isEditing}
                  />
                )}

                {activeStep === 2 && (
                  <Step3EstadoComentarios
                    comentario={comentario}
                    setComentario={setComentario}
                    status={status}
                    setStatus={setStatus}
                    handleNext={handleNext}
                    handleBack={handleBack}
                    generalState={generalState}
                    setGeneralState={setGeneralState}
                    fusibles={fusibles}
                    setFusibles={setFusibles}
                    inspectionId={inspectionId}
                    isEditing={isEditing}
                  />
                )}

                {activeStep === 3 && (
                  <Step4OficialFirma
                    oficiales={oficiales}
                    selectedOficial={selectedOficial}
                    setSelectedOficial={setSelectedOficial}
                    firma={firma}
                    handleFirmaPad={handleFirmaPad}
                    handleBack={handleBack}
                    handleSubmit={handleSubmit}
                    uploading={uploading}
                    inspectionId={inspectionId}
                    isEditing={isEditing}
                  />
                )}
              </div>
            )}
          </div>

          {/* Pie de página con información */}
          <div className="bg-gray-50 p-4 border-t border-gray-200 text-center text-xs text-gray-500">
            {isEditing ? "Editando inspección" : "Creando nueva inspección"} •
            {inspectionId
              ? ` ID: ${inspectionId.substring(0, 8)}...`
              : " Sin guardar"}{" "}
            •{` Usuario: ${user?.email || "No identificado"}`}
          </div>
        </div>
      </div>

      {/* Botón flotante para guardar (solo en último paso) */}
      {activeStep === 3 && !uploading && (
        <div className="fixed bottom-8 right-8">
          <button
            onClick={handleSubmit}
            disabled={uploading}
            className="flex items-center justify-center w-14 h-14 bg-indigo-700 text-white rounded-full shadow-lg hover:bg-indigo-800 transition-colors focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          >
            <SaveIcon />
          </button>
        </div>
      )}

      {/* Snackbar / Notificaciones */}
      <SnackbarAlert />

      {/* Estilos CSS para animaciones */}
      <style jsx="true">{`
        @keyframes fade-in {
          from {
            opacity: 0;
          }
          to {
            opacity: 1;
          }
        }

        @keyframes progress-indeterminate {
          0% {
            transform: translateX(-100%);
          }
          100% {
            transform: translateX(100%);
          }
        }

        .animate-fade-in {
          animation: fade-in 0.3s ease-out;
        }

        .animate-progress-indeterminate {
          animation: progress-indeterminate 1.5s infinite
            cubic-bezier(0.65, 0.815, 0.735, 0.395);
          width: 100%;
        }
      `}</style>
    </div>
  );
};

export default NuevaInspeccion;
