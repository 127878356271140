import { jsPDF } from "jspdf";

/**
 * Genera un PDF con la información del candidato
 * @param {Object} candidato - Datos del candidato
 * @param {string} uid - ID único del candidato
 * @returns {Promise<string>} - Nombre del archivo generado
 */
export const generarPDFCandidato = async (candidato, uid) => {
    if (!candidato) throw new Error("No hay datos de candidato para generar el PDF");

    try {
        // Crear PDF en formato A4
        const pdf = new jsPDF({
            orientation: 'portrait',
            unit: 'mm',
            format: 'a4',
            putOnlyUsedFonts: true,
            compress: true
        });

        // Configuración de estilos
        pdf.setFont("helvetica", "bold");
        pdf.setFontSize(18);
        pdf.setTextColor(0, 0, 0);

        // Título del documento
        pdf.text("SOLICITUD DE EMPLEO", 105, 20, { align: "center" });

        // Agregar nombre del candidato
        pdf.setFontSize(14);
        const nombreCompleto = `${candidato.datosPersonales?.nombres || ""} ${candidato.datosPersonales?.apellidoPaterno || ""} ${candidato.datosPersonales?.apellidoMaterno || ""}`;
        pdf.text(nombreCompleto.trim(), 105, 30, { align: "center" });

        // Agregar folio
        pdf.setFontSize(10);
        pdf.setFont("helvetica", "normal");
        pdf.setTextColor(100, 100, 100);
        pdf.text(`Folio: ${uid.substring(0, 6).toUpperCase()}`, 105, 35, { align: "center" });
        pdf.text(`ID: ${uid}`, 105, 39, { align: "center" });

        // Fecha
        const fechaGeneracion = new Date().toLocaleDateString("es-MX", {
            day: '2-digit',
            month: 'long',
            year: 'numeric'
        });
        pdf.text(`Fecha de generación: ${fechaGeneracion}`, 105, 43, { align: "center" });

        let yPos = 50; // Posición vertical inicial
        const margenIzq = 20;
        const anchoDisponible = 170;

        // Función para agregar una sección
        const agregarSeccion = (titulo, datos, margenInferior = 10) => {
            // Verificar si necesitamos una nueva página
            if (yPos > 270) {
                pdf.addPage();
                yPos = 20;
            }

            // Título de la sección
            pdf.setFont("helvetica", "bold");
            pdf.setFontSize(12);
            pdf.setTextColor(0, 0, 0);
            pdf.text(titulo, margenIzq, yPos);
            yPos += 5;

            // Línea separadora
            pdf.setDrawColor(0, 120, 255);
            pdf.setLineWidth(0.5);
            pdf.line(margenIzq, yPos, margenIzq + anchoDisponible, yPos);
            yPos += 8;

            // Contenido
            pdf.setFont("helvetica", "normal");
            pdf.setFontSize(10);
            pdf.setTextColor(60, 60, 60);

            // Iterar sobre los datos
            Object.entries(datos).forEach(([clave, valor]) => {
                // Si el valor es undefined, null, o cadena vacía, no lo mostramos
                if (valor === undefined || valor === null || valor === "") return;

                // Si el valor es un objeto, lo tratamos diferente
                if (typeof valor === 'object' && !Array.isArray(valor)) {
                    pdf.setFont("helvetica", "bold");
                    pdf.text(`${clave}:`, margenIzq, yPos);
                    yPos += 5;
                    pdf.setFont("helvetica", "normal");

                    Object.entries(valor).forEach(([subclave, subvalor]) => {
                        if (subvalor === undefined || subvalor === null || subvalor === "") return;
                        pdf.text(`• ${subclave}: ${subvalor}`, margenIzq + 5, yPos);
                        yPos += 5;
                    });
                }
                // Si el valor es un array, lo manejamos como una lista
                else if (Array.isArray(valor)) {
                    pdf.setFont("helvetica", "bold");
                    pdf.text(`${clave}:`, margenIzq, yPos);
                    yPos += 5;
                    pdf.setFont("helvetica", "normal");

                    valor.forEach((item, index) => {
                        if (typeof item === 'object') {
                            pdf.text(`• Elemento ${index + 1}:`, margenIzq + 5, yPos);
                            yPos += 5;

                            Object.entries(item).forEach(([itemKey, itemValue]) => {
                                if (itemValue === undefined || itemValue === null || itemValue === "" || itemKey === "id") return;
                                pdf.text(`  - ${itemKey}: ${itemValue}`, margenIzq + 10, yPos);
                                yPos += 5;
                            });
                        } else {
                            pdf.text(`• ${item}`, margenIzq + 5, yPos);
                            yPos += 5;
                        }
                    });
                }
                // Valor simple
                else {
                    pdf.text(`${clave}: ${valor}`, margenIzq, yPos);
                    yPos += 5;
                }
            });

            yPos += margenInferior; // Espacio después de la sección
        };

        // Datos personales
        agregarSeccion("DATOS PERSONALES", {
            "Nombre completo": nombreCompleto.trim(),
            "Edad": candidato.datosPersonales?.edad ? `${candidato.datosPersonales.edad} años` : "No especificado",
            "Fecha de nacimiento": candidato.datosPersonales?.fechaNacimiento || "No especificado",
            "Lugar de nacimiento": candidato.datosPersonales?.lugarNacimiento || "No especificado",
            "Nacionalidad": candidato.datosPersonales?.nacionalidad || "No especificado",
            "Domicilio": candidato.datosPersonales?.domicilio || "No especificado",
            "Colonia": candidato.datosPersonales?.colonia || "No especificado",
            "Código Postal": candidato.datosPersonales?.codigoPostal || "No especificado",
            "Teléfono": candidato.datosPersonales?.telefono || "No especificado",
            "Correo": candidato.contactInfo || "No especificado"
        });

        // Documentación
        if (candidato.documentacion) {
            agregarSeccion("DOCUMENTACIÓN", {
                "CURP": candidato.documentacion?.curp || "No especificado",
                "RFC": candidato.documentacion?.rfc || "No especificado",
                "NSS": candidato.documentacion?.nss || "No especificado",
                "AFORE": candidato.documentacion?.afore || "No especificado",
                "Tipo de Licencia": candidato.documentacion?.licencia || "No especificado",
                "Pasaporte": candidato.documentacion?.pasaporte || "No especificado"
            });
        }

        // Estado de Salud
        if (candidato.estadoSalud) {
            agregarSeccion("ESTADO DE SALUD", {
                "Estado General": candidato.estadoSalud?.estadoSalud || "No especificado",
                "Enfermedad Crónica": candidato.estadoSalud?.enfermedadCronica || "No especificado",
                "Detalle de enfermedad": candidato.estadoSalud?.detalleEnfermedad || "No aplica",
                "Deporte": candidato.estadoSalud?.deporte || "No especificado",
                "Pasatiempo": candidato.estadoSalud?.pasatiempo || "No especificado",
                "Club Social": candidato.estadoSalud?.clubSocial || "No especificado",
                "Meta en la vida": candidato.estadoSalud?.metaVida || "No especificado"
            });
        }

        // Datos Familiares
        if (candidato.datosFamiliares) {
            agregarSeccion("DATOS FAMILIARES", {
                "Estado Civil": candidato.datosFamiliares?.estadoCivil || "No especificado",
                "Nombre del Cónyuge": candidato.datosFamiliares?.nombreConyuge || "No aplica",
                "Ocupación del Cónyuge": candidato.datosFamiliares?.ocupacionConyuge || "No aplica",
                "Número de Hijos": candidato.datosFamiliares?.numeroHijos || "0",
                "Nombre del Padre": candidato.datosFamiliares?.nombrePadre || "No especificado",
                "Ocupación del Padre": candidato.datosFamiliares?.ocupacionPadre || "No especificado",
                "Vive": candidato.datosFamiliares?.vivePadre || "No especificado",
                "Nombre de la Madre": candidato.datosFamiliares?.nombreMadre || "No especificado",
                "Ocupación de la Madre": candidato.datosFamiliares?.ocupacionMadre || "No especificado",
                "Vive": candidato.datosFamiliares?.viveMadre || "No especificado"
            });
        }

        // Escolaridad
        if (candidato.escolaridad) {
            agregarSeccion("ESCOLARIDAD", {
                "Primaria": candidato.escolaridad?.primaria || "No especificado",
                "Años Cursados (Primaria)": candidato.escolaridad?.aniosPrimaria || "No especificado",
                "Certificado (Primaria)": candidato.escolaridad?.certificadoPrimaria ? "Sí" : "No",
                "Secundaria": candidato.escolaridad?.secundaria || "No especificado",
                "Años Cursados (Secundaria)": candidato.escolaridad?.aniosSecundaria || "No especificado",
                "Certificado (Secundaria)": candidato.escolaridad?.certificadoSecundaria ? "Sí" : "No",
                "Preparatoria": candidato.escolaridad?.preparatoria || "No especificado",
                "Años Cursados (Preparatoria)": candidato.escolaridad?.aniosPreparatoria || "No especificado",
                "Certificado (Preparatoria)": candidato.escolaridad?.certificadoPreparatoria ? "Sí" : "No",
                "Universidad": candidato.escolaridad?.universidad || "No especificado",
                "Años Cursados (Universidad)": candidato.escolaridad?.aniosUniversidad || "No especificado",
                "Certificado (Universidad)": candidato.escolaridad?.certificadoUniversidad ? "Sí" : "No",
                "Título Profesional": candidato.escolaridad?.titulo || "No especificado",
                "Cédula Profesional": candidato.escolaridad?.cedula || "No especificado",
                "Estudios Actuales": candidato.escolaridad?.estudiosActuales || "No especificado",
                "Horario de Estudios": candidato.escolaridad?.horarioEstudios || "No especificado"
            });
        }

        // Conocimientos Generales
        if (candidato.conocimientosGenerales) {
            agregarSeccion("CONOCIMIENTOS GENERALES", {
                "Idiomas": candidato.conocimientosGenerales?.idiomas || "No especificado",
                "Nivel de inglés": candidato.conocimientosGenerales?.nivelIngles || "No especificado",
                "Funciones de oficina": candidato.conocimientosGenerales?.funcionesOficina || "No especificado",
                "Máquinas de oficina": candidato.conocimientosGenerales?.maquinasOficina || "No especificado",
                "Software": candidato.conocimientosGenerales?.software || "No especificado",
                "Otros conocimientos": candidato.conocimientosGenerales?.otrosConocimientos || "No especificado"
            });
        }

        // Historial de Empleos
        if (candidato.historialEmpleos && candidato.historialEmpleos.length > 0) {
            pdf.addPage();
            yPos = 20;

            pdf.setFont("helvetica", "bold");
            pdf.setFontSize(12);
            pdf.setTextColor(0, 0, 0);
            pdf.text("HISTORIAL DE EMPLEOS", margenIzq, yPos);
            yPos += 5;

            // Línea separadora
            pdf.setDrawColor(0, 120, 255);
            pdf.setLineWidth(0.5);
            pdf.line(margenIzq, yPos, margenIzq + anchoDisponible, yPos);
            yPos += 8;

            candidato.historialEmpleos.forEach((empleo, index) => {
                // Verificar si necesitamos una nueva página
                if (yPos > 250) {
                    pdf.addPage();
                    yPos = 20;
                }

                pdf.setFont("helvetica", "bold");
                pdf.setFontSize(11);
                pdf.text(`Empleo ${index + 1}`, margenIzq, yPos);
                yPos += 6;

                pdf.setFont("helvetica", "normal");
                pdf.setFontSize(10);

                const datosEmpleo = [
                    ["Empresa", empleo.empresa || "No especificado"],
                    ["Dirección", empleo.direccion || "No especificado"],
                    ["Teléfono", empleo.telefono || "No especificado"],
                    ["Puesto", empleo.puesto || "No especificado"],
                    ["Sueldo Inicial", empleo.sueldoInicial || "No especificado"],
                    ["Sueldo Final", empleo.sueldoFinal || "No especificado"],
                    ["Jefe Directo", empleo.jefeDirecto || "No especificado"],
                    ["Puesto del Jefe", empleo.puestoJefe || "No especificado"],
                    ["Fecha de Ingreso", empleo.fechaIngreso || "No especificado"],
                    ["Fecha de Salida", empleo.fechaSalida || "No especificado"],
                    ["Motivo de Separación", empleo.motivoSeparacion || "No especificado"]
                ];

                datosEmpleo.forEach(([clave, valor]) => {
                    pdf.text(`${clave}: ${valor}`, margenIzq + 5, yPos);
                    yPos += 5;
                });

                yPos += 10; // Espacio después de cada empleo
            });
        }

        // Referencias Personales
        if (candidato.referenciasPersonales && candidato.referenciasPersonales.length > 0) {
            // Verificar si necesitamos una nueva página
            if (yPos > 220) {
                pdf.addPage();
                yPos = 20;
            }

            pdf.setFont("helvetica", "bold");
            pdf.setFontSize(12);
            pdf.setTextColor(0, 0, 0);
            pdf.text("REFERENCIAS PERSONALES", margenIzq, yPos);
            yPos += 5;

            // Línea separadora
            pdf.setDrawColor(0, 120, 255);
            pdf.setLineWidth(0.5);
            pdf.line(margenIzq, yPos, margenIzq + anchoDisponible, yPos);
            yPos += 8;

            pdf.setFont("helvetica", "normal");
            pdf.setFontSize(10);

            candidato.referenciasPersonales.forEach((referencia, index) => {
                // Verificar si necesitamos una nueva página
                if (yPos > 270) {
                    pdf.addPage();
                    yPos = 20;
                }

                pdf.setFont("helvetica", "bold");
                pdf.text(`Referencia ${index + 1}`, margenIzq, yPos);
                yPos += 5;
                pdf.setFont("helvetica", "normal");

                const datosReferencia = [
                    ["Nombre", referencia.nombre || "No especificado"],
                    ["Teléfono", referencia.telefono || "No especificado"],
                    ["Ocupación", referencia.ocupacion || "No especificado"],
                    ["Tiempo de Conocerlo", referencia.tiempoConocerlo || "No especificado"],
                ];

                datosReferencia.forEach(([clave, valor]) => {
                    pdf.text(`${clave}: ${valor}`, margenIzq + 5, yPos);
                    yPos += 5;
                });

                yPos += 5; // Espacio después de cada referencia
            });
        }

        // Datos Generales
        if (candidato.datosGenerales) {
            // Verificar si necesitamos una nueva página
            if (yPos > 220) {
                pdf.addPage();
                yPos = 20;
            }

            agregarSeccion("DATOS GENERALES", {
                "Cómo se enteró de la vacante": candidato.datosGenerales?.medioVacante || "No especificado",
                "Tiene parientes trabajando aquí": candidato.datosGenerales?.tieneFamiliares ? "Sí" : "No",
                "Nombres de los familiares": candidato.datosGenerales?.nombresFamiliares || "No aplica",
                "Ha trabajado aquí antes": candidato.datosGenerales?.haTrabajoAntes ? "Sí" : "No",
                "Cuándo trabajó aquí": candidato.datosGenerales?.cuandoTrabajo || "No aplica",
                "Puede viajar": candidato.datosGenerales?.puedeViajar ? "Sí" : "No",
                "Restricciones para viajar": candidato.datosGenerales?.restriccionesViaje || "No aplica",
                "Puede cambiar de residencia": candidato.datosGenerales?.cambioResidencia ? "Sí" : "No",
                "Fecha disponible": candidato.datosGenerales?.fechaDisponible || "No especificado",
                "Sueldo deseado": candidato.datosGenerales?.sueldoDeseado || "No especificado"
            });
        }

        // QR Code en el PDF (última página)
        pdf.addPage();
        yPos = 20;

        pdf.setFont("helvetica", "bold");
        pdf.setFontSize(12);
        pdf.setTextColor(0, 0, 0);
        pdf.text("CÓDIGO QR DE IDENTIFICACIÓN", 105, yPos, { align: "center" });
        yPos += 10;

        // Texto alternativo en lugar del QR
        pdf.setFontSize(10);
        pdf.setFont("helvetica", "normal");
        pdf.text("Para ver el código QR, acceda a la plataforma web.", 105, yPos + 20, { align: "center" });
        pdf.text(`URL: https://app.example.com/candidatos/${uid}`, 105, yPos + 30, { align: "center" });

        yPos += 85;

        // Agregar información de identificación
        pdf.setFont("helvetica", "normal");
        pdf.setFontSize(10);
        pdf.setTextColor(60, 60, 60);
        pdf.text(`ID: ${uid}`, 105, yPos, { align: "center" });
        yPos += 5;
        pdf.text(`Folio: ${uid.substring(0, 6).toUpperCase()}`, 105, yPos, { align: "center" });

        // Pie de página en todas las páginas
        const totalPages = pdf.getNumberOfPages();
        for (let i = 1; i <= totalPages; i++) {
            pdf.setPage(i);
            pdf.setFont("helvetica", "normal");
            pdf.setFontSize(8);
            pdf.setTextColor(150, 150, 150);
            pdf.text(`Página ${i} de ${totalPages}`, 105, 290, { align: "center" });
            pdf.text(`Generado el ${fechaGeneracion}`, 105, 295, { align: "center" });
        }

        // Guardar el PDF
        const nombreArchivo = `Candidato_${candidato.datosPersonales?.nombres || ''}_${candidato.datosPersonales?.apellidoPaterno || ''}_${uid.substring(0, 6)}.pdf`;
        pdf.save(nombreArchivo);

        return nombreArchivo;
    } catch (error) {
        console.error("Error al generar PDF:", error);
        throw new Error("Ocurrió un error al generar el PDF");
    }
};

export default generarPDFCandidato;