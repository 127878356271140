import React, { useState, useEffect, useRef, useCallback } from 'react';
import { User, Home, Phone, Briefcase, Calendar, X, Plus } from 'lucide-react';

const ReferenciasPersonales = ({ solicitudId, datosIniciales, onUpdate }) => {
    // Usar ref en lugar de estado para evitar re-renders durante la edición
    const internalFormRef = useRef({
        referencias: [
            {
                nombre: '',
                domicilio: '',
                telefono: '',
                ocupacion: '',
                tiempoConocer: ''
            }
        ]
    });

    // Estado visible para forzar re-renders cuando sea necesario
    const [form, setForm] = useState(internalFormRef.current);

    // Estado para rastrear el último campo editado
    const [lastEditedField, setLastEditedField] = useState(null);

    // Referencia para el timer de debounce
    const debounceTimerRef = useRef(null);

    // Cargar datos iniciales si existen (solo una vez)
    useEffect(() => {
        if (datosIniciales && datosIniciales.length > 0) {
            // Actualizar la referencia interna
            internalFormRef.current = {
                referencias: datosIniciales.map(referencia => ({
                    ...referencia,
                    nombre: referencia.nombre || '',
                    domicilio: referencia.domicilio || '',
                    telefono: referencia.telefono || '',
                    ocupacion: referencia.ocupacion || '',
                    tiempoConocer: referencia.tiempoConocer || ''
                }))
            };

            // Actualizar el estado visible
            setForm({ ...internalFormRef.current });
        }
    }, []); // Dependencia vacía - solo se ejecuta al montar

    // Función para manejar el debounce en los cambios
    const debouncedUpdate = useCallback(() => {
        // Cancelar el timer anterior si existe
        if (debounceTimerRef.current) {
            clearTimeout(debounceTimerRef.current);
        }

        // Crear un nuevo timer para actualizar después de 3 segundos
        debounceTimerRef.current = setTimeout(() => {
            if (onUpdate) {
                onUpdate(internalFormRef.current.referencias);
            }
        }, 3000);
    }, [onUpdate]);

    // Limpiar el timer al desmontar
    useEffect(() => {
        return () => {
            if (debounceTimerRef.current) {
                clearTimeout(debounceTimerRef.current);
            }
        };
    }, []);

    // Función de sincronización - actualiza el estado visible a partir de la ref interna
    const syncFormState = useCallback(() => {
        setForm({ ...internalFormRef.current });
    }, []);

    // Handler para cambios en el formulario - actualiza la ref interna sin re-render
    const handleInputChange = useCallback((index, campo, valor) => {
        // Actualizar directamente la referencia sin causar re-render
        internalFormRef.current.referencias[index] = {
            ...internalFormRef.current.referencias[index],
            [campo]: valor
        };

        // Almacenar el campo que se está editando
        setLastEditedField(`${index}-${campo}`);

        // Programar el debounce
        debouncedUpdate();

        // Forzar actualización del estado visible
        setForm({ ...internalFormRef.current });
    }, [debouncedUpdate]);

    // Añadir nueva referencia
    const addReferenciaEntry = useCallback(() => {
        internalFormRef.current.referencias.push({
            nombre: '',
            domicilio: '',
            telefono: '',
            ocupacion: '',
            tiempoConocer: ''
        });

        // Forzar actualización del estado visible
        setForm({ ...internalFormRef.current });
        debouncedUpdate();
    }, [debouncedUpdate]);

    // Eliminar referencia
    const removeReferenciaEntry = useCallback((index) => {
        // Eliminar la entrada del array en la referencia interna
        internalFormRef.current.referencias.splice(index, 1);

        // Forzar actualización del estado visible
        setForm({ ...internalFormRef.current });
        debouncedUpdate();
    }, [debouncedUpdate]);

    // useEffect para sincronizar el ref con el estado visible periódicamente
    useEffect(() => {
        // Sincronizar el estado cada 5 segundos o cuando cambie lastEditedField
        const syncInterval = setInterval(syncFormState, 5000);

        // Limpieza al desmontar
        return () => {
            clearInterval(syncInterval);
        };
    }, [syncFormState, lastEditedField]);

    return (
        <div className="bg-white rounded-lg shadow-md p-4 md:p-6">
            <div className="mb-6">
                <h2 className="text-xl font-bold text-gray-800 mb-2">Referencias Personales</h2>
                <p className="text-sm text-gray-600 mb-2">
                    Favor de no incluir a jefes anteriores
                </p>
                <div className="text-xs text-blue-600 mt-1">
                    Los cambios se guardan automáticamente 3 segundos después de dejar de escribir.
                </div>
            </div>

            {form.referencias.map((referencia, index) => (
                <div
                    key={index}
                    className="border border-gray-200 rounded-lg mb-4 p-4 bg-gray-50 relative"
                >
                    {/* Botón de eliminar para referencias adicionales */}
                    {index > 0 && (
                        <button
                            onClick={() => removeReferenciaEntry(index)}
                            className="absolute top-2 right-2 text-red-500 hover:text-red-700 transition-colors"
                        >
                            <X className="h-5 w-5" />
                        </button>
                    )}

                    {/* Nombre */}
                    <div className="mb-4">
                        <label className="block text-sm font-medium text-gray-700 mb-1">
                            Nombre Completo
                        </label>
                        <div className="relative">
                            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                <User className="h-4 w-4 text-gray-400" />
                            </div>
                            <input
                                type="text"
                                value={referencia.nombre}
                                onChange={(e) => handleInputChange(index, 'nombre', e.target.value)}
                                placeholder="Nombre completo"
                                className="block w-full pl-10 py-2 sm:text-sm border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                            />
                        </div>
                    </div>

                    {/* Domicilio */}
                    <div className="mb-4">
                        <label className="block text-sm font-medium text-gray-700 mb-1">
                            Domicilio
                        </label>
                        <div className="relative">
                            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                <Home className="h-4 w-4 text-gray-400" />
                            </div>
                            <input
                                type="text"
                                value={referencia.domicilio}
                                onChange={(e) => handleInputChange(index, 'domicilio', e.target.value)}
                                placeholder="Dirección completa"
                                className="block w-full pl-10 py-2 sm:text-sm border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                            />
                        </div>
                    </div>

                    {/* Teléfono */}
                    <div className="mb-4">
                        <label className="block text-sm font-medium text-gray-700 mb-1">
                            Teléfono
                        </label>
                        <div className="relative">
                            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                <Phone className="h-4 w-4 text-gray-400" />
                            </div>
                            <input
                                type="text"
                                value={referencia.telefono}
                                onChange={(e) => handleInputChange(index, 'telefono', e.target.value)}
                                placeholder="Número de contacto"
                                className="block w-full pl-10 py-2 sm:text-sm border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                            />
                        </div>
                    </div>

                    {/* Ocupación */}
                    <div className="mb-4">
                        <label className="block text-sm font-medium text-gray-700 mb-1">
                            Ocupación
                        </label>
                        <div className="relative">
                            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                <Briefcase className="h-4 w-4 text-gray-400" />
                            </div>
                            <input
                                type="text"
                                value={referencia.ocupacion}
                                onChange={(e) => handleInputChange(index, 'ocupacion', e.target.value)}
                                placeholder="Profesión o trabajo"
                                className="block w-full pl-10 py-2 sm:text-sm border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                            />
                        </div>
                    </div>

                    {/* Tiempo de conocer */}
                    <div>
                        <label className="block text-sm font-medium text-gray-700 mb-1">
                            Tiempo de Conocer
                        </label>
                        <div className="relative">
                            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                <Calendar className="h-4 w-4 text-gray-400" />
                            </div>
                            <input
                                type="text"
                                value={referencia.tiempoConocer}
                                onChange={(e) => handleInputChange(index, 'tiempoConocer', e.target.value)}
                                placeholder="Ejemplo: 5 años"
                                className="block w-full pl-10 py-2 sm:text-sm border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                            />
                        </div>
                    </div>
                </div>
            ))}

            {/* Botón para Añadir Nueva Referencia */}
            <div className="mt-4">
                <button
                    onClick={addReferenciaEntry}
                    className="w-full bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 transition-colors flex items-center justify-center"
                >
                    <Plus className="h-5 w-5 mr-2" /> Añadir Referencia
                </button>
            </div>
        </div>
    );
};

export default ReferenciasPersonales;