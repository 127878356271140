import React, { useState, useEffect, useRef, useCallback } from "react";
import { School, MapPin, Calendar, Bookmark, BookOpen, GraduationCap } from "lucide-react";

const Escolaridad = ({ solicitudId, datosIniciales, onUpdate }) => {
  // Ref para mantener los datos del formulario sin causar re-renders
  const formDataRef = useRef({
    niveles: {
      primaria: {
        nombre: "",
        direccion: "",
        de: "",
        a: "",
        años: "",
        titulo: ""
      },
      secundaria: {
        nombre: "",
        direccion: "",
        de: "",
        a: "",
        años: "",
        titulo: ""
      },
      preparatoria: {
        nombre: "",
        direccion: "",
        de: "",
        a: "",
        años: "",
        titulo: ""
      },
      profesional: {
        nombre: "",
        direccion: "",
        de: "",
        a: "",
        años: "",
        titulo: ""
      },
      comercial: {
        nombre: "",
        direccion: "",
        de: "",
        a: "",
        años: "",
        titulo: ""
      }
    },
    actual: {
      escuela: "",
      horario: "",
      curso: "",
      grado: ""
    }
  });

  // Referencia para el timer de debounce
  const debounceTimerRef = useRef(null);

  // Estado para forzar re-render solo cuando sea absolutamente necesario
  const [renderCount, setRenderCount] = useState(0);

  // Cargar datos iniciales si existen (solo una vez)
  useEffect(() => {
    if (datosIniciales && Object.keys(datosIniciales).length > 0) {
      let newForm = { ...formDataRef.current };

      // Si los datos iniciales tienen la estructura correcta, usarlos directamente
      if (datosIniciales.niveles && datosIniciales.actual) {
        newForm = { ...datosIniciales };
      } else {
        // Si no tienen la estructura correcta, intentar adaptarlos
        if (datosIniciales.niveles) {
          newForm.niveles = { ...newForm.niveles, ...datosIniciales.niveles };
        }

        if (datosIniciales.actual) {
          newForm.actual = { ...newForm.actual, ...datosIniciales.actual };
        }

        // Si los datos vienen en formato plano, intentar mapearlos a la estructura
        const nivelesKeys = ["primaria", "secundaria", "preparatoria", "profesional", "comercial"];
        nivelesKeys.forEach(nivel => {
          if (datosIniciales[nivel]) {
            newForm.niveles[nivel] = { ...newForm.niveles[nivel], ...datosIniciales[nivel] };
          }
        });

        if (datosIniciales.escuela || datosIniciales.horario || datosIniciales.curso || datosIniciales.grado) {
          newForm.actual = {
            escuela: datosIniciales.escuela || newForm.actual.escuela,
            horario: datosIniciales.horario || newForm.actual.horario,
            curso: datosIniciales.curso || newForm.actual.curso,
            grado: datosIniciales.grado || newForm.actual.grado
          };
        }
      }

      // Actualizar la referencia
      formDataRef.current = newForm;

      // Forzar un re-render una sola vez para mostrar los valores iniciales
      setRenderCount(c => c + 1);
    }
  }, []); // Dependencia vacía - solo se ejecuta al montar

  // Función para manejar el debounce en los cambios y enviarlos solo después de un tiempo
  const debouncedUpdate = useCallback(() => {
    // Cancelar el timer anterior si existe
    if (debounceTimerRef.current) {
      clearTimeout(debounceTimerRef.current);
    }

    // Crear un nuevo timer para actualizar después de un retardo
    debounceTimerRef.current = setTimeout(() => {
      if (onUpdate) {
        onUpdate({ ...formDataRef.current });
      }
    }, 3000); // 3 segundos de demora
  }, [onUpdate]);

  // Limpiar el timer al desmontar
  useEffect(() => {
    return () => {
      if (debounceTimerRef.current) {
        clearTimeout(debounceTimerRef.current);
      }
    };
  }, []);

  // Handler para cambios en niveles educativos sin causar re-renders
  const handleNivelChange = useCallback((nivel, campo, valor) => {
    // Actualizar directamente la referencia sin causar re-render
    formDataRef.current.niveles[nivel][campo] = valor;

    // Programar el debounce para guardar los cambios
    debouncedUpdate();
  }, [debouncedUpdate]);

  // Handler para cambios en estudios actuales sin causar re-renders
  const handleEstudioActualChange = useCallback((campo, valor) => {
    // Actualizar directamente la referencia sin causar re-render
    formDataRef.current.actual[campo] = valor;

    // Programar el debounce para guardar los cambios
    debouncedUpdate();
  }, [debouncedUpdate]);

  // Componente para un nivel educativo usando refs para los valores
  const NivelEducativo = useCallback(({ tipo, label }) => {
    // Obtener los valores actuales directamente de la ref
    const nivelData = formDataRef.current.niveles[tipo];

    return (
      <div className="mb-8 p-4 bg-gray-50 rounded-lg shadow-sm">
        <div className="flex items-center mb-4">
          <GraduationCap className="h-5 w-5 text-blue-600 mr-2" />
          <h3 className="text-lg font-medium text-gray-800">{label}</h3>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
          <div className="space-y-2">
            <label htmlFor={`${tipo}-nombre`} className="block text-sm font-medium text-gray-700">
              Nombre de la Institución
            </label>
            <div className="relative rounded-md shadow-sm">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <School className="h-4 w-4 text-gray-400" />
              </div>
              <input
                type="text"
                id={`${tipo}-nombre`}
                className="block w-full pl-10 py-2 sm:text-sm border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                defaultValue={nivelData.nombre || ""}
                onChange={(e) => handleNivelChange(tipo, "nombre", e.target.value)}
                placeholder="Nombre de la institución"
              />
            </div>
          </div>

          <div className="space-y-2">
            <label htmlFor={`${tipo}-direccion`} className="block text-sm font-medium text-gray-700">
              Dirección
            </label>
            <div className="relative rounded-md shadow-sm">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <MapPin className="h-4 w-4 text-gray-400" />
              </div>
              <input
                type="text"
                id={`${tipo}-direccion`}
                className="block w-full pl-10 py-2 sm:text-sm border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                defaultValue={nivelData.direccion || ""}
                onChange={(e) => handleNivelChange(tipo, "direccion", e.target.value)}
                placeholder="Dirección completa"
              />
            </div>
          </div>
        </div>

        <div className="grid grid-cols-2 md:grid-cols-4 gap-4 mb-4">
          <div className="space-y-2">
            <label htmlFor={`${tipo}-de`} className="block text-sm font-medium text-gray-700">
              Año Inicio
            </label>
            <div className="relative rounded-md shadow-sm">
              <input
                type="number"
                id={`${tipo}-de`}
                min="1900"
                max="2100"
                className="block w-full py-2 px-3 sm:text-sm border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                defaultValue={nivelData.de || ""}
                onChange={(e) => handleNivelChange(tipo, "de", e.target.value)}
                placeholder="Ej: 2010"
              />
            </div>
          </div>

          <div className="space-y-2">
            <label htmlFor={`${tipo}-a`} className="block text-sm font-medium text-gray-700">
              Año Fin
            </label>
            <div className="relative rounded-md shadow-sm">
              <input
                type="number"
                id={`${tipo}-a`}
                min="1900"
                max="2100"
                className="block w-full py-2 px-3 sm:text-sm border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                defaultValue={nivelData.a || ""}
                onChange={(e) => handleNivelChange(tipo, "a", e.target.value)}
                placeholder="Ej: 2014"
              />
            </div>
          </div>

          <div className="space-y-2">
            <label htmlFor={`${tipo}-años`} className="block text-sm font-medium text-gray-700">
              Años Cursados
            </label>
            <div className="relative rounded-md shadow-sm">
              <input
                type="number"
                id={`${tipo}-años`}
                min="1"
                max="20"
                className="block w-full py-2 px-3 sm:text-sm border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                defaultValue={nivelData.años || ""}
                onChange={(e) => handleNivelChange(tipo, "años", e.target.value)}
                placeholder="Ej: 4"
              />
            </div>
          </div>

          <div className="space-y-2">
            <label htmlFor={`${tipo}-titulo`} className="block text-sm font-medium text-gray-700">
              Título Recibido
            </label>
            <div className="relative rounded-md shadow-sm">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <BookOpen className="h-4 w-4 text-gray-400" />
              </div>
              <input
                type="text"
                id={`${tipo}-titulo`}
                className="block w-full pl-10 py-2 sm:text-sm border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                defaultValue={nivelData.titulo || ""}
                onChange={(e) => handleNivelChange(tipo, "titulo", e.target.value)}
                placeholder="Título o certificado"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }, [handleNivelChange]); // Solo depende de handleNivelChange

  // Forzar un renderizado inicial para tomar los valores predeterminados
  return (
    <div className="bg-white rounded-md">
      <div className="mb-6">
        <h2 className="text-lg font-semibold text-gray-800 mb-2">Escolaridad</h2>
        <p className="text-sm text-gray-600">Por favor complete la información sobre su formación académica.</p>
        <div className="text-xs text-blue-600 mt-1">
          Los cambios se guardan automáticamente 3 segundos después de dejar de escribir.
        </div>
      </div>

      {/* Sección de niveles educativos - diseño responsivo para móvil */}
      <div className="mb-8">
        <h3 className="text-md font-medium text-gray-700 mb-4 border-b border-gray-200 pb-2">
          Historial Académico
        </h3>

        <NivelEducativo tipo="primaria" label="Primaria" />
        <NivelEducativo tipo="secundaria" label="Secundaria o Prevocacional" />
        <NivelEducativo tipo="preparatoria" label="Preparatoria o Vocacional" />
        <NivelEducativo tipo="profesional" label="Profesional" />
        <NivelEducativo tipo="comercial" label="Comercial u Otras" />
      </div>

      {/* Sección de estudios actuales */}
      <div className="mt-6 p-4 bg-gray-50 rounded-lg shadow-sm">
        <h3 className="text-md font-medium text-gray-700 mb-4 border-b border-gray-200 pb-2">
          Estudios que está efectuando en la actualidad
        </h3>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mt-3">
          <div className="space-y-2">
            <label htmlFor="escuela-actual" className="block text-sm font-medium text-gray-700">
              Escuela
            </label>
            <div className="relative rounded-md shadow-sm">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <School className="h-4 w-4 text-gray-400" />
              </div>
              <input
                type="text"
                id="escuela-actual"
                className="block w-full pl-10 py-2 sm:text-sm border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                defaultValue={formDataRef.current.actual.escuela || ""}
                onChange={(e) => handleEstudioActualChange("escuela", e.target.value)}
                placeholder="Nombre de la institución"
              />
            </div>
          </div>

          <div className="space-y-2">
            <label htmlFor="horario-actual" className="block text-sm font-medium text-gray-700">
              Horario
            </label>
            <div className="relative rounded-md shadow-sm">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <Calendar className="h-4 w-4 text-gray-400" />
              </div>
              <input
                type="text"
                id="horario-actual"
                className="block w-full pl-10 py-2 sm:text-sm border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                defaultValue={formDataRef.current.actual.horario || ""}
                onChange={(e) => handleEstudioActualChange("horario", e.target.value)}
                placeholder="Ej: Lun-Vie 18:00-21:00"
              />
            </div>
          </div>

          <div className="space-y-2">
            <label htmlFor="curso-actual" className="block text-sm font-medium text-gray-700">
              Curso o Carrera
            </label>
            <div className="relative rounded-md shadow-sm">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <BookOpen className="h-4 w-4 text-gray-400" />
              </div>
              <input
                type="text"
                id="curso-actual"
                className="block w-full pl-10 py-2 sm:text-sm border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                defaultValue={formDataRef.current.actual.curso || ""}
                onChange={(e) => handleEstudioActualChange("curso", e.target.value)}
                placeholder="Nombre del curso o carrera"
              />
            </div>
          </div>

          <div className="space-y-2">
            <label htmlFor="grado-actual" className="block text-sm font-medium text-gray-700">
              Grado
            </label>
            <div className="relative rounded-md shadow-sm">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <Bookmark className="h-4 w-4 text-gray-400" />
              </div>
              <input
                type="text"
                id="grado-actual"
                className="block w-full pl-10 py-2 sm:text-sm border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                defaultValue={formDataRef.current.actual.grado || ""}
                onChange={(e) => handleEstudioActualChange("grado", e.target.value)}
                placeholder="Semestre o año cursando"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Escolaridad;