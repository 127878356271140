import React, { useEffect, useState, useRef } from "react";
import { useParams, Link } from "react-router-dom";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "../../../firebase";
import QRCode from "qrcode.react";
import {
    User, ArrowLeft, Briefcase, Book, Clipboard, Heart,
    Users, Award, FileText, UserCheck, Download, Printer,
    QrCode, Eye, Check, Clock, X, AlertTriangle
} from "lucide-react";

// Importar el generador de PDF
import generarPDFCandidato from "./generarPDFCandidato";

// Importación de componentes de sección
import SeccionGeneral from "./secciones/SeccionGeneral";
import SeccionDatosPersonales from "./secciones/SeccionDatosPersonales";
import SeccionDocumentacion from "./secciones/SeccionDocumentacion";
import SeccionEstadoSalud from "./secciones/SeccionEstadoSalud";
import SeccionDatosFamiliares from "./secciones/SeccionDatosFamiliares";
import SeccionEscolaridad from "./secciones/SeccionEscolaridad";
import SeccionConocimientos from "./secciones/SeccionConocimientos";
import SeccionHistorialEmpleos from "./secciones/SeccionHistorialEmpleos";
import SeccionReferencias from "./secciones/SeccionReferencias";
import SeccionDatosGenerales from "./secciones/SeccionDatosGenerales";

const InfoCandidato = () => {
    const { uid } = useParams();
    const [candidato, setCandidato] = useState(null);
    const [loading, setLoading] = useState(true);
    const [seccionActiva, setSeccionActiva] = useState("todas");
    const [generandoPDF, setGenerandoPDF] = useState(false);
    const [qrVisible, setQrVisible] = useState(true);
    const [updatingStatus, setUpdatingStatus] = useState(false);
    const [statusActual, setStatusActual] = useState("pendiente");

    // Referencia al contenedor principal
    const contenedorRef = useRef(null);

    useEffect(() => {
        const fetchCandidato = async () => {
            setLoading(true);
            try {
                const docRef = doc(db, "Solicitudes", uid);
                const docSnap = await getDoc(docRef);
                if (docSnap.exists()) {
                    const data = docSnap.data();
                    setCandidato(data);
                    setStatusActual(data.status || "pendiente");
                } else {
                    console.error("No se encontró el candidato");
                }
            } catch (error) {
                console.error("Error al obtener el candidato:", error);
            }
            setLoading(false);
        };

        fetchCandidato();
    }, [uid]);

    // Función para actualizar el status del candidato
    const handleUpdateStatus = async (nuevoStatus) => {
        if (updatingStatus || statusActual === nuevoStatus) return;

        setUpdatingStatus(true);

        try {
            const docRef = doc(db, "Solicitudes", uid);
            await updateDoc(docRef, { status: nuevoStatus });
            setStatusActual(nuevoStatus);
            // Actualizar el objeto candidato local
            setCandidato(prevState => ({
                ...prevState,
                status: nuevoStatus
            }));
            console.log(`Status actualizado a: ${nuevoStatus}`);
        } catch (error) {
            console.error("Error al actualizar status:", error);
            alert("Error al actualizar el status. Intente nuevamente.");
        } finally {
            setUpdatingStatus(false);
        }
    };

    // Función para generar el PDF usando el componente externo
    const handleGenerarPDF = async () => {
        if (!candidato) return;

        setGenerandoPDF(true);

        try {
            await generarPDFCandidato(candidato, uid);
        } catch (error) {
            console.error("Error al generar PDF:", error);
            alert("Ocurrió un error al generar el PDF. Por favor intente nuevamente.");
        } finally {
            setGenerandoPDF(false);
        }
    };

    // Función auxiliar para formatear fechas
    const formatearFecha = (timestamp) => {
        if (!timestamp) return "No disponible";
        try {
            return new Date(timestamp.toDate()).toLocaleDateString("es-MX", {
                day: '2-digit',
                month: 'long',
                year: 'numeric'
            });
        } catch (error) {
            if (typeof timestamp === 'string') {
                return timestamp;
            }
            return "Formato de fecha inválido";
        }
    };

    // Función para obtener el color del status
    const getStatusColor = (status) => {
        switch (status) {
            case "entrevista": return "bg-green-100 text-green-800 border-green-200";
            case "pendiente": return "bg-yellow-100 text-yellow-800 border-yellow-200";
            case "descartado": return "bg-red-100 text-red-800 border-red-200";
            default: return "bg-gray-100 text-gray-800 border-gray-200";
        }
    };

    // Función para obtener el icono del status
    const getStatusIcon = (status) => {
        switch (status) {
            case "entrevista": return <Check className="h-5 w-5" />;
            case "pendiente": return <Clock className="h-5 w-5" />;
            case "descartado": return <X className="h-5 w-5" />;
            default: return <AlertTriangle className="h-5 w-5" />;
        }
    };

    // Configuración de las secciones
    const secciones = [
        { id: "todas", name: "Todas las secciones", icon: <Eye className="h-5 w-5" /> },
        { id: "general", name: "General", icon: <User className="h-5 w-5" /> },
        { id: "datosPersonales", name: "Datos Personales", icon: <User className="h-5 w-5" /> },
        { id: "documentacion", name: "Documentación", icon: <FileText className="h-5 w-5" /> },
        { id: "estadoSalud", name: "Estado de Salud", icon: <Heart className="h-5 w-5" /> },
        { id: "datosFamiliares", name: "Datos Familiares", icon: <Users className="h-5 w-5" /> },
        { id: "escolaridad", name: "Escolaridad", icon: <Book className="h-5 w-5" /> },
        { id: "conocimientos", name: "Conocimientos", icon: <Award className="h-5 w-5" /> },
        { id: "historialEmpleos", name: "Historial de Empleos", icon: <Briefcase className="h-5 w-5" /> },
        { id: "referencias", name: "Referencias", icon: <UserCheck className="h-5 w-5" /> },
        { id: "datosGenerales", name: "Datos Generales", icon: <Clipboard className="h-5 w-5" /> }
    ];

    // Componente de QR
    const QRCodeComponent = () => (
        <div className="bg-white shadow-md rounded-lg p-6 text-center">
            <h3 className="text-lg font-bold text-gray-900 mb-4 flex items-center justify-center">
                <QrCode className="h-5 w-5 text-blue-500 mr-2" />
                Código QR de Identificación
            </h3>
            <div className="flex flex-col items-center justify-center space-y-4">
                <QRCode
                    value={`https://app.example.com/candidatos/${uid}`}
                    size={180}
                    level="H"
                    includeMargin={true}
                />
                <div className="text-sm text-gray-600 mt-2">
                    <p>ID: <span className="font-semibold">{uid}</span></p>
                    <p>Folio: <span className="font-semibold">{uid.substring(0, 6).toUpperCase()}</span></p>
                </div>
            </div>
        </div>
    );

    if (loading) {
        return (
            <div className="flex justify-center items-center h-64">
                <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
                <p className="ml-3 text-gray-600">Cargando información...</p>
            </div>
        );
    }

    if (!candidato) {
        return (
            <div className="flex flex-col justify-center items-center h-64">
                <p className="text-gray-600 text-lg mb-4">No se encontró el candidato</p>
                <Link to="/candidatos" className="text-blue-600 hover:text-blue-800 flex items-center">
                    <ArrowLeft className="h-5 w-5 mr-2" /> Volver a lista de candidatos
                </Link>
            </div>
        );
    }

    return (
        <div className="w-full bg-gray-50 min-h-screen">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-6">
                {/* Encabezado con botones */}
                <div className="mb-6">
                    <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center gap-4 mb-4">
                        <Link to="/candidatos" className="text-blue-600 hover:text-blue-800 flex items-center">
                            <ArrowLeft className="h-5 w-5 mr-2" /> Volver a lista de candidatos
                        </Link>
                        <div className="text-center">
                            <h1 className="text-xl font-bold text-gray-800 sm:text-2xl">
                                {candidato.datosPersonales?.nombres || ""} {candidato.datosPersonales?.apellidoPaterno || ""} {candidato.datosPersonales?.apellidoMaterno || ""}
                            </h1>
                            <p className="text-sm text-gray-600">Folio: {uid.substring(0, 6).toUpperCase()}</p>
                        </div>
                        <div className="flex justify-center">
                            <div
                                className={`inline-flex items-center px-3 py-1.5 rounded-full text-sm font-medium ${getStatusColor(statusActual)} border`}
                            >
                                {getStatusIcon(statusActual)}
                                <span className="ml-2 capitalize">
                                    {statusActual === "entrevista" ? "Entrevista" :
                                        statusActual === "pendiente" ? "Pendiente" :
                                            statusActual === "descartado" ? "Descartado" : "Sin estado"}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Clasificador de Status */}
                <div className="bg-white shadow-md rounded-lg p-4 mb-6">
                    <h3 className="text-lg font-semibold text-gray-800 mb-3">Estado del candidato</h3>
                    <div className="flex flex-wrap items-center gap-3">
                        <div className="flex items-center text-sm text-gray-500 mr-3">
                            <span>Cambiar estado:</span>
                        </div>
                        <div className="flex border divide-x rounded-md overflow-hidden shadow-sm">
                            <button
                                disabled={updatingStatus}
                                onClick={() => handleUpdateStatus("entrevista")}
                                className={`flex items-center px-4 py-2 ${statusActual === "entrevista"
                                        ? "bg-green-100 text-green-700 font-medium"
                                        : "hover:bg-green-50 text-green-600 hover:text-green-700"
                                    } transition-colors duration-150 ease-in-out`}
                                title="Marcar para entrevista"
                            >
                                <Check className="h-5 w-5 mr-2" />
                                <span>Entrevista</span>
                            </button>
                            <button
                                disabled={updatingStatus}
                                onClick={() => handleUpdateStatus("pendiente")}
                                className={`flex items-center px-4 py-2 ${statusActual === "pendiente"
                                        ? "bg-yellow-100 text-yellow-700 font-medium"
                                        : "hover:bg-yellow-50 text-yellow-600 hover:text-yellow-700"
                                    } transition-colors duration-150 ease-in-out`}
                                title="Marcar como pendiente"
                            >
                                <Clock className="h-5 w-5 mr-2" />
                                <span>Pendiente</span>
                            </button>
                            <button
                                disabled={updatingStatus}
                                onClick={() => handleUpdateStatus("descartado")}
                                className={`flex items-center px-4 py-2 ${statusActual === "descartado"
                                        ? "bg-red-100 text-red-700 font-medium"
                                        : "hover:bg-red-50 text-red-600 hover:text-red-700"
                                    } transition-colors duration-150 ease-in-out`}
                                title="Descartar candidato"
                            >
                                <X className="h-5 w-5 mr-2" />
                                <span>Descartado</span>
                            </button>
                        </div>
                        {updatingStatus && (
                            <div className="flex items-center ml-3 text-blue-600">
                                <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-blue-600 mr-2"></div>
                                <span className="text-sm">Actualizando...</span>
                            </div>
                        )}
                    </div>
                </div>

                {/* Barra de acciones */}
                <div className="bg-white shadow-md rounded-lg p-4 mb-6 flex flex-wrap justify-center gap-3">
                    <button
                        onClick={() => setQrVisible(!qrVisible)}
                        className="flex-grow sm:flex-grow-0 flex items-center justify-center px-6 py-3 bg-blue-100 text-blue-700 rounded-md hover:bg-blue-200 focus:outline-none focus:ring-2 focus:ring-blue-500"
                    >
                        <QrCode className="h-5 w-5 mr-2" />
                        {qrVisible ? "Ocultar QR" : "Mostrar QR"}
                    </button>

                    <button
                        onClick={handleGenerarPDF}
                        disabled={generandoPDF}
                        className="flex-grow sm:flex-grow-0 flex items-center justify-center px-6 py-3 bg-green-600 text-white rounded-md hover:bg-green-700 disabled:opacity-50 disabled:cursor-not-allowed focus:outline-none focus:ring-2 focus:ring-green-500"
                    >
                        {generandoPDF ? (
                            <>
                                <div className="animate-spin rounded-full h-5 w-5 border-t-2 border-b-2 border-white mr-2"></div>
                                Generando PDF...
                            </>
                        ) : (
                            <>
                                <Download className="h-5 w-5 mr-2" />
                                Descargar PDF
                            </>
                        )}
                    </button>

                    <button
                        onClick={() => window.print()}
                        className="flex-grow sm:flex-grow-0 flex items-center justify-center px-6 py-3 bg-gray-600 text-white rounded-md hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500"
                    >
                        <Printer className="h-5 w-5 mr-2" />
                        Imprimir
                    </button>
                </div>

                <div className="flex flex-col lg:flex-row gap-6">
                    {/* Sidebar/Navegación */}
                    <div className="lg:w-64 flex-shrink-0 print:hidden">
                        <div className="bg-white shadow-md rounded-lg p-4 sticky top-4">
                            <h3 className="font-semibold text-gray-800 mb-4">Información del Candidato</h3>
                            <nav className="space-y-1">
                                {secciones.map((seccion) => (
                                    <button
                                        key={seccion.id}
                                        onClick={() => setSeccionActiva(seccion.id)}
                                        className={`flex items-center px-3 py-2 text-sm font-medium rounded-md w-full ${seccionActiva === seccion.id
                                            ? "bg-blue-50 text-blue-700"
                                            : "text-gray-600 hover:bg-gray-50"
                                            }`}
                                    >
                                        <span className="mr-3">{seccion.icon}</span>
                                        {seccion.name}
                                    </button>
                                ))}
                            </nav>

                            {/* QR Code en la sidebar */}
                            {qrVisible && (
                                <div className="mt-6 print:hidden">
                                    <QRCodeComponent />
                                </div>
                            )}
                        </div>
                    </div>

                    {/* Contenido principal */}
                    <div className="flex-1 space-y-6" ref={contenedorRef}>
                        {/* Título de la solicitud */}
                        <div className="bg-white shadow-md rounded-lg p-6 mb-4 text-center">
                            <h1 className="text-2xl font-bold text-gray-900 mb-2">
                                Solicitud de Empleo
                            </h1>
                            <h2 className="text-xl">
                                {candidato.datosPersonales?.nombres || ""} {candidato.datosPersonales?.apellidoPaterno || ""} {candidato.datosPersonales?.apellidoMaterno || ""}
                            </h2>
                            <p className="text-gray-600">Fecha de solicitud: {formatearFecha(candidato.createdAt)}</p>
                            <p className="text-gray-600">Folio: {uid.substring(0, 6).toUpperCase()}</p>
                        </div>

                        {/* Mostrar QR cuando está visible en dispositivos pequeños */}
                        {qrVisible && window.innerWidth < 1024 && (
                            <div className="mb-6 lg:hidden">
                                <QRCodeComponent />
                            </div>
                        )}

                        {/* Renderizar las secciones según la selección */}
                        {seccionActiva === "todas" ? (
                            <>
                                <SeccionGeneral candidato={candidato} formatearFecha={formatearFecha} />
                                <SeccionDatosPersonales candidato={candidato} />
                                <SeccionDocumentacion candidato={candidato} />
                                <SeccionEstadoSalud candidato={candidato} />
                                <SeccionDatosFamiliares candidato={candidato} />
                                <SeccionEscolaridad candidato={candidato} />
                                <SeccionConocimientos candidato={candidato} />
                                <SeccionHistorialEmpleos candidato={candidato} formatearFecha={formatearFecha} />
                                <SeccionReferencias candidato={candidato} />
                                <SeccionDatosGenerales candidato={candidato} />
                            </>
                        ) : (
                            <>
                                {seccionActiva === "general" && <SeccionGeneral candidato={candidato} formatearFecha={formatearFecha} />}
                                {seccionActiva === "datosPersonales" && <SeccionDatosPersonales candidato={candidato} />}
                                {seccionActiva === "documentacion" && <SeccionDocumentacion candidato={candidato} />}
                                {seccionActiva === "estadoSalud" && <SeccionEstadoSalud candidato={candidato} />}
                                {seccionActiva === "datosFamiliares" && <SeccionDatosFamiliares candidato={candidato} />}
                                {seccionActiva === "escolaridad" && <SeccionEscolaridad candidato={candidato} />}
                                {seccionActiva === "conocimientos" && <SeccionConocimientos candidato={candidato} />}
                                {seccionActiva === "historialEmpleos" && <SeccionHistorialEmpleos candidato={candidato} formatearFecha={formatearFecha} />}
                                {seccionActiva === "referencias" && <SeccionReferencias candidato={candidato} />}
                                {seccionActiva === "datosGenerales" && <SeccionDatosGenerales candidato={candidato} />}
                            </>
                        )}
                    </div>
                </div>

                {/* Botón flotante para la versión móvil */}
                <div className="fixed bottom-6 right-6 lg:hidden z-10">
                    <button
                        onClick={handleGenerarPDF}
                        disabled={generandoPDF}
                        className="flex items-center justify-center w-14 h-14 rounded-full bg-green-600 text-white shadow-lg hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                    >
                        {generandoPDF ? (
                            <div className="animate-spin rounded-full h-6 w-6 border-t-2 border-b-2 border-white"></div>
                        ) : (
                            <Download className="h-6 w-6" />
                        )}
                    </button>
                </div>

                {/* Estilo de impresión - se aplica cuando el usuario imprime */}
                <style>
                    {`
            @media print {
              body {
                font-size: 12pt;
                background-color: white !important;
              }
              .print\\:hidden {
                display: none !important;
              }
              .shadow-md, .shadow-lg {
                box-shadow: none !important;
              }
              .space-y-6 > * + * {
                margin-top: 1.5rem;
              }
              .page-break {
                page-break-before: always;
              }
            }
            `}
                </style>
            </div>
        </div>
    );
};

export default InfoCandidato;