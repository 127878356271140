import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { collection, query, orderBy, onSnapshot } from "firebase/firestore";
import { db } from "../../firebase";
import {
    PlusCircle,
    Clipboard,
    Calendar,
    User,
    Briefcase,
    ChevronRight,
    RefreshCcw,
    Search,
    Filter
} from "lucide-react";

const ProjectsList = () => {
    const navigate = useNavigate();

    // Estados
    const [projects, setProjects] = useState([]);
    const [filteredProjects, setFilteredProjects] = useState([]);
    const [loading, setLoading] = useState(true);
    const [searchTerm, setSearchTerm] = useState("");
    const [typeFilter, setTypeFilter] = useState("");
    const [statusFilter, setStatusFilter] = useState("");

    // Cargar proyectos
    useEffect(() => {
        const projectsRef = collection(db, "projects");
        const q = query(projectsRef, orderBy("createdAt", "desc"));

        const unsubscribe = onSnapshot(q, (snapshot) => {
            const projectsData = snapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data()
            }));
            setProjects(projectsData);
            setFilteredProjects(projectsData);
            setLoading(false);
        }, (error) => {
            console.error("Error al cargar proyectos:", error);
            setLoading(false);
        });

        return () => unsubscribe();
    }, []);

    // Filtrar proyectos
    useEffect(() => {
        let results = [...projects];

        // Aplicar búsqueda
        if (searchTerm) {
            const searchLower = searchTerm.toLowerCase();
            results = results.filter(project =>
                (project.name?.toLowerCase().includes(searchLower)) ||
                (project.client?.toLowerCase().includes(searchLower))
            );
        }

        // Aplicar filtro por tipo
        if (typeFilter) {
            results = results.filter(project => project.projectType === typeFilter);
        }

        // Aplicar filtro por estado
        if (statusFilter) {
            let filterProgress;
            switch (statusFilter) {
                case "completed":
                    filterProgress = project => project.progress === 100;
                    break;
                case "inProgress":
                    filterProgress = project => project.progress > 0 && project.progress < 100;
                    break;
                case "notStarted":
                    filterProgress = project => project.progress === 0;
                    break;
                default:
                    filterProgress = () => true;
            }
            results = results.filter(filterProgress);
        }

        setFilteredProjects(results);
    }, [projects, searchTerm, typeFilter, statusFilter]);

    // Crear nuevo proyecto
    const handleCreateProject = () => {
        navigate("/newproject");
    };

    // Formatear fecha
    const formatDate = (dateString) => {
        if (!dateString) return "No especificada";
        const date = new Date(dateString);
        return date.toLocaleDateString('es-MX', {
            day: 'numeric',
            month: 'short',
            year: 'numeric'
        });
    };

    // Agrupar proyectos por estado
    const groupedProjects = {
        inProgress: filteredProjects.filter(p => p.progress > 0 && p.progress < 100),
        completed: filteredProjects.filter(p => p.progress === 100),
        notStarted: filteredProjects.filter(p => p.progress === 0)
    };

    // Renderizar proyecto individual
    const renderProjectCard = (project) => (
        <Link
            key={project.id}
            to={`/project/${project.id}`}
            className="block border border-gray-200 rounded-lg hover:bg-gray-50 transition-colors duration-150 mb-4 overflow-hidden"
        >
            <div className="p-5">
                <div className="flex justify-between">
                    <h3 className="text-lg font-medium text-gray-900">{project.name}</h3>
                    <ChevronRight className="h-5 w-5 text-gray-400" />
                </div>

                <div className="mt-2 grid grid-cols-1 md:grid-cols-3 gap-4">
                    <div className="flex items-center text-sm text-gray-600">
                        <User className="h-4 w-4 text-gray-400 mr-1.5" />
                        <span>{project.client}</span>
                    </div>

                    <div className="flex items-center text-sm text-gray-600">
                        <Calendar className="h-4 w-4 text-gray-400 mr-1.5" />
                        <span>Entrega: {formatDate(project.dueDate)}</span>
                    </div>

                    <div className="flex items-center text-sm text-gray-600">
                        <Briefcase className="h-4 w-4 text-gray-400 mr-1.5" />
                        <span>{project.projectType}</span>
                    </div>
                </div>

                <div className="mt-4">
                    <div className="flex justify-between items-center mb-1.5">
                        <span className="text-sm font-medium text-gray-700">
                            {project.completedUnits || 0} de {project.unitCount || 0} unidades
                        </span>
                        <span className="text-sm text-gray-500">{project.progress || 0}%</span>
                    </div>
                    <div className="w-full bg-gray-200 rounded-full h-2">
                        <div
                            className={`h-2 rounded-full ${project.progress === 100
                                    ? 'bg-green-500'
                                    : project.progress > 60
                                        ? 'bg-blue-500'
                                        : project.progress > 30
                                            ? 'bg-yellow-500'
                                            : 'bg-orange-500'
                                }`}
                            style={{ width: `${project.progress || 0}%` }}
                        ></div>
                    </div>
                </div>
            </div>
        </Link>
    );

    return (
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
            <div className="flex justify-between items-center mb-6">
                <h1 className="text-2xl font-bold text-gray-900">Proyectos</h1>

                <button
                    onClick={handleCreateProject}
                    className="px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 flex items-center"
                >
                    <PlusCircle className="h-4 w-4 mr-2" />
                    Nuevo Proyecto
                </button>
            </div>

            {/* Filtros y búsqueda */}
            <div className="bg-white p-4 rounded-lg shadow-sm mb-6">
                <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                    <div className="relative">
                        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                            <Search className="h-5 w-5 text-gray-400" />
                        </div>
                        <input
                            type="text"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            placeholder="Buscar por nombre o cliente"
                            className="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                        />
                    </div>

                    <div className="flex items-center">
                        <Filter className="h-5 w-5 text-gray-400 mr-2" />
                        <select
                            value={typeFilter}
                            onChange={(e) => setTypeFilter(e.target.value)}
                            className="block w-full py-2 px-3 border border-gray-300 bg-white rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                        >
                            <option value="">Todos los tipos</option>
                            <option value="Equipamiento Vehicular">Equipamiento Vehicular</option>
                            <option value="Instalación">Instalación</option>
                            <option value="Mantenimiento">Mantenimiento</option>
                            <option value="Repuestos">Repuestos</option>
                            <option value="Integración">Integración</option>
                            <option value="Otro">Otro</option>
                        </select>
                    </div>

                    <div className="flex items-center">
                        <Filter className="h-5 w-5 text-gray-400 mr-2" />
                        <select
                            value={statusFilter}
                            onChange={(e) => setStatusFilter(e.target.value)}
                            className="block w-full py-2 px-3 border border-gray-300 bg-white rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                        >
                            <option value="">Todos los estados</option>
                            <option value="inProgress">En progreso</option>
                            <option value="completed">Completados</option>
                            <option value="notStarted">No iniciados</option>
                        </select>
                    </div>
                </div>
            </div>

            {/* Contenido de proyectos */}
            {loading ? (
                <div className="flex justify-center items-center h-64">
                    <RefreshCcw className="h-8 w-8 text-blue-500 animate-spin" />
                    <span className="ml-2 text-gray-600">Cargando proyectos...</span>
                </div>
            ) : filteredProjects.length === 0 ? (
                <div className="bg-white rounded-lg shadow-sm p-12 text-center">
                    <Clipboard className="h-12 w-12 text-gray-400 mx-auto mb-4" />
                    <h3 className="text-lg font-medium text-gray-900 mb-2">No hay proyectos</h3>
                    <p className="text-gray-500 mb-6">No se encontraron proyectos que coincidan con tus criterios de búsqueda.</p>
                    <button
                        onClick={() => {
                            setSearchTerm("");
                            setTypeFilter("");
                            setStatusFilter("");
                        }}
                        className="inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                    >
                        Limpiar filtros
                    </button>
                </div>
            ) : statusFilter ? (
                <div className="space-y-6">
                    {filteredProjects.map(renderProjectCard)}
                </div>
            ) : (
                <div className="space-y-10">
                    {/* Proyectos en progreso */}
                    {groupedProjects.inProgress.length > 0 && (
                        <div>
                            <h2 className="text-lg font-medium text-gray-900 mb-4">En Progreso</h2>
                            <div className="space-y-4">
                                {groupedProjects.inProgress.map(renderProjectCard)}
                            </div>
                        </div>
                    )}

                    {/* Proyectos no iniciados */}
                    {groupedProjects.notStarted.length > 0 && (
                        <div>
                            <h2 className="text-lg font-medium text-gray-900 mb-4">No Iniciados</h2>
                            <div className="space-y-4">
                                {groupedProjects.notStarted.map(renderProjectCard)}
                            </div>
                        </div>
                    )}

                    {/* Proyectos completados */}
                    {groupedProjects.completed.length > 0 && (
                        <div>
                            <h2 className="text-lg font-medium text-gray-900 mb-4">Completados</h2>
                            <div className="space-y-4">
                                {groupedProjects.completed.map(renderProjectCard)}
                            </div>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default ProjectsList;