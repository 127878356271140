import React, { useState, useEffect } from "react";
import { Heart, Activity, Users, Coffee } from "lucide-react";

const EstadoSalud = ({ solicitudId, datosIniciales, onUpdate }) => {
  const [form, setForm] = useState({
    estadoSalud: "",
    enfermedadCronica: "No",
    detalleEnfermedad: "",
    deporte: "",
    clubSocial: "",
    pasatiempo: "",
    metaVida: ""
  });

  useEffect(() => {
    if (datosIniciales && Object.keys(datosIniciales).length > 0) {
      setForm(prevForm => ({
        ...prevForm,
        ...datosIniciales
      }));
    }
  }, [datosIniciales]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    const updatedForm = { ...form, [name]: value };
    setForm(updatedForm);

    if (onUpdate) {
      onUpdate(updatedForm);
    }
  };

  const handleRadioChange = (e) => {
    const { name, value } = e.target;
    const updatedForm = { ...form, [name]: value };
    setForm(updatedForm);

    if (onUpdate) {
      onUpdate(updatedForm);
    }
  };

  return (
    <div className="bg-white rounded-md">
      <div className="mb-6">
       
        <p className="text-sm text-gray-600">Esta información nos ayuda a conocerle mejor.</p>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {/* Estado de salud */}
        <div className="space-y-3">
          <label className="block text-sm font-medium text-gray-700">
            ¿Cómo considera su estado de salud actual?
          </label>
          <div className="relative rounded-md">
            <div className="flex items-center space-x-6">
              <div className="flex items-center">
                <input
                  id="bueno"
                  name="estadoSalud"
                  type="radio"
                  value="Bueno"
                  checked={form.estadoSalud === "Bueno"}
                  onChange={handleRadioChange}
                  className="h-4 w-4 text-blue-600 border-gray-300 focus:ring-blue-500"
                />
                <label htmlFor="bueno" className="ml-2 block text-sm text-gray-700">
                  Bueno
                </label>
              </div>
              <div className="flex items-center">
                <input
                  id="regular"
                  name="estadoSalud"
                  type="radio"
                  value="Regular"
                  checked={form.estadoSalud === "Regular"}
                  onChange={handleRadioChange}
                  className="h-4 w-4 text-blue-600 border-gray-300 focus:ring-blue-500"
                />
                <label htmlFor="regular" className="ml-2 block text-sm text-gray-700">
                  Regular
                </label>
              </div>
              <div className="flex items-center">
                <input
                  id="malo"
                  name="estadoSalud"
                  type="radio"
                  value="Malo"
                  checked={form.estadoSalud === "Malo"}
                  onChange={handleRadioChange}
                  className="h-4 w-4 text-blue-600 border-gray-300 focus:ring-blue-500"
                />
                <label htmlFor="malo" className="ml-2 block text-sm text-gray-700">
                  Malo
                </label>
              </div>
            </div>
          </div>
        </div>

        {/* Enfermedad crónica */}
        <div className="space-y-3">
          <label className="block text-sm font-medium text-gray-700">
            ¿Padece alguna enfermedad crónica?
          </label>
          <div className="flex items-center space-x-6">
            <div className="flex items-center">
              <input
                id="no-enfermedad"
                name="enfermedadCronica"
                type="radio"
                value="No"
                checked={form.enfermedadCronica === "No"}
                onChange={handleRadioChange}
                className="h-4 w-4 text-blue-600 border-gray-300 focus:ring-blue-500"
              />
              <label htmlFor="no-enfermedad" className="ml-2 block text-sm text-gray-700">
                No
              </label>
            </div>
            <div className="flex items-center">
              <input
                id="si-enfermedad"
                name="enfermedadCronica"
                type="radio"
                value="Si"
                checked={form.enfermedadCronica === "Si"}
                onChange={handleRadioChange}
                className="h-4 w-4 text-blue-600 border-gray-300 focus:ring-blue-500"
              />
              <label htmlFor="si-enfermedad" className="ml-2 block text-sm text-gray-700">
                Sí (Explique)
              </label>
            </div>
          </div>
          
          {form.enfermedadCronica === "Si" && (
            <div className="relative mt-2">
              <div className="relative rounded-md shadow-sm">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <Heart className="h-5 w-5 text-gray-400" />
                </div>
                <input
                  type="text"
                  id="detalleEnfermedad"
                  name="detalleEnfermedad"
                  className="block w-full pl-10 py-2 sm:text-sm border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                  placeholder="Describa su enfermedad"
                  value={form.detalleEnfermedad || ""}
                  onChange={handleChange}
                />
              </div>
            </div>
          )}
        </div>

        {/* Deporte */}
        <div className="space-y-3">
          <label htmlFor="deporte" className="block text-sm font-medium text-gray-700">
            ¿Práctica Ud. Algún Deporte?
          </label>
          <div className="relative rounded-md shadow-sm">
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <Activity className="h-5 w-5 text-gray-400" />
            </div>
            <input
              type="text"
              id="deporte"
              name="deporte"
              className="block w-full pl-10 py-2 sm:text-sm border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
              placeholder="Especifique si practica algún deporte"
              value={form.deporte || ""}
              onChange={handleChange}
            />
          </div>
        </div>

        {/* Club social */}
        <div className="space-y-3">
          <label htmlFor="clubSocial" className="block text-sm font-medium text-gray-700">
            ¿Pertenece a algún Club Social o Deportivo?
          </label>
          <div className="relative rounded-md shadow-sm">
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <Users className="h-5 w-5 text-gray-400" />
            </div>
            <input
              type="text"
              id="clubSocial"
              name="clubSocial"
              className="block w-full pl-10 py-2 sm:text-sm border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
              placeholder="Nombre del club o asociación"
              value={form.clubSocial || ""}
              onChange={handleChange}
            />
          </div>
        </div>

        {/* Pasatiempo */}
        <div className="space-y-3">
          <label htmlFor="pasatiempo" className="block text-sm font-medium text-gray-700">
            ¿Cuál es su pasatiempo favorito?
          </label>
          <div className="relative rounded-md shadow-sm">
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <Coffee className="h-5 w-5 text-gray-400" />
            </div>
            <input
              type="text"
              id="pasatiempo"
              name="pasatiempo"
              className="block w-full pl-10 py-2 sm:text-sm border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
              placeholder="Describa su pasatiempo o hobby"
              value={form.pasatiempo || ""}
              onChange={handleChange}
            />
          </div>
        </div>

        {/* Meta en la vida */}
        <div className="md:col-span-2 space-y-3">
          <label htmlFor="metaVida" className="block text-sm font-medium text-gray-700">
            ¿Cuál es su meta en la vida?
          </label>
          <textarea
            id="metaVida"
            name="metaVida"
            rows={3}
            className="block w-full py-2 px-3 sm:text-sm border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
            placeholder="Describa sus aspiraciones y metas personales"
            value={form.metaVida || ""}
            onChange={handleChange}
          />
        </div>
      </div>

      {/* Nota informativa */}
      <div className="mt-6 p-4 bg-gray-50 border-l-4 border-blue-400 rounded">
        <div className="flex">
          <div className="flex-shrink-0">
            <svg className="h-5 w-5 text-blue-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd" />
            </svg>
          </div>
          <div className="ml-3">
            <p className="text-sm text-gray-700">
              Esta información es confidencial y será utilizada únicamente para conocer mejor su perfil. No afectará el proceso de su solicitud.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EstadoSalud;