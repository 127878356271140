import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useUserAuth } from "./context/userAuthContext";
import { Menu, X, User, ChevronDown } from "lucide-react";

// Componente para agregar espacio después del navbar
const NavbarSpacer = () => {
  return <div className="h-20"></div>;
};

const Navbar = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const { user } = useUserAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const handleAvatarClick = () => {
    if (user) {
      navigate("/home");
    } else {
      navigate("/login");
    }
  };

  // Detectar scroll para cambiar la apariencia del navbar
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 20) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Cerrar drawer al cambiar de ruta
  useEffect(() => {
    setIsDrawerOpen(false);
  }, [location.pathname]);

  const menuItems = [
    { text: "Inicio", path: "/" },
    { text: "Equipamiento", path: "/contacto" },
    { text: "Radiocomunicación", path: "/radios" },
    { text: "Contacto", path: "/contacto" },
  ];

  const isActive = (path) => {
    return location.pathname === path;
  };

  return (
    <>
      <nav
        className={`fixed top-0 left-0 right-0 z-50 transition-all duration-300 ${
          isScrolled ? "bg-white shadow-md py-2" : "bg-white py-3"
        }`}
      >
        <div className="max-w-7xl mx-auto px-4">
          <div className="flex items-center justify-between h-14">
            {/* Logo - Visible en todos los tamaños */}
            <div className="flex-shrink-0">
              <Link to="/" className="flex items-center">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/radiosistemas-a46cd.appspot.com/o/LogoRS.png?alt=media&token=0132c19f-8a46-4d45-8166-6905d743da2d"
                  alt="Radio Sistemas"
                  className="h-10 w-auto"
                />
              </Link>
            </div>

            {/* Menú para escritorio */}
            <div className="hidden md:flex items-center space-x-1">
              {menuItems.map((item, index) => (
                <Link
                  key={index}
                  to={item.path}
                  className={`px-3 py-2 rounded-md text-sm font-medium transition-colors duration-200 ${
                    isActive(item.path)
                      ? "text-blue-700 bg-blue-50"
                      : "text-gray-700 hover:text-blue-700 hover:bg-gray-50"
                  }`}
                >
                  {item.text}
                </Link>
              ))}
            </div>

            {/* Botón de usuario y menú móvil */}
            <div className="flex items-center space-x-2">
              {/* Avatar/Perfil de usuario */}
              <div className="relative">
                <button
                  onClick={() => setDropdownOpen(!dropdownOpen)}
                  className="flex items-center space-x-1 rounded-full focus:outline-none"
                >
                  <div
                    className="w-10 h-10 rounded-full border-2 border-gray-200 overflow-hidden flex items-center justify-center bg-gray-100"
                    aria-label="Perfil de usuario"
                  >
                    {user && user.photoURL ? (
                      <img
                        src={user.photoURL}
                        alt={user.displayName || "Usuario"}
                        className="w-full h-full object-cover"
                      />
                    ) : (
                      <User className="w-5 h-5 text-gray-500" />
                    )}
                  </div>
                  <ChevronDown className="w-4 h-4 text-gray-500 hidden md:block" />
                </button>

                {/* Dropdown del perfil */}
                {dropdownOpen && (
                  <div
                    className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg py-1 z-50 border border-gray-200"
                    onMouseLeave={() => setDropdownOpen(false)}
                  >
                    {user ? (
                      <>
                        <div className="px-4 py-2 border-b border-gray-100">
                          <p className="text-sm font-medium text-gray-900 truncate">
                            {user.displayName || user.email}
                          </p>
                          <p className="text-xs text-gray-500 truncate">
                            {user.email}
                          </p>
                        </div>
                        <Link
                          to="/home"
                          className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                        >
                          Panel de control
                        </Link>
                        <Link
                          to="/profile"
                          className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                        >
                          Mi perfil
                        </Link>
                        <button
                          onClick={() => {
                            /* Función de logout */
                          }}
                          className="block w-full text-left px-4 py-2 text-sm text-red-600 hover:bg-gray-100"
                        >
                          Cerrar sesión
                        </button>
                      </>
                    ) : (
                      <>
                        <Link
                          to="/login"
                          className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                        >
                          Iniciar sesión
                        </Link>
                        <Link
                          to="/registro"
                          className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                        >
                          Registrarse
                        </Link>
                      </>
                    )}
                  </div>
                )}
              </div>

              {/* Botón de menú móvil */}
              <button
                onClick={toggleDrawer}
                type="button"
                className="md:hidden inline-flex items-center justify-center p-2 rounded-md text-gray-700 hover:text-blue-700 hover:bg-gray-100 focus:outline-none"
                aria-expanded="false"
              >
                <span className="sr-only">Abrir menú principal</span>
                {isDrawerOpen ? (
                  <X className="block h-6 w-6" aria-hidden="true" />
                ) : (
                  <Menu className="block h-6 w-6" aria-hidden="true" />
                )}
              </button>
            </div>
          </div>
        </div>

        {/* Menú móvil */}
        <div
          className={`md:hidden transition-all duration-300 ease-in-out ${
            isDrawerOpen
              ? "max-h-screen opacity-100"
              : "max-h-0 opacity-0 overflow-hidden"
          }`}
        >
          <div className="px-2 pt-2 pb-3 space-y-1 bg-white shadow-lg">
            {menuItems.map((item, index) => (
              <Link
                key={index}
                to={item.path}
                className={`block px-3 py-2 rounded-md text-base font-medium ${
                  isActive(item.path)
                    ? "text-blue-700 bg-blue-50"
                    : "text-gray-700 hover:text-blue-700 hover:bg-gray-50"
                }`}
              >
                {item.text}
              </Link>
            ))}

            {/* Sección adicional de usuario en menú móvil */}
            <div className="pt-4 pb-3 border-t border-gray-200">
              <div className="flex items-center px-3">
                <div className="flex-shrink-0">
                  <div className="w-10 h-10 rounded-full bg-gray-100 flex items-center justify-center">
                    {user && user.photoURL ? (
                      <img
                        src={user.photoURL}
                        alt={user.displayName || "Usuario"}
                        className="w-full h-full object-cover rounded-full"
                      />
                    ) : (
                      <User className="w-5 h-5 text-gray-500" />
                    )}
                  </div>
                </div>
                <div className="ml-3">
                  <div className="text-base font-medium text-gray-800">
                    {user ? user.displayName || "Usuario" : "Invitado"}
                  </div>
                  <div className="text-sm font-medium text-gray-500">
                    {user ? user.email : "Inicia sesión para acceder"}
                  </div>
                </div>
              </div>
              <div className="mt-3 space-y-1 px-2">
                {user ? (
                  <>
                    <Link
                      to="/home"
                      className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-blue-700 hover:bg-gray-50"
                    >
                      Panel de control
                    </Link>
                    <Link
                      to="/profile"
                      className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-blue-700 hover:bg-gray-50"
                    >
                      Mi perfil
                    </Link>
                    <button
                      onClick={() => {
                        /* Función de logout */
                      }}
                      className="block w-full text-left px-3 py-2 rounded-md text-base font-medium text-red-600 hover:bg-gray-50"
                    >
                      Cerrar sesión
                    </button>
                  </>
                ) : (
                  <Link
                    to="/login"
                    className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-blue-700 hover:bg-gray-50"
                  >
                    Iniciar sesión
                  </Link>
                )}
              </div>
            </div>
          </div>
        </div>
      </nav>

      {/* Espaciador para evitar que el contenido se oculte debajo del navbar */}
      <NavbarSpacer />
    </>
  );
};

export default Navbar;
