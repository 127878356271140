import React, { useState, useEffect, useRef, useCallback } from "react";
import { 
  Languages, 
  Briefcase, 
  Laptop, 
  Monitor, 
  Code 
} from "lucide-react";

const ConocimientosGenerales = ({ solicitudId, datosIniciales, onUpdate }) => {
  // Estado completo para manejar el formulario
  const [form, setForm] = useState({
    idiomas: "",
    funcionesOficina: "",
    maquinaria: "",
    software: "",
    otrosTrabajosOFunciones: ""
  });
  
  // Referencia para el timer de debounce
  const debounceTimerRef = useRef(null);

  // Cargar datos iniciales si existen
  useEffect(() => {
    if (datosIniciales && Object.keys(datosIniciales).length > 0) {
      setForm(prevForm => ({
        ...prevForm,
        ...datosIniciales
      }));
    }
  }, [datosIniciales]);

  // Función para manejar el debounce en los cambios
  const debouncedUpdate = useCallback(() => {
    // Cancelar el timer anterior si existe
    if (debounceTimerRef.current) {
      clearTimeout(debounceTimerRef.current);
    }
    
    // Crear un nuevo timer para actualizar después de 500 ms (más rápido y responsivo)
    debounceTimerRef.current = setTimeout(() => {
      if (onUpdate) {
        onUpdate({...form});
      }
    }, 500);
  }, [onUpdate, form]);

  // Limpiar el timer al desmontar
  useEffect(() => {
    return () => {
      if (debounceTimerRef.current) {
        clearTimeout(debounceTimerRef.current);
      }
    };
  }, []);

  // Handler para cambios en el formulario
  const handleInputChange = useCallback((campo, valor) => {
    setForm(prevForm => {
      const newForm = {
        ...prevForm,
        [campo]: valor
      };
      
      // Programar el debounce
      debouncedUpdate();
      
      return newForm;
    });
  }, [debouncedUpdate]);

  return (
    <div className="bg-white rounded-md">
      <div className="mb-6">
      
        <p className="text-sm text-gray-600">Por favor complete la información sobre sus conocimientos y habilidades.</p>
        <div className="text-xs text-blue-600 mt-1">
          Los cambios se guardan automáticamente después de escribir.
        </div>
      </div>

      <div className="overflow-hidden border border-gray-200 rounded-lg">
        {/* Encabezado */}
        <div className="bg-gray-100 px-4 py-3 border-b border-gray-200">
          <h3 className="text-md font-medium text-gray-700">
            Conocimientos Generales
          </h3>
        </div>
        
        {/* Idiomas y Funciones de oficina */}
        <div className="grid grid-cols-1 md:grid-cols-2 border-b border-gray-200">
          <div className="p-4 border-b md:border-b-0 md:border-r border-gray-200">
            <div className="space-y-2">
              <label htmlFor="idiomas" className="block text-sm font-medium text-gray-700">
                Que idiomas habla (Nivel 50%, 75%, 100%)
              </label>
              <div className="relative rounded-md shadow-sm">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <Languages className="h-4 w-4 text-gray-400" />
                </div>
                <textarea
                  id="idiomas"
                  rows={3}
                  className="block w-full pl-10 py-2 sm:text-sm border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                  value={form.idiomas}
                  onChange={(e) => handleInputChange("idiomas", e.target.value)}
                  placeholder="Ej: Inglés (75%), Francés (50%)..."
                />
              </div>
            </div>
          </div>
          
          <div className="p-4">
            <div className="space-y-2">
              <label htmlFor="funcionesOficina" className="block text-sm font-medium text-gray-700">
                Funciones de oficina que domina
              </label>
              <div className="relative rounded-md shadow-sm">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <Briefcase className="h-4 w-4 text-gray-400" />
                </div>
                <textarea
                  id="funcionesOficina"
                  rows={3}
                  className="block w-full pl-10 py-2 sm:text-sm border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                  value={form.funcionesOficina}
                  onChange={(e) => handleInputChange("funcionesOficina", e.target.value)}
                  placeholder="Ej: Archivo, Atención a clientes, Contabilidad básica..."
                />
              </div>
            </div>
          </div>
        </div>
        
        {/* Maquinaria y Software */}
        <div className="grid grid-cols-1 md:grid-cols-2 border-b border-gray-200">
          <div className="p-4 border-b md:border-b-0 md:border-r border-gray-200">
            <div className="space-y-2">
              <label htmlFor="maquinaria" className="block text-sm font-medium text-gray-700">
                Maquina de Oficina o taller que sepa manejar
              </label>
              <div className="relative rounded-md shadow-sm">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <Laptop className="h-4 w-4 text-gray-400" />
                </div>
                <textarea
                  id="maquinaria"
                  rows={3}
                  className="block w-full pl-10 py-2 sm:text-sm border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                  value={form.maquinaria}
                  onChange={(e) => handleInputChange("maquinaria", e.target.value)}
                  placeholder="Ej: Copiadora, Conmutador, Maquinaria industrial..."
                />
              </div>
            </div>
          </div>
          
          <div className="p-4">
            <div className="space-y-2">
              <label htmlFor="software" className="block text-sm font-medium text-gray-700">
                Software que conoce
              </label>
              <div className="relative rounded-md shadow-sm">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <Monitor className="h-4 w-4 text-gray-400" />
                </div>
                <textarea
                  id="software"
                  rows={3}
                  className="block w-full pl-10 py-2 sm:text-sm border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                  value={form.software}
                  onChange={(e) => handleInputChange("software", e.target.value)}
                  placeholder="Ej: Microsoft Office, Adobe Photoshop, AutoCAD..."
                />
              </div>
            </div>
          </div>
        </div>
        
        {/* Otros trabajos */}
        <div className="p-4">
          <div className="space-y-2">
            <label htmlFor="otrosTrabajosOFunciones" className="block text-sm font-medium text-gray-700">
              Otros trabajos o funciones que domina
            </label>
            <div className="relative rounded-md shadow-sm">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <Code className="h-4 w-4 text-gray-400" />
              </div>
              <textarea
                id="otrosTrabajosOFunciones"
                rows={3}
                className="block w-full pl-10 py-2 sm:text-sm border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                value={form.otrosTrabajosOFunciones}
                onChange={(e) => handleInputChange("otrosTrabajosOFunciones", e.target.value)}
                placeholder="Describa otras habilidades o conocimientos relevantes..."
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConocimientosGenerales;