import React from "react";
import { MapPin, Phone, Mail, Clock } from "lucide-react";

const Location = () => {
  return (
    <section
      id="location"
      className="py-20 px-4 bg-gradient-to-b from-gray-50 to-gray-100"
    >
      <div className="max-w-6xl mx-auto">
        <div className="text-center mb-12">
          <h2 className="text-3xl font-bold text-gray-900 mb-4">Ubicación</h2>
          <div className="w-20 h-1 bg-blue-700 mx-auto mb-6"></div>
          <p className="text-gray-600 max-w-2xl mx-auto">
            Visítanos en nuestras instalaciones o contáctanos por nuestros
            canales oficiales.
          </p>
        </div>

        <div className="flex flex-col-reverse lg:flex-row gap-8">
          {/* Información de contacto */}
          <div className="w-full lg:w-1/3">
            <div className="bg-white rounded-lg shadow-lg p-8 h-full">
              <h3 className="text-xl font-semibold text-gray-800 mb-6 border-b border-gray-200 pb-3">
                Información de Contacto
              </h3>

              <div className="space-y-5">
                <div className="flex items-start">
                  <div className="bg-blue-50 p-2 rounded-md mr-4">
                    <MapPin className="h-5 w-5 text-blue-700" />
                  </div>
                  <div>
                    <h4 className="font-medium text-gray-900 mb-1">
                      Dirección
                    </h4>
                    <p className="text-gray-600">
                      Yañez #27 esq. Nuevo Leon, Col. San Benito, CP 83190,
                      Hermosillo, Sonora
                    </p>
                  </div>
                </div>

                <div className="flex items-start">
                  <div className="bg-blue-50 p-2 rounded-md mr-4">
                    <Phone className="h-5 w-5 text-blue-700" />
                  </div>
                  <div>
                    <h4 className="font-medium text-gray-900 mb-1">Teléfono</h4>
                    <p className="text-gray-600">(662) 217 2654</p>
                  </div>
                </div>

                <div className="flex items-start">
                  <div className="bg-blue-50 p-2 rounded-md mr-4">
                    <Mail className="h-5 w-5 text-blue-700" />
                  </div>
                  <div>
                    <h4 className="font-medium text-gray-900 mb-1">
                      Correo Electrónico
                    </h4>
                    <p className="text-gray-600">soporte@radiosistemas.com</p>
                  </div>
                </div>

                <div className="flex items-start">
                  <div className="bg-blue-50 p-2 rounded-md mr-4">
                    <Clock className="h-5 w-5 text-blue-700" />
                  </div>
                  <div>
                    <h4 className="font-medium text-gray-900 mb-1">
                      Horario de Atención
                    </h4>
                    <p className="text-gray-600">
                      Lunes a Viernes: 9:00 - 18:00
                    </p>
                    <p className="text-gray-600">Sábados: 9:00 - 13:00</p>
                  </div>
                </div>
              </div>

              {/* Botones de acción */}
              <div className="mt-8 space-y-3">
                <a
                  href="tel:+526622172654"
                  className="flex items-center justify-center w-full py-3 bg-blue-700 text-white rounded-md hover:bg-blue-800 transition-colors duration-200"
                >
                  <Phone className="h-4 w-4 mr-2" />
                  Llamar ahora
                </a>
                <a
                  href="https://goo.gl/maps/ABCxyz123"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex items-center justify-center w-full py-3 border border-blue-700 text-blue-700 rounded-md hover:bg-blue-50 transition-colors duration-200"
                >
                  <MapPin className="h-4 w-4 mr-2" />
                  Cómo llegar
                </a>
              </div>
            </div>
          </div>

          {/* Mapa */}
          <div className="w-full lg:w-2/3">
            <div className="bg-white rounded-lg shadow-lg overflow-hidden h-full">
              <div className="relative pb-[56.25%] h-0">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3486.1095877457483!2d-110.96694108490721!3d29.10611228223674!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x86ce85ecedbb2f95%3A0xafcce07456750d6c!2sRadiosistemas!5e0!3m2!1sen!2sus!4v1628703995965!5m2!1sen!2sus"
                  className="absolute top-0 left-0 w-full h-full border-0"
                  allowFullScreen=""
                  loading="lazy"
                  title="Ubicación de Radiosistemas"
                  style={{ minHeight: "400px" }}
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Location;
