import React from "react";
import { Link } from "react-router-dom";
import {
  Shield,
  BarChart3,
  Package,
  ClipboardCheck,
  CheckSquare,
  UserSearch,
  Car,
  Mail,
  Wrench,
  ChevronRight,
  Building,
  AlertCircle,
} from "lucide-react";
import AdminMenu from "./AdminMenu";

const AdminDashboard = () => {
  const menuItems = [
    {
      text: "Unidades",
      icon: <Car className="h-5 w-5" />,
      path: "/buscarunidades",
      description: "Búsqueda y gestión de vehículos",
      color: "bg-blue-600 hover:bg-blue-700",
    },
    {
      text: "Solicitudes de Empleo",
      icon: <UserSearch className="h-5 w-5" />,
      path: "/candidatos",
      description: "Solicitudes de empleo",
      color: "bg-cyan-600 hover:bg-cyan-700",
    },
    {
      text: "Administración",
      icon: <Building className="h-5 w-5" />,
      path: "/administracion",
      description: "Panel de control administrativo",
      color: "bg-indigo-600 hover:bg-indigo-700",
    },
    {
      text: "Analítica",
      icon: <BarChart3 className="h-5 w-5" />,
      path: "/hikvision",
      description: "Estadísticas y reportes avanzados",
      color: "bg-purple-600 hover:bg-purple-700",
    },
    {
      text: "Nuevo Inventario",
      icon: <Package className="h-5 w-5" />,
      path: "/nuevoinventario",
      description: "Registrar nuevos elementos al inventario",
      color: "bg-green-600 hover:bg-green-700",
    },
    {
      text: "Inspecciones",
      icon: <ClipboardCheck className="h-5 w-5" />,
      path: "/buscarinspeccion",
      description: "Consulta de inspecciones realizadas",
      color: "bg-amber-600 hover:bg-amber-700",
    },
    {
      text: "Nueva Inspección",
      icon: <CheckSquare className="h-5 w-5" />,
      path: "/nuevainspeccion",
      description: "Crear un nuevo reporte de inspección",
      color: "bg-red-600 hover:bg-red-700",
    },
    {
      text: "Buscar Oficial",
      icon: <UserSearch className="h-5 w-5" />,
      path: "/buscaroficial",
      description: "Localizar personal por nombre o ID",
      color: "bg-cyan-600 hover:bg-cyan-700",
    },
    {
      text: "Proyectos",
      icon: <AlertCircle className="h-5 w-5" />,
      path: "/projects",
      description: "Sistema de identificación automática",
      color: "bg-teal-600 hover:bg-teal-700",
    },
    {
      text: "Contactos",
      icon: <Mail className="h-5 w-5" />,
      path: "/contactos",
      description: "Directorio de contactos importantes",
      color: "bg-pink-600 hover:bg-pink-700",
    },
    {
      text: "Garantías",
      icon: <Wrench className="h-5 w-5" />,
      path: "/garantias",
      description: "Gestión de garantías y servicios",
      color: "bg-orange-600 hover:bg-orange-700",
    },
  ];

  return (
    <div className="min-h-screen bg-gray-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        {/* Header */}
        <div className="bg-white rounded-2xl shadow-xl overflow-hidden mb-8">
          <div className="bg-gradient-to-r from-blue-700 to-indigo-800 px-6 py-8 sm:px-10">
            <div className="flex items-center space-x-3">
              <Shield className="h-8 w-8 text-white" />
              <h1 className="text-2xl font-bold text-white">
                Panel de Administración
              </h1>
            </div>
            <p className="mt-2 text-blue-100 max-w-3xl">
              Bienvenido al panel de control administrativo. Desde aquí puede
              gestionar todos los aspectos del sistema.
            </p>
          </div>
        </div>

        {/* Main Content - Accesos Directos */}
        <div className="mb-12">
          <div className="flex items-center mb-6 border-b border-gray-200 pb-3">
            <h2 className="text-xl font-bold text-gray-800">
              Accesos Directos
            </h2>
            <div className="ml-3 h-1 w-16 bg-blue-600 rounded"></div>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-5">
            {menuItems.map((item, index) => (
              <Link
                key={index}
                to={item.path}
                className="group bg-white rounded-xl shadow-md hover:shadow-xl transition-all duration-300 flex flex-col h-full overflow-hidden"
              >
                <div
                  className={`${item.color} p-5 text-white flex justify-between items-center`}
                >
                  {item.icon}
                  <ChevronRight className="h-4 w-4 transform group-hover:translate-x-1 transition-transform duration-200" />
                </div>
                <div className="p-5 flex-grow">
                  <h3 className="font-semibold text-gray-800">{item.text}</h3>
                  <p className="text-sm text-gray-500 mt-1">
                    {item.description}
                  </p>
                </div>
              </Link>
            ))}
          </div>
        </div>

        {/* Admin Menu */}
        <div className="bg-white rounded-xl shadow-md p-6">
          <h2 className="text-xl font-bold text-gray-800 mb-6">
            Menú Administrativo
          </h2>
          <AdminMenu />
        </div>
      </div>

      {/* Footer */}
      <footer className="bg-white border-t border-gray-200 py-6 mt-12">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex flex-col md:flex-row justify-between items-center">
            <p className="text-sm text-gray-500">
              &copy; {new Date().getFullYear()} Panel Administrativo. Todos los
              derechos reservados.
            </p>
            <div className="mt-4 md:mt-0">
              <ul className="flex space-x-6">
                <li>
                  <a
                    href="#"
                    className="text-sm text-gray-500 hover:text-gray-900"
                  >
                    Ayuda
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    className="text-sm text-gray-500 hover:text-gray-900"
                  >
                    Documentación
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    className="text-sm text-gray-500 hover:text-gray-900"
                  >
                    Soporte
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default AdminDashboard;
