import React, { useState, useEffect } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { collection, addDoc, writeBatch, doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "../../firebase";
import {
    PlusCircle,
    Calendar,
    User,
    Briefcase,
    RefreshCcw,
    AlertTriangle,
    X,
    Save,
    ArrowLeft
} from "lucide-react";

const CreateProject = ({ onProjectCreated, onCancel }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const editProjectId = queryParams.get('edit');

    // Estados para formulario
    const [projectName, setProjectName] = useState("");
    const [client, setClient] = useState("");
    const [dueDate, setDueDate] = useState("");
    const [projectType, setProjectType] = useState("Equipamiento Vehicular");
    const [unitCount, setUnitCount] = useState(60);
    const [creatingProject, setCreatingProject] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const [success, setSuccess] = useState(null);

    // Cargar datos del proyecto si estamos en modo edición
    useEffect(() => {
        const fetchProject = async () => {
            if (!editProjectId) return;

            setLoading(true);
            setIsEditing(true);

            try {
                const projectRef = doc(db, "projects", editProjectId);
                const projectSnap = await getDoc(projectRef);

                if (projectSnap.exists()) {
                    const projectData = projectSnap.data();
                    setProjectName(projectData.name || "");
                    setClient(projectData.client || "");
                    setDueDate(projectData.dueDate || "");
                    setProjectType(projectData.projectType || "Equipamiento Vehicular");
                    setUnitCount(projectData.unitCount || 60);
                } else {
                    setError("Proyecto no encontrado");
                }
            } catch (err) {
                console.error("Error al cargar el proyecto:", err);
                setError("Error al cargar el proyecto");
            } finally {
                setLoading(false);
            }
        };

        fetchProject();
    }, [editProjectId]);

    // Manejar la creación o actualización del proyecto
    const handleCreateProject = async (e) => {
        e.preventDefault();

        // Validación básica
        if (!projectName.trim() || !client.trim() || !dueDate) {
            setError("Por favor complete todos los campos obligatorios");
            return;
        }

        setCreatingProject(true);
        setError(null);
        setSuccess(null);

        try {
            // Datos básicos del proyecto
            const projectData = {
                name: projectName.trim(),
                client: client.trim(),
                dueDate,
                projectType,
                unitCount: parseInt(unitCount),
                updatedAt: new Date()
            };

            // Si estamos actualizando
            if (isEditing && editProjectId) {
                const projectRef = doc(db, "projects", editProjectId);
                await updateDoc(projectRef, projectData);

                setSuccess("Proyecto actualizado exitosamente");

                // Si hay una función callback, la llamamos
                if (typeof onProjectCreated === 'function') {
                    try {
                        onProjectCreated({ id: editProjectId, ...projectData });
                    } catch (callbackError) {
                        console.warn("Error en callback onProjectCreated:", callbackError);
                    }
                }

                // Navegar a la página de detalles del proyecto después de un breve delay
                setTimeout(() => {
                    if (typeof onCancel === 'function') {
                        onCancel();
                    } else {
                        navigate(`/project/${editProjectId}`);
                    }
                }, 1500);

                return;
            }

            // Si estamos creando uno nuevo
            // Añadir campos adicionales para proyecto nuevo
            projectData.progress = 0;
            projectData.completedUnits = 0;
            projectData.createdAt = new Date();

            // Crear documento de proyecto
            const projectRef = collection(db, "projects");
            const projectDoc = await addDoc(projectRef, projectData);
            const projectId = projectDoc.id;

            // Crear unidades para el proyecto usando batch para eficiencia
            const batch = writeBatch(db);

            for (let i = 1; i <= unitCount; i++) {
                const unitRef = doc(collection(db, `projects/${projectId}/units`));
                batch.set(unitRef, {
                    number: i,
                    status: "Pendiente",
                    progress: 0,
                    completedComponents: 0,
                    totalComponents: 0,
                    createdAt: new Date(),
                    updatedAt: new Date()
                });
            }

            await batch.commit();

            setSuccess("Proyecto creado exitosamente");

            // Notificar que el proyecto se creó exitosamente mediante callback
            if (typeof onProjectCreated === 'function') {
                try {
                    onProjectCreated({ id: projectId, ...projectData });
                } catch (callbackError) {
                    console.warn("Error en callback onProjectCreated:", callbackError);
                }
            }

            // Resetear formulario
            setProjectName("");
            setClient("");
            setDueDate("");
            setProjectType("Equipamiento Vehicular");
            setUnitCount(60);

            // Navegar a la página de detalles del proyecto después de un breve delay
            setTimeout(() => {
                if (typeof onCancel === 'function') {
                    onCancel();
                } else {
                    navigate(`/project/${projectId}`);
                }
            }, 1500);

        } catch (error) {
            console.error("Error al procesar el proyecto:", error);
            setError("Ocurrió un error. Intente nuevamente.");
        } finally {
            setCreatingProject(false);
        }
    };

    // Cancelar y volver atrás
    const handleCancel = () => {
        try {
            if (typeof onCancel === 'function') {
                onCancel();
            } else if (isEditing && editProjectId) {
                navigate(`/project/${editProjectId}`);
            } else {
                navigate("/projects");
            }
        } catch (error) {
            console.warn("Error en callback onCancel:", error);
            // Si no podemos navegar, al menos limpiamos el formulario
            setProjectName("");
            setClient("");
            setDueDate("");
            setProjectType("Equipamiento Vehicular");
            setUnitCount(60);
            setError(null);
        }
    };

    // Si está cargando
    if (loading) {
        return (
            <div className="flex justify-center items-center h-64">
                <RefreshCcw className="h-8 w-8 text-blue-500 animate-spin" />
                <span className="ml-2 text-gray-600">Cargando proyecto...</span>
            </div>
        );
    }

    return (
        <div className={onCancel ? "" : "max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8"}>
            {!onCancel && (
                <button
                    onClick={handleCancel}
                    className="text-blue-600 hover:text-blue-800 flex items-center mb-6"
                >
                    <ArrowLeft className="h-5 w-5 mr-2" />
                    Volver
                </button>
            )}

            <div className="bg-white rounded-lg shadow-md p-6 mb-6">
                <div className="flex justify-between items-start mb-4">
                    <h2 className="text-xl font-bold text-gray-800 flex items-center">
                        {isEditing ? (
                            <>
                                <Save className="h-5 w-5 text-blue-500 mr-2" />
                                Editar Proyecto
                            </>
                        ) : (
                            <>
                                <PlusCircle className="h-5 w-5 text-blue-500 mr-2" />
                                Crear Nuevo Proyecto
                            </>
                        )}
                    </h2>
                    {onCancel && (
                        <button
                            onClick={handleCancel}
                            className="text-gray-400 hover:text-gray-500"
                            aria-label="Cerrar"
                        >
                            <X className="h-5 w-5" />
                        </button>
                    )}
                </div>

                {error && (
                    <div className="mb-4 bg-red-50 border border-red-200 text-red-700 px-4 py-3 rounded-md flex items-start">
                        <AlertTriangle className="h-5 w-5 text-red-400 mr-2 mt-0.5" />
                        <span>{error}</span>
                    </div>
                )}

                {success && (
                    <div className="mb-4 bg-green-50 border border-green-200 text-green-700 px-4 py-3 rounded-md flex items-start">
                        <Check className="h-5 w-5 text-green-500 mr-2 mt-0.5" />
                        <span>{success}</span>
                    </div>
                )}

                <form onSubmit={handleCreateProject} className="space-y-4">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        <div>
                            <label className="block text-sm font-medium text-gray-700 mb-1">
                                Nombre del Proyecto*
                            </label>
                            <input
                                type="text"
                                value={projectName}
                                onChange={(e) => setProjectName(e.target.value)}
                                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                                placeholder="Ej: Equipamiento Patrullas 2025"
                                required
                            />
                        </div>

                        <div>
                            <label className="block text-sm font-medium text-gray-700 mb-1">
                                Cliente*
                            </label>
                            <div className="relative">
                                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                    <User className="h-4 w-4 text-gray-400" />
                                </div>
                                <input
                                    type="text"
                                    value={client}
                                    onChange={(e) => setClient(e.target.value)}
                                    className="w-full pl-10 px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                                    placeholder="Ej: Policía Estatal"
                                    required
                                />
                            </div>
                        </div>
                    </div>

                    <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                        <div>
                            <label className="block text-sm font-medium text-gray-700 mb-1">
                                Fecha de Entrega*
                            </label>
                            <div className="relative">
                                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                    <Calendar className="h-4 w-4 text-gray-400" />
                                </div>
                                <input
                                    type="date"
                                    value={dueDate}
                                    onChange={(e) => setDueDate(e.target.value)}
                                    className="w-full pl-10 px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                                    required
                                />
                            </div>
                        </div>

                        <div>
                            <label className="block text-sm font-medium text-gray-700 mb-1">
                                Tipo de Proyecto
                            </label>
                            <div className="relative">
                                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                    <Briefcase className="h-4 w-4 text-gray-400" />
                                </div>
                                <select
                                    value={projectType}
                                    onChange={(e) => setProjectType(e.target.value)}
                                    className="w-full pl-10 px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 bg-white"
                                >
                                    <option value="Equipamiento Vehicular">Equipamiento Vehicular</option>
                                    <option value="Instalación">Instalación</option>
                                    <option value="Mantenimiento">Mantenimiento</option>
                                    <option value="Repuestos">Repuestos</option>
                                    <option value="Integración">Integración</option>
                                    <option value="Otro">Otro</option>
                                </select>
                            </div>
                        </div>

                        <div>
                            <label className="block text-sm font-medium text-gray-700 mb-1">
                                Cantidad de Unidades
                            </label>
                            <input
                                type="number"
                                value={unitCount}
                                onChange={(e) => setUnitCount(Math.max(1, parseInt(e.target.value) || 1))}
                                min="1"
                                max="200"
                                className={`w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 ${isEditing ? 'bg-gray-100' : ''}`}
                                disabled={isEditing} // Deshabilitar edición de unitCount si estamos editando
                            />
                            {isEditing ? (
                                <p className="mt-1 text-xs text-gray-500">
                                    No se puede modificar la cantidad de unidades en un proyecto existente
                                </p>
                            ) : (
                                <p className="mt-1 text-xs text-gray-500">
                                    Se crearán {unitCount} unidades para este proyecto
                                </p>
                            )}
                        </div>
                    </div>

                    <div className="flex justify-end space-x-3 pt-4">
                        <button
                            type="button"
                            onClick={handleCancel}
                            className="px-4 py-2 border border-gray-300 rounded-md text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                            disabled={creatingProject}
                        >
                            Cancelar
                        </button>
                        <button
                            type="submit"
                            disabled={creatingProject}
                            className="px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50 flex items-center"
                        >
                            {creatingProject ? (
                                <>
                                    <RefreshCcw className="inline-block h-4 w-4 mr-2 animate-spin" />
                                    {isEditing ? "Actualizando..." : "Creando..."}
                                </>
                            ) : (
                                <>
                                    {isEditing ? (
                                        <>
                                            <Save className="h-4 w-4 mr-2" />
                                            Actualizar Proyecto
                                        </>
                                    ) : (
                                        <>
                                            <PlusCircle className="h-4 w-4 mr-2" />
                                            Crear Proyecto
                                        </>
                                    )}
                                </>
                            )}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

// Agregar el import que faltaba
const Check = ({ className }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" className={className} width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
            <polyline points="20 6 9 17 4 12"></polyline>
        </svg>
    );
};

export default CreateProject;