import React, { useState, useEffect } from "react";
import {
  Delete as DeleteIcon,
  AddPhotoAlternate as AddPhotoAlternateIcon,
  PhotoCamera as PhotoCameraIcon,
  CheckCircle as CheckCircleIcon,
  ArrowBack as ArrowBackIcon,
  ArrowForward as ArrowForwardIcon,
  ErrorOutline as ErrorOutlineIcon,
  RemoveRedEye as RemoveRedEyeIcon,
} from "@mui/icons-material";
import {
  ref,
  uploadBytesResumable,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import { db, storage } from "../../../firebase";
import { doc, updateDoc } from "firebase/firestore";

const Step2Fotos = ({
  existingFotos = {},
  handleImageChange,
  handleNext,
  handleBack,
  inspectionId,
  isEditing = false,
}) => {
  const [previews, setPreviews] = useState({});
  const [loadingStates, setLoadingStates] = useState({});
  const [uploadProgress, setUploadProgress] = useState({});
  const [additionalImages, setAdditionalImages] = useState([]);
  const [previewModal, setPreviewModal] = useState(null);
  const [error, setError] = useState(null);

  // Información predefinida de las imágenes obligatorias
  const requiredImages = [
    {
      label: "Foto unidad",
      placeholder:
        "https://firebasestorage.googleapis.com/v0/b/radiosistemas-a46cd.appspot.com/o/Fotos%20form%20Unidad%2Ffrontal.png?alt=media&token=5b67d9ed-40da-41e7-9112-b9a7038e711e",
      title: "Frontal",
      description: "Vista frontal completa del vehículo",
    },
    {
      label: "Derecha",
      placeholder:
        "https://firebasestorage.googleapis.com/v0/b/radiosistemas-a46cd.appspot.com/o/Fotos%20form%20Unidad%2FDerecha.png?alt=media&token=cf40c813-f2ed-4bab-acd2-20a6027f79f2",
      title: "Lateral Derecho",
      description: "Vista lateral derecha del vehículo",
    },
    {
      label: "Trasera",
      placeholder:
        "https://firebasestorage.googleapis.com/v0/b/radiosistemas-a46cd.appspot.com/o/Fotos%20form%20Unidad%2FTrasera.png?alt=media&token=fbf8a72d-0672-4310-8505-1838f333f67d",
      title: "Trasera",
      description: "Vista trasera completa del vehículo",
    },
    {
      label: "Techo",
      placeholder:
        "https://firebasestorage.googleapis.com/v0/b/radiosistemas-a46cd.appspot.com/o/Fotos%20form%20Unidad%2FTecho.png?alt=media&token=ca7824b7-e021-4779-a971-911d460a9a06",
      title: "Techo",
      description: "Vista superior del vehículo",
    },
    {
      label: "Izquierda",
      placeholder:
        "https://firebasestorage.googleapis.com/v0/b/radiosistemas-a46cd.appspot.com/o/Fotos%20form%20Unidad%2FIzquierda.png?alt=media&token=fe7ad6c7-4c8f-4cce-a6f1-6ee3f3ebc417",
      title: "Lateral Izquierdo",
      description: "Vista lateral izquierda del vehículo",
    },
  ];

  // Inicializar las previews con las fotos existentes
  useEffect(() => {
    const processedPreviews = {};
    if (existingFotos) {
      Object.entries(existingFotos).forEach(([key, value]) => {
        if (value && value.previewURL) {
          processedPreviews[key] = value.previewURL;
        } else if (value && typeof value === "string") {
          processedPreviews[key] = value;
        }
      });
    }
    setPreviews(processedPreviews);

    // Detectar imágenes adicionales en las existentes
    const additionalKeys = Object.keys(processedPreviews).filter(
      (key) =>
        !requiredImages.some((img) => img.label === key) &&
        key !== "Firma" &&
        key !== "Oficial"
    );

    if (additionalKeys.length > 0) {
      const newAdditionalImages = additionalKeys.map((key) => ({
        label: key,
        preview: processedPreviews[key],
      }));
      setAdditionalImages(newAdditionalImages);
    }
  }, [existingFotos]);

  const handleImageSelect = async (label, e) => {
    const file = e.target.files[0];
    if (!file) return;

    // Verificar tamaño de archivo (máximo 5MB)
    if (file.size > 5 * 1024 * 1024) {
      setError(`La imagen ${label} excede el tamaño máximo de 5MB`);
      setTimeout(() => setError(null), 5000);
      return;
    }

    // Verificar tipo de archivo
    if (!file.type.startsWith("image/")) {
      setError(`El archivo seleccionado para ${label} no es una imagen válida`);
      setTimeout(() => setError(null), 5000);
      return;
    }

    // Crear preview local inmediato
    const previewURL = URL.createObjectURL(file);
    setPreviews((prev) => ({ ...prev, [label]: previewURL }));

    // Notificar al componente padre sobre el cambio
    handleImageChange(label, file);

    // Si estamos en modo edición y tenemos un ID, subir la imagen directamente
    if (inspectionId) {
      setLoadingStates((prev) => ({ ...prev, [label]: true }));
      setUploadProgress((prev) => ({ ...prev, [label]: 0 }));

      const storageRef = ref(
        storage,
        `inspecciones/${inspectionId}/${label}.jpg`
      );
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setUploadProgress((prev) => ({ ...prev, [label]: progress }));
        },
        (error) => {
          console.error("Error al subir la imagen:", error);
          setError(`Error al subir la imagen ${label}: ${error.message}`);
          setLoadingStates((prev) => ({ ...prev, [label]: false }));
          setTimeout(() => setError(null), 5000);
        },
        async () => {
          try {
            const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
            setPreviews((prev) => ({ ...prev, [label]: downloadURL }));

            // Actualizar en Firestore
            await updateDoc(doc(db, "inspecciones", inspectionId), {
              [`fotos.${label}`]: downloadURL,
            });
          } catch (error) {
            console.error("Error al finalizar la subida:", error);
            setError(`Error al finalizar la subida de ${label}`);
            setTimeout(() => setError(null), 5000);
          } finally {
            setLoadingStates((prev) => ({ ...prev, [label]: false }));
          }
        }
      );
    }
  };

  const handleRemoveImage = async (label) => {
    setPreviews((prev) => {
      const updatedPreviews = { ...prev };
      delete updatedPreviews[label];
      return updatedPreviews;
    });

    handleImageChange(label, null);

    if (inspectionId) {
      try {
        // Actualizar en Firestore
        await updateDoc(doc(db, "inspecciones", inspectionId), {
          [`fotos.${label}`]: null,
        });

        // Eliminar de Storage
        const storageRef = ref(
          storage,
          `inspecciones/${inspectionId}/${label}.jpg`
        );

        await deleteObject(storageRef).catch((error) => {
          // Si el archivo no existe, no es un error crítico
          if (error.code !== "storage/object-not-found") {
            console.error("Error al eliminar la imagen de Storage:", error);
            setError(`Error al eliminar la imagen ${label}`);
            setTimeout(() => setError(null), 5000);
          }
        });
      } catch (error) {
        console.error("Error al eliminar la imagen:", error);
        setError(`Error al eliminar la imagen ${label}`);
        setTimeout(() => setError(null), 5000);
      }
    }
  };

  const handleAdditionalImagesSelect = (e) => {
    const files = Array.from(e.target.files);
    if (!files.length) return;

    const newImages = files.map((file, index) => {
      const label = `Foto_${Date.now()}_${index}`;
      const preview = URL.createObjectURL(file);

      // Notificar al componente padre sobre el cambio
      handleImageSelect(label, { target: { files: [file] } });

      return { label, preview, file };
    });

    setAdditionalImages((prev) => [...prev, ...newImages]);
  };

  const handleRemoveAdditionalImage = (index) => {
    const imageToRemove = additionalImages[index];
    setAdditionalImages((prev) => prev.filter((_, i) => i !== index));
    handleRemoveImage(imageToRemove.label);
  };

  const openPreviewModal = (imageUrl) => {
    setPreviewModal(imageUrl);
  };

  const closePreviewModal = () => {
    setPreviewModal(null);
  };

  // Componente para mostrar un campo de carga de imagen
  const ImageUploadField = ({ label, placeholder, title, description }) => {
    const isUploading = loadingStates[label];
    const progress = uploadProgress[label] || 0;
    const hasImage = !!previews[label];

    return (
      <div
        className="relative p-4 border-2 border-dashed rounded-lg transition-all hover:bg-gray-50"
        style={{ borderColor: hasImage ? "#4f46e5" : "#d1d5db" }}
      >
        <input
          accept="image/*"
          className="hidden"
          id={`upload-${label}`}
          type="file"
          onChange={(e) => handleImageSelect(label, e)}
        />

        <div className="flex flex-col items-center">
          <div className="relative mb-3 w-32 h-32 flex items-center justify-center rounded-lg overflow-hidden bg-gray-100 border border-gray-200">
            {isUploading ? (
              <div className="flex flex-col items-center justify-center w-full h-full">
                <div className="w-12 h-12 border-4 border-indigo-200 border-t-indigo-600 rounded-full animate-spin"></div>
                <div className="mt-2 text-xs text-gray-600">
                  {Math.round(progress)}%
                </div>
              </div>
            ) : (
              <>
                <img
                  src={previews[label] || placeholder}
                  alt={title}
                  className={`w-full h-full object-cover transition-opacity ${
                    hasImage ? "opacity-100" : "opacity-70"
                  }`}
                />

                {hasImage && (
                  <div className="absolute top-0 right-0 p-1">
                    <div className="bg-indigo-600 text-white rounded-full p-1">
                      <CheckCircleIcon style={{ fontSize: 16 }} />
                    </div>
                  </div>
                )}
              </>
            )}
          </div>

          <h3 className="font-medium text-gray-800">{title}</h3>
          <p className="text-xs text-gray-500 text-center mb-3">
            {description}
          </p>

          <div className="flex space-x-2">
            <label htmlFor={`upload-${label}`} className="cursor-pointer">
              <div
                className={`px-3 py-2 text-sm font-medium rounded-md flex items-center space-x-1 ${
                  hasImage
                    ? "bg-gray-100 text-gray-700 hover:bg-gray-200"
                    : "bg-indigo-600 text-white hover:bg-indigo-700"
                }`}
              >
                <PhotoCameraIcon style={{ fontSize: 16 }} />
                <span>{hasImage ? "Cambiar" : "Subir"}</span>
              </div>
            </label>

            {hasImage && (
              <>
                <button
                  type="button"
                  onClick={() => openPreviewModal(previews[label])}
                  className="px-3 py-2 text-sm font-medium bg-gray-100 text-gray-700 rounded-md hover:bg-gray-200 flex items-center space-x-1"
                >
                  <RemoveRedEyeIcon style={{ fontSize: 16 }} />
                  <span>Ver</span>
                </button>

                <button
                  type="button"
                  onClick={() => handleRemoveImage(label)}
                  className="px-3 py-2 text-sm font-medium bg-red-50 text-red-600 rounded-md hover:bg-red-100 flex items-center space-x-1"
                >
                  <DeleteIcon style={{ fontSize: 16 }} />
                  <span>Eliminar</span>
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="w-full">
      {/* Título y descripción */}
      <div className="mb-6">
        <h2 className="text-lg font-semibold text-gray-800 flex items-center mb-2">
          <PhotoCameraIcon className="mr-2 text-indigo-600" />
          Fotografías del Vehículo
        </h2>
        <p className="text-gray-600 text-sm">
          Sube las fotografías requeridas del vehículo. Todas las vistas son
          necesarias para completar la inspección.
        </p>
      </div>

      {/* Mensaje de error */}
      {error && (
        <div className="mb-4 p-3 bg-red-50 border border-red-200 rounded-md flex items-center text-red-600">
          <ErrorOutlineIcon className="mr-2" />
          <span>{error}</span>
        </div>
      )}

      {/* Grid de imágenes requeridas */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mb-8">
        {requiredImages.map((image) => (
          <ImageUploadField
            key={image.label}
            label={image.label}
            placeholder={image.placeholder}
            title={image.title}
            description={image.description}
          />
        ))}
      </div>

      {/* Imágenes adicionales */}
      {additionalImages.length > 0 && (
        <div className="mb-8">
          <h3 className="text-md font-medium text-gray-700 mb-3 flex items-center">
            <AddPhotoAlternateIcon className="mr-2 text-indigo-600" />
            Fotografías Adicionales ({additionalImages.length})
          </h3>

          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
            {additionalImages.map((image, index) => (
              <div
                key={index}
                className="relative border border-gray-200 rounded-lg p-3 bg-white"
              >
                <div className="relative w-full pt-[75%] rounded-md overflow-hidden bg-gray-100">
                  <img
                    src={image.preview}
                    alt={`Imagen adicional ${index + 1}`}
                    className="absolute top-0 left-0 w-full h-full object-cover"
                  />
                </div>
                <div className="mt-2 flex justify-between items-center">
                  <span className="text-xs text-gray-500 truncate">
                    Imagen {index + 1}
                  </span>
                  <div className="flex space-x-1">
                    <button
                      onClick={() => openPreviewModal(image.preview)}
                      className="p-1 text-gray-500 hover:text-indigo-600"
                    >
                      <RemoveRedEyeIcon style={{ fontSize: 16 }} />
                    </button>
                    <button
                      onClick={() => handleRemoveAdditionalImage(index)}
                      className="p-1 text-gray-500 hover:text-red-600"
                    >
                      <DeleteIcon style={{ fontSize: 16 }} />
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}

      {/* Botón para agregar más imágenes */}
      <div className="mb-8 flex justify-center">
        <label className="cursor-pointer">
          <div className="flex items-center px-4 py-3 bg-indigo-50 text-indigo-700 rounded-lg border border-indigo-100 hover:bg-indigo-100 transition-colors">
            <AddPhotoAlternateIcon className="mr-2" />
            <span className="font-medium">Agregar Más Fotografías</span>
          </div>
          <input
            type="file"
            accept="image/*"
            multiple
            className="hidden"
            onChange={handleAdditionalImagesSelect}
          />
        </label>
      </div>

      {/* Mensaje con el progreso */}
      <div className="mb-6 text-center">
        <p className="text-sm text-gray-600">
          {Object.keys(previews).length} de{" "}
          {requiredImages.length + additionalImages.length} imágenes subidas
        </p>
        <div className="w-full bg-gray-200 rounded-full h-2.5 mt-2">
          <div
            className="bg-indigo-600 h-2.5 rounded-full transition-all"
            style={{
              width: `${Math.min(
                100,
                (Object.keys(previews).length /
                  (requiredImages.length + additionalImages.length)) *
                  100
              )}%`,
            }}
          />
        </div>
      </div>

      {/* Botones de navegación */}
      <div className="flex justify-between mt-8">
        <button
          type="button"
          onClick={handleBack}
          className="px-5 py-2.5 rounded-md border border-gray-300 bg-white text-gray-700 hover:bg-gray-50 flex items-center"
        >
          <ArrowBackIcon className="mr-1" fontSize="small" />
          Anterior
        </button>

        <button
          type="button"
          onClick={handleNext}
          className="px-5 py-2.5 rounded-md bg-indigo-600 text-white hover:bg-indigo-700 flex items-center"
        >
          Siguiente
          <ArrowForwardIcon className="ml-1" fontSize="small" />
        </button>
      </div>

      {/* Modal de vista previa de imagen */}
      {previewModal && (
        <div
          className="fixed inset-0 bg-black bg-opacity-75 z-50 flex items-center justify-center p-4"
          onClick={closePreviewModal}
        >
          <div
            className="relative max-w-2xl max-h-[90vh] w-full bg-white rounded-lg overflow-hidden"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="flex justify-between items-center p-4 border-b">
              <h3 className="font-medium">Vista previa de imagen</h3>
              <button
                onClick={closePreviewModal}
                className="text-gray-500 hover:text-gray-700"
              >
                <DeleteIcon />
              </button>
            </div>
            <div className="relative w-full h-[70vh] bg-gray-100">
              <img
                src={previewModal}
                alt="Vista previa"
                className="absolute top-0 left-0 w-full h-full object-contain"
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Step2Fotos;
