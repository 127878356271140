import React, { useState, useEffect } from "react";
import SearchIcon from "@mui/icons-material/Search";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import AddIcon from "@mui/icons-material/Add";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import PlaceIcon from "@mui/icons-material/Place";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import { addDoc, collection, Timestamp } from "firebase/firestore";
import { db } from "../../../firebase";
import { useUserAuth } from "../../../context/userAuthContext";
import NuevaUnidad from "../../AdminDashboard/Unidad/NuevaUnidad";
import CloseIcon from "@mui/icons-material/Close";

const Step1Unidad = ({
  unidades,
  selectedUnidad,
  setSelectedUnidad,
  handleNext,
  setUnidades,
  setInspectionId,
  isEditing = false,
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredUnidades, setFilteredUnidades] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const { user } = useUserAuth();

  // Filtrar unidades cuando cambia el término de búsqueda o la lista de unidades
  useEffect(() => {
    if (!searchTerm.trim()) {
      setFilteredUnidades(unidades);
    } else {
      setFilteredUnidades(
        unidades.filter((unidad) =>
          unidad.Unidad.toLowerCase().includes(searchTerm.toLowerCase())
        )
      );
    }
  }, [searchTerm, unidades]);

  const handleSelectUnidad = (unidad) => {
    setSelectedUnidad(unidad.Unidad);
  };

  const handleUnidadAdded = (newUnidad) => {
    setUnidades((prevUnidades) => [...prevUnidades, newUnidad]);
    setSelectedUnidad(newUnidad.Unidad);
    setSearchTerm("");
    setOpenModal(false);
  };

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const handleClearSearch = () => {
    setSearchTerm("");
  };

  const handleNextStep = async () => {
    if (!selectedUnidad) return;

    // Si estamos editando, no necesitamos crear un nuevo documento
    if (isEditing) {
      handleNext();
      return;
    }

    setLoading(true);

    try {
      const fechaActual = Timestamp.now();
      const docRef = await addDoc(collection(db, "inspecciones"), {
        Unidad: selectedUnidad,
        Usuario: user.email,
        Active: true,
        Timing: { Start: fechaActual, End: "" },
        Date: fechaActual,
        Status: "En Progreso",
      });

      // Establecer el ID de la inspección creada
      setInspectionId(docRef.id);

      // Avanzar al siguiente paso
      handleNext();
    } catch (error) {
      console.error("Error al crear la inspección:", error);
      // Aquí podríamos mostrar un mensaje de error al usuario
    } finally {
      setLoading(false);
    }
  };

  // Determinar si hay alguna unidad seleccionada
  const hasSelectedUnidad = !!selectedUnidad;

  return (
    <div className="w-full">
      {/* Botón para agregar nueva unidad */}
      <div className="mb-6">
        <button
          onClick={handleOpenModal}
          className="w-full flex items-center justify-center gap-2 bg-indigo-50 text-indigo-700 border border-indigo-200 hover:bg-indigo-100 transition-colors py-3 px-4 rounded-md font-medium"
        >
          <AddIcon fontSize="small" />
          Agregar Nueva Unidad
        </button>
      </div>

      {/* Título de la sección */}
      <h2 className="text-gray-800 text-lg font-semibold mb-4 flex items-center">
        <DirectionsCarIcon className="mr-2 text-indigo-600" />
        Selecciona una Unidad
      </h2>

      {/* Buscador */}
      <div className="mb-6 relative">
        <div className="relative">
          <input
            type="text"
            placeholder="Buscar unidad"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="w-full py-3 pl-4 pr-12 rounded-md border border-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
          />
          <div className="absolute right-3 top-1/2 transform -translate-y-1/2 flex items-center">
            {searchTerm && (
              <button
                onClick={handleClearSearch}
                className="text-gray-400 hover:text-gray-600 mr-1"
              >
                <CloseIcon fontSize="small" />
              </button>
            )}
            <SearchIcon className="text-gray-400" />
          </div>
        </div>
      </div>

      {/* Grid de unidades */}
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 mb-6">
        {filteredUnidades.length > 0 ? (
          filteredUnidades.map((unidad) => (
            <div key={unidad.id} onClick={() => handleSelectUnidad(unidad)}>
              <div
                className={`
                  relative rounded-lg p-4 border-2 cursor-pointer transition-all
                  ${
                    selectedUnidad === unidad.Unidad
                      ? "border-indigo-500 bg-indigo-50"
                      : "border-gray-200 hover:border-indigo-300 hover:bg-gray-50"
                  }
                `}
              >
                {selectedUnidad === unidad.Unidad && (
                  <div className="absolute top-2 right-2">
                    <CheckCircleIcon
                      className="text-indigo-600"
                      fontSize="small"
                    />
                  </div>
                )}

                <div className="text-center mt-1">
                  <h3 className="font-semibold text-gray-800 text-lg mb-1">
                    {unidad.Unidad}
                  </h3>
                  <p className="text-gray-600 flex items-center justify-center text-sm">
                    <PlaceIcon fontSize="small" className="mr-1" />
                    Zona: {unidad.Zona || "No especificada"}
                  </p>
                </div>
              </div>
            </div>
          ))
        ) : (
          <div className="col-span-full text-center py-8 border border-dashed border-gray-300 rounded-lg bg-gray-50">
            <p className="text-gray-500">
              {searchTerm
                ? `No se encontraron unidades con "${searchTerm}"`
                : "No hay unidades disponibles"}
            </p>
          </div>
        )}
      </div>

      {/* Botón para continuar */}
      <div className="mt-8">
        <button
          onClick={handleNextStep}
          disabled={!hasSelectedUnidad || loading}
          className={`
            w-full py-3 px-4 rounded-md font-medium flex items-center justify-center gap-2
            ${
              hasSelectedUnidad && !loading
                ? "bg-indigo-600 text-white hover:bg-indigo-700"
                : "bg-gray-300 text-gray-500 cursor-not-allowed"
            }
            transition-colors
          `}
        >
          {loading ? (
            <>
              <div className="w-5 h-5 border-t-2 border-b-2 border-white rounded-full animate-spin mr-2"></div>
              Procesando...
            </>
          ) : (
            <>
              Continuar
              <ArrowForwardIcon fontSize="small" />
            </>
          )}
        </button>

        {selectedUnidad && (
          <p className="text-center mt-2 text-sm text-gray-600">
            Unidad seleccionada:{" "}
            <span className="font-medium">{selectedUnidad}</span>
          </p>
        )}
      </div>

      {/* Modal para nueva unidad */}
      {openModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
          <div className="bg-white rounded-lg shadow-xl max-w-md w-full max-h-[90vh] overflow-auto">
            <div className="p-4 border-b border-gray-200 flex justify-between items-center">
              <h2 className="text-lg font-semibold text-gray-800">
                Agregar Nueva Unidad
              </h2>
              <button
                onClick={handleCloseModal}
                className="text-gray-500 hover:text-gray-700"
              >
                <CloseIcon />
              </button>
            </div>
            <div className="p-4">
              <NuevaUnidad
                handleClose={handleCloseModal}
                onUnidadAdded={handleUnidadAdded}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Step1Unidad;
