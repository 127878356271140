import React, { useState, useEffect, useCallback, useMemo } from "react";
import { collection, doc, onSnapshot, updateDoc, deleteDoc, getDoc } from "firebase/firestore";
import { ref, uploadBytesResumable, getDownloadURL, deleteObject } from "firebase/storage";
import { db, storage } from "../../firebase";
import {
    RefreshCcw,
    AlertTriangle,
    CheckSquare,
    Edit,
    Save,
    X,
    Camera,
    FileText,
    Hash,
    Image,
    Trash2,
    Percent,
    CircleCheck,
    CircleMinus,
    Filter,
    MinusCircle,
    AlertCircle,
    PieChart,
    Zap,
    CheckCircle,
    Clock,
    Package
} from "lucide-react";

const UnitDetail = ({ unit, projectId, onUnitUpdate }) => {
    const [components, setComponents] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
    const [selectedComponent, setSelectedComponent] = useState(null);

    // Estados para edición de unidad
    const [serialNumber, setSerialNumber] = useState("");
    const [notes, setNotes] = useState("");
    const [images, setImages] = useState([]);
    const [uploadingImages, setUploadingImages] = useState(false);
    const [showBulkActions, setShowBulkActions] = useState(false);

    // Estado para mostrar u ocultar componentes por categoría
    const [filter, setFilter] = useState("all"); // "all", "installed", "pending"

    // Cargar datos de la unidad
    useEffect(() => {
        if (!unit?.id || !projectId) {
            setError("Datos de unidad incompletos");
            setLoading(false);
            return;
        }

        const unitRef = doc(db, `projects/${projectId}/units/${unit.id}`);

        // Usar onSnapshot para escuchar cambios en los detalles de la unidad
        const unsubscribeUnit = onSnapshot(unitRef, (snapshot) => {
            if (snapshot.exists()) {
                const unitData = snapshot.data();
                setSerialNumber(unitData.serialNumber || "");
                setNotes(unitData.notes || "");
                setImages(unitData.images || []);
            }
        }, (error) => {
            console.error("Error al escuchar cambios en la unidad:", error);
        });

        return () => unsubscribeUnit();
    }, [unit?.id, projectId]);

    // Cargar componentes con onSnapshot
    useEffect(() => {
        if (!unit?.id || !projectId) return;

        setLoading(true);

        const componentsRef = collection(db, `projects/${projectId}/units/${unit.id}/components`);

        const unsubscribe = onSnapshot(componentsRef, (snapshot) => {
            const componentsData = snapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));

            setComponents(componentsData);
            setLoading(false);

            // Calcular progreso basado en los componentes
            if (componentsData.length > 0) {
                calculateAndUpdateProgress(componentsData);
            }
        }, (err) => {
            console.error("Error al escuchar cambios en componentes:", err);
            setError("Error al cargar componentes");
            setLoading(false);
        });

        return () => unsubscribe();
    }, [unit?.id, projectId]);

    // Calcular y actualizar el progreso de la unidad
    const calculateAndUpdateProgress = useCallback(async (componentsData) => {
        if (!unit?.id || !projectId) return;

        const totalComponents = componentsData.length;
        const completedComponents = componentsData.filter(comp => comp.installed).length;
        const progress = totalComponents > 0 ? Math.round((completedComponents / totalComponents) * 100) : 0;

        // Determinar estado basado en el progreso
        let status = "Pendiente";
        if (progress === 100) {
            status = "Completado";
        } else if (progress > 0) {
            status = "En progreso";
        }

        // Solo actualizar si hay cambios para evitar escrituras innecesarias
        if (unit.progress !== progress ||
            unit.totalComponents !== totalComponents ||
            unit.completedComponents !== completedComponents ||
            unit.status !== status) {

            try {
                const unitRef = doc(db, `projects/${projectId}/units/${unit.id}`);
                await updateDoc(unitRef, {
                    progress,
                    totalComponents,
                    completedComponents,
                    status,
                    updatedAt: new Date()
                });

                // No necesitamos actualizar el estado local porque onSnapshot lo hará automáticamente
            } catch (error) {
                console.error("Error al actualizar progreso:", error);
            }
        }
    }, [projectId, unit]);

    // Manejar la actualización del estado de instalación
    const handleToggleInstalled = async (componentId, currentStatus) => {
        try {
            const componentRef = doc(db, `projects/${projectId}/units/${unit.id}/components/${componentId}`);
            await updateDoc(componentRef, {
                installed: !currentStatus,
                updatedAt: new Date()
            });

            // No necesitamos actualizar estado local ni recalcular progreso manualmente
            // onSnapshot lo hará automáticamente
        } catch (error) {
            console.error("Error al actualizar componente:", error);
            alert("Error al actualizar el estado del componente");
        }
    };

    // Manejar la eliminación de un componente
    const handleDeleteComponent = async (componentId) => {
        try {
            const componentRef = doc(db, `projects/${projectId}/units/${unit.id}/components/${componentId}`);
            await deleteDoc(componentRef);
            setShowDeleteConfirm(false);
            setSelectedComponent(null);

            // No necesitamos actualizar nada manualmente, onSnapshot actualizará todo
        } catch (error) {
            console.error("Error al eliminar componente:", error);
            alert("Error al eliminar el componente");
        }
    };

    // Manejar acciones masivas en componentes
    const handleBulkAction = async (action) => {
        if (components.length === 0) return;

        try {
            // Filtrar componentes según la acción y el filtro actual
            let componentsToUpdate = [...components];
            if (filter === "installed") {
                componentsToUpdate = components.filter(comp => comp.installed);
            } else if (filter === "pending") {
                componentsToUpdate = components.filter(comp => !comp.installed);
            }

            // Realizar la acción correspondiente
            for (const component of componentsToUpdate) {
                const componentRef = doc(db, `projects/${projectId}/units/${unit.id}/components/${component.id}`);

                if (action === "delete") {
                    await deleteDoc(componentRef);
                } else if (action === "markInstalled") {
                    if (!component.installed) {
                        await updateDoc(componentRef, {
                            installed: true,
                            updatedAt: new Date()
                        });
                    }
                } else if (action === "markPending") {
                    if (component.installed) {
                        await updateDoc(componentRef, {
                            installed: false,
                            updatedAt: new Date()
                        });
                    }
                }
            }

            setShowBulkActions(false);
            // onSnapshot actualizará todo automáticamente
        } catch (error) {
            console.error("Error al realizar acción masiva:", error);
            alert("Error al procesar la acción en componentes");
        }
    };

    // Manejar la carga de imágenes
    const handleImageUpload = (e) => {
        const files = Array.from(e.target.files);
        if (files.length === 0) return;

        setUploadingImages(true);
        const updatedImages = [...images];

        files.forEach((file) => {
            const storageRef = ref(storage, `projects/${projectId}/units/${unit.id}/${file.name}`);
            const uploadTask = uploadBytesResumable(storageRef, file);

            uploadTask.on(
                "state_changed",
                null,
                (error) => {
                    console.error("Error al subir la imagen:", error);
                    setUploadingImages(false);
                },
                async () => {
                    const imageURL = await getDownloadURL(uploadTask.snapshot.ref);
                    updatedImages.push({ url: imageURL, name: file.name, createdAt: new Date() });

                    try {
                        // Actualizar las imágenes en Firestore
                        const unitRef = doc(db, `projects/${projectId}/units/${unit.id}`);
                        await updateDoc(unitRef, {
                            images: updatedImages,
                            updatedAt: new Date()
                        });

                        // onSnapshot actualizará el estado local con las nuevas imágenes
                    } catch (error) {
                        console.error("Error al guardar imágenes:", error);
                    } finally {
                        if (updatedImages.length === images.length + files.length) {
                            setUploadingImages(false);
                        }
                    }
                }
            );
        });
    };

    // Eliminar imagen
    const handleDeleteImage = async (image, index) => {
        try {
            // Eliminar del storage
            if (image.name) {
                const storageRef = ref(storage, `projects/${projectId}/units/${unit.id}/${image.name}`);
                await deleteObject(storageRef);
            }

            // Eliminar de Firestore
            const updatedImages = [...images];
            updatedImages.splice(index, 1);

            const unitRef = doc(db, `projects/${projectId}/units/${unit.id}`);
            await updateDoc(unitRef, {
                images: updatedImages,
                updatedAt: new Date()
            });

            // onSnapshot actualizará el estado local
        } catch (error) {
            console.error("Error al eliminar la imagen:", error);
            alert("Error al eliminar la imagen");
        }
    };

    // Guardar cambios en detalles
    const handleSaveChanges = async () => {
        if (!unit?.id || !projectId) return;

        try {
            const unitRef = doc(db, `projects/${projectId}/units/${unit.id}`);
            await updateDoc(unitRef, {
                serialNumber,
                notes,
                updatedAt: new Date()
            });
            setIsEditing(false);

            // onSnapshot actualizará todo
        } catch (error) {
            console.error("Error al actualizar la unidad:", error);
            alert("Error al guardar los cambios");
        }
    };

    // Filtrar componentes según el filtro seleccionado (memoizado para evitar recálculos innecesarios)
    const filteredComponents = useMemo(() => {
        if (filter === "installed") {
            return components.filter(comp => comp.installed);
        } else if (filter === "pending") {
            return components.filter(comp => !comp.installed);
        }
        return components;
    }, [components, filter]);

    // Calculamos las estadísticas una sola vez para usarlas en diferentes lugares
    const stats = useMemo(() => {
        const totalComponents = components.length;
        const completedComponents = components.filter(comp => comp.installed).length;
        const progress = totalComponents > 0 ? Math.round((completedComponents / totalComponents) * 100) : 0;

        return {
            totalComponents,
            completedComponents,
            pendingComponents: totalComponents - completedComponents,
            progress
        };
    }, [components]);

    // Obtener color basado en el progreso
    const getProgressColor = useCallback((progress) => {
        if (progress === 100) return 'bg-green-500';
        if (progress > 60) return 'bg-blue-500';
        if (progress > 30) return 'bg-yellow-500';
        return 'bg-orange-500';
    }, []);

    // Sección de progreso de la unidad con animación
    const renderUnitProgress = () => {
        const { totalComponents, completedComponents, pendingComponents, progress } = stats;
        const progressColor = getProgressColor(progress);

        return (
            <div className="bg-white border rounded-md p-4 mb-4 transition-all duration-300 hover:shadow-md">
                <h4 className="text-sm font-medium text-gray-700 mb-3 flex items-center">
                    <PieChart className="h-4 w-4 text-blue-500 mr-2" />
                    Progreso de Instalación
                </h4>

                <div className="flex items-center mb-3">
                    <div className="w-full bg-gray-200 rounded-full h-5 mr-3 overflow-hidden">
                        <div
                            className={`h-5 rounded-full flex items-center justify-center text-xs text-white font-medium transition-all duration-500 ease-in-out ${progressColor}`}
                            style={{ width: `${progress}%` }}
                        >
                            {progress > 10 ? `${progress}%` : ''}
                        </div>
                    </div>
                    <span className="text-sm font-medium text-gray-700 whitespace-nowrap">
                        {completedComponents} de {totalComponents}
                    </span>
                </div>

                <div className="grid grid-cols-3 gap-2 mt-3">
                    <div className="bg-green-50 border border-green-100 rounded-md p-2 text-center transition-all duration-300 hover:bg-green-100">
                        <span className="text-xs font-medium text-green-700 flex items-center justify-center">
                            <CheckCircle className="h-3 w-3 mr-1" />
                            Instalados
                        </span>
                        <p className="text-lg font-bold text-green-700">{completedComponents}</p>
                    </div>
                    <div className="bg-orange-50 border border-orange-100 rounded-md p-2 text-center transition-all duration-300 hover:bg-orange-100">
                        <span className="text-xs font-medium text-orange-700 flex items-center justify-center">
                            <Clock className="h-3 w-3 mr-1" />
                            Pendientes
                        </span>
                        <p className="text-lg font-bold text-orange-700">{pendingComponents}</p>
                    </div>
                    <div className={`${progressColor.replace('bg-', 'bg-').replace('500', '50')} border ${progressColor.replace('bg-', 'border-').replace('500', '100')} rounded-md p-2 text-center transition-all duration-300`}>
                        <span className={`text-xs font-medium ${progressColor.replace('bg-', 'text-').replace('500', '700')} flex items-center justify-center`}>
                            <Zap className="h-3 w-3 mr-1" />
                            Estado
                        </span>
                        <p className={`text-lg font-bold ${progressColor.replace('bg-', 'text-').replace('500', '700')}`}>
                            {progress === 100 ? 'Completo' : progress > 0 ? 'En Progreso' : 'Pendiente'}
                        </p>
                    </div>
                </div>
            </div>
        );
    };

    // Sección de edición de detalles de la unidad
    const renderUnitDetailsEditor = () => (
        <div className="bg-gray-50 rounded-md p-4 mb-4 transition-all duration-300 hover:shadow-md">
            <h4 className="text-sm font-medium text-gray-700 mb-3 flex items-center">
                <Edit className="h-4 w-4 text-gray-500 mr-2" />
                Editar Detalles de la Unidad
            </h4>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div>
                    <label className="block text-xs font-medium text-gray-500 mb-1">
                        Número de Serie
                    </label>
                    <div className="flex items-center">
                        <Hash className="h-4 w-4 text-gray-400 absolute ml-2" />
                        <input
                            type="text"
                            value={serialNumber}
                            onChange={(e) => setSerialNumber(e.target.value)}
                            className="pl-8 w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 text-sm transition-all duration-300"
                            placeholder="Ingrese número de serie"
                        />
                    </div>
                </div>

                <div className="md:col-span-2">
                    <label className="block text-xs font-medium text-gray-500 mb-1">
                        Notas
                    </label>
                    <div className="flex items-start">
                        <FileText className="h-4 w-4 text-gray-400 mt-2 mr-2" />
                        <textarea
                            value={notes}
                            onChange={(e) => setNotes(e.target.value)}
                            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 text-sm transition-all duration-300"
                            placeholder="Añada notas sobre esta unidad"
                            rows={3}
                        ></textarea>
                    </div>
                </div>
            </div>

            <div className="flex justify-end space-x-2 mt-4">
                <button
                    onClick={() => setIsEditing(false)}
                    className="px-3 py-1.5 border border-gray-300 rounded-md text-sm text-gray-700 hover:bg-gray-50 transition-colors duration-300"
                >
                    <X className="h-4 w-4 inline-block mr-1" />
                    Cancelar
                </button>
                <button
                    onClick={handleSaveChanges}
                    className="px-3 py-1.5 bg-blue-600 text-white rounded-md text-sm hover:bg-blue-700 transition-colors duration-300"
                >
                    <Save className="h-4 w-4 inline-block mr-1" />
                    Guardar Cambios
                </button>
            </div>
        </div>
    );

    // Sección de visualización de detalles de la unidad
    const renderUnitDetails = () => (
        <div className="bg-white border rounded-md p-4 mb-4 transition-all duration-300 hover:shadow-md">
            <div className="flex justify-between items-start">
                <h4 className="text-sm font-medium text-gray-700 mb-3">
                    Detalles de la Unidad
                </h4>
                <button
                    onClick={() => setIsEditing(true)}
                    className="text-blue-600 hover:text-blue-800 transition-colors duration-300"
                >
                    <Edit className="h-4 w-4" />
                </button>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div>
                    <p className="text-xs text-gray-500">Número de Serie</p>
                    <p className="text-sm">
                        {serialNumber ? serialNumber : <span className="text-gray-400 italic">No especificado</span>}
                    </p>
                </div>

                {notes && (
                    <div className="md:col-span-2">
                        <p className="text-xs text-gray-500">Notas</p>
                        <p className="text-sm whitespace-pre-line">{notes}</p>
                    </div>
                )}
            </div>
        </div>
    );

    // Sección de imágenes
    const renderImages = () => (
        <div className="mb-4">
            <h4 className="text-sm font-medium text-gray-700 mb-3 flex items-center">
                <Image className="h-4 w-4 text-gray-500 mr-2" />
                Imágenes
            </h4>

            <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-3">
                {images.map((image, index) => (
                    <div key={index} className="relative group transition-transform duration-300 transform hover:scale-105">
                        <img
                            src={image.url}
                            alt={`Imagen ${index + 1}`}
                            className="w-full h-32 object-cover rounded-md border border-gray-200 shadow-sm"
                        />
                        <button
                            onClick={() => handleDeleteImage(image, index)}
                            className="absolute top-1 right-1 bg-red-500 text-white rounded-full p-1 opacity-0 group-hover:opacity-100 transition-opacity duration-300"
                        >
                            <Trash2 className="h-4 w-4" />
                        </button>
                    </div>
                ))}

                <label className="flex flex-col items-center justify-center h-32 border-2 border-dashed border-gray-300 rounded-md cursor-pointer hover:bg-gray-50 transition-all duration-300 hover:border-blue-400">
                    {uploadingImages ? (
                        <div className="flex flex-col items-center text-gray-500">
                            <RefreshCcw className="h-6 w-6 animate-spin mb-1 text-blue-500" />
                            <span className="text-xs">Subiendo...</span>
                        </div>
                    ) : (
                        <div className="flex flex-col items-center text-gray-500">
                            <Camera className="h-6 w-6 mb-1 text-blue-500" />
                            <span className="text-xs">Añadir imagen</span>
                        </div>
                    )}
                    <input
                        type="file"
                        accept="image/*"
                        multiple
                        className="hidden"
                        onChange={handleImageUpload}
                        disabled={uploadingImages}
                    />
                </label>
            </div>
        </div>
    );

    // Modal de confirmación para eliminar componente con animación
    const renderDeleteConfirmation = () => (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 animate-fadeIn">
            <div className="bg-white rounded-lg p-6 max-w-md w-full shadow-xl transform transition-all animate-scaleIn">
                <h3 className="text-lg font-medium text-gray-900 mb-4 flex items-center">
                    <AlertCircle className="h-5 w-5 text-red-500 mr-2" />
                    Confirmar eliminación
                </h3>
                <p className="text-gray-600 mb-6">
                    ¿Está seguro que desea eliminar el componente "{selectedComponent?.name || 'Seleccionado'}"? Esta acción no se puede deshacer.
                </p>
                <div className="flex justify-end space-x-3">
                    <button
                        onClick={() => {
                            setShowDeleteConfirm(false);
                            setSelectedComponent(null);
                        }}
                        className="px-4 py-2 bg-gray-200 text-gray-800 rounded-md hover:bg-gray-300 transition-colors duration-300"
                    >
                        Cancelar
                    </button>
                    <button
                        onClick={() => handleDeleteComponent(selectedComponent?.id)}
                        className="px-4 py-2 bg-red-600 text-white rounded-md hover:bg-red-700 transition-colors duration-300"
                    >
                        Eliminar
                    </button>
                </div>
            </div>
        </div>
    );

    // Renderizaciones condicionales
    if (loading) {
        return (
            <div className="mt-4 ml-4 sm:ml-10 py-4 flex justify-center items-center h-32 animate-pulse">
                <div className="bg-blue-100 rounded-full p-3">
                    <RefreshCcw className="h-6 w-6 text-blue-500 animate-spin" />
                </div>
                <span className="ml-3 text-gray-500 font-medium">Cargando componentes...</span>
            </div>
        );
    }

    if (error) {
        return (
            <div className="mt-4 ml-4 sm:ml-10 py-4 text-center text-red-500 animate-fadeIn">
                <AlertTriangle className="h-8 w-8 mx-auto mb-2 text-red-400" />
                <p className="font-medium">{error}</p>
                <p className="text-sm text-gray-500 mt-2">Intente recargar la página</p>
            </div>
        );
    }

    return (
        <div className="mt-4 ml-2 sm:ml-6 md:ml-10 transition-all duration-500 animate-fadeIn">
            {/* Sección de detalles de la unidad */}
            {isEditing ? renderUnitDetailsEditor() : renderUnitDetails()}

            {/* Sección de progreso */}
            {renderUnitProgress()}

            {/* Sección de imágenes */}
            {renderImages()}

            {/* Sección de componentes */}
            <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center mb-3 gap-2">
                <h4 className="text-sm font-medium text-gray-700 flex items-center">
                    <CheckSquare className="h-4 w-4 text-blue-500 mr-2" />
                    Componentes ({components.length})
                </h4>

                <div className="flex flex-wrap items-center gap-2">
                    {/* Filtros */}
                    <div className="border rounded-md overflow-hidden flex shadow-sm">
                        <button
                            onClick={() => setFilter("all")}
                            className={`px-2 py-1 text-xs ${filter === "all" ?
                                "bg-blue-100 text-blue-700 font-medium" :
                                "bg-white text-gray-700 hover:bg-gray-50"} transition-colors duration-300`}
                        >
                            Todos ({components.length})
                        </button>
                        <button
                            onClick={() => setFilter("installed")}
                            className={`px-2 py-1 text-xs ${filter === "installed" ?
                                "bg-green-100 text-green-700 font-medium" :
                                "bg-white text-gray-700 hover:bg-gray-50"} transition-colors duration-300`}
                        >
                            Instalados ({components.filter(c => c.installed).length})
                        </button>
                        <button
                            onClick={() => setFilter("pending")}
                            className={`px-2 py-1 text-xs ${filter === "pending" ?
                                "bg-orange-100 text-orange-700 font-medium" :
                                "bg-white text-gray-700 hover:bg-gray-50"} transition-colors duration-300`}
                        >
                            Pendientes ({components.filter(c => !c.installed).length})
                        </button>
                    </div>

                    {/* Botón de acciones masivas */}
                    <div className="relative z-20">
                        <button
                            onClick={() => setShowBulkActions(!showBulkActions)}
                            className="px-2 py-1 border border-gray-300 rounded-md text-xs font-medium text-gray-700 hover:bg-gray-50 transition-colors duration-300 shadow-sm"
                        >
                            Acciones <Filter className="h-3 w-3 inline ml-1" />
                        </button>

                        {showBulkActions && (
                            <div className="absolute right-0 mt-1 bg-white border border-gray-200 rounded-md shadow-lg z-10 w-48 overflow-hidden animate-fadeIn">
                                <div className="py-1">
                                    <button
                                        onClick={() => handleBulkAction("markInstalled")}
                                        className="w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-green-50 transition-colors duration-300 flex items-center"
                                    >
                                        <CircleCheck className="h-4 w-4 text-green-500 mr-2" />
                                        Marcar como instalados
                                    </button>
                                    <button
                                        onClick={() => handleBulkAction("markPending")}
                                        className="w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-orange-50 transition-colors duration-300 flex items-center"
                                    >
                                        <CircleMinus className="h-4 w-4 text-orange-500 mr-2" />
                                        Marcar como pendientes
                                    </button>
                                    <div className="border-t border-gray-100 my-1"></div>
                                    <button
                                        onClick={() => handleBulkAction("delete")}
                                        className="w-full text-left px-4 py-2 text-sm text-red-600 hover:bg-red-50 transition-colors duration-300 flex items-center"
                                    >
                                        <Trash2 className="h-4 w-4 text-red-500 mr-2" />
                                        Eliminar componentes
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>

            {components.length === 0 ? (
                <div className="text-center text-gray-500 py-8 border rounded-md">
                    <Package className="h-8 w-8 text-gray-300 mx-auto mb-2" />
                    <p>No hay componentes asignados a esta unidad.</p>
                    <p className="text-sm mt-2">Use el botón "Agregar Componente" para añadir equipos.</p>
                </div>
            ) : filteredComponents.length === 0 ? (
                <div className="text-center text-gray-500 py-8 border rounded-md">
                    <Filter className="h-8 w-8 text-gray-300 mx-auto mb-2" />
                    <p>No hay componentes que coincidan con el filtro seleccionado.</p>
                    <p className="text-sm mt-2">Cambie el filtro para ver más componentes.</p>
                </div>
            ) : (
                <div className="border rounded-md divide-y divide-gray-200 overflow-hidden shadow-sm">
                    {filteredComponents.map((component) => (
                        <div
                            key={component.id}
                            className={`px-4 py-3 flex justify-between items-center hover:bg-gray-50 transition-colors duration-300 ${component.installed ? 'bg-green-50' : ''
                                }`}
                        >
                            <div className="flex items-center">
                                <button
                                    onClick={() => handleToggleInstalled(component.id, component.installed)}
                                    className="text-gray-400 hover:text-blue-500 mr-3 transition-colors duration-300"
                                    aria-label={component.installed ? "Marcar como pendiente" : "Marcar como instalado"}
                                    title={component.installed ? "Marcar como pendiente" : "Marcar como instalado"}
                                >
                                    <CheckSquare className={`h-5 w-5 ${component.installed ? 'text-green-500' : 'text-gray-300'} transition-colors duration-300`} />
                                </button>
                                <div>
                                    <div className="text-sm font-medium text-gray-900 flex items-center flex-wrap gap-1">
                                        {component.name || "Componente sin nombre"}
                                        {component.installed && (
                                            <span className="ml-2 px-2 py-0.5 bg-green-100 text-green-800 rounded-md text-xs transition-all duration-300">
                                                Instalado
                                            </span>
                                        )}
                                    </div>
                                    <div className="text-xs text-gray-500 flex flex-wrap items-center gap-1 mt-1">
                                        <span>Cantidad: {component.quantity || 1}</span>
                                        {component.inventoryItemId && (
                                            <span className="flex items-center">
                                                <span className="mx-1 text-gray-300">•</span>
                                                ID Inventario: {
                                                    typeof component.inventoryItemId === 'string'
                                                        ? component.inventoryItemId.substring(0, 8)
                                                        : 'Inválido'
                                                }
                                            </span>
                                        )}
                                    </div>
                                </div>
                            </div>

                            <div className="flex items-center space-x-3">
                                {component.link && (
                                    <a
                                        href={component.link}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="text-blue-500 hover:text-blue-700 text-sm transition-colors duration-300"
                                    >
                                        Ver enlace
                                    </a>
                                )}

                                <button
                                    onClick={() => {
                                        setSelectedComponent(component);
                                        setShowDeleteConfirm(true);
                                    }}
                                    className="text-gray-400 hover:text-red-500 transition-colors duration-300"
                                    aria-label="Eliminar componente"
                                    title="Eliminar componente"
                                >
                                    <MinusCircle className="h-5 w-5" />
                                </button>
                            </div>
                        </div>
                    ))}
                </div>
            )}

            {/* Modal de confirmación */}
            {showDeleteConfirm && renderDeleteConfirmation()}

            {/* Estilos adicionales para animaciones */}
            <style jsx global>{`
                @keyframes fadeIn {
                    from { opacity: 0; }
                    to { opacity: 1; }
                }
                @keyframes scaleIn {
                    from { transform: scale(0.95); opacity: 0; }
                    to { transform: scale(1); opacity: 1; }
                }
                .animate-fadeIn {
                    animation: fadeIn 0.3s ease-out;
                }
                .animate-scaleIn {
                    animation: scaleIn 0.3s ease-out;
                }
                .animate-pulse {
                    animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
                }
                @keyframes pulse {
                    0%, 100% { opacity: 1; }
                    50% { opacity: 0.7; }
                }
            `}</style>
        </div>
    );
};

export default UnitDetail;