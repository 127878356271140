import React, { useState, useEffect, useRef, useCallback } from "react";
import { User, MapPin, Calendar, Flag } from "lucide-react";

const DatosPersonales = ({ solicitudId, datosIniciales, onUpdate }) => {
  const [form, setForm] = useState({
    apellidoPaterno: "",
    apellidoMaterno: "",
    nombres: "",
    edad: "",
    domicilio: "",
    colonia: "",
    codigoPostal: "",
    telefono: "",
    lugarNacimiento: "",
    fechaNacimiento: "",
    nacionalidad: "",
  });
  
  // Referencia para el timer de debounce
  const debounceTimerRef = useRef(null);

  // Cargar datos iniciales si existen
  useEffect(() => {
    if (datosIniciales && Object.keys(datosIniciales).length > 0) {
      setForm(prevForm => ({
        ...prevForm,
        ...datosIniciales
      }));
    }
  }, [datosIniciales]);

  // Función para manejar el debounce en los cambios
  const debouncedUpdate = useCallback((updatedForm) => {
    // Cancelar el timer anterior si existe
    if (debounceTimerRef.current) {
      clearTimeout(debounceTimerRef.current);
    }
    
    // Crear un nuevo timer para actualizar después de 3 segundos
    debounceTimerRef.current = setTimeout(() => {
      if (onUpdate) {
        onUpdate(updatedForm);
      }
    }, 3000);
  }, [onUpdate]);

  // Limpiar el timer al desmontar
  useEffect(() => {
    return () => {
      if (debounceTimerRef.current) {
        clearTimeout(debounceTimerRef.current);
      }
    };
  }, []);

  // Maneja los cambios en los campos
  const handleChange = (e) => {
    const { name, value } = e.target;
    const updatedForm = { ...form, [name]: value };
    setForm(updatedForm);
    
    // Iniciar el debounce para autoguardado
    debouncedUpdate(updatedForm);
  };

  return (
    <div className="bg-white rounded-md">
      <div className="mb-6">
        <h2 className="text-lg font-semibold text-gray-800 mb-2">Datos Personales</h2>
        <p className="text-sm text-gray-600">Por favor complete la información personal solicitada.</p>
        <div className="text-xs text-blue-600 mt-1">
          Los cambios se guardan automáticamente 3 segundos después de dejar de escribir.
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        {/* Sección de información personal */}
        <div className="md:col-span-2 mb-2">
          <h3 className="text-sm font-medium text-gray-700 mb-3">Información Personal</h3>
          <div className="h-0.5 bg-gray-100 w-full"></div>
        </div>

        <div className="space-y-1">
          <label htmlFor="apellidoPaterno" className="block text-sm font-medium text-gray-700">
            Apellido Paterno <span className="text-red-500">*</span>
          </label>
          <div className="relative rounded-md shadow-sm">
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <User className="h-4 w-4 text-gray-400" />
            </div>
            <input
              type="text"
              id="apellidoPaterno"
              name="apellidoPaterno"
              className="block w-full pl-10 py-2 sm:text-sm border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
              value={form.apellidoPaterno}
              onChange={handleChange}
              required
            />
          </div>
        </div>

        <div className="space-y-1">
          <label htmlFor="apellidoMaterno" className="block text-sm font-medium text-gray-700">
            Apellido Materno
          </label>
          <div className="relative rounded-md shadow-sm">
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <User className="h-4 w-4 text-gray-400" />
            </div>
            <input
              type="text"
              id="apellidoMaterno"
              name="apellidoMaterno"
              className="block w-full pl-10 py-2 sm:text-sm border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
              value={form.apellidoMaterno}
              onChange={handleChange}
            />
          </div>
        </div>

        <div className="md:col-span-2 space-y-1">
          <label htmlFor="nombres" className="block text-sm font-medium text-gray-700">
            Nombre(s) <span className="text-red-500">*</span>
          </label>
          <div className="relative rounded-md shadow-sm">
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <User className="h-4 w-4 text-gray-400" />
            </div>
            <input
              type="text"
              id="nombres"
              name="nombres"
              className="block w-full pl-10 py-2 sm:text-sm border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
              value={form.nombres}
              onChange={handleChange}
              required
            />
          </div>
        </div>

        <div className="space-y-1">
          <label htmlFor="edad" className="block text-sm font-medium text-gray-700">
            Edad
          </label>
          <input
            type="number"
            id="edad"
            name="edad"
            className="block w-full py-2 px-3 sm:text-sm border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
            value={form.edad}
            onChange={handleChange}
          />
        </div>

        <div className="space-y-1">
          <label htmlFor="telefono" className="block text-sm font-medium text-gray-700">
            Teléfono <span className="text-red-500">*</span>
          </label>
          <input
            type="tel"
            id="telefono"
            name="telefono"
            className="block w-full py-2 px-3 sm:text-sm border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
            value={form.telefono}
            onChange={handleChange}
            required
          />
        </div>

        {/* Sección de domicilio */}
        <div className="md:col-span-2 mb-2 mt-4">
          <h3 className="text-sm font-medium text-gray-700 mb-3">Domicilio</h3>
          <div className="h-0.5 bg-gray-100 w-full"></div>
        </div>

        <div className="md:col-span-2 space-y-1">
          <label htmlFor="domicilio" className="block text-sm font-medium text-gray-700">
            Dirección <span className="text-red-500">*</span>
          </label>
          <div className="relative rounded-md shadow-sm">
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <MapPin className="h-4 w-4 text-gray-400" />
            </div>
            <input
              type="text"
              id="domicilio"
              name="domicilio"
              className="block w-full pl-10 py-2 sm:text-sm border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
              value={form.domicilio}
              onChange={handleChange}
              required
            />
          </div>
        </div>

        <div className="space-y-1">
          <label htmlFor="colonia" className="block text-sm font-medium text-gray-700">
            Colonia
          </label>
          <input
            type="text"
            id="colonia"
            name="colonia"
            className="block w-full py-2 px-3 sm:text-sm border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
            value={form.colonia}
            onChange={handleChange}
          />
        </div>

        <div className="space-y-1">
          <label htmlFor="codigoPostal" className="block text-sm font-medium text-gray-700">
            Código Postal
          </label>
          <input
            type="text"
            id="codigoPostal"
            name="codigoPostal"
            className="block w-full py-2 px-3 sm:text-sm border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
            value={form.codigoPostal}
            onChange={handleChange}
          />
        </div>

        {/* Sección de lugar de nacimiento */}
        <div className="md:col-span-2 mb-2 mt-4">
          <h3 className="text-sm font-medium text-gray-700 mb-3">Lugar de Nacimiento</h3>
          <div className="h-0.5 bg-gray-100 w-full"></div>
        </div>

        <div className="space-y-1">
          <label htmlFor="lugarNacimiento" className="block text-sm font-medium text-gray-700">
            Lugar de Nacimiento
          </label>
          <div className="relative rounded-md shadow-sm">
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <MapPin className="h-4 w-4 text-gray-400" />
            </div>
            <input
              type="text"
              id="lugarNacimiento"
              name="lugarNacimiento"
              className="block w-full pl-10 py-2 sm:text-sm border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
              value={form.lugarNacimiento}
              onChange={handleChange}
            />
          </div>
        </div>

        <div className="space-y-1">
          <label htmlFor="fechaNacimiento" className="block text-sm font-medium text-gray-700">
            Fecha de Nacimiento
          </label>
          <div className="relative rounded-md shadow-sm">
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <Calendar className="h-4 w-4 text-gray-400" />
            </div>
            <input
              type="date"
              id="fechaNacimiento"
              name="fechaNacimiento"
              className="block w-full pl-10 py-2 sm:text-sm border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
              value={form.fechaNacimiento}
              onChange={handleChange}
            />
          </div>
        </div>

        <div className="space-y-1">
          <label htmlFor="nacionalidad" className="block text-sm font-medium text-gray-700">
            Nacionalidad
          </label>
          <div className="relative rounded-md shadow-sm">
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <Flag className="h-4 w-4 text-gray-400" />
            </div>
            <input
              type="text"
              id="nacionalidad"
              name="nacionalidad"
              className="block w-full pl-10 py-2 sm:text-sm border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
              value={form.nacionalidad}
              onChange={handleChange}
            />
          </div>
        </div>
      </div>

      {/* Mensaje informativo sobre campos requeridos */}
      <div className="mt-4 text-xs text-gray-500">
        Los campos marcados con <span className="text-red-500">*</span> son obligatorios.
      </div>
    </div>
  );
};

export default DatosPersonales;