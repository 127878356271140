import React, { useEffect, useState } from "react";
import {
  Container,
  Paper,
  Typography,
  Grid,
  CircularProgress,
  Box,
  Card,
  CardContent,
  Chip,
  Divider,
  Button,
  Alert,
  Tooltip,
  Fade,
  Skeleton,
  Badge,
  Avatar,
  IconButton,
} from "@mui/material";
import { collection, query, where, getDocs } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { db } from "../../firebase";
import { format } from "date-fns";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import CommentIcon from "@mui/icons-material/Comment";
import AssignmentIcon from "@mui/icons-material/Assignment";
import RefreshIcon from "@mui/icons-material/Refresh";
import InfoIcon from "@mui/icons-material/Info";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import AccessTimeIcon from "@mui/icons-material/AccessTime";

const UnidadInspections = ({ unidad }) => {
  const [inspections, setInspections] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [refreshing, setRefreshing] = useState(false);
  const navigate = useNavigate();

  const fetchInspections = async () => {
    setLoading(true);
    try {
      const q = query(
        collection(db, "inspecciones"),
        where("Unidad", "==", unidad)
      );
      const querySnapshot = await getDocs(q);
      const inspectionsData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      // Ordenar inspecciones por fecha (más reciente primero)
      inspectionsData.sort((a, b) => {
        const dateA =
          a.Date && a.Date.seconds
            ? new Date(a.Date.seconds * 1000)
            : new Date(0);
        const dateB =
          b.Date && b.Date.seconds
            ? new Date(b.Date.seconds * 1000)
            : new Date(0);
        return dateB - dateA;
      });

      setInspections(inspectionsData);
      setError(null);
    } catch (error) {
      console.error("Error al obtener las inspecciones:", error);
      setError(
        "Error al cargar las inspecciones. Por favor, intente nuevamente."
      );
    } finally {
      setLoading(false);
      setRefreshing(false);
    }
  };

  useEffect(() => {
    fetchInspections();
  }, [unidad]);

  const handleRefresh = () => {
    setRefreshing(true);
    fetchInspections();
  };

  const handleInspectionClick = (id) => {
    navigate(`/inspeccion/${id}`);
  };

  const getStatusChip = (status) => {
    if (!status)
      return <Chip size="small" label="Sin estado" color="default" />;

    switch (status.toLowerCase()) {
      case "aprobado":
      case "aprobada":
      case "ok":
      case "completo":
      case "completa":
        return (
          <Chip
            size="small"
            icon={<CheckCircleIcon />}
            label="Aprobado"
            color="success"
          />
        );
      case "pendiente":
      case "en progreso":
      case "en proceso":
        return (
          <Chip
            size="small"
            icon={<AccessTimeIcon />}
            label="Pendiente"
            color="warning"
          />
        );
      case "rechazado":
      case "rechazada":
      case "fallo":
      case "error":
        return (
          <Chip
            size="small"
            icon={<ErrorIcon />}
            label="Rechazado"
            color="error"
          />
        );
      default:
        return <Chip size="small" label={status} color="info" />;
    }
  };

  if (loading && !refreshing) {
    return (
      <Box display="flex" justifyContent="center" py={4}>
        <CircularProgress color="primary" />
      </Box>
    );
  }

  return (
    <Box sx={{ position: "relative" }}>
      {/* Título y barra superior */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 2,
          flexWrap: "wrap",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <AssignmentIcon sx={{ color: "#1976d2", mr: 1 }} />
          <Typography
            variant="h6"
            sx={{
              color: "#333",
              fontWeight: "500",
            }}
          >
            Inspecciones de Unidad {unidad}
          </Typography>
        </Box>

        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <Tooltip title="Actualizar inspecciones">
            <IconButton
              size="small"
              onClick={handleRefresh}
              disabled={refreshing}
              color="primary"
            >
              <RefreshIcon fontSize="small" />
            </IconButton>
          </Tooltip>

          <Chip
            icon={<InfoIcon />}
            label={`Total: ${inspections.length}`}
            color="primary"
            size="small"
            variant="outlined"
          />
        </Box>
      </Box>

      {error && (
        <Alert severity="error" sx={{ mb: 3 }}>
          {error}
        </Alert>
      )}

      {/* Mensaje si no hay inspecciones */}
      {!loading && inspections.length === 0 && (
        <Alert severity="info" sx={{ mb: 3 }}>
          No se encontraron inspecciones para esta unidad.
        </Alert>
      )}

      {/* Lista de inspecciones */}
      <Fade in={!loading || refreshing}>
        <Grid container spacing={2}>
          {loading && refreshing
            ? // Mostrar skeletons mientras se actualiza
              Array(3)
                .fill(0)
                .map((_, index) => (
                  <Grid item xs={12} key={`skeleton-${index}`}>
                    <Skeleton
                      variant="rectangular"
                      width="100%"
                      height={120}
                      sx={{ borderRadius: 2 }}
                    />
                  </Grid>
                ))
            : // Mostrar las inspecciones
              inspections.map((inspection) => {
                const inspectionDate =
                  inspection.Date && inspection.Date.seconds
                    ? new Date(inspection.Date.seconds * 1000)
                    : null;

                return (
                  <Grid
                    item
                    xs={12}
                    key={inspection.id}
                    onClick={() => handleInspectionClick(inspection.id)}
                  >
                    <Card
                      elevation={2}
                      sx={{
                        borderRadius: 2,
                        transition: "transform 0.2s, box-shadow 0.2s",
                        cursor: "pointer",
                        border: "1px solid #e0e0e0",
                        "&:hover": {
                          transform: "translateY(-3px)",
                          boxShadow: "0 6px 12px rgba(0,0,0,0.1)",
                        },
                      }}
                    >
                      <CardContent sx={{ p: { xs: 2, sm: 3 } }}>
                        <Grid container spacing={2} alignItems="center">
                          <Grid item xs={12} sm={9}>
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "flex-start",
                                mb: 1,
                                flexWrap: "wrap",
                              }}
                            >
                              <Typography
                                variant="subtitle1"
                                sx={{
                                  fontWeight: 600,
                                  color: "#1976d2",
                                  mb: 1,
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <VerifiedUserIcon
                                  fontSize="small"
                                  sx={{ mr: 0.5 }}
                                />
                                Inspección #{inspection.id.substring(0, 8)}...
                              </Typography>

                              {getStatusChip(inspection.Status)}
                            </Box>

                            <Divider sx={{ my: 1 }} />

                            <Grid container spacing={1}>
                              <Grid item xs={12} sm={6}>
                                <Typography
                                  variant="body2"
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    color: "#555",
                                    mb: 1,
                                  }}
                                >
                                  <CalendarTodayIcon
                                    fontSize="small"
                                    sx={{ mr: 0.5, color: "#1976d2" }}
                                  />
                                  <strong>Fecha:</strong>&nbsp;
                                  {inspectionDate
                                    ? format(inspectionDate, "dd/MM/yyyy")
                                    : "N/A"}
                                </Typography>
                              </Grid>

                              <Grid item xs={12}>
                                <Typography
                                  variant="body2"
                                  sx={{
                                    display: "flex",
                                    alignItems: "flex-start",
                                    color: "#555",
                                  }}
                                >
                                  <CommentIcon
                                    fontSize="small"
                                    sx={{ mr: 0.5, mt: 0.5, color: "#1976d2" }}
                                  />
                                  <Box>
                                    <strong>Comentario:</strong>&nbsp;
                                    {inspection.Comentario
                                      ? inspection.Comentario.length > 100
                                        ? `${inspection.Comentario.substring(
                                            0,
                                            100
                                          )}...`
                                        : inspection.Comentario
                                      : "Sin comentarios"}
                                  </Box>
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>

                          <Grid item xs={12} sm={3}>
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "100%",
                                minHeight: 100,
                              }}
                            >
                              {inspection.fotos &&
                              inspection.fotos["Foto unidad"] ? (
                                <Box
                                  sx={{ position: "relative", width: "100%" }}
                                >
                                  <Avatar
                                    variant="rounded"
                                    src={inspection.fotos["Foto unidad"]}
                                    alt="Foto unidad"
                                    sx={{
                                      width: "100%",
                                      height: "auto",
                                      aspectRatio: "4/3",
                                      borderRadius: 1,
                                      boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                                    }}
                                  />
                                  <Badge
                                    badgeContent="Foto"
                                    color="secondary"
                                    sx={{
                                      position: "absolute",
                                      bottom: 5,
                                      right: 5,
                                    }}
                                  />
                                </Box>
                              ) : (
                                <Box
                                  sx={{
                                    borderRadius: 1,
                                    bgcolor: "#f5f5f5",
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    p: 2,
                                    border: "1px dashed #bdbdbd",
                                    height: "100%",
                                    minHeight: 100,
                                  }}
                                >
                                  <PhotoCameraIcon
                                    sx={{ color: "#bdbdbd", fontSize: 40 }}
                                  />
                                  <Typography
                                    variant="caption"
                                    align="center"
                                    sx={{ color: "#757575", mt: 1 }}
                                  >
                                    Sin foto disponible
                                  </Typography>
                                </Box>
                              )}
                            </Box>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                );
              })}
        </Grid>
      </Fade>

      {/* Botón para añadir inspección (opcional) */}
      <Box sx={{ display: "flex", justifyContent: "center", mt: 3 }}>
        <Button
          variant="outlined"
          startIcon={<AssignmentIcon />}
          onClick={() => navigate(`/nueva-inspeccion/${unidad}`)}
        >
          Nueva Inspección
        </Button>
      </Box>
    </Box>
  );
};

export default UnidadInspections;
