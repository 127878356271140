import React, { useEffect, useState } from "react";
import {
  Container,
  Grid,
  Box,
  Typography,
  Paper,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Chip,
  Divider,
  CircularProgress,
  Fade,
  Card,
  CardContent,
  IconButton,
  Tooltip,
  Badge,
  Alert,
  Breadcrumbs,
  Link,
} from "@mui/material";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../firebase";
import { useNavigate } from "react-router-dom";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import PlaceIcon from "@mui/icons-material/Place";
import GppMaybeIcon from "@mui/icons-material/GppMaybe";
import ElectricCarIcon from "@mui/icons-material/ElectricCar";
import SearchIcon from "@mui/icons-material/Search";
import FilterListIcon from "@mui/icons-material/FilterList";
import ClearIcon from "@mui/icons-material/Clear";
import HomeIcon from "@mui/icons-material/Home";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import InfoIcon from "@mui/icons-material/Info";

const SearchUnidades = () => {
  const [unidades, setUnidades] = useState([]);
  const [filteredUnidades, setFilteredUnidades] = useState([]);
  const [searchUnidad, setSearchUnidad] = useState("");
  const [selectedZona, setSelectedZona] = useState("");
  const [selectedRequerida, setSelectedRequerida] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const requeridas = ["TRUE", "FALSE"];

  const sortedZonas = (zonas) => {
    return zonas.sort((a, b) => a.localeCompare(b));
  };

  const zonas = sortedZonas([
    ...new Set(unidades.map((unidad) => unidad.Zona)),
  ]);

  // Indicadores estadísticos
  const totalUnidades = unidades.length;
  const unidadesRequeridas = unidades.filter(
    (unidad) => unidad.Requerida === "TRUE"
  ).length;
  const unidadesNoRequeridas = totalUnidades - unidadesRequeridas;

  useEffect(() => {
    const fetchUnidades = async () => {
      setLoading(true);
      try {
        const querySnapshot = await getDocs(collection(db, "unidades"));
        const unidadesData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        unidadesData.sort((a, b) => a.Unidad.localeCompare(b.Unidad));
        setUnidades(unidadesData);
        setFilteredUnidades(unidadesData);
        setError(null);
      } catch (error) {
        console.error("Error al obtener las unidades:", error);
        setError(
          "No se pudieron cargar las unidades. Intente nuevamente más tarde."
        );
      } finally {
        setLoading(false);
      }
    };

    fetchUnidades();
  }, []);

  useEffect(() => {
    let filtered = unidades;

    if (searchUnidad) {
      filtered = filtered.filter((unidad) =>
        unidad.Unidad.toLowerCase().includes(searchUnidad.toLowerCase())
      );
    }

    if (selectedZona) {
      filtered = filtered.filter((unidad) => unidad.Zona === selectedZona);
    }

    if (selectedRequerida) {
      filtered = filtered.filter(
        (unidad) => unidad.Requerida === selectedRequerida
      );
    }

    setFilteredUnidades(filtered);
  }, [searchUnidad, selectedZona, selectedRequerida, unidades]);

  const handleUnidadClick = (id) => {
    navigate(`/unidad/${id}`);
  };

  const handleClearFilters = () => {
    setSearchUnidad("");
    setSelectedZona("");
    setSelectedRequerida("");
  };

  const getStatusColor = (requerida) => {
    return requerida === "TRUE" ? "#ff5252" : "#4caf50";
  };

  return (
    <Box
      sx={{
        bgcolor: "#f5f7fa",
        minHeight: "100vh",
        py: 4,
        px: { xs: 1, sm: 2, md: 3 },
      }}
    >
      <Container maxWidth="lg">
        {/* Breadcrumbs */}
        <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 2, color: "#555" }}>
          <Link
            underline="hover"
            sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}
            color="inherit"
            onClick={() => navigate("/")}
          >
            <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
            Inicio
          </Link>
          <Typography
            color="text.primary"
            sx={{ display: "flex", alignItems: "center" }}
          >
            <DirectionsCarIcon sx={{ mr: 0.5 }} fontSize="inherit" />
            Unidades
          </Typography>
        </Breadcrumbs>

        {/* Panel de estadísticas */}
        <Grid container spacing={3} sx={{ mb: 3 }}>
          <Grid item xs={12} sm={4}>
            <Card elevation={2} sx={{ height: "100%" }}>
              <CardContent sx={{ textAlign: "center" }}>
                <DirectionsCarIcon
                  sx={{ fontSize: 40, color: "#1976d2", mb: 1 }}
                />
                <Typography variant="h4" color="text.secondary">
                  {totalUnidades}
                </Typography>
                <Typography variant="body1" color="text.secondary">
                  Total Unidades
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Card elevation={2} sx={{ height: "100%" }}>
              <CardContent sx={{ textAlign: "center" }}>
                <Badge color="error" badgeContent=" " variant="dot">
                  <GppMaybeIcon
                    sx={{ fontSize: 40, color: "#ff5252", mb: 1 }}
                  />
                </Badge>
                <Typography variant="h4" color="error">
                  {unidadesRequeridas}
                </Typography>
                <Typography variant="body1" color="text.secondary">
                  Unidades Requeridas
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Card elevation={2} sx={{ height: "100%" }}>
              <CardContent sx={{ textAlign: "center" }}>
                <Badge color="success" badgeContent=" " variant="dot">
                  <GppMaybeIcon
                    sx={{ fontSize: 40, color: "#4caf50", mb: 1 }}
                  />
                </Badge>
                <Typography variant="h4" color="success">
                  {unidadesNoRequeridas}
                </Typography>
                <Typography variant="body1" color="text.secondary">
                  Unidades No Requeridas
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        <Paper
          elevation={3}
          sx={{
            p: { xs: 2, sm: 3, md: 4 },
            borderRadius: 2,
            backgroundColor: "#ffffff",
          }}
        >
          <Typography
            variant="h5"
            sx={{
              color: "#1976d2",
              fontWeight: "600",
              mb: 3,
              display: "flex",
              alignItems: "center",
            }}
          >
            <SearchIcon sx={{ mr: 1 }} />
            Gestor de Unidades
          </Typography>

          {error && (
            <Alert severity="error" sx={{ mb: 3 }}>
              {error}
            </Alert>
          )}

          <Paper
            elevation={1}
            sx={{
              p: 3,
              mb: 4,
              borderRadius: 2,
              backgroundColor: "#f8f9fa",
            }}
          >
            <Typography
              variant="subtitle1"
              gutterBottom
              sx={{
                display: "flex",
                alignItems: "center",
                mb: 2,
                color: "#555",
                fontWeight: "500",
              }}
            >
              <FilterListIcon sx={{ mr: 1 }} />
              Filtros de búsqueda
            </Typography>

            <Grid container spacing={3}>
              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  label="Buscar por Unidad"
                  variant="outlined"
                  value={searchUnidad}
                  onChange={(e) => setSearchUnidad(e.target.value)}
                  InputProps={{
                    endAdornment: searchUnidad ? (
                      <IconButton
                        size="small"
                        onClick={() => setSearchUnidad("")}
                        edge="end"
                      >
                        <ClearIcon fontSize="small" />
                      </IconButton>
                    ) : (
                      <SearchIcon color="action" />
                    ),
                  }}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "&.Mui-focused fieldset": {
                        borderColor: "#1976d2",
                      },
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel>Zona</InputLabel>
                  <Select
                    value={selectedZona}
                    onChange={(e) => setSelectedZona(e.target.value)}
                    label="Zona"
                  >
                    <MenuItem value="">
                      <em>Todas las zonas</em>
                    </MenuItem>
                    {zonas.map((zona, index) => (
                      <MenuItem key={index} value={zona}>
                        {zona}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel>Estado</InputLabel>
                  <Select
                    value={selectedRequerida}
                    onChange={(e) => setSelectedRequerida(e.target.value)}
                    label="Estado"
                  >
                    <MenuItem value="">
                      <em>Todos los estados</em>
                    </MenuItem>
                    <MenuItem value="TRUE">
                      <Chip
                        size="small"
                        label="Requerida"
                        color="error"
                        sx={{ mr: 1 }}
                      />
                    </MenuItem>
                    <MenuItem value="FALSE">
                      <Chip
                        size="small"
                        label="No Requerida"
                        color="success"
                        sx={{ mr: 1 }}
                      />
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                mt: 3,
              }}
            >
              <Typography variant="body2" color="text.secondary">
                {filteredUnidades.length} unidad(es) encontrada(s)
              </Typography>
              <IconButton
                onClick={handleClearFilters}
                disabled={!searchUnidad && !selectedZona && !selectedRequerida}
                color="primary"
                title="Limpiar filtros"
              >
                <ClearIcon />
              </IconButton>
            </Box>
          </Paper>

          {loading ? (
            <Box sx={{ display: "flex", justifyContent: "center", my: 5 }}>
              <CircularProgress color="primary" />
            </Box>
          ) : filteredUnidades.length === 0 ? (
            <Alert severity="info" sx={{ mt: 2 }}>
              No se encontraron unidades con los filtros seleccionados.
            </Alert>
          ) : (
            <Fade in={!loading}>
              <Grid container spacing={3}>
                {filteredUnidades.map((unidad) => (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    key={unidad.id}
                    sx={{ cursor: "pointer" }}
                  >
                    <Tooltip
                      title="Click para ver detalles"
                      arrow
                      placement="top"
                    >
                      <Card
                        elevation={2}
                        onClick={() => handleUnidadClick(unidad.id)}
                        sx={{
                          height: "100%",
                          transition: "transform 0.3s, box-shadow 0.3s",
                          "&:hover": {
                            transform: "translateY(-5px)",
                            boxShadow: "0 8px 24px rgba(25, 118, 210, 0.15)",
                          },
                          position: "relative",
                          overflow: "visible",
                          borderTop: `4px solid ${getStatusColor(
                            unidad.Requerida
                          )}`,
                        }}
                      >
                        <Badge
                          badgeContent={
                            unidad.Requerida === "TRUE" ? "R" : "NR"
                          }
                          color={
                            unidad.Requerida === "TRUE" ? "error" : "success"
                          }
                          sx={{
                            position: "absolute",
                            top: -10,
                            right: 15,
                            "& .MuiBadge-badge": {
                              fontSize: "0.75rem",
                              fontWeight: "bold",
                              width: 25,
                              height: 25,
                              borderRadius: "50%",
                            },
                          }}
                        />
                        <CardContent>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              mb: 2,
                            }}
                          >
                            <ElectricCarIcon
                              sx={{
                                fontSize: 40,
                                color: "#1976d2",
                                mr: 1.5,
                              }}
                            />
                            <Typography
                              variant="h6"
                              sx={{ fontWeight: 600, color: "#333" }}
                            >
                              Unidad: {unidad.Unidad}
                            </Typography>
                          </Box>

                          <Divider sx={{ my: 1.5 }} />

                          <Typography
                            variant="body1"
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              mb: 1,
                              color: "#555",
                            }}
                          >
                            <PlaceIcon
                              sx={{
                                color: "#1976d2",
                                mr: 1,
                                fontSize: 20,
                              }}
                            />
                            <strong>Zona:</strong>&nbsp;{unidad.Zona}
                          </Typography>

                          <Typography
                            variant="body1"
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              color: "#555",
                            }}
                          >
                            <GppMaybeIcon
                              sx={{
                                color: getStatusColor(unidad.Requerida),
                                mr: 1,
                                fontSize: 20,
                              }}
                            />
                            <strong>Estado:</strong>&nbsp;
                            <Chip
                              size="small"
                              label={
                                unidad.Requerida === "TRUE"
                                  ? "Requerida"
                                  : "No Requerida"
                              }
                              color={
                                unidad.Requerida === "TRUE"
                                  ? "error"
                                  : "success"
                              }
                              sx={{
                                ml: 1,
                                fontSize: "0.7rem",
                                height: 24,
                              }}
                            />
                          </Typography>
                        </CardContent>
                      </Card>
                    </Tooltip>
                  </Grid>
                ))}
              </Grid>
            </Fade>
          )}
        </Paper>
      </Container>
    </Box>
  );
};

export default SearchUnidades;
