import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Container,
  Grid,
  Paper,
  Typography,
  Box,
  CircularProgress,
  Card,
  CardContent,
  Chip,
  Divider,
  IconButton,
  Button,
  Alert,
  Breadcrumbs,
  Link,
  Tooltip,
  Fade,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { db } from "../../firebase";
import { doc, getDoc } from "firebase/firestore";
import UnidadInspections from "./UnidadInspections";
import HomeIcon from "@mui/icons-material/Home";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import RadioIcon from "@mui/icons-material/Radio";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import VideocamIcon from "@mui/icons-material/Videocam";
import LocalAtmIcon from "@mui/icons-material/LocalAtm";
import BadgeIcon from "@mui/icons-material/Badge";
import BrushIcon from "@mui/icons-material/Brush";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import GppMaybeIcon from "@mui/icons-material/GppMaybe";
import RefreshIcon from "@mui/icons-material/Refresh";

const UnidadDetails = () => {
  const { uid } = useParams();
  const navigate = useNavigate();
  const [unidadData, setUnidadData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [refreshing, setRefreshing] = useState(false);

  const fetchUnidad = async () => {
    setLoading(true);
    try {
      const docRef = doc(db, "unidades", uid);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setUnidadData(docSnap.data());
        setError(null);
      } else {
        setError("No se encontró la información de la unidad solicitada.");
      }
    } catch (err) {
      console.error("Error al recuperar los datos de la unidad:", err);
      setError(
        "Error al recuperar los datos de la unidad. Por favor, intente nuevamente."
      );
    } finally {
      setLoading(false);
      setRefreshing(false);
    }
  };

  useEffect(() => {
    fetchUnidad();
  }, [uid]);

  const handleRefresh = () => {
    setRefreshing(true);
    fetchUnidad();
  };

  const getStatusColor = (requerida) => {
    return requerida === "TRUE" ? "#ff5252" : "#4caf50";
  };

  const getStatusIcon = (value) => {
    if (value === "TRUE") return <CheckCircleIcon sx={{ color: "#4caf50" }} />;
    if (value === "FALSE") return <CancelIcon sx={{ color: "#ff5252" }} />;
    return null;
  };

  const getItemIcon = (label) => {
    switch (label) {
      case "Radio":
        return <RadioIcon />;
      case "Bocina":
        return <VolumeUpIcon />;
      case "DVR":
        return <VideocamIcon />;
      case "Cobrar":
        return <LocalAtmIcon />;
      case "Identificador":
        return <BadgeIcon />;
      case "Rotulación":
        return <BrushIcon />;
      case "Torreta":
        return <NotificationsActiveIcon />;
      case "Requerida":
        return <GppMaybeIcon />;
      default:
        return null;
    }
  };

  if (loading && !refreshing) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
        bgcolor="#f5f7fa"
      >
        <CircularProgress color="primary" />
      </Box>
    );
  }

  if (error && !unidadData) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        height="100vh"
        bgcolor="#f5f7fa"
        px={3}
      >
        <Alert severity="error" sx={{ mb: 2, maxWidth: 500 }}>
          {error}
        </Alert>
        <Button
          variant="contained"
          startIcon={<ArrowBackIcon />}
          onClick={() => navigate(-1)}
        >
          Volver a la lista de unidades
        </Button>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        bgcolor: "#f5f7fa",
        minHeight: "100vh",
        py: 4,
        px: { xs: 1, sm: 2, md: 3 },
      }}
    >
      <Container maxWidth="lg">
        {/* Breadcrumbs */}
        <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 2, color: "#555" }}>
          <Link
            underline="hover"
            sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}
            color="inherit"
            onClick={() => navigate("/")}
          >
            <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
            Inicio
          </Link>
          <Link
            underline="hover"
            sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}
            color="inherit"
            onClick={() => navigate("/unidades")}
          >
            <DirectionsCarIcon sx={{ mr: 0.5 }} fontSize="inherit" />
            Unidades
          </Link>
          <Typography
            color="text.primary"
            sx={{ display: "flex", alignItems: "center" }}
          >
            {loading ? (
              <Skeleton width={100} />
            ) : (
              <>
                <DirectionsCarIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                {unidadData?.Unidad || "Detalles de unidad"}
              </>
            )}
          </Typography>
        </Breadcrumbs>

        {/* Botón de retorno */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mb: 3,
            alignItems: "center",
          }}
        >
          <Button
            variant="outlined"
            startIcon={<ArrowBackIcon />}
            onClick={() => navigate(-1)}
          >
            Regresar
          </Button>

          <Tooltip title="Actualizar datos">
            <IconButton
              onClick={handleRefresh}
              color="primary"
              disabled={refreshing}
            >
              <RefreshIcon />
            </IconButton>
          </Tooltip>
        </Box>

        {error && (
          <Alert severity="error" sx={{ mb: 3 }}>
            {error}
          </Alert>
        )}

        <Fade in={!loading || refreshing}>
          <Box>
            <Card
              elevation={3}
              sx={{
                mb: 4,
                borderRadius: 2,
                overflow: "visible",
                position: "relative",
                borderTop: unidadData
                  ? `4px solid ${getStatusColor(unidadData.Requerida)}`
                  : "none",
              }}
            >
              {unidadData && (
                <Chip
                  label={
                    unidadData.Requerida === "TRUE"
                      ? "Requerida"
                      : "No Requerida"
                  }
                  color={unidadData.Requerida === "TRUE" ? "error" : "success"}
                  sx={{
                    position: "absolute",
                    top: -16,
                    right: 16,
                    fontWeight: "bold",
                  }}
                />
              )}

              <CardContent sx={{ p: { xs: 2, sm: 3, md: 4 } }}>
                <Typography
                  variant="h4"
                  align="center"
                  gutterBottom
                  sx={{
                    color: "#1976d2",
                    borderBottom: "2px solid #1976d2",
                    paddingBottom: "12px",
                    fontWeight: "bold",
                    fontSize: { xs: "1.5rem", sm: "2rem", md: "2.5rem" },
                    mb: 3,
                  }}
                >
                  {loading ? (
                    <Skeleton width="50%" sx={{ mx: "auto" }} />
                  ) : (
                    <>
                      <DirectionsCarIcon sx={{ mr: 1, verticalAlign: "top" }} />
                      Unidad: {unidadData?.Unidad}
                    </>
                  )}
                </Typography>

                {loading ? (
                  <Skeleton variant="rectangular" width="100%" height={60} />
                ) : (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor: "#f8f9fa",
                      p: 2,
                      borderRadius: 2,
                      mb: 4,
                    }}
                  >
                    <LocationOnIcon
                      sx={{ color: "#1976d2", fontSize: 30, mr: 1 }}
                    />
                    <Typography
                      variant="h6"
                      sx={{
                        color: "#333",
                        fontWeight: "500",
                        fontSize: { xs: "1.2rem", sm: "1.5rem" },
                      }}
                    >
                      <strong>Zona:</strong>{" "}
                      {unidadData?.Zona || "No especificada"}
                    </Typography>
                  </Box>
                )}

                <Typography
                  variant="h6"
                  sx={{
                    color: "#555",
                    mb: 2,
                    fontWeight: "500",
                  }}
                >
                  Especificaciones de la Unidad
                </Typography>

                <Divider sx={{ mb: 3 }} />

                {loading ? (
                  <Box sx={{ mb: 4 }}>
                    {[...Array(8)].map((_, index) => (
                      <Skeleton
                        key={index}
                        variant="rectangular"
                        width="100%"
                        height={50}
                        sx={{ mb: 1, borderRadius: 1 }}
                      />
                    ))}
                  </Box>
                ) : (
                  <TableContainer
                    component={Paper}
                    elevation={0}
                    sx={{ mb: 4 }}
                  >
                    <Table sx={{ minWidth: 650 }}>
                      <TableHead>
                        <TableRow sx={{ backgroundColor: "#f5f7fa" }}>
                          <TableCell width="5%"></TableCell>
                          <TableCell sx={{ fontWeight: "bold" }}>
                            Característica
                          </TableCell>
                          <TableCell sx={{ fontWeight: "bold" }} align="center">
                            Estado
                          </TableCell>
                          <TableCell sx={{ fontWeight: "bold" }} align="center">
                            Valor
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {[
                          { label: "Radio", value: unidadData?.Radio },
                          { label: "Bocina", value: unidadData?.Bocina },
                          { label: "DVR", value: unidadData?.DVR },
                          { label: "Cobrar", value: unidadData?.Cobrar },
                          {
                            label: "Identificador",
                            value: unidadData?.Identificador,
                          },
                          {
                            label: "Rotulación",
                            value: unidadData?.Rotulacion,
                          },
                          { label: "Torreta", value: unidadData?.Torreta },
                          { label: "ESN", value: unidadData?.ESN },
                          { label: "Requerida", value: unidadData?.Requerida },
                        ].map((item, index) => (
                          <TableRow
                            key={index}
                            sx={{
                              "&:nth-of-type(odd)": {
                                backgroundColor: "#fafafa",
                              },
                              "&:hover": { backgroundColor: "#f1f8fe" },
                            }}
                          >
                            <TableCell>
                              <Box sx={{ color: "#1976d2" }}>
                                {getItemIcon(item.label)}
                              </Box>
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Typography
                                variant="body1"
                                sx={{ fontWeight: "500" }}
                              >
                                {item.label}
                              </Typography>
                            </TableCell>
                            <TableCell align="center">
                              {getStatusIcon(item.value)}
                            </TableCell>
                            <TableCell align="center">
                              {item.value === "TRUE" ? (
                                <Chip label="Sí" size="small" color="success" />
                              ) : item.value === "FALSE" ? (
                                <Chip label="No" size="small" color="error" />
                              ) : item.value ? (
                                <Typography>{item.value}</Typography>
                              ) : (
                                <Typography
                                  variant="body2"
                                  color="text.secondary"
                                >
                                  N/A
                                </Typography>
                              )}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}

                {/* Componente de inspecciones */}
                {unidadData && (
                  <Box sx={{ mt: 2 }}>
                    <Typography
                      variant="h6"
                      sx={{
                        color: "#555",
                        mb: 2,
                        fontWeight: "500",
                      }}
                    >
                      Historial de Inspecciones
                    </Typography>
                    <Divider sx={{ mb: 3 }} />
                    <Card variant="outlined" sx={{ p: 2 }}>
                      <UnidadInspections unidad={unidadData.Unidad} />
                    </Card>
                  </Box>
                )}
              </CardContent>
            </Card>
          </Box>
        </Fade>
      </Container>
    </Box>
  );
};

export default UnidadDetails;
