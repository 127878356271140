import React from "react";
import { Book } from "lucide-react";

const SeccionEscolaridad = ({ candidato }) => (
    <div className="bg-white shadow-md rounded-lg p-6">
        <h3 className="text-lg font-bold text-gray-900 mb-4 flex items-center">
            <Book className="h-5 w-5 text-blue-500 mr-2" />
            Escolaridad
        </h3>

        {candidato.escolaridad?.actual && (
            <div className="mb-6">
                <h4 className="font-semibold text-gray-800 mb-2">Estudios Actuales</h4>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-y-2 gap-x-6 pl-4">
                    <div className="flex flex-col">
                        <span className="text-sm text-gray-500">Escuela</span>
                        <span className="font-medium">{candidato.escolaridad.actual.escuela || "No especificado"}</span>
                    </div>
                    <div className="flex flex-col">
                        <span className="text-sm text-gray-500">Curso</span>
                        <span className="font-medium">{candidato.escolaridad.actual.curso || "No especificado"}</span>
                    </div>
                    <div className="flex flex-col">
                        <span className="text-sm text-gray-500">Grado</span>
                        <span className="font-medium">{candidato.escolaridad.actual.grado || "No especificado"}</span>
                    </div>
                    <div className="flex flex-col">
                        <span className="text-sm text-gray-500">Horario</span>
                        <span className="font-medium">{candidato.escolaridad.actual.horario || "No especificado"}</span>
                    </div>
                </div>
            </div>
        )}

        {candidato.escolaridad?.niveles && (
            <div>
                <h4 className="font-semibold text-gray-800 mb-2">Historial Académico</h4>

                {candidato.escolaridad.niveles.primaria && candidato.escolaridad.niveles.primaria.nombre && (
                    <div className="mb-4 border-l-2 border-blue-200 pl-4 py-2">
                        <h5 className="font-medium text-blue-600">Primaria</h5>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-y-1 gap-x-6 mt-1">
                            <div className="flex flex-col">
                                <span className="text-sm text-gray-500">Nombre</span>
                                <span className="font-medium">{candidato.escolaridad.niveles.primaria.nombre || "No especificado"}</span>
                            </div>
                            <div className="flex flex-col">
                                <span className="text-sm text-gray-500">Dirección</span>
                                <span className="font-medium">{candidato.escolaridad.niveles.primaria.direccion || "No especificado"}</span>
                            </div>
                            <div className="flex flex-col">
                                <span className="text-sm text-gray-500">Años</span>
                                <span className="font-medium">{candidato.escolaridad.niveles.primaria.años || "No especificado"}</span>
                            </div>
                            <div className="flex flex-col">
                                <span className="text-sm text-gray-500">Periodo</span>
                                <span className="font-medium">
                                    {candidato.escolaridad.niveles.primaria.de && candidato.escolaridad.niveles.primaria.a
                                        ? `${candidato.escolaridad.niveles.primaria.de} - ${candidato.escolaridad.niveles.primaria.a}`
                                        : "No especificado"}
                                </span>
                            </div>
                            <div className="flex flex-col">
                                <span className="text-sm text-gray-500">Título</span>
                                <span className="font-medium">{candidato.escolaridad.niveles.primaria.titulo || "No especificado"}</span>
                            </div>
                        </div>
                    </div>
                )}

                {candidato.escolaridad.niveles.secundaria && candidato.escolaridad.niveles.secundaria.nombre && (
                    <div className="mb-4 border-l-2 border-blue-200 pl-4 py-2">
                        <h5 className="font-medium text-blue-600">Secundaria</h5>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-y-1 gap-x-6 mt-1">
                            <div className="flex flex-col">
                                <span className="text-sm text-gray-500">Nombre</span>
                                <span className="font-medium">{candidato.escolaridad.niveles.secundaria.nombre || "No especificado"}</span>
                            </div>
                            <div className="flex flex-col">
                                <span className="text-sm text-gray-500">Dirección</span>
                                <span className="font-medium">{candidato.escolaridad.niveles.secundaria.direccion || "No especificado"}</span>
                            </div>
                            <div className="flex flex-col">
                                <span className="text-sm text-gray-500">Años</span>
                                <span className="font-medium">{candidato.escolaridad.niveles.secundaria.años || "No especificado"}</span>
                            </div>
                            <div className="flex flex-col">
                                <span className="text-sm text-gray-500">Periodo</span>
                                <span className="font-medium">
                                    {candidato.escolaridad.niveles.secundaria.de && candidato.escolaridad.niveles.secundaria.a
                                        ? `${candidato.escolaridad.niveles.secundaria.de} - ${candidato.escolaridad.niveles.secundaria.a}`
                                        : "No especificado"}
                                </span>
                            </div>
                            <div className="flex flex-col">
                                <span className="text-sm text-gray-500">Título</span>
                                <span className="font-medium">{candidato.escolaridad.niveles.secundaria.titulo || "No especificado"}</span>
                            </div>
                        </div>
                    </div>
                )}

                {/* Preparatoria, Profesional y Comercial siguen el mismo patrón */}
                {/* Similar para las demás niveles educativos */}
            </div>
        )}
    </div>
);

export default SeccionEscolaridad;