import React, { useState } from "react";
import { NavLink, Outlet, useLocation } from "react-router-dom";
import {
  Menu,
  Package,
  Users,
  Link2,
  UserPlus,
  FileText,
  DollarSign,
  Briefcase,
  Phone,
  Shield,
  ChevronDown,
  ChevronRight,
  Settings,
  Home,
  X,
  ChevronsLeft,
  ChevronsRight
} from "lucide-react";

const Administracion = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [expandedCategory, setExpandedCategory] = useState(null);
  const location = useLocation();

  const toggleCategory = (title) => {
    setExpandedCategory(expandedCategory === title ? null : title);
  };

  const categories = [
    {
      title: "Gestión General",
      items: [
        { path: "inventario", label: "Inventario", icon: <Package size={20} /> },
        { path: "enlaces", label: "Enlaces", icon: <Link2 size={20} /> },
        { path: "clientes", label: "Clientes", icon: <Users size={20} /> },
        { path: "NuevoEmpleado", label: "Nuevo Empleado", icon: <UserPlus size={20} /> },
        { path: "Inspecciones", label: "Mantenimiento Policial", icon: <Shield size={20} /> },
      ],
    },
    {
      title: "Finanzas",
      items: [
        { path: "ganancias", label: "Ganancias", icon: <DollarSign size={20} /> },
        { path: "facturas", label: "Facturas", icon: <FileText size={20} /> },
        { path: "gastos", label: "Gastos", icon: <Briefcase size={20} /> },
      ],
    },
    {
      title: "Contactos",
      items: [
        { path: "contactos", label: "Contactos", icon: <Phone size={20} /> },
      ],
    },
    {
      title: "Inventarios",
      items: [
        { path: "garantias", label: "Garantías", icon: <Shield size={20} /> },
      ],
    },
  ];

  // Determinar si un elemento de la categoría está activo
  const isCategoryActive = (category) => {
    return category.items.some(item => location.pathname.includes(item.path));
  };

  // Expandir automáticamente la categoría con el elemento activo
  React.useEffect(() => {
    categories.forEach(category => {
      if (isCategoryActive(category)) {
        setExpandedCategory(category.title);
      }
    });
  }, [location.pathname]);

  return (
    <div className="flex h-screen bg-gray-50">
      {/* Barra Lateral */}
      <div
        className={`${isSidebarOpen ? "w-72" : "w-20"
          } bg-white shadow-xl z-20 transition-all duration-300 flex flex-col h-full overflow-hidden relative`}
      >
        {/* Encabezado */}
        <div className="flex items-center justify-between p-5 border-b border-gray-200 bg-gradient-to-r from-blue-600 to-indigo-700 text-white">
          <div className="flex items-center gap-3">
            <Settings className="w-6 h-6" />
            {isSidebarOpen && <h1 className="text-xl font-bold">Administración</h1>}
          </div>
          <button
            onClick={() => setIsSidebarOpen(!isSidebarOpen)}
            className="p-1.5 rounded-full hover:bg-white/20 transition-colors"
          >
            {isSidebarOpen ? <ChevronsLeft className="w-5 h-5" /> : <ChevronsRight className="w-5 h-5" />}
          </button>
        </div>

        {/* Botón de retorno al dashboard */}
        <NavLink
          to="/dashboard"
          className="flex items-center gap-3 p-4 text-gray-700 hover:bg-gray-100 border-b border-gray-200"
        >
          <Home size={20} />
          {isSidebarOpen && <span className="font-medium">Volver al Dashboard</span>}
        </NavLink>

        {/* Navegación */}
        <div className="flex-grow overflow-y-auto scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-transparent p-3">
          <nav className="space-y-2">
            {categories.map((category) => {
              const isActive = isCategoryActive(category);
              const isExpanded = expandedCategory === category.title;

              return (
                <div key={category.title} className="rounded-lg overflow-hidden">
                  {/* Encabezado de categoría */}
                  <button
                    onClick={() => toggleCategory(category.title)}
                    className={`w-full p-3 flex items-center justify-between rounded-lg transition-colors
                      ${isActive ? 'bg-blue-50 text-blue-700' : 'text-gray-700 hover:bg-gray-100'}`}
                  >
                    <div className="flex items-center gap-3 font-medium">
                      {isSidebarOpen ? (
                        <span className="text-sm">{category.title}</span>
                      ) : (
                        <span className="text-xs text-gray-500 uppercase">{category.title.charAt(0)}</span>
                      )}
                    </div>
                    {isSidebarOpen && (
                      isExpanded ? (
                        <ChevronDown size={18} className="text-gray-500" />
                      ) : (
                        <ChevronRight size={18} className="text-gray-500" />
                      )
                    )}
                  </button>

                  {/* Elementos de la categoría */}
                  <div className={`mt-1 space-y-1 overflow-hidden transition-all duration-300 
                    ${(isExpanded || !isSidebarOpen) ? 'max-h-96' : 'max-h-0'}`}>
                    {category.items.map((item) => (
                      <NavLink
                        key={item.path}
                        to={item.path}
                        className={({ isActive }) =>
                          `flex items-center gap-3 p-3 pl-${isSidebarOpen ? '6' : '3'} rounded-lg transition-colors
                          ${isActive
                            ? "bg-blue-600 text-white"
                            : "text-gray-600 hover:bg-gray-100"
                          }`
                        }
                      >
                        {item.icon}
                        {isSidebarOpen && <span className="text-sm font-medium">{item.label}</span>}
                      </NavLink>
                    ))}
                  </div>
                </div>
              );
            })}
          </nav>
        </div>

        {/* Footer con información de usuario */}
        <div className="border-t border-gray-200 p-4 bg-gray-50">
          <div className="flex items-center gap-3">
            <div className="w-8 h-8 rounded-full bg-blue-600 flex items-center justify-center text-white font-bold">
              A
            </div>
            {isSidebarOpen && (
              <div className="overflow-hidden">
                <p className="text-sm font-medium text-gray-800 truncate">Administrador</p>
                <p className="text-xs text-gray-500 truncate">admin@ejemplo.com</p>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Contenido Principal */}
      <div className="flex-1 overflow-auto bg-gray-50">
        {/* Header móvil */}
        <div className="lg:hidden flex items-center justify-between p-4 bg-white border-b border-gray-200 sticky top-0 z-10">
          <button
            onClick={() => setIsSidebarOpen(true)}
            className="p-2 rounded-lg hover:bg-gray-100"
          >
            <Menu className="w-6 h-6 text-gray-700" />
          </button>
          <h1 className="text-lg font-bold text-gray-800">Administración</h1>
          <div className="w-10"></div> {/* Espaciador para centrar el título */}
        </div>

        {/* Overlay cuando el sidebar está abierto en móvil */}
        {isSidebarOpen && (
          <div
            className="lg:hidden fixed inset-0 bg-black bg-opacity-50 z-10"
            onClick={() => setIsSidebarOpen(false)}
          ></div>
        )}

        {/* Contenido de la página */}
        <div className="container mx-auto p-6">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default Administracion;