import React, { useState, useEffect, useRef, useCallback } from 'react';
import {
  Building,
  Calendar,
  DollarSign,
  UserCheck,
  PhoneCall,
  MapPin,
  Plus
} from 'lucide-react';

const HistorialEmpleos = ({ solicitudId, datosIniciales, onUpdate }) => {
  // Usar ref en lugar de estado para evitar re-renders durante la edición
  const internalFormRef = useRef({
    empleos: [
      {
        tiempoDesde: new Date().getTime(),
        tiempoHasta: null,
        nombreCompania: '',
        direccion: '',
        telefono: '',
        puestoDesempenado: '',
        sueldoInicial: '',
        sueldoFinal: '',
        motivoSeparacion: '',
        nombreJefe: '',
        puestoJefe: '',
        comentariosJefes: '',
        solicitarInformes: false,
        trabajoActual: true
      }
    ]
  });

  // Estado visible para forzar re-renders cuando sea necesario
  const [form, setForm] = useState(internalFormRef.current);

  // Estado para rastrear el último campo editado
  const [lastEditedField, setLastEditedField] = useState(null);

  // Referencia para el timer de debounce
  const debounceTimerRef = useRef(null);

  // Cargar datos iniciales si existen (solo una vez)
  useEffect(() => {
    if (datosIniciales && datosIniciales.length > 0) {
      // Actualizar la referencia interna
      internalFormRef.current = {
        empleos: datosIniciales.map(empleo => ({
          ...empleo,
          tiempoDesde: empleo.tiempoDesde || new Date().getTime(),
          tiempoHasta: empleo.tiempoHasta || null
        }))
      };

      // Actualizar el estado visible
      setForm({ ...internalFormRef.current });
    }
  }, []); // Dependencia vacía - solo se ejecuta al montar

  // Función para manejar el debounce en los cambios
  const debouncedUpdate = useCallback(() => {
    // Cancelar el timer anterior si existe
    if (debounceTimerRef.current) {
      clearTimeout(debounceTimerRef.current);
    }

    // Crear un nuevo timer para actualizar después de 3 segundos
    debounceTimerRef.current = setTimeout(() => {
      if (onUpdate) {
        onUpdate(internalFormRef.current.empleos);
      }
    }, 3000);
  }, [onUpdate]);

  // Limpiar el timer al desmontar
  useEffect(() => {
    return () => {
      if (debounceTimerRef.current) {
        clearTimeout(debounceTimerRef.current);
      }
    };
  }, []);

  // Función de sincronización - actualiza el estado visible a partir de la ref interna
  const syncFormState = useCallback(() => {
    setForm({ ...internalFormRef.current });
  }, []);

  // Convertir timestamp a fecha formateada
  const formatDate = (timestamp) => {
    if (!timestamp) return '';
    const date = new Date(timestamp);
    return date.toISOString().split('T')[0];
  };

  // Convertir fecha a timestamp
  const parseDate = (dateString) => {
    if (!dateString) return null;
    return new Date(dateString).getTime();
  };

  // Handler para cambios en el formulario - actualiza la ref interna sin re-render
  const handleInputChange = useCallback((index, campo, valor) => {
    // Manejar cambios específicos de fecha
    if (campo === 'tiempoDesde' || campo === 'tiempoHasta') {
      valor = parseDate(valor);
    }

    // Manejar cambio de trabajo actual
    if (campo === 'trabajoActual') {
      internalFormRef.current.empleos[index] = {
        ...internalFormRef.current.empleos[index],
        trabajoActual: valor,
        tiempoHasta: valor ? null : internalFormRef.current.empleos[index].tiempoHasta
      };
    } else {
      // Actualizar directamente la referencia sin causar re-render
      internalFormRef.current.empleos[index] = {
        ...internalFormRef.current.empleos[index],
        [campo]: valor
      };
    }

    // Almacenar el campo que se está editando
    setLastEditedField(`${index}-${campo}`);

    // Programar el debounce
    debouncedUpdate();

    // Forzar actualización del estado visible
    setForm({ ...internalFormRef.current });
  }, [debouncedUpdate]);

  // Añadir nueva entrada de empleo
  const addEmpleoEntry = useCallback(() => {
    internalFormRef.current.empleos.push({
      tiempoDesde: new Date().getTime(),
      tiempoHasta: new Date().getTime(),
      nombreCompania: '',
      direccion: '',
      telefono: '',
      puestoDesempenado: '',
      sueldoInicial: '',
      sueldoFinal: '',
      motivoSeparacion: '',
      nombreJefe: '',
      puestoJefe: '',
      comentariosJefes: '',
      solicitarInformes: false,
      trabajoActual: false
    });

    // Forzar actualización del estado visible
    setForm({ ...internalFormRef.current });
    debouncedUpdate();
  }, [debouncedUpdate]);

  // Eliminar entrada de empleo
  const removeEmpleoEntry = useCallback((index) => {
    // Eliminar la entrada del array en la referencia interna
    internalFormRef.current.empleos.splice(index, 1);

    // Forzar actualización del estado visible
    setForm({ ...internalFormRef.current });
    debouncedUpdate();
  }, [debouncedUpdate]);

  // useEffect para sincronizar el ref con el estado visible periódicamente
  useEffect(() => {
    // Sincronizar el estado cada 5 segundos o cuando cambie lastEditedField
    const syncInterval = setInterval(syncFormState, 5000);

    // Limpieza al desmontar
    return () => {
      clearInterval(syncInterval);
    };
  }, [syncFormState, lastEditedField]);

  return (
    <div className="bg-white rounded-lg shadow-md p-4 md:p-6">
      <div className="mb-6">
        <h2 className="text-xl font-bold text-gray-800 mb-2">Historial de Empleos</h2>
        <p className="text-sm text-gray-600">
          Por favor, proporcione información detallada sobre sus empleos anteriores y actuales.
        </p>
        <div className="text-xs text-blue-600 mt-1">
          Los cambios se guardan automáticamente 3 segundos después de dejar de escribir.
        </div>
      </div>

      {form.empleos.map((empleo, index) => (
        <div
          key={index}
          className="border border-gray-200 rounded-lg mb-6 p-4 bg-gray-50"
        >
          {/* Encabezado de la entrada */}
          <div className="flex justify-between items-center mb-4">
            <h3 className="text-lg font-semibold text-gray-700">
              {empleo.trabajoActual ? 'Empleo Actual' : `Empleo Anterior `}
            </h3>
            {!empleo.trabajoActual && (
              <button
                onClick={() => removeEmpleoEntry(index)}
                className="text-red-500 hover:text-red-700 transition-colors"
              >
                Eliminar
              </button>
            )}
          </div>

          {/* Periodo de Tiempo */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Tiempo que presto servicios
              </label>
              <div className="flex space-x-2 items-center">
                <div className="relative flex-1">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <Calendar className="h-4 w-4 text-gray-400" />
                  </div>
                  <input
                    type="date"
                    value={formatDate(empleo.tiempoDesde)}
                    onChange={(e) => handleInputChange(index, 'tiempoDesde', e.target.value)}
                    className="block w-full pl-10 py-2 sm:text-sm border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                  />
                </div>
                {!empleo.trabajoActual && (
                  <div className="relative flex-1">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                      <Calendar className="h-4 w-4 text-gray-400" />
                    </div>
                    <input
                      type="date"
                      value={formatDate(empleo.tiempoHasta)}
                      onChange={(e) => handleInputChange(index, 'tiempoHasta', e.target.value)}
                      className="block w-full pl-10 py-2 sm:text-sm border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                    />
                  </div>
                )}
                {empleo.trabajoActual && (
                  <div className="flex items-center">
                    <input
                      type="checkbox"
                      id={`trabajo-actual-${index}`}
                      checked={empleo.trabajoActual}
                      onChange={(e) => handleInputChange(index, 'trabajoActual', e.target.checked)}
                      className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded mr-2"
                    />
                    <label
                      htmlFor={`trabajo-actual-${index}`}
                      className="text-sm text-gray-700"
                    >
                      Trabajo Actual
                    </label>
                  </div>
                )}
              </div>
            </div>
          </div>

          {/* Detalles de la Compañía */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Nombre de la Compañía
              </label>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <Building className="h-4 w-4 text-gray-400" />
                </div>
                <input
                  type="text"
                  value={empleo.nombreCompania}
                  onChange={(e) => handleInputChange(index, 'nombreCompania', e.target.value)}
                  placeholder="Nombre de la empresa"
                  className="block w-full pl-10 py-2 sm:text-sm border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                />
              </div>
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Dirección
              </label>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <MapPin className="h-4 w-4 text-gray-400" />
                </div>
                <input
                  type="text"
                  value={empleo.direccion}
                  onChange={(e) => handleInputChange(index, 'direccion', e.target.value)}
                  placeholder="Dirección de la empresa"
                  className="block w-full pl-10 py-2 sm:text-sm border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                />
              </div>
            </div>
          </div>

          {/* Contacto y Puesto */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Teléfono
              </label>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <PhoneCall className="h-4 w-4 text-gray-400" />
                </div>
                <input
                  type="text"
                  value={empleo.telefono}
                  onChange={(e) => handleInputChange(index, 'telefono', e.target.value)}
                  placeholder="Teléfono de contacto"
                  className="block w-full pl-10 py-2 sm:text-sm border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                />
              </div>
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Puesto Desempeñado
              </label>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <UserCheck className="h-4 w-4 text-gray-400" />
                </div>
                <input
                  type="text"
                  value={empleo.puestoDesempenado}
                  onChange={(e) => handleInputChange(index, 'puestoDesempenado', e.target.value)}
                  placeholder="Cargo ocupado"
                  className="block w-full pl-10 py-2 sm:text-sm border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                />
              </div>
            </div>
          </div>

          {/* Detalles de Salario */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Sueldo Mensual
              </label>
              <div className="flex space-x-2">
                <div className="relative flex-1">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <DollarSign className="h-4 w-4 text-gray-400" />
                  </div>
                  <input
                    type="text"
                    placeholder="Inicial"
                    value={empleo.sueldoInicial}
                    onChange={(e) => handleInputChange(index, 'sueldoInicial', e.target.value)}
                    className="block w-full pl-10 py-2 sm:text-sm border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                  />
                </div>
                <div className="relative flex-1">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <DollarSign className="h-4 w-4 text-gray-400" />
                  </div>
                  <input
                    type="text"
                    placeholder="Final"
                    value={empleo.sueldoFinal}
                    onChange={(e) => handleInputChange(index, 'sueldoFinal', e.target.value)}
                    className="block w-full pl-10 py-2 sm:text-sm border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                  />
                </div>
              </div>
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Motivo de Separación
              </label>
              <input
                type="text"
                value={empleo.motivoSeparacion}
                onChange={(e) => handleInputChange(index, 'motivoSeparacion', e.target.value)}
                placeholder="Razón de salida"
                className="block w-full py-2 sm:text-sm border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
              />
            </div>
          </div>

          {/* Detalles del Jefe */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Nombre de su Jefe Directo
              </label>
              <input
                type="text"
                value={empleo.nombreJefe}
                onChange={(e) => handleInputChange(index, 'nombreJefe', e.target.value)}
                placeholder="Nombre del jefe"
                className="block w-full py-2 sm:text-sm border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Puesto de su Jefe Directo
              </label>
              <input
                type="text"
                value={empleo.puestoJefe}
                onChange={(e) => handleInputChange(index, 'puestoJefe', e.target.value)}
                placeholder="Cargo del jefe"
                className="block w-full py-2 sm:text-sm border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
              />
            </div>
          </div>

          {/* Comentarios y Referencias */}
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Comentarios de sus Jefes
            </label>
            <textarea
              rows={3}
              value={empleo.comentariosJefes}
              onChange={(e) => handleInputChange(index, 'comentariosJefes', e.target.value)}
              placeholder="Comentarios adicionales"
              className="block w-full py-2 sm:text-sm border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
            />
          </div>

          {/* Solicitud de Informes */}
          <div className="flex items-center">
            <input
              type="checkbox"
              id={`solicitar-informes-${index}`}
              checked={empleo.solicitarInformes}
              onChange={(e) => handleInputChange(index, 'solicitarInformes', e.target.checked)}
              className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded mr-2"
            />
            <label
              htmlFor={`solicitar-informes-${index}`}
              className="text-sm text-gray-700"
            >
              Podemos solicitar informes de usted
            </label>
          </div>
        </div>
      ))}

      {/* Botón para Añadir Nuevo Empleo */}
      <div className="mt-4">
        <button
          onClick={addEmpleoEntry}
          className="w-full bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 transition-colors flex items-center justify-center"
        >
          <Plus className="h-5 w-5 mr-2" /> Añadir Otro Empleo
        </button>
      </div>
    </div>
  );
};

export default HistorialEmpleos;