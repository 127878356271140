import React from "react";
import { Award } from "lucide-react";

const SeccionConocimientos = ({ candidato }) => (
    <div className="bg-white shadow-md rounded-lg p-6">
        <h3 className="text-lg font-bold text-gray-900 mb-4 flex items-center">
            <Award className="h-5 w-5 text-blue-500 mr-2" />
            Conocimientos y Habilidades
        </h3>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-y-3 gap-x-6">
            <div className="flex flex-col">
                <span className="text-sm text-gray-500">Idiomas</span>
                <span className="font-medium">{candidato.conocimientosGenerales?.idiomas || "No especificado"}</span>
            </div>
            <div className="flex flex-col">
                <span className="text-sm text-gray-500">Software</span>
                <span className="font-medium">{candidato.conocimientosGenerales?.software || "No especificado"}</span>
            </div>
            <div className="flex flex-col">
                <span className="text-sm text-gray-500">Funciones de Oficina</span>
                <span className="font-medium">{candidato.conocimientosGenerales?.funcionesOficina || "No especificado"}</span>
            </div>
            <div className="flex flex-col">
                <span className="text-sm text-gray-500">Maquinaria</span>
                <span className="font-medium">{candidato.conocimientosGenerales?.maquinaria || "No especificado"}</span>
            </div>
            <div className="flex flex-col col-span-2">
                <span className="text-sm text-gray-500">Otros Trabajos o Funciones</span>
                <span className="font-medium">{candidato.conocimientosGenerales?.otrosTrabajosOFunciones || "No especificado"}</span>
            </div>
        </div>
    </div>
);

export default SeccionConocimientos;