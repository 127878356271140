import React, { useState, useEffect, useRef, useCallback } from 'react';
import {
    Megaphone,
    Users,
    ShieldCheck,
    Link2,
    Shield,
    Plane,
    MapPin,
    Calendar,
    DollarSign,
    Car,
    CreditCard
} from 'lucide-react';

const DatosGenerales = ({ solicitudId, datosIniciales, onUpdate }) => {
    // Usar ref en lugar de estado para evitar re-renders durante la edición
    const internalFormRef = useRef({
        // Datos Generales
        medioConocimiento: '',
        medioConocimientoOtro: '',
        parientesTrabajando: 'no',
        parientesTrabajandoNombres: '',
        afianzado: 'no',
        afianzadoCompania: '',
        afiliadoSindicato: 'no',
        afiliadoSindicatoNombre: '',
        seguroVida: 'no',
        seguroVidaCompania: '',
        puedeViajar: 'si',
        puedeViajarRazones: '',
        cambioResidencia: 'si',
        cambioResidenciaRazones: '',
        fechaPresentacion: '',

        // Datos Económicos
        otrosIngresos: 'no',
        otrosIngresosImporte: '',
        conyugeTrabaja: 'no',
        conyugeTrabajoLugar: '',
        conyugePercepcionMensual: '',
        casaPropia: 'no',
        casaPropiaValor: '',
        pagaRenta: 'no',
        rentaMensual: '',
        automovilPropio: 'no',
        automovilMarca: '',
        automovilModelo: '',
        deudas: 'no',
        deudasDescripcion: '',
        deudasImporte: '',
        abonaMensual: '',
        gastosMensuales: ''
    });

    // Estado visible para forzar re-renders cuando sea necesario
    const [form, setForm] = useState(internalFormRef.current);

    // Estado para rastrear el último campo editado
    const [lastEditedField, setLastEditedField] = useState(null);

    // Referencia para el timer de debounce
    const debounceTimerRef = useRef(null);

    // Cargar datos iniciales si existen (solo una vez)
    useEffect(() => {
        if (datosIniciales && Object.keys(datosIniciales).length > 0) {
            // Actualizar la referencia interna
            internalFormRef.current = {
                ...internalFormRef.current,
                ...datosIniciales
            };

            // Actualizar el estado visible
            setForm({ ...internalFormRef.current });
        }
    }, []); // Dependencia vacía - solo se ejecuta al montar

    // Función para manejar el debounce en los cambios
    const debouncedUpdate = useCallback(() => {
        // Cancelar el timer anterior si existe
        if (debounceTimerRef.current) {
            clearTimeout(debounceTimerRef.current);
        }

        // Crear un nuevo timer para actualizar después de 3 segundos
        debounceTimerRef.current = setTimeout(() => {
            if (onUpdate) {
                onUpdate(internalFormRef.current);
            }
        }, 3000);
    }, [onUpdate]);

    // Limpiar el timer al desmontar
    useEffect(() => {
        return () => {
            if (debounceTimerRef.current) {
                clearTimeout(debounceTimerRef.current);
            }
        };
    }, []);

    // Handler para cambios en el formulario - actualiza la ref interna sin re-render
    const handleInputChange = useCallback((campo, valor) => {
        // Actualizar directamente la referencia sin causar re-render
        internalFormRef.current = {
            ...internalFormRef.current,
            [campo]: valor
        };

        // Almacenar el campo que se está editando
        setLastEditedField(campo);

        // Programar el debounce
        debouncedUpdate();

        // Forzar actualización del estado visible
        setForm({ ...internalFormRef.current });
    }, [debouncedUpdate]);

    // Función de cambio para radio buttons
    const handleRadioChange = useCallback((campo, valor) => {
        handleInputChange(campo, valor);

        // Limpiar campos relacionados si se desmarca
        switch (campo) {
            case 'parientesTrabajando':
                if (valor === 'no') handleInputChange('parientesTrabajandoNombres', '');
                break;
            case 'afianzado':
                if (valor === 'no') handleInputChange('afianzadoCompania', '');
                break;
            case 'afiliadoSindicato':
                if (valor === 'no') handleInputChange('afiliadoSindicatoNombre', '');
                break;
            case 'seguroVida':
                if (valor === 'no') handleInputChange('seguroVidaCompania', '');
                break;
            case 'puedeViajar':
                if (valor === 'no') handleInputChange('puedeViajarRazones', '');
                break;
            case 'cambioResidencia':
                if (valor === 'no') handleInputChange('cambioResidenciaRazones', '');
                break;
            case 'otrosIngresos':
                if (valor === 'no') handleInputChange('otrosIngresosImporte', '');
                break;
            case 'conyugeTrabaja':
                if (valor === 'no') {
                    handleInputChange('conyugeTrabajoLugar', '');
                    handleInputChange('conyugePercepcionMensual', '');
                }
                break;
            case 'casaPropia':
                if (valor === 'no') handleInputChange('casaPropiaValor', '');
                break;
            case 'pagaRenta':
                if (valor === 'no') handleInputChange('rentaMensual', '');
                break;
            case 'automovilPropio':
                if (valor === 'no') {
                    handleInputChange('automovilMarca', '');
                    handleInputChange('automovilModelo', '');
                }
                break;
            case 'deudas':
                if (valor === 'no') {
                    handleInputChange('deudasDescripcion', '');
                    handleInputChange('deudasImporte', '');
                }
                break;
        }
    }, [handleInputChange]);

    // Radio button y campo condicional genérico
    const ConditionalRadioInput = ({
        label,
        name,
        value,
        onChange,
        conditionalField,
        conditionalPlaceholder
    }) => (
        <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
                {label}
            </label>
            <div className="flex items-center space-x-4">
                <label className="inline-flex items-center">
                    <input
                        type="radio"
                        name={name}
                        value="no"
                        checked={form[name] === 'no'}
                        onChange={() => handleRadioChange(name, 'no')}
                        className="form-radio h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300"
                    />
                    <span className="ml-2">No</span>
                </label>
                <label className="inline-flex items-center">
                    <input
                        type="radio"
                        name={name}
                        value="si"
                        checked={form[name] === 'si'}
                        onChange={() => handleRadioChange(name, 'si')}
                        className="form-radio h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300"
                    />
                    <span className="ml-2">Sí</span>
                </label>
            </div>
            {form[name] === 'si' && conditionalField && (
                <input
                    type="text"
                    placeholder={conditionalPlaceholder}
                    value={form[conditionalField]}
                    onChange={(e) => handleInputChange(conditionalField, e.target.value)}
                    className="mt-2 block w-full py-2 sm:text-sm border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                />
            )}
        </div>
    );

    return (
        <div className="bg-white rounded-lg shadow-md p-4 md:p-6">
            <div className="mb-6">
                <p className="text-sm text-gray-600">
                    Por favor, proporcione información detallada sobre su situación general
                </p>
                <div className="text-xs text-blue-600 mt-1">
                    Los cambios se guardan automáticamente 3 segundos después de dejar de escribir.
                </div>
            </div>

            {/* Sección de Datos Generales */}
            <div className="space-y-6 mb-6">
                <h3 className="text-lg font-semibold text-gray-700 border-b pb-2">Datos Generales</h3>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    {/* Medio de Conocimiento */}
                    <div>
                        <label className="block text-sm font-medium text-gray-700 mb-1">
                            ¿Cómo supo de este empleo?
                        </label>
                        <div className="relative">
                            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                <Megaphone className="h-4 w-4 text-gray-400" />
                            </div>
                            <select
                                value={form.medioConocimiento}
                                onChange={(e) => handleInputChange('medioConocimiento', e.target.value)}
                                className="block w-full pl-10 py-2 sm:text-sm border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                            >
                                <option value="">Seleccione</option>
                                <option value="indeed">Indeed</option>
                                <option value="google">Google Jobs</option>
                                <option value="google">Facebook</option>
                                <option value="amigo">Amigo</option>
                                <option value="otro">Otro medio</option>

                            </select>
                        </div>
                        {form.medioConocimiento === 'otro' && (
                            <input
                                type="text"
                                placeholder="Especifique otro medio"
                                value={form.medioConocimientoOtro}
                                onChange={(e) => handleInputChange('medioConocimientoOtro', e.target.value)}
                                className="mt-2 block w-full py-2 sm:text-sm border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                            />
                        )}
                    </div>

                    {/* Parientes Trabajando */}
                    <ConditionalRadioInput
                        label="¿Tiene parientes trabajando en esta Empresa?"
                        name="parientesTrabajando"
                        conditionalField="parientesTrabajandoNombres"
                        conditionalPlaceholder="Nombres de parientes"
                    />

                    {/* Afianzado */}
                    <ConditionalRadioInput
                        label="¿Ha estado afianzado?"
                        name="afianzado"
                        conditionalField="afianzadoCompania"
                        conditionalPlaceholder="Nombre de la Compañía"
                    />

                    {/* Afiliado a Sindicato */}
                    <ConditionalRadioInput
                        label="¿Ha estado afiliado a algún sindicato?"
                        name="afiliadoSindicato"
                        conditionalField="afiliadoSindicatoNombre"
                        conditionalPlaceholder="¿A cuál?"
                    />

                    {/* Seguro de Vida */}
                    <ConditionalRadioInput
                        label="¿Tiene seguro de vida?"
                        name="seguroVida"
                        conditionalField="seguroVidaCompania"
                        conditionalPlaceholder="Nombre de la Compañía"
                    />

                    {/* Puede Viajar */}
                    <ConditionalRadioInput
                        label="¿Puede viajar?"
                        name="puedeViajar"
                        conditionalField="puedeViajarRazones"
                        conditionalPlaceholder="Razones"
                    />

                    {/* Cambio de Residencia */}
                    <ConditionalRadioInput
                        label="¿Está dispuesto a cambiar de lugar de residencia?"
                        name="cambioResidencia"
                        conditionalField="cambioResidenciaRazones"
                        conditionalPlaceholder="Razones"
                    />

                    {/* Fecha de Presentación */}
                    <div>
                        <label className="block text-sm font-medium text-gray-700 mb-1">
                            Fecha en que podría presentarse a trabajar
                        </label>
                        <div className="relative">
                            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                <Calendar className="h-4 w-4 text-gray-400" />
                            </div>
                            <input
                                type="date"
                                value={form.fechaPresentacion}
                                onChange={(e) => handleInputChange('fechaPresentacion', e.target.value)}
                                className="block w-full pl-10 py-2 sm:text-sm border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                            />
                        </div>
                    </div>
                </div>
            </div>

            {/* Sección de Datos Económicos */}
            <div className="space-y-6">
                <h3 className="text-lg font-semibold text-gray-700 border-b pb-2">Datos Económicos</h3>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    {/* Otros Ingresos */}
                    <div>
                        <label className="block text-sm font-medium text-gray-700 mb-1">
                            ¿Tiene usted otros ingresos?
                        </label>
                        <div className="flex items-center space-x-4">
                            <label className="inline-flex items-center">
                                <input
                                    type="radio"
                                    name="otrosIngresos"
                                    value="no"
                                    checked={form.otrosIngresos === 'no'}
                                    onChange={() => handleRadioChange('otrosIngresos', 'no')}
                                    className="form-radio h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300"
                                />
                                <span className="ml-2">No</span>
                            </label>
                            <label className="inline-flex items-center"></label><input
                                type="radio"
                                name="otrosIngresos"
                                value="si"
                                checked={form.otrosIngresos === 'si'}
                                onChange={() => handleRadioChange('otrosIngresos', 'si')}
                                className="form-radio h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300"
                            />
                            <span className="ml-2">Sí</span>

                        </div>
                        {form.otrosIngresos === 'si' && (
                            <div className="mt-2 flex items-center space-x-2">
                                <span>$</span>
                                <input
                                    type="text"
                                    placeholder="Importe mensual"
                                    value={form.otrosIngresosImporte}
                                    onChange={(e) => handleInputChange('otrosIngresosImporte', e.target.value)}
                                    className="block flex-1 py-2 sm:text-sm border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                                />
                            </div>
                        )}
                    </div>

                    {/* Cónyuge Trabaja */}
                    <div>
                        <label className="block text-sm font-medium text-gray-700 mb-1">
                            ¿Su cónyuge trabaja?
                        </label>
                        <div className="flex items-center space-x-4">
                            <label className="inline-flex items-center">
                                <input
                                    type="radio"
                                    name="conyugeTrabaja"
                                    value="no"
                                    checked={form.conyugeTrabaja === 'no'}
                                    onChange={() => handleRadioChange('conyugeTrabaja', 'no')}
                                    className="form-radio h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300"
                                />
                                <span className="ml-2">No</span>
                            </label>
                            <label className="inline-flex items-center">
                                <input
                                    type="radio"
                                    name="conyugeTrabaja"
                                    value="si"
                                    checked={form.conyugeTrabaja === 'si'}
                                    onChange={() => handleRadioChange('conyugeTrabaja', 'si')}
                                    className="form-radio h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300"
                                />
                                <span className="ml-2">Sí</span>
                            </label>
                        </div>
                        {form.conyugeTrabaja === 'si' && (
                            <div className="mt-2 space-y-2">
                                <input
                                    type="text"
                                    placeholder="¿Dónde?"
                                    value={form.conyugeTrabajoLugar}
                                    onChange={(e) => handleInputChange('conyugeTrabajoLugar', e.target.value)}
                                    className="block w-full py-2 sm:text-sm border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                                />
                                <div className="flex items-center space-x-2">
                                    <span>$</span>
                                    <input
                                        type="text"
                                        placeholder="Percepción mensual"
                                        value={form.conyugePercepcionMensual}
                                        onChange={(e) => handleInputChange('conyugePercepcionMensual', e.target.value)}
                                        className="block flex-1 py-2 sm:text-sm border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                                    />
                                </div>
                            </div>
                        )}
                    </div>

                    {/* Casa Propia */}
                    <div>
                        <label className="block text-sm font-medium text-gray-700 mb-1">
                            ¿Vive en casa propia?
                        </label>
                        <div className="flex items-center space-x-4">
                            <label className="inline-flex items-center">
                                <input
                                    type="radio"
                                    name="casaPropia"
                                    value="no"
                                    checked={form.casaPropia === 'no'}
                                    onChange={() => handleRadioChange('casaPropia', 'no')}
                                    className="form-radio h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300"
                                />
                                <span className="ml-2">No</span>
                            </label>
                            <label className="inline-flex items-center">
                                <input
                                    type="radio"
                                    name="casaPropia"
                                    value="si"
                                    checked={form.casaPropia === 'si'}
                                    onChange={() => handleRadioChange('casaPropia', 'si')}
                                    className="form-radio h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300"
                                />
                                <span className="ml-2">Sí</span>
                            </label>
                        </div>
                        {form.casaPropia === 'si' && (
                            <div className="mt-2 flex items-center space-x-2">
                                <span>$</span>
                                <input
                                    type="text"
                                    placeholder="Valor aproximado"
                                    value={form.casaPropiaValor}
                                    onChange={(e) => handleInputChange('casaPropiaValor', e.target.value)}
                                    className="block flex-1 py-2 sm:text-sm border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                                />
                            </div>
                        )}
                    </div>

                    {/* Paga Renta */}
                    <div>
                        <label className="block text-sm font-medium text-gray-700 mb-1">
                            ¿Paga renta?
                        </label>
                        <div className="flex items-center space-x-4">
                            <label className="inline-flex items-center">
                                <input
                                    type="radio"
                                    name="pagaRenta"
                                    value="no"
                                    checked={form.pagaRenta === 'no'}
                                    onChange={() => handleRadioChange('pagaRenta', 'no')}
                                    className="form-radio h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300"
                                />
                                <span className="ml-2">No</span>
                            </label>
                            <label className="inline-flex items-center">
                                <input
                                    type="radio"
                                    name="pagaRenta"
                                    value="si"
                                    checked={form.pagaRenta === 'si'}
                                    onChange={() => handleRadioChange('pagaRenta', 'si')}
                                    className="form-radio h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300"
                                />
                                <span className="ml-2">Sí</span>
                            </label>
                        </div>
                        {form.pagaRenta === 'si' && (
                            <div className="mt-2 flex items-center space-x-2">
                                <span>$</span>
                                <input
                                    type="text"
                                    placeholder="Renta mensual"
                                    value={form.rentaMensual}
                                    onChange={(e) => handleInputChange('rentaMensual', e.target.value)}
                                    className="block flex-1 py-2 sm:text-sm border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                                />
                            </div>
                        )}
                    </div>

                    {/* Automóvil Propio */}
                    <div>
                        <label className="block text-sm font-medium text-gray-700 mb-1">
                            ¿Tiene automóvil propio?
                        </label>
                        <div className="flex items-center space-x-4">
                            <label className="inline-flex items-center">
                                <input
                                    type="radio"
                                    name="automovilPropio"
                                    value="no"
                                    checked={form.automovilPropio === 'no'}
                                    onChange={() => handleRadioChange('automovilPropio', 'no')}
                                    className="form-radio h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300"
                                />
                                <span className="ml-2">No</span>
                            </label>
                            <label className="inline-flex items-center">
                                <input
                                    type="radio"
                                    name="automovilPropio"
                                    value="si"
                                    checked={form.automovilPropio === 'si'}
                                    onChange={() => handleRadioChange('automovilPropio', 'si')}
                                    className="form-radio h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300"
                                />
                                <span className="ml-2">Sí</span>
                            </label>
                        </div>
                        {form.automovilPropio === 'si' && (
                            <div className="mt-2 space-y-2">
                                <input
                                    type="text"
                                    placeholder="Marca"
                                    value={form.automovilMarca}
                                    onChange={(e) => handleInputChange('automovilMarca', e.target.value)}
                                    className="block w-full py-2 sm:text-sm border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                                />
                                <input
                                    type="text"
                                    placeholder="Modelo"
                                    value={form.automovilModelo}
                                    onChange={(e) => handleInputChange('automovilModelo', e.target.value)}
                                    className="block w-full py-2 sm:text-sm border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                                />
                            </div>
                        )}
                    </div>

                    {/* Deudas */}
                    <div>
                        <label className="block text-sm font-medium text-gray-700 mb-1">
                            ¿Tiene deudas?
                        </label>
                        <div className="flex items-center space-x-4">
                            <label className="inline-flex items-center">
                                <input
                                    type="radio"
                                    name="deudas"
                                    value="no"
                                    checked={form.deudas === 'no'}
                                    onChange={() => handleRadioChange('deudas', 'no')}
                                    className="form-radio h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300"
                                />
                                <span className="ml-2">No</span>
                            </label>
                            <label className="inline-flex items-center">
                                <input
                                    type="radio"
                                    name="deudas"
                                    value="si"
                                    checked={form.deudas === 'si'}
                                    onChange={() => handleRadioChange('deudas', 'si')}
                                    className="form-radio h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300"
                                />
                                <span className="ml-2">Sí</span>
                            </label>
                        </div>
                        {form.deudas === 'si' && (
                            <div className="mt-2 space-y-2">
                                <input
                                    type="text"
                                    placeholder="¿Con quién?"
                                    value={form.deudasDescripcion}
                                    onChange={(e) => handleInputChange('deudasDescripcion', e.target.value)}
                                    className="block w-full py-2 sm:text-sm border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                                />
                                <div className="flex items-center space-x-2">
                                    <span>$</span>
                                    <input
                                        type="text"
                                        placeholder="Importe"
                                        value={form.deudasImporte}
                                        onChange={(e) => handleInputChange('deudasImporte', e.target.value)}
                                        className="block flex-1 py-2 sm:text-sm border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                                    />
                                </div>
                            </div>
                        )}
                    </div>

                    {/* Abono Mensual */}
                    <div>
                        <label className="block text-sm font-medium text-gray-700 mb-1">
                            ¿Cuánto abona mensualmente?
                        </label>
                        <div className="flex items-center space-x-2">
                            <span>$</span>
                            <input
                                type="text"
                                placeholder="Monto"
                                value={form.abonaMensual}
                                onChange={(e) => handleInputChange('abonaMensual', e.target.value)}
                                className="block flex-1 py-2 sm:text-sm border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                            />
                        </div>
                    </div>

                    {/* Gastos Mensuales */}
                    <div>
                        <label className="block text-sm font-medium text-gray-700 mb-1">
                            ¿A cuánto ascienden sus gastos mensuales?
                        </label>
                        <div className="flex items-center space-x-2">
                            <span>$</span>
                            <input
                                type="text"
                                placeholder="Total de gastos"
                                value={form.gastosMensuales}
                                onChange={(e) => handleInputChange('gastosMensuales', e.target.value)}
                                className="block flex-1 py-2 sm:text-sm border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
};

export default DatosGenerales;
