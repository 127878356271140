import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import { doc, getDoc, collection, onSnapshot, query, orderBy, updateDoc, getDocs, deleteDoc } from "firebase/firestore";
import { db } from "../../firebase";
import {
    Briefcase,
    Calendar,
    User,
    Clock,
    Edit,
    ChevronLeft,
    Package,
    PlusCircle,
    ChevronDown,
    ChevronUp,
    RefreshCcw,
    AlertTriangle,
    BarChart2,
    Building,
    CheckCircle,
    Tag,
    Users,
    Search,
    Sparkles,
    Trash2,
    Grid,
    List,
    X,
    AlertCircle
} from "lucide-react";
import ProjectComponents from "./ProjectComponents";
import UnitDetail from "./UnitDetail";

const ProjectInfo = () => {
    // Obtenemos el projectId de los parámetros de la URL
    const params = useParams();
    const projectId = params.projectId || params.uid; // Intentamos con ambos nombres posibles
    const navigate = useNavigate();

    // Estados principales
    const [project, setProject] = useState(null);
    const [units, setUnits] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [showAddComponents, setShowAddComponents] = useState(false);
    const [selectedUnit, setSelectedUnit] = useState(null);
    const [expandedUnits, setExpandedUnits] = useState({});
    const [updating, setUpdating] = useState(false);

    // Estados adicionales para UI mejorada
    const [searchTerm, setSearchTerm] = useState("");
    const [viewMode, setViewMode] = useState("list"); // "list" o "grid"
    const [showRemoveDialog, setShowRemoveDialog] = useState(false);
    const [unitsLoaded, setUnitsLoaded] = useState(false);

    // Cargar datos del proyecto usando onSnapshot para actualizaciones en tiempo real
    useEffect(() => {
        if (!projectId) {
            setError("ID de proyecto no encontrado en la URL");
            setLoading(false);
            return;
        }

        setLoading(true);

        // Usar onSnapshot para escuchar cambios en el proyecto
        const projectRef = doc(db, "projects", projectId);
        const unsubscribeProject = onSnapshot(projectRef, (snapshot) => {
            if (snapshot.exists()) {
                setProject({ id: snapshot.id, ...snapshot.data() });
            } else {
                setError(`Proyecto con ID ${projectId} no encontrado`);
            }
            setLoading(false);
        }, (err) => {
            console.error("Error al escuchar cambios en el proyecto:", err);
            setError("Error al cargar el proyecto: " + (err.message || "Error desconocido"));
            setLoading(false);
        });

        return () => unsubscribeProject();
    }, [projectId]);

    // Cargar unidades del proyecto con onSnapshot
    useEffect(() => {
        if (!projectId) return;

        setUnitsLoaded(false);

        // Usar onSnapshot para escuchar cambios en las unidades
        const unitsRef = collection(db, `projects/${projectId}/units`);
        const q = query(unitsRef, orderBy("number", "asc"));

        const unsubscribeUnits = onSnapshot(q, (snapshot) => {
            const unitsData = snapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));
            setUnits(unitsData);
            setUnitsLoaded(true);

            // Calcular estadísticas del proyecto basadas en unidades actualizadas
            calculateProjectStats(unitsData);
        }, (err) => {
            console.error("Error al escuchar cambios en unidades:", err);
            setUnitsLoaded(true);
        });

        return () => unsubscribeUnits();
    }, [projectId]);

    // Calcular estadísticas del proyecto y actualizar si es necesario
    const calculateProjectStats = useCallback(async (unitsData) => {
        if (!projectId || !project) return;

        try {
            // Calcular estadísticas
            const totalUnits = unitsData.length;
            const completedUnits = unitsData.filter(unit => unit.status === "Completado").length;
            const inProgressUnits = unitsData.filter(unit => unit.status === "En progreso").length;
            const pendingUnits = unitsData.filter(unit => unit.status === "Pendiente").length;

            // Calcular el progreso total sumando los progresos de todas las unidades
            let totalProgress = 0;
            if (totalUnits > 0) {
                totalProgress = unitsData.reduce((sum, unit) => sum + (unit.progress || 0), 0) / totalUnits;
                totalProgress = Math.round(totalProgress);
            }

            // Solo actualizar si hay cambios, para evitar escrituras innecesarias
            if (totalProgress !== project.progress ||
                completedUnits !== project.completedUnits ||
                inProgressUnits !== (project.inProgressUnits || 0) ||
                pendingUnits !== (project.pendingUnits || 0)) {

                setUpdating(true);

                const projectRef = doc(db, "projects", projectId);
                await updateDoc(projectRef, {
                    progress: totalProgress,
                    completedUnits,
                    inProgressUnits,
                    pendingUnits,
                    updatedAt: new Date()
                });

                // No necesitamos actualizar el estado local porque onSnapshot lo hará
                setUpdating(false);
            }
        } catch (err) {
            console.error("Error al actualizar progreso del proyecto:", err);
            setUpdating(false);
        }
    }, [projectId, project]);

    // Navegar a la página de edición
    const handleEdit = useCallback(() => {
        navigate(`/newproject?edit=${projectId}`);
    }, [navigate, projectId]);

    // Toggle para expandir/colapsar unidades
    const toggleUnitExpansion = useCallback((unitId) => {
        setExpandedUnits(prev => ({
            ...prev,
            [unitId]: !prev[unitId]
        }));
    }, []);

    // Abre el panel para agregar componentes
    const handleAddComponents = useCallback((unit = null) => {
        setSelectedUnit(unit);
        setShowAddComponents(true);
    }, []);

    // Manejar la eliminación de componentes de todas las unidades
    const handleRemoveAllComponents = useCallback(async () => {
        if (!projectId || units.length === 0) return;

        setUpdating(true);
        try {
            // Para cada unidad
            for (const unit of units) {
                // Obtener todos los componentes
                const componentsRef = collection(db, `projects/${projectId}/units/${unit.id}/components`);
                const componentsSnap = await getDocs(componentsRef);

                // Eliminar cada componente
                for (const componentDoc of componentsSnap.docs) {
                    await deleteDoc(doc(db, `projects/${projectId}/units/${unit.id}/components/${componentDoc.id}`));
                }

                // Actualizar el estado de la unidad
                await updateDoc(doc(db, `projects/${projectId}/units/${unit.id}`), {
                    progress: 0,
                    completedComponents: 0,
                    totalComponents: 0,
                    status: "Pendiente",
                    updatedAt: new Date()
                });
            }

            // onSnapshot actualizará todo automáticamente
            setShowRemoveDialog(false);
        } catch (error) {
            console.error("Error al eliminar componentes:", error);
            alert("Error al eliminar los componentes");
        } finally {
            setUpdating(false);
        }
    }, [projectId, units]);

    // Filtrar unidades según el término de búsqueda (memoizado)
    const filteredUnits = useMemo(() => {
        if (!searchTerm) return units;

        const searchLower = searchTerm.toLowerCase();

        return units.filter(unit => (
            unit.number?.toString().includes(searchLower) ||
            (unit.status && unit.status.toLowerCase().includes(searchLower)) ||
            (unit.serialNumber && unit.serialNumber.toLowerCase().includes(searchLower))
        ));
    }, [units, searchTerm]);

    // Estadísticas del proyecto (memoizado)
    const stats = useMemo(() => ({
        total: units.length,
        completed: units.filter(unit => unit.status === "Completado").length,
        inProgress: units.filter(unit => unit.status === "En progreso").length,
        pending: units.filter(unit => unit.status === "Pendiente").length
    }), [units]);

    // Formatear fecha de forma segura
    const formatDate = useCallback((dateString) => {
        if (!dateString) return "No especificada";
        try {
            return new Date(dateString).toLocaleDateString('es-MX', {
                year: 'numeric',
                month: 'long',
                day: 'numeric'
            });
        } catch (err) {
            console.error("Error al formatear fecha:", err);
            return String(dateString);
        }
    }, []);

    // Calcular el progreso de forma segura
    const progress = useMemo(() => {
        if (project && typeof project.progress === 'number') {
            return project.progress;
        }

        if (stats.total === 0) return 0;
        return Math.round((stats.completed / stats.total) * 100);
    }, [project, stats.total, stats.completed]);

    // Modal de confirmación para eliminar todos los componentes
    const renderRemoveConfirmation = () => (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 animate-fadeIn">
            <div className="bg-white rounded-lg p-6 max-w-md w-full shadow-xl transform transition-all animate-scaleIn">
                <h3 className="text-lg font-medium text-gray-900 mb-4 flex items-center">
                    <AlertCircle className="h-5 w-5 text-red-500 mr-2" />
                    Eliminar todos los componentes
                </h3>
                <p className="text-gray-600 mb-6">
                    ¿Está seguro que desea eliminar todos los componentes de todas las unidades? Esta acción no se puede deshacer.
                </p>
                <div className="flex justify-end space-x-3">
                    <button
                        onClick={() => setShowRemoveDialog(false)}
                        className="px-4 py-2 bg-gray-200 text-gray-800 rounded-md hover:bg-gray-300 transition-colors duration-300"
                    >
                        Cancelar
                    </button>
                    <button
                        onClick={handleRemoveAllComponents}
                        className="px-4 py-2 bg-red-600 text-white rounded-md hover:bg-red-700 transition-colors duration-300 flex items-center"
                        disabled={updating}
                    >
                        {updating ? (
                            <>
                                <RefreshCcw className="h-4 w-4 mr-2 animate-spin" />
                                Procesando...
                            </>
                        ) : (
                            <>
                                <Trash2 className="h-4 w-4 mr-2" />
                                Eliminar todos
                            </>
                        )}
                    </button>
                </div>
            </div>
        </div>
    );

    // Renderiza el estado de carga
    if (loading) {
        return (
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
                <div className="flex flex-col justify-center items-center h-64 animate-pulse">
                    <div className="bg-blue-100 rounded-full p-4 mb-4">
                        <RefreshCcw className="h-10 w-10 text-blue-500 animate-spin" />
                    </div>
                    <span className="text-gray-600 font-medium">Cargando proyecto...</span>
                    <p className="text-gray-400 text-sm mt-2">Esto puede tomar un momento</p>
                </div>
            </div>
        );
    }

    // Renderiza mensaje de error
    if (error) {
        return (
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
                <div className="bg-red-50 border border-red-200 text-red-700 px-6 py-5 rounded-lg flex flex-col items-center justify-center h-64 shadow-sm animate-fadeIn">
                    <AlertTriangle className="h-10 w-10 text-red-400 mb-3" />
                    <h3 className="text-lg font-medium mb-2">Error al cargar el proyecto</h3>
                    <p className="text-center mb-4">{error}</p>
                    <Link to="/projects" className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-colors duration-300 flex items-center">
                        <ChevronLeft className="h-4 w-4 mr-2" />
                        Volver a proyectos
                    </Link>
                </div>
            </div>
        );
    }

    // Si no hay proyecto
    if (!project) {
        return (
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
                <div className="text-center py-12 animate-fadeIn">
                    <Package className="h-16 w-16 text-gray-300 mx-auto mb-4" />
                    <p className="text-xl text-gray-500 mb-4">No se encontró el proyecto</p>
                    <p className="text-gray-400 mb-6">El proyecto solicitado no existe o ha sido eliminado</p>
                    <Link to="/projects" className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-colors duration-300 inline-flex items-center">
                        <ChevronLeft className="h-4 w-4 mr-2" />
                        Volver a la lista de proyectos
                    </Link>
                </div>
            </div>
        );
    }

    return (
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8 animate-fadeIn">
            {/* Navegación y encabezado */}
            <div className="mb-6">
                <Link to="/projects" className="text-blue-600 hover:text-blue-800 flex items-center text-sm mb-4 transition-colors duration-300">
                    <ChevronLeft className="h-4 w-4 mr-1" />
                    Volver a la lista de proyectos
                </Link>

                <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center gap-4">
                    <div>
                        <h1 className="text-2xl font-bold text-gray-900 flex items-center">
                            {project.name || "Proyecto sin nombre"}
                            {updating && (
                                <RefreshCcw className="h-4 w-4 text-blue-500 animate-spin ml-2" />
                            )}
                        </h1>
                        <p className="text-sm text-gray-600 flex items-center mt-1">
                            <Tag className="h-4 w-4 text-gray-400 mr-1" />
                            ID: {projectId.substring(0, 8)}...
                        </p>
                    </div>

                    <div className="flex flex-wrap gap-2">
                        <button
                            onClick={handleEdit}
                            className="px-4 py-2 border border-gray-300 rounded-md text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 flex items-center shadow-sm transition-colors duration-300"
                        >
                            <Edit className="h-4 w-4 mr-2" />
                            Editar Proyecto
                        </button>

                        <button
                            onClick={() => handleAddComponents()}
                            className="px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 flex items-center transition-colors duration-300"
                        >
                            <PlusCircle className="h-4 w-4 mr-2" />
                            Agregar Componentes
                        </button>

                        <button
                            onClick={() => setShowRemoveDialog(true)}
                            className="px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 flex items-center transition-colors duration-300"
                        >
                            <Trash2 className="h-4 w-4 mr-2" />
                            Eliminar Componentes
                        </button>
                    </div>
                </div>
            </div>

            {/* Panel con información del proyecto */}
            <div className="bg-white rounded-lg shadow-md p-6 mb-6 border border-gray-200 hover:shadow-lg transition-shadow duration-200 transform hover:scale-[1.01]">
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
                    <div className="bg-gray-50 p-3 rounded-md transition-colors duration-300 hover:bg-gray-100">
                        <h2 className="text-xs font-medium text-gray-500 uppercase tracking-wide mb-1 flex items-center">
                            <Building className="h-4 w-4 text-gray-400 mr-1" />
                            Cliente
                        </h2>
                        <p className="text-base font-medium text-gray-900">{project.client || "No especificado"}</p>
                    </div>

                    <div className="bg-gray-50 p-3 rounded-md transition-colors duration-300 hover:bg-gray-100">
                        <h2 className="text-xs font-medium text-gray-500 uppercase tracking-wide mb-1 flex items-center">
                            <Briefcase className="h-4 w-4 text-gray-400 mr-1" />
                            Tipo de Proyecto
                        </h2>
                        <p className="text-base font-medium text-gray-900">{project.projectType || "No especificado"}</p>
                    </div>

                    <div className="bg-gray-50 p-3 rounded-md transition-colors duration-300 hover:bg-gray-100">
                        <h2 className="text-xs font-medium text-gray-500 uppercase tracking-wide mb-1 flex items-center">
                            <Calendar className="h-4 w-4 text-gray-400 mr-1" />
                            Fecha de Entrega
                        </h2>
                        <p className="text-base font-medium text-gray-900">{formatDate(project.dueDate)}</p>
                    </div>

                    <div className="bg-gray-50 p-3 rounded-md transition-colors duration-300 hover:bg-gray-100">
                        <h2 className="text-xs font-medium text-gray-500 uppercase tracking-wide mb-1 flex items-center">
                            <Package className="h-4 w-4 text-gray-400 mr-1" />
                            Unidades
                        </h2>
                        <p className="text-base font-medium text-gray-900">{project.unitCount || units.length || 0}</p>
                    </div>
                </div>

                <div className="mt-8 pt-6 border-t border-gray-200">
                    <h2 className="text-lg font-medium text-gray-900 mb-4 flex items-center">
                        <BarChart2 className="h-5 w-5 text-blue-500 mr-2" />
                        Progreso del Proyecto
                    </h2>

                    <div className="flex items-center mb-3">
                        <div className="w-full bg-gray-200 rounded-full h-5 mr-3 overflow-hidden">
                            <div
                                className={`h-5 rounded-full flex items-center justify-center text-xs text-white font-medium transition-all duration-500 ease-in-out ${progress === 100
                                    ? 'bg-green-500'
                                    : progress > 60
                                        ? 'bg-blue-500'
                                        : progress > 30
                                            ? 'bg-yellow-500'
                                            : 'bg-orange-500'
                                    }`}
                                style={{ width: `${progress}%` }}
                            >
                                {progress > 10 ? `${progress}%` : ''}
                            </div>
                        </div>
                        <span className="text-sm font-medium text-gray-700 whitespace-nowrap">
                            {stats.completed} de {stats.total} unidades
                        </span>
                    </div>

                    <div className="grid grid-cols-2 sm:grid-cols-4 gap-3 mt-4">
                        <div className="bg-green-50 border border-green-100 rounded-md p-3 text-center transition-all duration-300 hover:shadow-md transform hover:scale-[1.02]">
                            <div className="flex items-center justify-center text-green-700 mb-1">
                                <CheckCircle className="h-4 w-4 text-green-500 mr-1" />
                                <span className="text-xs font-medium uppercase">Completadas</span>
                            </div>
                            <p className="text-xl font-bold text-green-700">{stats.completed}</p>
                        </div>
                        <div className="bg-yellow-50 border border-yellow-100 rounded-md p-3 text-center transition-all duration-300 hover:shadow-md transform hover:scale-[1.02]">
                            <div className="flex items-center justify-center text-yellow-700 mb-1">
                                <Clock className="h-4 w-4 text-yellow-500 mr-1" />
                                <span className="text-xs font-medium uppercase">En Progreso</span>
                            </div>
                            <p className="text-xl font-bold text-yellow-700">{stats.inProgress}</p>
                        </div>
                        <div className="bg-orange-50 border border-orange-100 rounded-md p-3 text-center transition-all duration-300 hover:shadow-md transform hover:scale-[1.02]">
                            <div className="flex items-center justify-center text-orange-700 mb-1">
                                <Clock className="h-4 w-4 text-orange-500 mr-1" />
                                <span className="text-xs font-medium uppercase">Pendientes</span>
                            </div>
                            <p className="text-xl font-bold text-orange-700">{stats.pending}</p>
                        </div>
                        <div className="bg-blue-50 border border-blue-100 rounded-md p-3 text-center transition-all duration-300 hover:shadow-md transform hover:scale-[1.02]">
                            <div className="flex items-center justify-center text-blue-700 mb-1">
                                <Users className="h-4 w-4 text-blue-500 mr-1" />
                                <span className="text-xs font-medium uppercase">Total</span>
                            </div>
                            <p className="text-xl font-bold text-blue-700">{stats.total}</p>
                        </div>
                    </div>
                </div>
            </div>

            {/* Lista de unidades */}
            <div className="bg-white rounded-lg shadow-md overflow-hidden border border-gray-200">
                <div className="px-6 py-4 bg-gray-50 border-b border-gray-200">
                    <div className="flex flex-col md:flex-row md:items-center justify-between gap-3">
                        <h2 className="text-lg font-medium text-gray-900 flex items-center">
                            <Package className="h-5 w-5 text-blue-500 mr-2" />
                            Unidades ({units.length})
                        </h2>

                        <div className="flex flex-wrap items-center gap-2">
                            {/* Buscador */}
                            <div className="relative">
                                <Search className="h-4 w-4 text-gray-400 absolute left-3 top-1/2 transform -translate-y-1/2" />
                                <input
                                    type="text"
                                    placeholder="Buscar unidades..."
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                    className="pl-9 pr-3 py-1.5 border border-gray-300 rounded-md text-sm focus:outline-none focus:ring-1 focus:ring-blue-500 w-full md:w-60"
                                />
                                {searchTerm && (
                                    <button
                                        className="absolute right-2 top-1/2 transform -translate-y-1/2 text-gray-400 hover:text-gray-600"
                                        onClick={() => setSearchTerm("")}
                                    >
                                        <X className="h-4 w-4" />
                                    </button>
                                )}
                            </div>

                            {/* Cambio de vista */}
                            <div className="bg-gray-100 rounded-md p-1 flex space-x-1">
                                <button
                                    onClick={() => setViewMode("list")}
                                    className={`p-1 rounded-md ${viewMode === "list" ? "bg-white shadow-sm" : "hover:bg-gray-200"}`}
                                    title="Vista de lista"
                                >
                                    <List className="h-4 w-4 text-gray-700" />
                                </button>
                                <button
                                    onClick={() => setViewMode("grid")}
                                    className={`p-1 rounded-md ${viewMode === "grid" ? "bg-white shadow-sm" : "hover:bg-gray-200"}`}
                                    title="Vista de cuadrícula"
                                >
                                    <Grid className="h-4 w-4 text-gray-700" />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                {!unitsLoaded ? (
                    <div className="p-6 text-center">
                        <div className="inline-block animate-pulse">
                            <RefreshCcw className="h-8 w-8 text-blue-500 animate-spin mx-auto" />
                            <p className="mt-2 text-gray-500">Cargando unidades...</p>
                        </div>
                    </div>
                ) : units.length === 0 ? (
                    <div className="p-6 text-center text-gray-500">
                        <Package className="h-10 w-10 text-gray-300 mx-auto mb-3" />
                        <p className="mb-1">No hay unidades disponibles para este proyecto.</p>
                        <p className="text-sm">El proyecto debe tener al menos una unidad para poder añadir componentes.</p>
                    </div>
                ) : filteredUnits.length === 0 ? (
                    <div className="p-6 text-center text-gray-500">
                        <Search className="h-10 w-10 text-gray-300 mx-auto mb-3" />
                        <p className="mb-1">No se encontraron unidades que coincidan con "{searchTerm}".</p>
                        <p className="text-sm">Intente con otro término de búsqueda.</p>
                    </div>
                ) : viewMode === "grid" ? (
                    // Vista de cuadrícula 
                    <div className="p-4 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
                        {filteredUnits.map((unit) => (
                            <div
                                key={unit.id}
                                className={`border rounded-lg overflow-hidden shadow-sm transition-all duration-300 hover:shadow-md transform hover:scale-[1.02] ${unit.status === 'Completado' ? 'border-green-200 bg-green-50' :
                                        unit.status === 'En progreso' ? 'border-yellow-200 bg-yellow-50' : 'border-gray-200'
                                    }`}
                            >
                                <div className="px-4 py-3 border-b border-gray-200 flex justify-between items-center">
                                    <div>
                                        <h3 className="text-base font-medium text-gray-900">Unidad {unit.number}</h3>
                                        {unit.serialNumber && (
                                            <p className="text-xs text-gray-500">S/N: {unit.serialNumber}</p>
                                        )}
                                    </div>
                                    <span
                                        className={`px-2.5 py-0.5 rounded-full text-xs font-medium 
                                            ${unit.status === 'Completado'
                                                ? 'bg-green-100 text-green-800'
                                                : unit.status === 'En progreso'
                                                    ? 'bg-yellow-100 text-yellow-800'
                                                    : 'bg-gray-100 text-gray-800'
                                            }`}
                                    >
                                        {unit.status}
                                    </span>
                                </div>

                                <div className="p-4">
                                    <div className="flex items-center justify-between mb-3">
                                        <span className="text-sm text-gray-500">Progreso</span>
                                        <span className="text-sm font-medium">{unit.completedComponents || 0}/{unit.totalComponents || 0}</span>
                                    </div>
                                    <div className="w-full bg-gray-200 rounded-full h-2.5 mb-4">
                                        <div
                                            className={`h-2.5 rounded-full ${unit.progress === 100
                                                ? 'bg-green-500'
                                                : unit.progress > 60
                                                    ? 'bg-blue-500'
                                                    : unit.progress > 30
                                                        ? 'bg-yellow-500'
                                                        : 'bg-orange-500'
                                                }`}
                                            style={{ width: `${unit.progress || 0}%` }}
                                        ></div>
                                    </div>

                                    <div className="flex justify-between mt-4">
                                        <button
                                            onClick={() => toggleUnitExpansion(unit.id)}
                                            className="px-2 py-1 border border-gray-300 rounded text-xs text-gray-700 hover:bg-gray-50"
                                        >
                                            {expandedUnits[unit.id] ? 'Ocultar' : 'Ver detalles'}
                                        </button>
                                        <button
                                            onClick={() => handleAddComponents(unit)}
                                            className="px-2 py-1 bg-blue-600 text-white rounded text-xs hover:bg-blue-700"
                                        >
                                            <PlusCircle className="h-3 w-3 inline-block mr-1" />
                                            Agregar
                                        </button>
                                    </div>
                                </div>

                                {expandedUnits[unit.id] && (
                                    <div className="border-t border-gray-200">
                                        <UnitDetail
                                            key={`unit-detail-${unit.id}`}
                                            unit={unit}
                                            projectId={projectId}
                                        />
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                ) : (
                    // Vista de lista
                    <div className="divide-y divide-gray-200">
                        {filteredUnits.map((unit) => (
                            <div
                                key={unit.id}
                                className={`p-4 ${unit.status === 'Completado'
                                    ? 'bg-green-50'
                                    : unit.status === 'En progreso'
                                        ? 'bg-yellow-50'
                                        : 'bg-white'
                                    }`}
                            >
                                <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center gap-2">
                                    <div
                                        className="flex items-center cursor-pointer"
                                        onClick={() => toggleUnitExpansion(unit.id)}
                                    >
                                        {expandedUnits[unit.id] ? (
                                            <ChevronDown className="h-5 w-5 text-gray-400" />
                                        ) : (
                                            <ChevronUp className="h-5 w-5 text-gray-400" />
                                        )}
                                        <h3 className="ml-2 text-base font-medium text-gray-900">
                                            Unidad {unit.number}
                                        </h3>
                                        <span
                                            className={`ml-3 px-2.5 py-0.5 rounded-full text-xs font-medium transition-colors duration-300
                                                ${unit.status === 'Completado'
                                                    ? 'bg-green-100 text-green-800'
                                                    : unit.status === 'En progreso'
                                                        ? 'bg-yellow-100 text-yellow-800'
                                                        : 'bg-gray-100 text-gray-800'
                                                }`}
                                        >
                                            {unit.status}
                                        </span>

                                        {unit.serialNumber && (
                                            <span className="ml-3 text-xs text-gray-500 hidden sm:inline-block">
                                                S/N: {unit.serialNumber}
                                            </span>
                                        )}
                                    </div>

                                    <div className="flex items-center space-x-4">
                                        <div className="flex items-center">
                                            <span className="text-sm text-gray-500 mr-2 hidden sm:inline-block">
                                                {unit.completedComponents || 0}/{unit.totalComponents || 0}
                                            </span>
                                            <div className="w-16 sm:w-24 bg-gray-200 rounded-full h-2">
                                                <div
                                                    className={`h-2 rounded-full transition-all duration-500 ${unit.progress === 100
                                                        ? 'bg-green-500'
                                                        : unit.progress > 60
                                                            ? 'bg-blue-500'
                                                            : unit.progress > 30
                                                                ? 'bg-yellow-500'
                                                                : 'bg-orange-500'
                                                        }`}
                                                    style={{ width: `${unit.progress || 0}%` }}
                                                ></div>
                                            </div>
                                        </div>

                                        <button
                                            onClick={() => handleAddComponents(unit)}
                                            className="px-3 py-1 border border-gray-300 rounded-md text-xs font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-1 focus:ring-blue-500 shadow-sm transition-colors duration-300"
                                        >
                                            <PlusCircle className="h-3 w-3 mr-1 inline-block" />
                                            <span className="hidden sm:inline-block">Agregar Componente</span>
                                            <span className="inline-block sm:hidden">Agregar</span>
                                        </button>
                                    </div>
                                </div>

                                {expandedUnits[unit.id] && (
                                    <UnitDetail
                                        key={`unit-detail-${unit.id}`}
                                        unit={unit}
                                        projectId={projectId}
                                    />
                                )}
                            </div>
                        ))}
                    </div>
                )}
            </div>

            {/* Panel de agregar componentes (mostrado condicionalmente) */}
            {showAddComponents && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4 animate-fadeIn">
                    <div className="bg-white rounded-lg shadow-xl max-w-4xl w-full max-h-[90vh] overflow-auto animate-scaleIn">
                        <ProjectComponents
                            projectId={projectId}
                            unitId={selectedUnit?.id}
                            onCancel={() => {
                                setShowAddComponents(false);
                                setSelectedUnit(null);
                            }}
                        />
                    </div>
                </div>
            )}

            {/* Modal de confirmación para eliminar todos los componentes */}
            {showRemoveDialog && renderRemoveConfirmation()}

            {/* Estilos adicionales para animaciones */}
            <style jsx global>{`
                @keyframes fadeIn {
                    from { opacity: 0; }
                    to { opacity: 1; }
                }
                @keyframes scaleIn {
                    from { transform: scale(0.95); opacity: 0; }
                    to { transform: scale(1); opacity: 1; }
                }
                .animate-fadeIn {
                    animation: fadeIn 0.3s ease-out;
                }
                .animate-scaleIn {
                    animation: scaleIn 0.3s ease-out;
                }
                .animate-pulse {
                    animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
                }
                @keyframes pulse {
                    0%, 100% { opacity: 1; }
                    50% { opacity: 0.7; }
                }
            `}</style>
        </div>
    );
};

export default ProjectInfo;