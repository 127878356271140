import React, { useState, useEffect } from "react";
import { db } from "../../firebase";
import { doc, getDoc } from "firebase/firestore";
import {
    Typography,
    CardContent,
    Grid,
    CircularProgress,
    Box,
    Avatar,
    Button,
    Card,
    Container,
    Chip,
    Paper,
    Divider,
    Alert,
    Breadcrumbs,
    Link,
    Fade,
    Modal,
    IconButton,
    Tab,
    Tabs,
    Tooltip,
} from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import EngineeringIcon from "@mui/icons-material/Engineering";
import CommentIcon from "@mui/icons-material/Comment";
import ElectricCarIcon from "@mui/icons-material/ElectricCar";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import HomeIcon from "@mui/icons-material/Home";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import InfoIcon from "@mui/icons-material/Info";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import SecurityIcon from "@mui/icons-material/Security";
import FolderIcon from "@mui/icons-material/Folder";
import PhotoLibraryIcon from "@mui/icons-material/PhotoLibrary";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import CloseIcon from "@mui/icons-material/Close";
import InspectionPdfGenerator from "./InspectionPdfGenerator";

// Componente para mostrar el diagnóstico
const DiagnosticoInspeccion = ({ estadoGeneral, fusiblesReemplazados }) => {
    // Calcular estadísticas
    const totalComponentes = estadoGeneral ? Object.keys(estadoGeneral).length : 0;
    const componentesConProblemas = estadoGeneral
        ? Object.values(estadoGeneral).filter((estado) => estado !== "Correcto").length
        : 0;
    const porcentajeCorrecto = totalComponentes > 0
        ? Math.round(((totalComponentes - componentesConProblemas) / totalComponentes) * 100)
        : 0;

    const getCategoriaEstado = () => {
        if (porcentajeCorrecto >= 80) return "Óptimo";
        if (porcentajeCorrecto >= 60) return "Funcional";
        if (porcentajeCorrecto >= 40) return "Requiere atención";
        return "Crítico";
    };

    const getColorEstado = () => {
        if (porcentajeCorrecto >= 80) return "#4caf50";
        if (porcentajeCorrecto >= 60) return "#2196f3";
        if (porcentajeCorrecto >= 40) return "#ff9800";
        return "#f44336";
    };

    if (!estadoGeneral || Object.keys(estadoGeneral).length === 0) {
        return (
            <Alert severity="info">
                No hay información de diagnóstico disponible para esta inspección.
            </Alert>
        );
    }

    return (
        <>
            <Box sx={{ mb: 3 }}>
                <Paper
                    elevation={0}
                    sx={{
                        p: 2,
                        borderRadius: 2,
                        bgcolor: "#f0f7ff",
                        borderLeft: "4px solid #1976d2",
                    }}
                >
                    <Typography variant="subtitle1" gutterBottom sx={{ fontWeight: "bold" }}>
                        Resumen del diagnóstico
                    </Typography>

                    <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                        <Box
                            sx={{
                                position: "relative",
                                width: 60,
                                height: 60,
                                mr: 2,
                            }}
                        >
                            <Box
                                sx={{
                                    position: "absolute",
                                    top: 0,
                                    left: 0,
                                    width: "100%",
                                    height: "100%",
                                    borderRadius: "50%",
                                    border: "5px solid #e0e0e0",
                                }}
                            />
                            <Box
                                sx={{
                                    position: "absolute",
                                    top: 0,
                                    left: 0,
                                    width: "100%",
                                    height: "100%",
                                    borderRadius: "50%",
                                    border: "5px solid",
                                    borderColor: getColorEstado(),
                                    clipPath: `polygon(50% 50%, 50% 0%, ${porcentajeCorrecto < 25
                                            ? "50% 0%"
                                            : porcentajeCorrecto < 50
                                                ? "100% 0%"
                                                : porcentajeCorrecto < 75
                                                    ? "100% 100%"
                                                    : "0% 100%"
                                        }, ${porcentajeCorrecto <= 25
                                            ? "50% 0%"
                                            : porcentajeCorrecto <= 50
                                                ? "100% 0%, 100% 100%"
                                                : porcentajeCorrecto <= 75
                                                    ? "100% 100%, 0% 100%"
                                                    : "0% 100%, 0% 0%"
                                        })`,
                                    transform: "rotate(-90deg)",
                                }}
                            />
                            <Typography
                                align="center"
                                sx={{
                                    position: "absolute",
                                    top: "50%",
                                    left: "50%",
                                    transform: "translate(-50%, -50%)",
                                    fontWeight: "bold",
                                    fontSize: "1rem",
                                }}
                            >
                                {porcentajeCorrecto}%
                            </Typography>
                        </Box>

                        <Box>
                            <Typography variant="body1" sx={{ fontWeight: "medium" }}>
                                Estado: <span style={{ color: getColorEstado() }}>{getCategoriaEstado()}</span>
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                {totalComponentes - componentesConProblemas} de {totalComponentes} componentes en buen estado
                            </Typography>
                        </Box>
                    </Box>

                    <Divider sx={{ my: 1 }} />

                    <Grid container spacing={1} sx={{ mt: 1 }}>
                        {Object.entries(estadoGeneral).map(([componente, estado]) => (
                            <Grid item xs={12} sm={6} key={componente}>
                                <Box
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        p: 1,
                                        borderRadius: 1,
                                        bgcolor: estado === "Correcto" ? "#e8f5e9" : "#ffebee",
                                        borderLeft: estado === "Correcto" ? "3px solid #4caf50" : "3px solid #f44336",
                                    }}
                                >
                                    {estado === "Correcto" ? (
                                        <CheckCircleIcon sx={{ color: "#4caf50", mr: 1, fontSize: 20 }} />
                                    ) : (
                                        <CancelIcon sx={{ color: "#f44336", mr: 1, fontSize: 20 }} />
                                    )}
                                    <Typography variant="body2" sx={{ mr: 1 }}>
                                        <strong>{componente}:</strong>
                                    </Typography>
                                    <Typography variant="body2">{estado}</Typography>
                                </Box>
                            </Grid>
                        ))}
                    </Grid>

                    {fusiblesReemplazados && fusiblesReemplazados.length > 0 && (
                        <>
                            <Divider sx={{ my: 2 }} />
                            <Typography variant="subtitle2" sx={{ fontWeight: "medium", mb: 1 }}>
                                Componentes reemplazados:
                            </Typography>
                            <Box sx={{ pl: 2 }}>
                                {fusiblesReemplazados.map((item, index) => (
                                    <Typography key={index} variant="body2" sx={{ mb: 0.5 }}>
                                        • {item}
                                    </Typography>
                                ))}
                            </Box>
                        </>
                    )}
                </Paper>
            </Box>
        </>
    );
};

const InspectionSummary = () => {
    const { uid } = useParams();
    const [inspectionData, setInspectionData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [activeTab, setActiveTab] = useState(1); // Set to 1 for photos by default
    const [openModal, setOpenModal] = useState(false);
    const [selectedImage, setSelectedImage] = useState("");
    const navigate = useNavigate();

    const fetchInspection = async () => {
        setLoading(true);
        try {
            const docRef = doc(db, "inspecciones", uid);
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                setInspectionData({ ...docSnap.data(), id: uid });
                setError(null);
            } else {
                setError("No se encontró la inspección solicitada.");
            }
        } catch (err) {
            console.error("Error al recuperar los datos de la inspección:", err);
            setError(
                "Error al recuperar los datos de la inspección. Por favor, intente nuevamente."
            );
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchInspection();
    }, [uid]);

    const handleOpenModal = (imageUrl) => {
        setSelectedImage(imageUrl);
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
        setSelectedImage("");
    };

    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);
    };

    // Convierte el Timestamp a un formato de fecha legible
    const formatDate = (timestamp) => {
        if (!timestamp) return "Fecha no disponible";

        try {
            if (timestamp.toDate) {
                const date = timestamp.toDate();
                return new Intl.DateTimeFormat("es-ES", {
                    day: "2-digit",
                    month: "2-digit",
                    year: "numeric",
                    hour: "2-digit",
                    minute: "2-digit",
                }).format(date);
            }
        } catch (error) {
            console.error("Error al formatear la fecha:", error);
        }

        return "Fecha no disponible";
    };

    const getStatusChip = (status) => {
        if (!status)
            return <Chip size="small" label="Sin estado" color="default" />;

        switch (status.toLowerCase()) {
            case "correcto":
            case "aprobado":
            case "aprobada":
            case "ok":
            case "completo":
            case "completa":
                return (
                    <Chip
                        size="small"
                        icon={<CheckCircleIcon />}
                        label="Correcto"
                        color="success"
                    />
                );
            case "pendiente":
            case "en progreso":
            case "en proceso":
                return (
                    <Chip
                        size="small"
                        icon={<AccessTimeIcon />}
                        label="Pendiente"
                        color="warning"
                    />
                );
            case "incorrecto":
            case "rechazado":
            case "rechazada":
            case "fallo":
            case "error":
                return (
                    <Chip
                        size="small"
                        icon={<CancelIcon />}
                        label="Incorrecto"
                        color="error"
                    />
                );
            default:
                return <Chip size="small" label={status} color="info" />;
        }
    };

    if (loading) {
        return (
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                height="100vh"
                bgcolor="#f5f7fa"
            >
                <CircularProgress color="primary" />
            </Box>
        );
    }

    if (error) {
        return (
            <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                height="100vh"
                bgcolor="#f5f7fa"
                px={3}
            >
                <Alert severity="error" sx={{ mb: 2, maxWidth: 500 }}>
                    {error}
                </Alert>
                <Button
                    variant="contained"
                    startIcon={<ArrowBackIcon />}
                    onClick={() => navigate(-1)}
                >
                    Volver
                </Button>
            </Box>
        );
    }

    return (
        <Box
            sx={{
                bgcolor: "#f5f7fa",
                minHeight: "100vh",
                py: 4,
                px: { xs: 1, sm: 2, md: 3 },
            }}
        >
            <Container maxWidth="lg">
                {/* Breadcrumbs */}
                <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 2, color: "#555" }}>
                    <Link
                        underline="hover"
                        sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}
                        color="inherit"
                        onClick={() => navigate("/")}
                    >
                        <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                        Inicio
                    </Link>
                    <Typography
                        color="text.primary"
                        sx={{ display: "flex", alignItems: "center" }}
                    >
                        <VerifiedUserIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                        Certificado de Inspección
                    </Typography>
                </Breadcrumbs>

                <Fade in={true}>
                    <Box>
                        {/* Encabezado del certificado */}
                        <Card
                            elevation={3}
                            sx={{
                                borderRadius: 2,
                                overflow: "hidden",
                                mb: 3,
                                borderTop: "5px solid #1976d2",
                            }}
                        >
                            <Paper
                                elevation={0}
                                sx={{
                                    display: "flex",
                                    flexDirection: { xs: "column", sm: "row" },
                                    justifyContent: "space-between",
                                    alignItems: { xs: "flex-start", sm: "center" },
                                    p: 3,
                                    bgcolor: "white",
                                }}
                            >
                                <Box sx={{ display: "flex", alignItems: "center", mb: { xs: 2, sm: 0 } }}>
                                    <SecurityIcon
                                        sx={{
                                            fontSize: { xs: 50, sm: 60 },
                                            color: "#1976d2",
                                            mr: 2,
                                            opacity: 0.9
                                        }}
                                    />
                                    <Box>
                                        <Typography variant="h5" component="div" sx={{ fontWeight: "bold", color: "#1976d2" }}>
                                            Certificado de Inspección
                                        </Typography>
                                        <Typography variant="body2" color="text.secondary" sx={{ mt: 0.5 }}>
                                            Verificación oficial de RadioSistemas.mx
                                        </Typography>
                                    </Box>
                                </Box>
                                <Box sx={{ display: "flex", flexDirection: "column", alignItems: { xs: "flex-start", sm: "flex-end" } }}>
                                    <Typography variant="body2" sx={{ fontWeight: "medium", mb: 0.5 }}>
                                        Folio: <strong>{uid}</strong>
                                    </Typography>
                                    <Box sx={{ mt: 0.5 }}>
                                        {inspectionData && getStatusChip(inspectionData.Status)}
                                    </Box>
                                </Box>
                            </Paper>
                        </Card>

                        {/* Contenido principal */}
                        <Grid container spacing={3}>
                            {/* Información principal */}
                            <Grid item xs={12} md={4}>
                                <Card elevation={2} sx={{ borderRadius: 2, height: "100%" }}>
                                    <CardContent>
                                        <Typography
                                            variant="h6"
                                            sx={{
                                                fontWeight: "500",
                                                mb: 2,
                                                pb: 1,
                                                borderBottom: "1px solid #e0e0e0",
                                                display: "flex",
                                                alignItems: "center",
                                            }}
                                        >
                                            <InfoIcon sx={{ mr: 1, color: "#1976d2" }} />
                                            Información General
                                        </Typography>

                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <Box sx={{ mb: 2 }}>
                                                    <Typography variant="body2" color="text.secondary">
                                                        Fecha y hora de inspección
                                                    </Typography>
                                                    <Box sx={{ display: "flex", alignItems: "center", mt: 0.5 }}>
                                                        <CalendarTodayIcon sx={{ color: "#1976d2", mr: 1, fontSize: 18 }} />
                                                        <Typography variant="body1">
                                                            {formatDate(inspectionData?.Date)}
                                                        </Typography>
                                                    </Box>
                                                </Box>

                                                <Box sx={{ mb: 2 }}>
                                                    <Typography variant="body2" color="text.secondary">
                                                        Unidad
                                                    </Typography>
                                                    <Box sx={{ display: "flex", alignItems: "center", mt: 0.5 }}>
                                                        <ElectricCarIcon sx={{ color: "#1976d2", mr: 1, fontSize: 18 }} />
                                                        <Typography variant="body1">
                                                            {inspectionData?.Unidad || "No especificada"}
                                                        </Typography>
                                                    </Box>
                                                </Box>

                                                <Box sx={{ mb: 2 }}>
                                                    <Typography variant="body2" color="text.secondary">
                                                        Zona
                                                    </Typography>
                                                    <Box sx={{ display: "flex", alignItems: "center", mt: 0.5 }}>
                                                        <FolderIcon sx={{ color: "#1976d2", mr: 1, fontSize: 18 }} />
                                                        <Typography variant="body1">
                                                            {inspectionData?.Zona || "No especificada"}
                                                        </Typography>
                                                    </Box>
                                                </Box>

                                                <Divider sx={{ my: 2 }} />

                                                <Box sx={{ mb: 0 }}>
                                                    <Typography variant="body2" color="text.secondary">
                                                        Técnico responsable
                                                    </Typography>
                                                    <Box sx={{ display: "flex", alignItems: "center", mt: 0.5 }}>
                                                        <EngineeringIcon sx={{ color: "#1976d2", mr: 1, fontSize: 18 }} />
                                                        <Typography variant="body1">
                                                            {inspectionData?.Usuario || "No disponible"}
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid>

                            {/* Diagnóstico */}
                            <Grid item xs={12} md={8}>
                                <Card elevation={2} sx={{ borderRadius: 2 }}>
                                    <CardContent>
                                        <Typography
                                            variant="h6"
                                            sx={{
                                                fontWeight: "500",
                                                mb: 2,
                                                pb: 1,
                                                borderBottom: "1px solid #e0e0e0",
                                                display: "flex",
                                                alignItems: "center",
                                            }}
                                        >
                                            <VerifiedUserIcon sx={{ mr: 1, color: "#1976d2" }} />
                                            Diagnóstico Técnico
                                        </Typography>

                                        <DiagnosticoInspeccion
                                            estadoGeneral={inspectionData?.EstadoGeneral}
                                            fusiblesReemplazados={inspectionData?.FusiblesReemplazados}
                                        />

                                        {inspectionData?.Comentario && (
                                            <Box sx={{ mt: 3 }}>
                                                <Typography variant="subtitle1" sx={{ fontWeight: "medium", mb: 1 }}>
                                                    Comentarios técnicos
                                                </Typography>
                                                <Paper
                                                    elevation={0}
                                                    sx={{
                                                        p: 2,
                                                        bgcolor: "#f5f5f5",
                                                        borderRadius: 2,
                                                        borderLeft: "4px solid #1976d2",
                                                    }}
                                                >
                                                    <Box sx={{ display: "flex" }}>
                                                        <CommentIcon
                                                            sx={{
                                                                color: "#1976d2",
                                                                mr: 1.5,
                                                                fontSize: 20,
                                                                alignSelf: "flex-start",
                                                                mt: 0.5,
                                                            }}
                                                        />
                                                        <Typography variant="body1">
                                                            {inspectionData.Comentario}
                                                        </Typography>
                                                    </Box>
                                                </Paper>
                                            </Box>
                                        )}

                                        {/* Espacio para firmas */}
                                        {inspectionData?.fotos?.Firma && (
                                            <Box sx={{ mt: 3 }}>
                                                <Typography variant="subtitle1" sx={{ fontWeight: "medium", mb: 1 }}>
                                                    Firma del oficial
                                                </Typography>
                                                <Box
                                                    sx={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        p: 2,
                                                        bgcolor: "#f8f8f8",
                                                        borderRadius: 2
                                                    }}
                                                >
                                                    {inspectionData?.fotos?.Oficial && (
                                                        <Avatar
                                                            src={inspectionData.fotos.Oficial}
                                                            alt="Oficial"
                                                            sx={{
                                                                width: 60,
                                                                height: 60,
                                                                mr: 2,
                                                                border: "2px solid #1976d2"
                                                            }}
                                                        />
                                                    )}
                                                    <Box>
                                                        <Typography variant="body2" color="text.secondary" sx={{ mb: 0.5 }}>
                                                            Oficial: {inspectionData?.Nombre || "No disponible"}
                                                        </Typography>
                                                        <Box
                                                            sx={{
                                                                border: "1px solid #e0e0e0",
                                                                borderRadius: 1,
                                                                padding: 1,
                                                                bgcolor: "white",
                                                                width: "100%",
                                                                maxWidth: "280px"
                                                            }}
                                                        >
                                                            <img
                                                                src={inspectionData.fotos.Firma}
                                                                alt="Firma del oficial"
                                                                style={{
                                                                    maxWidth: "100%",
                                                                    height: 60,
                                                                    objectFit: "contain"
                                                                }}
                                                            />
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        )}
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>

                        {/* Galería de fotos - Siempre visible */}
                        <Card elevation={2} sx={{ borderRadius: 2, overflow: "hidden", mt: 3 }}>
                            <CardContent>
                                <Typography
                                    variant="h6"
                                    gutterBottom
                                    sx={{
                                        fontWeight: "500",
                                        mb: 2,
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                >
                                    <PhotoLibraryIcon sx={{ mr: 1 }} />
                                    Galería de fotos
                                </Typography>

                                {!inspectionData?.fotos || Object.keys(inspectionData.fotos).filter(k => k !== "Firma" && k !== "Oficial").length === 0 ? (
                                    <Alert severity="info" sx={{ mb: 2 }}>
                                        No hay fotos disponibles para esta inspección.
                                    </Alert>
                                ) : (
                                    <Grid container spacing={2}>
                                        {inspectionData?.fotos &&
                                            Object.entries(inspectionData.fotos)
                                                .filter(
                                                    ([key]) => key !== "Firma" && key !== "Oficial"
                                                )
                                                .map(([key, url]) => (
                                                    <Grid item xs={12} sm={6} md={4} key={key}>
                                                        <Card
                                                            elevation={2}
                                                            sx={{
                                                                borderRadius: 2,
                                                                cursor: "pointer",
                                                                transition:
                                                                    "transform 0.2s, box-shadow 0.2s",
                                                                "&:hover": {
                                                                    transform: "translateY(-4px)",
                                                                    boxShadow: "0 8px 16px rgba(0,0,0,0.1)",
                                                                },
                                                            }}
                                                            onClick={() => handleOpenModal(url)}
                                                        >
                                                            <Box
                                                                sx={{
                                                                    position: "relative",
                                                                    paddingTop: "75%", // 4:3 aspect ratio
                                                                    overflow: "hidden",
                                                                    backgroundColor: "#f5f5f5",
                                                                }}
                                                            >
                                                                <img
                                                                    src={url}
                                                                    alt={key}
                                                                    style={{
                                                                        position: "absolute",
                                                                        top: 0,
                                                                        left: 0,
                                                                        width: "100%",
                                                                        height: "100%",
                                                                        objectFit: "cover",
                                                                    }}
                                                                />
                                                                <Box
                                                                    sx={{
                                                                        position: "absolute",
                                                                        bottom: 0,
                                                                        left: 0,
                                                                        right: 0,
                                                                        backgroundColor: "rgba(0,0,0,0.6)",
                                                                        color: "white",
                                                                        p: 1,
                                                                    }}
                                                                >
                                                                    <Typography
                                                                        variant="body2"
                                                                        sx={{ fontWeight: "medium" }}
                                                                    >
                                                                        {key}
                                                                    </Typography>
                                                                </Box>
                                                                <Tooltip title="Ver imagen">
                                                                    <IconButton
                                                                        sx={{
                                                                            position: "absolute",
                                                                            top: 8,
                                                                            right: 8,
                                                                            backgroundColor:
                                                                                "rgba(255,255,255,0.8)",
                                                                            "&:hover": {
                                                                                backgroundColor:
                                                                                    "rgba(255,255,255,1)",
                                                                            },
                                                                        }}
                                                                    >
                                                                        <ZoomInIcon />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </Box>
                                                        </Card>
                                                    </Grid>
                                                ))}
                                    </Grid>
                                )}
                            </CardContent>
                        </Card>

                        {/* PDF Generator */}
                        <Box sx={{ mt: 3 }}>
                            <InspectionPdfGenerator inspectionData={inspectionData} />
                        </Box>
                    </Box>
                </Fade>
            </Container>

            {/* Modal para visualización de imágenes */}
            <Modal open={openModal} onClose={handleCloseModal} closeAfterTransition>
                <Fade in={openModal}>
                    <Box
                        sx={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            width: { xs: "95%", sm: "90%", md: "80%" },
                            maxWidth: "1200px",
                            maxHeight: "90vh",
                            bgcolor: "#fff",
                            borderRadius: 2,
                            boxShadow: 24,
                            p: { xs: 2, sm: 3 },
                            outline: "none",
                            display: "flex",
                            flexDirection: "column",
                        }}
                    >
                        <Box
                            sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}
                        >
                            <Typography
                                variant="h6"
                                component="div"
                                sx={{ color: "#1976d2" }}
                            >
                                Vista previa de imagen
                            </Typography>
                            <IconButton
                                onClick={handleCloseModal}
                                size="small"
                                sx={{ color: "#666" }}
                            >
                                <CloseIcon />
                            </IconButton>
                        </Box>

                        <Box
                            sx={{
                                flex: 1,
                                overflow: "auto",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                backgroundColor: "#f5f5f5",
                                borderRadius: 1,
                            }}
                        >
                            <img
                                src={selectedImage}
                                alt="Vista previa"
                                style={{
                                    maxWidth: "100%",
                                    maxHeight: "70vh",
                                    objectFit: "contain",
                                }}
                            />
                        </Box>
                    </Box>
                </Fade>
            </Modal>
        </Box>
    );
};

export default InspectionSummary;