import React, { useState, useEffect } from "react";
import { File, CreditCard, Settings, Briefcase, FileText, Car } from "lucide-react";

const Documentacion = ({ solicitudId, datosIniciales, onUpdate }) => {
  const [form, setForm] = useState(datosIniciales || {});

  useEffect(() => {
    setForm(datosIniciales || {});
  }, [datosIniciales]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    const updatedForm = { ...form, [name]: value };
    setForm(updatedForm);

    if (onUpdate) {
      onUpdate(updatedForm); // Actualizar en tiempo real
    }
  };

  return (
    <div className="bg-white rounded-md">
      <div className="grid grid-cols-1 gap-6">
        {/* CURP */}
        <div>
          <label htmlFor="curp" className="block text-sm font-medium text-gray-700 mb-1">
            Clave Única de Registro de Población (CURP)
          </label>
          <div className="relative rounded-md shadow-sm">
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <File className="h-5 w-5 text-gray-400" />
            </div>
            <input
              type="text"
              id="curp"
              name="curp"
              className="block w-full pl-10 py-2 sm:text-sm border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
              placeholder="XXXX000000XXXXXX00"
              value={form.curp || ""}
              onChange={handleChange}
              maxLength={18}
            />
          </div>
          <p className="mt-1 text-xs text-gray-500">
            18 caracteres que incluyen letras y números
          </p>
        </div>

        {/* RFC */}
        <div>
          <label htmlFor="rfc" className="block text-sm font-medium text-gray-700 mb-1">
            Registro Federal de Contribuyentes (RFC)
          </label>
          <div className="relative rounded-md shadow-sm">
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <CreditCard className="h-5 w-5 text-gray-400" />
            </div>
            <input
              type="text"
              id="rfc"
              name="rfc"
              className="block w-full pl-10 py-2 sm:text-sm border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
              placeholder="XXXX000000XXX"
              value={form.rfc || ""}
              onChange={handleChange}
              maxLength={13}
            />
          </div>
          <p className="mt-1 text-xs text-gray-500">
            13 caracteres para personas físicas, 12 para personas morales
          </p>
        </div>

        {/* NSS */}
        <div>
          <label htmlFor="nss" className="block text-sm font-medium text-gray-700 mb-1">
            Número de Seguridad Social (NSS)
          </label>
          <div className="relative rounded-md shadow-sm">
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <Settings className="h-5 w-5 text-gray-400" />
            </div>
            <input
              type="text"
              id="nss"
              name="nss"
              className="block w-full pl-10 py-2 sm:text-sm border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
              placeholder="0000 0000 0000 0000"
              value={form.nss || ""}
              onChange={handleChange}
              maxLength={11}
            />
          </div>
          <p className="mt-1 text-xs text-gray-500">
            11 dígitos proporcionados por el IMSS
          </p>
        </div>

        {/* AFORE */}
        <div>
          <label htmlFor="afore" className="block text-sm font-medium text-gray-700 mb-1">
            AFORE
          </label>
          <div className="relative rounded-md shadow-sm">
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <Briefcase className="h-5 w-5 text-gray-400" />
            </div>
            <input
              type="text"
              id="afore"
              name="afore"
              className="block w-full pl-10 py-2 sm:text-sm border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
              placeholder="Nombre de la AFORE actual"
              value={form.afore || ""}
              onChange={handleChange}
            />
          </div>
        </div>

        {/* Pasaporte */}
        <div>
          <label htmlFor="pasaporte" className="block text-sm font-medium text-gray-700 mb-1">
            Pasaporte No.
          </label>
          <div className="relative rounded-md shadow-sm">
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <FileText className="h-5 w-5 text-gray-400" />
            </div>
            <input
              type="text"
              id="pasaporte"
              name="pasaporte"
              className="block w-full pl-10 py-2 sm:text-sm border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
              placeholder="G00000000"
              value={form.pasaporte || ""}
              onChange={handleChange}
            />
          </div>
          <p className="mt-1 text-xs text-gray-500">
            Número de pasaporte vigente (opcional)
          </p>
        </div>

        {/* Licencia */}
        <div>
          <label htmlFor="licencia" className="block text-sm font-medium text-gray-700 mb-1">
            Clase y Número de Licencia
          </label>
          <div className="relative rounded-md shadow-sm">
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <Car className="h-5 w-5 text-gray-400" />
            </div>
            <input
              type="text"
              id="licencia"
              name="licencia"
              className="block w-full pl-10 py-2 sm:text-sm border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
              placeholder="Tipo y número de licencia de conducir"
              value={form.licencia || ""}
              onChange={handleChange}
            />
          </div>
          <p className="mt-1 text-xs text-gray-500">
            Ejemplo: Chofer 
          </p>
        </div>
      </div>

      {/* Información adicional */}
      <div className="mt-6 p-4 bg-blue-50 border-l-4 border-blue-400 rounded">
        <div className="flex">
          <div className="flex-shrink-0">
            <svg className="h-5 w-5 text-blue-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd" />
            </svg>
          </div>
          <div className="ml-3">
            <h3 className="text-sm font-medium text-blue-800">Aviso Importante</h3>
            <div className="mt-2 text-sm text-blue-700">
              <p>
                Los datos proporcionados serán tratados con confidencialidad y solo serán utilizados para los fines específicos del proceso de solicitud.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Documentacion;