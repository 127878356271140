import React from "react";
import { User } from "lucide-react";

const SeccionDatosPersonales = ({ candidato }) => (
    <div className="bg-white shadow-md rounded-lg p-6">
        <h3 className="text-lg font-bold text-gray-900 mb-4 flex items-center">
            <User className="h-5 w-5 text-blue-500 mr-2" />
            Datos Personales
        </h3>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-y-3 gap-x-6">
            <div className="flex flex-col">
                <span className="text-sm text-gray-500">Nombres</span>
                <span className="font-medium">{candidato.datosPersonales?.nombres || "No especificado"}</span>
            </div>
            <div className="flex flex-col">
                <span className="text-sm text-gray-500">Apellido Paterno</span>
                <span className="font-medium">{candidato.datosPersonales?.apellidoPaterno || "No especificado"}</span>
            </div>
            <div className="flex flex-col">
                <span className="text-sm text-gray-500">Apellido Materno</span>
                <span className="font-medium">{candidato.datosPersonales?.apellidoMaterno || "No especificado"}</span>
            </div>
            <div className="flex flex-col">
                <span className="text-sm text-gray-500">Edad</span>
                <span className="font-medium">{candidato.datosPersonales?.edad || "No especificado"}</span>
            </div>
            <div className="flex flex-col">
                <span className="text-sm text-gray-500">Lugar de Nacimiento</span>
                <span className="font-medium">{candidato.datosPersonales?.lugarNacimiento || "No especificado"}</span>
            </div>
            <div className="flex flex-col">
                <span className="text-sm text-gray-500">Teléfono</span>
                <span className="font-medium">{candidato.datosPersonales?.telefono || "No especificado"}</span>
            </div>
            <div className="flex flex-col">
                <span className="text-sm text-gray-500">Domicilio</span>
                <span className="font-medium">{candidato.datosPersonales?.domicilio || "No especificado"}</span>
            </div>
            <div className="flex flex-col">
                <span className="text-sm text-gray-500">Colonia</span>
                <span className="font-medium">{candidato.datosPersonales?.colonia || "No especificado"}</span>
            </div>
            <div className="flex flex-col">
                <span className="text-sm text-gray-500">Código Postal</span>
                <span className="font-medium">{candidato.datosPersonales?.codigoPostal || "No especificado"}</span>
            </div>
            <div className="flex flex-col">
                <span className="text-sm text-gray-500">Nacionalidad</span>
                <span className="font-medium">{candidato.datosPersonales?.nacionalidad || "No especificado"}</span>
            </div>
        </div>
    </div>
);

export default SeccionDatosPersonales;