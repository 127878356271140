import React, { useEffect } from "react";
import { Briefcase, Clock } from "lucide-react";

const SeccionHistorialEmpleos = ({ candidato, formatearFecha }) => {
    // Función para formatear fecha
    const formatearFechaSimple = (timestamp) => {
        if (!timestamp) return "No especificado";

        try {
            const fecha = new Date(timestamp);
            return fecha.toLocaleDateString('es-MX');
        } catch (error) {
            console.error("Error al formatear fecha:", timestamp, error);
            return "No especificado";
        }
    };

    // Función para calcular años de experiencia en un empleo
    const calcularAnosExperiencia = (tiempoDesde, tiempoHasta, trabajoActual) => {
        try {
            const fechaInicio = new Date(tiempoDesde);
            const fechaFin = trabajoActual ? new Date() : new Date(tiempoHasta);

            if (isNaN(fechaInicio.getTime()) || isNaN(fechaFin.getTime())) {
                return "No disponible";
            }

            const diferenciaMs = fechaFin - fechaInicio;
            const anos = diferenciaMs / (1000 * 60 * 60 * 24 * 365.25);

            // Formatear los años con 1 decimal
            return anos.toFixed(1) + " años";
        } catch (error) {
            console.error("Error al calcular experiencia:", error);
            return "No disponible";
        }
    };

    // Función para calcular el total de años de experiencia
    const calcularExperienciaTotal = (empleos) => {
        if (!empleos || empleos.length === 0) return "0 años";

        try {
            let totalMs = 0;

            empleos.forEach(empleo => {
                const fechaInicio = new Date(empleo.tiempoDesde);
                const fechaFin = empleo.trabajoActual ? new Date() : new Date(empleo.tiempoHasta);

                if (!isNaN(fechaInicio.getTime()) && !isNaN(fechaFin.getTime())) {
                    totalMs += fechaFin - fechaInicio;
                }
            });

            const totalAnos = totalMs / (1000 * 60 * 60 * 24 * 365.25);
            return totalAnos.toFixed(1) + " años";
        } catch (error) {
            console.error("Error al calcular experiencia total:", error);
            return "No disponible";
        }
    };

    // Ordenar empleos de más antiguo a más reciente
    const obtenerEmpleosOrdenados = () => {
        if (!candidato.historialEmpleos || candidato.historialEmpleos.length === 0) {
            return [];
        }

        return [...candidato.historialEmpleos].sort((a, b) => {
            const fechaA = a.tiempoDesde || 0;
            const fechaB = b.tiempoDesde || 0;
            return fechaA - fechaB;
        });
    };

    const empleosOrdenados = obtenerEmpleosOrdenados();
    const experienciaTotal = calcularExperienciaTotal(candidato.historialEmpleos);

    return (
        <div className="bg-white shadow-md rounded-lg p-6">
            <div className="flex justify-between items-center mb-4">
                <h3 className="text-lg font-bold text-gray-900 flex items-center">
                    <Briefcase className="h-5 w-5 text-blue-500 mr-2" />
                    Historial de Empleos
                </h3>
                <div className="flex items-center bg-blue-50 px-3 py-1 rounded-full text-blue-700">
                    <Clock className="h-4 w-4 mr-1" />
                    <span className="text-sm font-medium">Experiencia total: {experienciaTotal}</span>
                </div>
            </div>

            {empleosOrdenados.length > 0 ? (
                <div className="space-y-6">
                    {empleosOrdenados.map((empleo, index) => (
                        <div key={index} className="border-l-2 border-blue-200 pl-4 py-2">
                            <div className="flex justify-between items-center mb-2">
                                <h4 className="font-semibold text-gray-800">{empleo.nombreCompania || "Empresa no especificada"}</h4>
                                <span className="text-sm bg-gray-100 text-gray-700 px-2 py-1 rounded-full">
                                    {calcularAnosExperiencia(empleo.tiempoDesde, empleo.tiempoHasta, empleo.trabajoActual)}
                                </span>
                            </div>
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-y-2 gap-x-6">
                                <div className="flex flex-col">
                                    <span className="text-sm text-gray-500">Puesto Desempeñado</span>
                                    <span className="font-medium">{empleo.puestoDesempenado || "No especificado"}</span>
                                </div>
                                <div className="flex flex-col">
                                    <span className="text-sm text-gray-500">Dirección</span>
                                    <span className="font-medium">{empleo.direccion || "No especificado"}</span>
                                </div>
                                <div className="flex flex-col">
                                    <span className="text-sm text-gray-500">Teléfono</span>
                                    <span className="font-medium">{empleo.telefono || "No especificado"}</span>
                                </div>
                                <div className="flex flex-col">
                                    <span className="text-sm text-gray-500">Periodo</span>
                                    <span className="font-medium">
                                        {empleo.trabajoActual
                                            ? `Desde ${formatearFechaSimple(empleo.tiempoDesde)} - Actualidad`
                                            : `${formatearFechaSimple(empleo.tiempoDesde)} - ${formatearFechaSimple(empleo.tiempoHasta)}`
                                        }
                                    </span>
                                </div>
                                <div className="flex flex-col">
                                    <span className="text-sm text-gray-500">Sueldo Inicial</span>
                                    <span className="font-medium">${empleo.sueldoInicial || "No especificado"}</span>
                                </div>
                                <div className="flex flex-col">
                                    <span className="text-sm text-gray-500">Sueldo Final</span>
                                    <span className="font-medium">${empleo.sueldoFinal || "No especificado"}</span>
                                </div>
                                <div className="flex flex-col">
                                    <span className="text-sm text-gray-500">Nombre del Jefe</span>
                                    <span className="font-medium">{empleo.nombreJefe || "No especificado"}</span>
                                </div>
                                <div className="flex flex-col">
                                    <span className="text-sm text-gray-500">Puesto del Jefe</span>
                                    <span className="font-medium">{empleo.puestoJefe || "No especificado"}</span>
                                </div>
                                <div className="flex flex-col">
                                    <span className="text-sm text-gray-500">Motivo de Separación</span>
                                    <span className="font-medium">{empleo.trabajoActual ? "N/A" : (empleo.motivoSeparacion || "No especificado")}</span>
                                </div>
                                <div className="flex flex-col">
                                    <span className="text-sm text-gray-500">¿Trabajo Actual?</span>
                                    <span className="font-medium">{empleo.trabajoActual ? "Sí" : "No"}</span>
                                </div>
                                <div className="flex flex-col col-span-2">
                                    <span className="text-sm text-gray-500">Comentarios del Jefe</span>
                                    <span className="font-medium">{empleo.comentariosJefes || "No especificado"}</span>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            ) : (
                <p className="text-gray-500 italic">No hay historial de empleos registrado</p>
            )}
        </div>
    );
};

export default SeccionHistorialEmpleos;