import React, { useEffect, useState } from "react";
import { useUserAuth } from "../../context/userAuthContext";
import { Link, useNavigate } from "react-router-dom";
import { db } from "../../firebase";
import { doc, getDoc, setDoc } from "firebase/firestore";
import {
  LayoutDashboard,
  ClipboardList,
  DollarSign,
  HeadphonesIcon,
  User,
  Shield,
  LogOut,
  Settings,
  ChevronRight,
} from "lucide-react";

const UserDashboard = () => {
  const { user, logOut } = useUserAuth();
  const [displayName, setDisplayName] = useState("");
  const [photoURL, setPhotoURL] = useState("");
  const [isAdmin, setIsAdmin] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const createUserDocument = async (user) => {
      if (user) {
        const userDocRef = doc(db, "Users", user.uid);
        const userDoc = await getDoc(userDocRef);

        if (!userDoc.exists()) {
          await setDoc(userDocRef, {
            nombre: user.displayName || "Usuario",
            email: user.email,
            roles: [],
          });
        } else {
          const userData = userDoc.data();
          setIsAdmin(userData.roles && userData.roles.includes("admin"));
        }
      }
    };

    if (user) {
      setDisplayName(user.displayName || "Usuario");
      setPhotoURL(user.photoURL || "https://via.placeholder.com/150");
      createUserDocument(user);
    }
  }, [user]);

  const handleLogout = async () => {
    try {
      await logOut();
      navigate("/");
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };

  const menuItems = [
    {
      icon: <LayoutDashboard className="h-6 w-6" />,
      text: "Dashboard",
      description: "Vista general de todos tus proyectos y actividades",
      path: "/dashboard",
      color: "bg-blue-600 hover:bg-blue-700",
    },
    {
      icon: <ClipboardList className="h-6 w-6" />,
      text: "Proyectos",
      description: "Administra tus proyectos activos y pasados",
      path: "/projectdashboard",
      color: "bg-indigo-600 hover:bg-indigo-700",
    },
    {
      icon: <DollarSign className="h-6 w-6" />,
      text: "Cotizaciones",
      description: "Consulta tus cotizaciones y solicita nuevas",
      path: "/cotizaciones",
      color: "bg-green-600 hover:bg-green-700",
    },
    {
      icon: <HeadphonesIcon className="h-6 w-6" />,
      text: "Soporte",
      description: "Obtén ayuda y contacta a nuestro equipo",
      path: "/soporte",
      color: "bg-amber-600 hover:bg-amber-700",
    },
    {
      icon: <User className="h-6 w-6" />,
      text: "Perfil",
      description: "Edita tu información personal y preferencias",
      path: "/perfil",
      color: "bg-purple-600 hover:bg-purple-700",
    },
  ];

  return (
    <div className="min-h-screen bg-gray-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        {/* Header */}
        <div className="bg-white rounded-2xl shadow-xl overflow-hidden mb-8">
          <div className="bg-gradient-to-r from-blue-600 to-indigo-700 px-6 py-10 sm:px-10">
            <div className="flex flex-col sm:flex-row justify-between items-center">
              <div className="flex flex-col sm:flex-row items-center mb-6 sm:mb-0">
                <div className="relative">
                  <img
                    src={photoURL}
                    alt={displayName}
                    className="h-20 w-20 rounded-full border-4 border-white object-cover shadow-lg"
                  />
                  {isAdmin && (
                    <div className="absolute -top-1 -right-1 bg-red-500 text-white p-1 rounded-full">
                      <Shield className="h-4 w-4" />
                    </div>
                  )}
                </div>
                <div className="mt-4 sm:mt-0 sm:ml-6 text-center sm:text-left">
                  <h1 className="text-2xl font-bold text-white">
                    Bienvenido, {displayName}
                  </h1>
                  <p className="text-blue-100 font-medium">{user?.email}</p>
                </div>
              </div>
              <div className="flex gap-4">
                <button
                  onClick={() => navigate("/settings")}
                  className="bg-white/20 hover:bg-white/30 text-white rounded-full p-2 transition-all duration-200"
                >
                  <Settings className="h-5 w-5" />
                </button>
                <button
                  onClick={handleLogout}
                  className="bg-white/20 hover:bg-white/30 text-white rounded-full p-2 transition-all duration-200"
                >
                  <LogOut className="h-5 w-5" />
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* Main Content */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {menuItems.map((item, index) => (
            <Link
              to={item.path}
              key={index}
              className="group relative bg-white rounded-xl shadow-md hover:shadow-xl transition-all duration-300 overflow-hidden flex flex-col h-full"
            >
              <div className={`${item.color} p-6 text-white`}>
                <div className="flex justify-between items-center">
                  {item.icon}
                  <ChevronRight className="h-5 w-5 transform group-hover:translate-x-1 transition-transform duration-200" />
                </div>
                <h3 className="mt-4 text-xl font-semibold">{item.text}</h3>
              </div>
              <div className="p-6 flex-grow">
                <p className="text-gray-600">{item.description}</p>
              </div>
            </Link>
          ))}

          {/* Admin Dashboard Card */}
          {isAdmin && (
            <Link
              to="/admin"
              className="group relative bg-white rounded-xl shadow-md hover:shadow-xl transition-all duration-300 overflow-hidden flex flex-col h-full"
            >
              <div className="bg-red-700 hover:bg-red-800 p-6 text-white">
                <div className="flex justify-between items-center">
                  <Shield className="h-6 w-6" />
                  <ChevronRight className="h-5 w-5 transform group-hover:translate-x-1 transition-transform duration-200" />
                </div>
                <h3 className="mt-4 text-xl font-semibold">Admin Dashboard</h3>
              </div>
              <div className="p-6 flex-grow">
                <p className="text-gray-600">
                  Acceso a configuraciones y funciones administrativas
                </p>
              </div>
            </Link>
          )}

          {/* Logout Card */}
          <div
            onClick={handleLogout}
            className="group cursor-pointer relative bg-white rounded-xl shadow-md hover:shadow-xl transition-all duration-300 overflow-hidden flex flex-col h-full"
          >
            <div className="bg-gray-200 hover:bg-gray-300 p-6 text-gray-800">
              <div className="flex justify-between items-center">
                <LogOut className="h-6 w-6" />
                <ChevronRight className="h-5 w-5 transform group-hover:translate-x-1 transition-transform duration-200" />
              </div>
              <h3 className="mt-4 text-xl font-semibold">Cerrar Sesión</h3>
            </div>
            <div className="p-6 flex-grow">
              <p className="text-gray-600">
                Finalizar tu sesión actual de forma segura
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Footer */}
      <footer className="bg-white border-t border-gray-200 py-6 mt-12">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex flex-col md:flex-row justify-between items-center">
            <p className="text-sm text-gray-500">
              &copy; {new Date().getFullYear()} Your Company. Todos los derechos
              reservados.
            </p>
            <div className="mt-4 md:mt-0">
              <ul className="flex space-x-6">
                <li>
                  <a
                    href="#"
                    className="text-sm text-gray-500 hover:text-gray-900"
                  >
                    Términos
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    className="text-sm text-gray-500 hover:text-gray-900"
                  >
                    Privacidad
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    className="text-sm text-gray-500 hover:text-gray-900"
                  >
                    Contacto
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default UserDashboard;
