import React from "react";
import { Radio, Shield, Wrench, Clock, ChevronRight } from "lucide-react";

const features = [
  {
    title: "Puntos de Repetición",
    description:
      "Contamos con puntos de repetición de radio frecuencias estratégicamente ubicados en Hermosillo, Sonora.",
    icon: Radio,
    media:
      "https://firebasestorage.googleapis.com/v0/b/radiosistemas-a46cd.appspot.com/o/122094597_182670086833241_1973500611783783661_n-1500w.jpg?alt=media&token=9d945a7a-ccb3-4beb-8430-5108ccfd9f2d",
    details:
      "Ofrecemos espacios disponibles para el alquiler de repetidoras, permitiendo una conexión confiable y de calidad para sus comunicaciones.",
  },
  {
    title: "Equipamiento",
    description:
      "Nuestro equipo comprende la importancia de la seguridad pública y está comprometido con brindar soluciones confiables.",
    icon: Shield,
    media:
      "https://firebasestorage.googleapis.com/v0/b/radiosistemas-a46cd.appspot.com/o/frente%20nice-1500w.png?alt=media&token=c23d47fe-56ee-4d79-acac-3c3788079e82",
    details:
      "Instalamos equipos de seguridad de manera profesional, siguiendo las mejores prácticas de la industria.",
  },
  {
    title: "Tecnologías de Vanguardia",
    description:
      "Nuestros drones están equipados con las últimas características y capacidades para operaciones de seguridad.",
    icon: Wrench,
    media:
      "https://firebasestorage.googleapis.com/v0/b/radiosistemas-a46cd.appspot.com/o/img_20230613_1423511-1500w.jpg?alt=media&token=59d631dd-3db5-42db-abc5-94659d551fb5",
    details:
      "Utilizamos drones para vigilancia, monitoreo aéreo, y tareas de búsqueda y rescate, ofreciendo herramientas efectivas y avanzadas.",
  },
  {
    title: "Experiencia",
    description:
      "Más de 30 años de experiencia en el campo de la seguridad, ofreciendo soluciones tanto para clientes públicos como privados.",
    icon: Clock,
    media:
      "https://firebasestorage.googleapis.com/v0/b/radiosistemas-a46cd.appspot.com/o/aereas-_entrega_de_patrullas_03-1500w.jpg?alt=media&token=a4b133aa-48f3-4e57-9d71-24b1510a0927",
    details:
      "Con tres décadas de experiencia, garantizamos la implementación y mantenimiento de soluciones de seguridad confiables.",
  },
];

const FeatureCard = ({ feature }) => {
  const Icon = feature.icon;

  return (
    <div className="w-full md:w-1/2 lg:w-1/4 p-4">
      <div className="bg-white rounded-lg overflow-hidden border border-gray-200 transition-all duration-300 hover:shadow-md group h-full flex flex-col">
        <div className="relative overflow-hidden">
          <img
            src={feature.media}
            alt={feature.title}
            className="w-full h-64 object-cover object-center transition-transform duration-500 group-hover:scale-105"
          />
          <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent opacity-70"></div>
          <div className="absolute bottom-0 left-0 p-6">
            <div className="flex items-center space-x-3 mb-2">
              <div className="w-10 h-10 bg-blue-700 rounded-md flex items-center justify-center shadow-lg">
                <Icon className="w-5 h-5 text-white" />
              </div>
              <h3 className="text-lg font-bold text-white">{feature.title}</h3>
            </div>
          </div>
        </div>
        <div className="p-6 flex-grow flex flex-col justify-between">
          <div>
            <p className="text-gray-700 text-sm leading-relaxed mb-4">
              {feature.description}
            </p>
            <p className="text-gray-800 text-sm font-medium">
              {feature.details}
            </p>
          </div>
          <div className="mt-6">
            <button className="inline-flex items-center text-blue-700 font-medium text-sm group-hover:text-blue-800 transition-colors duration-200">
              Más información <ChevronRight className="w-4 h-4 ml-1" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const Features = () => {
  return (
    <section
      id="features"
      className="py-24 px-4 bg-gradient-to-b from-gray-50 to-white"
    >
      <div className="max-w-7xl mx-auto">
        <div className="text-center mb-16">
          <h2 className="text-3xl font-bold text-gray-900 mb-4">
            Soluciones Profesionales de Seguridad
          </h2>
          <div className="w-20 h-1 bg-blue-700 mx-auto mb-6"></div>
          <p className="max-w-2xl mx-auto text-gray-600">
            Tecnología avanzada y experiencia comprobada para brindarle
            soluciones de seguridad confiables y eficientes.
          </p>
        </div>
        <div className="flex flex-wrap -mx-4">
          {features.map((feature, index) => (
            <FeatureCard key={index} feature={feature} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Features;
