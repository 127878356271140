import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Package,
  Wrench,
  Shield,
  Radio,
  Users,
  ClipboardList,
  Receipt,
  DoorOpen,
  Building,
  Mail,
  Link2,
  FileCheck,
  Banknote,
  UserPlus,
  Search,
} from "lucide-react";

const AdminMenu = () => {
  const [searchTerm, setSearchTerm] = useState("");

  const menuItems = [
    { text: "Enlaces", icon: <Link2 className="h-6 w-6" />, path: "/enlaces" },
    {
      text: "Todas las inspecciones",
      icon: <FileCheck className="h-6 w-6" />,
      path: "/allinspections",
    },
    {
      text: "Empleados",
      icon: <Users className="h-6 w-6" />,
      path: "/empleados",
    },
    { text: "Nomina", icon: <Banknote className="h-6 w-6" />, path: "/nomina" },
    {
      text: "Nuevo Cliente",
      icon: <UserPlus className="h-6 w-6" />,
      path: "/nuevocliente",
    },
    {
      text: "Inventario",
      icon: <Package className="h-6 w-6" />,
      path: "/inventario",
    },
    {
      text: "Mantenimiento",
      icon: <Wrench className="h-6 w-6" />,
      path: "/mantenimiento",
    },
    {
      text: "Garantías",
      icon: <Shield className="h-6 w-6" />,
      path: "/garantias",
    },
    {
      text: "Personal",
      icon: <Users className="h-6 w-6" />,
      path: "/personal",
    },
    {
      text: "Proyectos",
      icon: <ClipboardList className="h-6 w-6" />,
      path: "/proyectos",
    },
    {
      text: "Facturas",
      icon: <Receipt className="h-6 w-6" />,
      path: "/facturas",
    },
    {
      text: "Reuniones",
      icon: <DoorOpen className="h-6 w-6" />,
      path: "/reuniones",
    },
    {
      text: "Clientes",
      icon: <Building className="h-6 w-6" />,
      path: "/clientes",
    },
    {
      text: "Contactos",
      icon: <Mail className="h-6 w-6" />,
      path: "/contactos",
    },
  ];

  const filteredItems = menuItems.filter((item) =>
    item.text.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div>
      <div className="mb-8">
        <h2 className="text-2xl font-bold text-gray-800 text-center relative pb-4 mb-6">
          Panel de Administración
          <span className="absolute bottom-0 left-1/2 transform -translate-x-1/2 w-32 h-1 bg-blue-600 rounded-full"></span>
        </h2>

        <div className="relative mb-8">
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <Search className="h-5 w-5 text-gray-400" />
          </div>
          <input
            type="text"
            className="block w-full pl-10 pr-3 py-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 bg-white shadow-sm"
            placeholder="Buscar en el menú..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
          {filteredItems.map((item, index) => (
            <Link
              key={index}
              to={item.path}
              className="group bg-white hover:bg-blue-50 border border-gray-200 rounded-xl shadow-sm hover:shadow-md transition-all duration-300 p-5 flex flex-col items-center justify-center text-center h-36"
            >
              <div className="text-blue-600 mb-3 transform transition-transform duration-300 group-hover:scale-110">
                {item.icon}
              </div>
              <span className="font-medium text-gray-800">{item.text}</span>
            </Link>
          ))}
        </div>
      </div>

      {/* Empty state for when no results are found */}
      {filteredItems.length === 0 && (
        <div className="text-center py-16 px-4">
          <div className="mx-auto h-12 w-12 text-gray-400 mb-4">
            <Search className="h-12 w-12" />
          </div>
          <h3 className="text-lg font-medium text-gray-900 mb-2">
            No se encontraron resultados
          </h3>
          <p className="text-gray-500 mb-6">
            No hay coincidencias para "{searchTerm}". Intente con otros
            términos.
          </p>
          <button
            onClick={() => setSearchTerm("")}
            className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            Limpiar búsqueda
          </button>
        </div>
      )}
    </div>
  );
};

export default AdminMenu;
