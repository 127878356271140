import React, { useState, useEffect } from "react";
import { PDFDownloadLink } from "@react-pdf/renderer";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import QRCode from "qrcode";
import {
  Button,
  Box,
  Tooltip,
  CircularProgress,
  IconButton,
  Card,
  Typography,
  Divider,
  Snackbar,
  Alert,
  Paper,
} from "@mui/material";
import PrintIcon from "@mui/icons-material/Print";
import ShareIcon from "@mui/icons-material/Share";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import DownloadIcon from "@mui/icons-material/Download";

// URL del logo de la empresa
const COMPANY_LOGO_URL =
  "https://firebasestorage.googleapis.com/v0/b/radiosistemas-a46cd.appspot.com/o/LogoRS.png?alt=media&token=0132c19f-8a46-4d45-8166-6905d743da2d";

// Estilos para el PDF
const styles = StyleSheet.create({
  page: {
    padding: 30,
    backgroundColor: "#FFFFFF",
    fontFamily: "Helvetica",
  },
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 20,
    borderBottom: 1,
    borderBottomColor: "#CCCCCC",
    paddingBottom: 10,
  },
  headerLeft: {
    flexDirection: "row",
    alignItems: "center",
  },
  headerRight: {
    alignItems: "center",
  },
  companyName: {
    fontSize: 14,
    fontWeight: "bold",
    color: "#333333",
    marginTop: 2,
  },
  logo: {
    width: 80,
    height: 40,
    marginRight: 10,
    objectFit: "contain",
  },
  qrCode: {
    width: 80,
    height: 80,
  },
  qrText: {
    fontSize: 8,
    color: "#666666",
    textAlign: "center",
    marginTop: 3,
  },
  verificationUrl: {
    fontSize: 7,
    color: "#1976d2",
    textAlign: "center",
    marginTop: 2,
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
    color: "#1976d2",
  },
  subtitle: {
    fontSize: 10,
    color: "#666666",
  },
  section: {
    marginBottom: 15,
  },
  sectionTitle: {
    fontSize: 14,
    marginBottom: 10,
    fontWeight: "bold",
    backgroundColor: "#f5f5f5",
    padding: 5,
    color: "#1976d2",
    borderLeftWidth: 4,
    borderLeftColor: "#1976d2",
    borderLeftStyle: "solid",
  },
  row: {
    flexDirection: "row",
    marginBottom: 8,
  },
  label: {
    width: "30%",
    fontWeight: "bold",
    fontSize: 10,
    color: "#666666",
  },
  value: {
    width: "70%",
    fontSize: 10,
    color: "#333333",
  },
  grid: {
    flexDirection: "row",
    flexWrap: "wrap",
    marginHorizontal: -5,
  },
  gridItem: {
    width: "50%",
    paddingHorizontal: 5,
    marginBottom: 5,
  },
  footer: {
    position: "absolute",
    bottom: 30,
    left: 30,
    right: 30,
    fontSize: 8,
    textAlign: "center",
    color: "#666666",
    borderTopWidth: 1,
    borderTopColor: "#EEEEEE",
    borderTopStyle: "solid",
    paddingTop: 10,
  },
  diagnosticoBox: {
    backgroundColor: "#f0f7ff",
    padding: 10,
    borderRadius: 4,
    marginBottom: 15,
    borderLeftWidth: 4,
    borderLeftColor: "#1976d2",
    borderLeftStyle: "solid",
  },
  diagnosticoTitle: {
    fontSize: 12,
    fontWeight: "bold",
    color: "#1976d2",
    marginBottom: 5,
  },
  comentario: {
    fontSize: 10,
    backgroundColor: "#f9f9f9",
    padding: 10,
    marginTop: 5,
    fontStyle: "italic",
  },
  itemContainer: {
    backgroundColor: "#f5f5f5",
    padding: 5,
    marginVertical: 2,
    borderRadius: 3,
  },
  itemCorrect: {
    backgroundColor: "#e8f5e9",
    borderLeftWidth: 3,
    borderLeftColor: "#4caf50",
    borderLeftStyle: "solid",
  },
  itemIncorrect: {
    backgroundColor: "#ffebee",
    borderLeftWidth: 3,
    borderLeftColor: "#f44336",
    borderLeftStyle: "solid",
  },
  summaryBox: {
    border: "1px solid #e0e0e0",
    borderRadius: 4,
    padding: 8,
    marginTop: 5,
    backgroundColor: "#f9f9f9",
  },
  bold: {
    fontWeight: "bold",
  },
  pageNumber: {
    position: "absolute",
    bottom: 20,
    right: 30,
    fontSize: 8,
    color: "#666666",
  },
  firmaContainer: {
    marginTop: 20,
    flexDirection: "row",
    justifyContent: "space-between",
  },
  firmaBox: {
    width: 150,
    height: 80,
    border: "1px solid #CCCCCC",
    marginBottom: 5,
  },
  firmaImage: {
    width: "100%",
    height: "100%",
    objectFit: "contain",
  },
  firmaLabel: {
    fontSize: 8,
    color: "#666666",
    textAlign: "center",
  },
  signatureColumn: {
    width: "45%",
    alignItems: "center",
  },
  watermark: {
    position: "absolute",
    top: "50%",
    left: "25%",
    right: "25%",
    opacity: 0.05,
    transform: "rotate(-45deg)",
    fontSize: 60,
    color: "#1976d2",
    fontWeight: "bold",
    textAlign: "center",
  },
  sello: {
    marginTop: 10,
    padding: 10,
    borderRadius: 4,
    border: "2px solid #1976d2",
    alignItems: "center",
    justifyContent: "center",
    width: 120,
    height: 120,
  },
  selloText: {
    fontSize: 10,
    fontWeight: "bold",
    color: "#1976d2",
    textAlign: "center",
  },
  selloTextSmall: {
    fontSize: 7,
    color: "#666666",
    textAlign: "center",
    marginTop: 2,
  },
  verificationBox: {
    backgroundColor: "#f0f7ff",
    padding: 10,
    borderRadius: 4,
    marginBottom: 15,
    marginTop: 20,
    alignItems: "center",
  },
  verificationTitle: {
    fontSize: 12,
    fontWeight: "bold",
    color: "#1976d2",
    marginBottom: 5,
    textAlign: "center",
  },
  verificationSubtitle: {
    fontSize: 9,
    color: "#666666",
    textAlign: "center",
    marginBottom: 5,
  },
});

// Componente del PDF
const InspectionPDFDocument = ({
  inspectionData,
  qrCodeUrl,
  verificationUrl,
  logoBase64,
}) => {
  // Función para formatear timestamps
  const formatDate = (timestamp) => {
    if (!timestamp) return "Fecha no disponible";
    try {
      if (timestamp.toDate) {
        const date = timestamp.toDate();
        return date.toLocaleString("es-ES", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
          hour: "2-digit",
          minute: "2-digit",
        });
      }
    } catch (error) {}
    return "Fecha no disponible";
  };

  // Calcular estadísticas para el diagnóstico
  const problemComponentsCount = inspectionData.EstadoGeneral
    ? Object.values(inspectionData.EstadoGeneral).filter(
        (status) => status !== "Correcto"
      ).length
    : 0;

  const totalComponents = inspectionData.EstadoGeneral
    ? Object.keys(inspectionData.EstadoGeneral).length
    : 0;
  const correctPercentage =
    totalComponents > 0
      ? Math.round(
          ((totalComponents - problemComponentsCount) / totalComponents) * 100
        )
      : 0;

  // Determinar el estado general del vehículo
  const getOverallStatus = () => {
    if (correctPercentage >= 80) return "Óptimo";
    if (correctPercentage >= 60) return "Funcional";
    if (correctPercentage >= 40) return "Requiere atención";
    return "Crítico";
  };

  const getFormattedDate = () => {
    const date = new Date();
    return date.toLocaleString("es-ES", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
  };

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        {/* Marca de agua */}
        <Text style={styles.watermark}>CERTIFICADO OFICIAL</Text>

        {/* Encabezado con logo y QR */}
        <View style={styles.header}>
          <View style={styles.headerLeft}>
            {logoBase64 ? (
              <Image src={logoBase64} style={styles.logo} />
            ) : (
              <View style={[styles.logo, { backgroundColor: "#f0f0f0" }]} />
            )}
            <View>
              <Text style={styles.companyName}>RadioSistemas.mx</Text>
              <Text style={styles.title}>Reporte de Inspección</Text>
              <Text style={styles.subtitle}>Folio: {inspectionData.id}</Text>
            </View>
          </View>
          <View style={styles.headerRight}>
            {qrCodeUrl && (
              <>
                <Image src={qrCodeUrl} style={styles.qrCode} />
                <Text style={styles.qrText}>Certificado Digital Original</Text>
                <Text style={styles.verificationUrl}>{verificationUrl}</Text>
              </>
            )}
          </View>
        </View>

        {/* Resumen del diagnóstico */}
        <View style={styles.diagnosticoBox}>
          <Text style={styles.diagnosticoTitle}>RESUMEN DE DIAGNÓSTICO</Text>
          <Text style={styles.value}>
            Unidad inspeccionada el {formatDate(inspectionData.Date)}.
            {problemComponentsCount > 0
              ? ` Se encontraron ${problemComponentsCount} componentes con problemas.`
              : " Todos los componentes funcionan correctamente."}
            {inspectionData.FusiblesReemplazados &&
            inspectionData.FusiblesReemplazados.length > 0
              ? ` Se realizaron ${inspectionData.FusiblesReemplazados.length} reemplazos de componentes.`
              : ""}
            {` Estado general: ${getOverallStatus()} (${correctPercentage}%).`}
          </Text>

          <View style={styles.summaryBox}>
            <View style={styles.row}>
              <Text style={styles.label}>Componentes en buen estado:</Text>
              <Text style={styles.value}>
                {totalComponents - problemComponentsCount} de {totalComponents}
              </Text>
            </View>
            {problemComponentsCount > 0 && (
              <View style={styles.row}>
                <Text style={styles.label}>Componentes con problemas:</Text>
                <Text style={[styles.value, styles.bold]}>
                  {problemComponentsCount}
                </Text>
              </View>
            )}
            {inspectionData.FusiblesReemplazados &&
              inspectionData.FusiblesReemplazados.length > 0 && (
                <View style={styles.row}>
                  <Text style={styles.label}>Componentes reemplazados:</Text>
                  <Text style={styles.value}>
                    {inspectionData.FusiblesReemplazados.length}
                  </Text>
                </View>
              )}
          </View>
        </View>

        {/* Información general */}
        <View style={styles.section}>
          <Text style={styles.sectionTitle}>Información General</Text>

          <View style={styles.row}>
            <Text style={styles.label}>ID de Inspección:</Text>
            <Text style={styles.value}>
              {inspectionData.id || "No disponible"}
            </Text>
          </View>

          <View style={styles.row}>
            <Text style={styles.label}>Unidad:</Text>
            <Text style={styles.value}>
              {inspectionData.Unidad || "No disponible"}
            </Text>
          </View>

          <View style={styles.row}>
            <Text style={styles.label}>Zona:</Text>
            <Text style={styles.value}>
              {inspectionData.Zona || "No especificada"}
            </Text>
          </View>

          <View style={styles.row}>
            <Text style={styles.label}>Fecha y hora:</Text>
            <Text style={styles.value}>{formatDate(inspectionData.Date)}</Text>
          </View>

          <View style={styles.row}>
            <Text style={styles.label}>Técnico:</Text>
            <Text style={styles.value}>
              {inspectionData.Usuario || "No disponible"}
            </Text>
          </View>

          <View style={styles.row}>
            <Text style={styles.label}>Oficial:</Text>
            <Text style={styles.value}>
              {inspectionData.Nombre || "No disponible"}
            </Text>
          </View>

          <View style={styles.row}>
            <Text style={styles.label}>Estado:</Text>
            <Text style={styles.value}>
              {inspectionData.Status || "No disponible"}
            </Text>
          </View>

          {inspectionData.Comentario && (
            <View style={styles.row}>
              <Text style={styles.label}>Comentarios:</Text>
              <Text style={styles.value}>{inspectionData.Comentario}</Text>
            </View>
          )}
        </View>

        {/* Estado de componentes */}
        {inspectionData.EstadoGeneral &&
          Object.keys(inspectionData.EstadoGeneral).length > 0 && (
            <View style={styles.section}>
              <Text style={styles.sectionTitle}>Estado de Componentes</Text>

              <View style={styles.grid}>
                {Object.entries(inspectionData.EstadoGeneral).map(
                  ([component, status]) => (
                    <View key={component} style={styles.gridItem}>
                      <View
                        style={[
                          styles.itemContainer,
                          status === "Correcto"
                            ? styles.itemCorrect
                            : styles.itemIncorrect,
                        ]}
                      >
                        <Text style={styles.value}>
                          <Text style={styles.bold}>{component}: </Text>
                          {status}
                        </Text>
                      </View>
                    </View>
                  )
                )}
              </View>
            </View>
          )}

        {/* Componentes reemplazados */}
        {inspectionData.FusiblesReemplazados &&
          inspectionData.FusiblesReemplazados.length > 0 && (
            <View style={styles.section}>
              <Text style={styles.sectionTitle}>Componentes Reemplazados</Text>

              {inspectionData.FusiblesReemplazados.map((item, index) => (
                <View key={index} style={styles.itemContainer}>
                  <Text style={styles.value}>{item}</Text>
                </View>
              ))}
            </View>
          )}

        {/* Verificación */}
        <View style={styles.verificationBox}>
          <Text style={styles.verificationTitle}>
            VERIFICACIÓN DE AUTENTICIDAD
          </Text>
          <Text style={styles.verificationSubtitle}>
            Escanee el código QR o visite el enlace para validar la inspección
            en línea
          </Text>
          <Text
            style={[styles.verificationUrl, { fontSize: 9, marginBottom: 5 }]}
          >
            {verificationUrl}
          </Text>
        </View>

        {/* Firma y sello */}
        <View style={styles.firmaContainer}>
          <View style={styles.signatureColumn}>
            {inspectionData.fotos?.["Firma"] && (
              <>
                <Text style={styles.firmaLabel}>Firma del técnico</Text>
                <View style={styles.firmaBox}>
                  <Image
                    src={inspectionData.fotos["Firma"]}
                    style={styles.firmaImage}
                  />
                </View>
                <Text style={styles.firmaLabel}>
                  {inspectionData.Usuario || "Técnico"}
                </Text>
              </>
            )}
          </View>

          <View style={styles.signatureColumn}>
            {inspectionData.fotos?.["Oficial"] && (
              <>
                <Text style={styles.firmaLabel}>Oficial responsable</Text>
                <View style={styles.firmaBox}>
                  <Image
                    src={inspectionData.fotos["Oficial"]}
                    style={styles.firmaImage}
                  />
                </View>
                <Text style={styles.firmaLabel}>
                  {inspectionData.Nombre || "Oficial"}
                </Text>
              </>
            )}
          </View>
        </View>

        {/* Sello de verificación */}
        <View style={{ alignItems: "center", marginTop: 20 }}>
          <View style={styles.sello}>
            <Text style={styles.selloText}>VERIFICADO</Text>
            <Text style={styles.selloTextSmall}>{getFormattedDate()}</Text>
            <Text style={styles.selloTextSmall}>RadioSistemas.mx</Text>
          </View>
        </View>

        {/* Pie de página */}
        <Text style={styles.footer}>
          Documento oficial generado el {new Date().toLocaleString("es-ES")} •
          RadioSistemas.mx • Este documento es válido como certificado de
          inspección técnica.
        </Text>

        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Page>
    </Document>
  );
};

// Componente principal que integra el generador de PDF
const InspectionPdfGenerator = ({ inspectionData }) => {
  const [qrCodeUrl, setQrCodeUrl] = useState("");
  const [logoBase64, setLogoBase64] = useState(null);
  const [generatingQR, setGeneratingQR] = useState(false);
  const [loadingLogo, setLoadingLogo] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [verificationUrl, setVerificationUrl] = useState("");

  useEffect(() => {
    if (inspectionData && inspectionData.id) {
      generateQRCode();
      convertLogoToBase64();
    }
  }, [inspectionData]);

  // Función para generar código QR
  const generateQRCode = async () => {
    setGeneratingQR(true);
    try {
      // Crear una URL que apunte a la inspección
      const url = `www.radiosistemas.mx/resumen/${inspectionData.id}`;
      setVerificationUrl(url);

      // Generar código QR como data URL
      const qrCodeDataUrl = await QRCode.toDataURL(url, {
        errorCorrectionLevel: "H",
        margin: 1,
        width: 200,
        color: {
          dark: "#000000",
          light: "#ffffff",
        },
      });

      setQrCodeUrl(qrCodeDataUrl);
    } catch (error) {
      console.error("Error al generar código QR:", error);
      setSnackbarMessage("Error al generar código QR");
      setSnackbarSeverity("error");
      setShowSnackbar(true);
    } finally {
      setGeneratingQR(false);
    }
  };

  // Función para convertir la imagen del logo a base64
  const convertLogoToBase64 = async () => {
    setLoadingLogo(true);
    try {
      const response = await fetch(COMPANY_LOGO_URL);
      const blob = await response.blob();

      const reader = new FileReader();
      reader.onloadend = () => {
        setLogoBase64(reader.result);
        setLoadingLogo(false);
      };
      reader.onerror = (error) => {
        console.error("Error al convertir logo a base64:", error);
        setLoadingLogo(false);
        setSnackbarMessage("Error al cargar el logo");
        setSnackbarSeverity("error");
        setShowSnackbar(true);
      };
      reader.readAsDataURL(blob);
    } catch (error) {
      console.error("Error al obtener el logo:", error);
      setLoadingLogo(false);
      setSnackbarMessage("Error al obtener el logo");
      setSnackbarSeverity("error");
      setShowSnackbar(true);
    }
  };

  // Si no hay datos de inspección, no mostramos nada
  if (!inspectionData || !inspectionData.id) {
    return null;
  }

  // Función para compartir el PDF generado (por implementar según necesidades)
  const handleShare = async () => {
    setSnackbarMessage("Función de compartir en desarrollo");
    setSnackbarSeverity("info");
    setShowSnackbar(true);
  };

  // Nombre del archivo PDF
  const fileName = `RS_Certificado_${
    inspectionData.Unidad || inspectionData.id
  }_${new Date().toISOString().slice(0, 10)}.pdf`;

  return (
    <>
      <Card
        elevation={3}
        sx={{
          borderRadius: 2,
          overflow: "hidden",
          mt: 4,
          backgroundImage: "linear-gradient(to right, #f8f9fa, #e8f4fc)",
        }}
      >
        <Paper
          elevation={0}
          sx={{
            bgcolor: "#1976d2",
            color: "white",
            p: 2,
            display: "flex",
            alignItems: "center",
          }}
        >
          <VerifiedUserIcon sx={{ mr: 1 }} />
          <Typography variant="h6" component="div">
            Certificado Digital Oficial
          </Typography>
        </Paper>

        <Box sx={{ p: 2 }}>
          <Typography
            variant="body1"
            sx={{ mb: 1, fontWeight: "medium", color: "#1976d2" }}
          >
            Genere un certificado oficial de inspección técnica
          </Typography>

          <Divider sx={{ my: 1 }} />

          <Typography variant="body2" color="text.secondary" paragraph>
            Este certificado incluye todos los detalles de la inspección, estado
            de componentes, firmas digitales y un código QR para validación en
            línea. Válido como documento oficial de RadioSistemas.
          </Typography>

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box>
              {verificationUrl && (
                <Typography
                  variant="caption"
                  color="text.secondary"
                  sx={{ display: "block", mb: 1 }}
                >
                  Verificar en: <strong>{verificationUrl}</strong>
                </Typography>
              )}
            </Box>

            <Box sx={{ display: "flex", gap: 2, flexWrap: "wrap" }}>
              {generatingQR || loadingLogo ? (
                <Button
                  variant="contained"
                  color="primary"
                  disabled
                  startIcon={<CircularProgress size={20} color="inherit" />}
                >
                  Preparando certificado...
                </Button>
              ) : (
                <PDFDownloadLink
                  document={
                    <InspectionPDFDocument
                      inspectionData={inspectionData}
                      qrCodeUrl={qrCodeUrl}
                      verificationUrl={verificationUrl}
                      logoBase64={logoBase64}
                    />
                  }
                  fileName={fileName}
                  style={{ textDecoration: "none" }}
                >
                  {({ loading, error }) => (
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={loading || error}
                      startIcon={
                        loading ? (
                          <CircularProgress size={20} color="inherit" />
                        ) : (
                          <DownloadIcon />
                        )
                      }
                    >
                      {loading ? "Generando..." : "Descargar Certificado"}
                    </Button>
                  )}
                </PDFDownloadLink>
              )}

              <Button
                variant="outlined"
                color="primary"
                startIcon={<PrintIcon />}
                onClick={() => {
                  setSnackbarMessage(
                    "Descargue el PDF para imprimir el certificado"
                  );
                  setSnackbarSeverity("info");
                  setShowSnackbar(true);
                }}
              >
                Imprimir
              </Button>

              <Tooltip title="Compartir certificado">
                <IconButton color="primary" onClick={handleShare}>
                  <ShareIcon />
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
        </Box>
      </Card>

      <Snackbar
        open={showSnackbar}
        autoHideDuration={4000}
        onClose={() => setShowSnackbar(false)}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={() => setShowSnackbar(false)}
          severity={snackbarSeverity}
          sx={{ width: "100%" }}
          variant="filled"
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default InspectionPdfGenerator;
