import React, { useState, useEffect } from "react";
import {
    collection,
    doc,
    addDoc,
    writeBatch,
    onSnapshot,
    getDocs,
    query,
    where,
    orderBy
} from "firebase/firestore";
import { db } from "../../firebase";
import {
    PlusCircle,
    Package,
    Link as LinkIcon,
    Search,
    Filter,
    RefreshCcw,
    AlertTriangle,
    ChevronDown,
    ChevronUp,
    Save,
    X,
    Check
} from "lucide-react";

const ProjectComponents = ({ projectId, unitId = null, onCancel = () => { } }) => {
    // Estados para formulario de componente
    const [componentName, setComponentName] = useState("");
    const [componentQuantity, setComponentQuantity] = useState(1);
    const [componentLink, setComponentLink] = useState("");
    const [selectedInventoryItem, setSelectedInventoryItem] = useState(null);
    const [applyToAllUnits, setApplyToAllUnits] = useState(!unitId);
    const [saving, setSaving] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);

    // Estados para inventario y filtrado
    const [inventoryItems, setInventoryItems] = useState([]);
    const [filteredItems, setFilteredItems] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [categoryFilter, setCategoryFilter] = useState("");
    const [brandFilter, setBrandFilter] = useState("");
    const [categories, setCategories] = useState([]);
    const [brands, setBrands] = useState([]);
    const [loading, setLoading] = useState(true);
    const [showInventory, setShowInventory] = useState(false);

    // Estados para unidades
    const [units, setUnits] = useState([]);
    const [selectedUnits, setSelectedUnits] = useState([]);

    // Cargar categorías y marcas
    useEffect(() => {
        const fetchCategoriesAndBrands = async () => {
            try {
                // Cargar categorías
                const categoriesSnapshot = await getDocs(collection(db, "categories"));
                const categoriesData = categoriesSnapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data()
                }));
                setCategories(categoriesData);

                // Cargar marcas
                const brandsSnapshot = await getDocs(collection(db, "brands"));
                const brandsData = brandsSnapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data()
                }));
                setBrands(brandsData);
            } catch (error) {
                console.error("Error al cargar categorías y marcas:", error);
                setError("Error al cargar filtros de inventario");
            }
        };

        fetchCategoriesAndBrands();
    }, []);

    // Cargar inventario
    useEffect(() => {
        const fetchInventory = () => {
            setLoading(true);
            const inventoryRef = collection(db, "inventory");
            const q = query(inventoryRef, orderBy("name"));

            const unsubscribe = onSnapshot(q, (snapshot) => {
                const items = snapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data()
                }));
                setInventoryItems(items);
                setFilteredItems(items);
                setLoading(false);
            });

            return unsubscribe;
        };

        return fetchInventory();
    }, []);

    // Cargar unidades del proyecto si es necesario
    useEffect(() => {
        if (!projectId || unitId) return;

        const fetchUnits = () => {
            const unitsRef = collection(db, `projects/${projectId}/units`);
            const q = query(unitsRef, orderBy("number", "asc"));

            const unsubscribe = onSnapshot(q, (snapshot) => {
                const unitsData = snapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data()
                }));
                setUnits(unitsData);
            });

            return unsubscribe;
        };

        return fetchUnits();
    }, [projectId, unitId]);

    // Filtrar inventario basado en búsqueda y filtros
    useEffect(() => {
        const applyFilters = () => {
            let results = [...inventoryItems];

            // Aplicar búsqueda
            if (searchTerm) {
                const searchLower = searchTerm.toLowerCase();
                results = results.filter(item =>
                    (item.name && item.name.toLowerCase().includes(searchLower)) ||
                    (item.description && item.description.toLowerCase().includes(searchLower)) ||
                    (item.brand && item.brand.toLowerCase().includes(searchLower)) ||
                    (item.model && item.model.toLowerCase().includes(searchLower))
                );
            }

            // Aplicar filtro de categoría
            if (categoryFilter) {
                results = results.filter(item => item.category === categoryFilter);
            }

            // Aplicar filtro de marca
            if (brandFilter) {
                results = results.filter(item => item.brand === brandFilter);
            }

            setFilteredItems(results);
        };

        applyFilters();
    }, [inventoryItems, searchTerm, categoryFilter, brandFilter]);

    // Funciones auxiliares
    const resetForm = () => {
        setComponentName("");
        setComponentQuantity(1);
        setComponentLink("");
        setSelectedInventoryItem(null);
        setError(null);
        setSuccess(null);
    };

    const handleSelectInventoryItem = (item) => {
        setSelectedInventoryItem(item);
        setComponentName(item.name || "");
        setComponentLink(item.purchaseLink || "");
        setShowInventory(false);
    };

    const handleToggleUnit = (unitId) => {
        setSelectedUnits(prev => {
            if (prev.includes(unitId)) {
                return prev.filter(id => id !== unitId);
            } else {
                return [...prev, unitId];
            }
        });
    };

    const handleSelectAllUnits = () => {
        if (selectedUnits.length === units.length) {
            setSelectedUnits([]);
        } else {
            setSelectedUnits(units.map(unit => unit.id));
        }
    };

    // Guarda el componente
    const handleSaveComponent = async () => {
        if (!componentName.trim()) {
            setError("Por favor ingrese un nombre para el componente");
            return;
        }

        if (!projectId) {
            setError("No se ha especificado un proyecto");
            return;
        }

        // Si se requieren unidades seleccionadas pero no hay ninguna
        if (!applyToAllUnits && !unitId && selectedUnits.length === 0) {
            setError("Por favor seleccione al menos una unidad");
            return;
        }

        setSaving(true);
        setError(null);
        setSuccess(null);

        try {
            // Crear componente con batch para eficiencia
            const batch = writeBatch(db);
            let unitIds = [];

            // Determinar a qué unidades aplicar el componente
            if (unitId) {
                // Solo a una unidad específica
                unitIds = [unitId];
            } else if (applyToAllUnits) {
                // A todas las unidades
                unitIds = units.map(unit => unit.id);
            } else {
                // A las unidades seleccionadas
                unitIds = selectedUnits;
            }

            // Datos del componente
            const componentData = {
                name: componentName.trim(),
                quantity: componentQuantity,
                link: componentLink.trim(),
                inventoryItemId: selectedInventoryItem?.id || null,
                installed: false,
                createdAt: new Date(),
                updatedAt: new Date()
            };

            // Agregar componente a cada unidad
            for (const uid of unitIds) {
                // Crear nuevo documento en la subcolección components
                const componentRef = doc(collection(db, `projects/${projectId}/units/${uid}/components`));
                batch.set(componentRef, componentData);

                // Actualizar contador de componentes totales de la unidad
                const unitRef = doc(db, `projects/${projectId}/units/${uid}`);
                batch.update(unitRef, {
                    totalComponents: unitId ? selectedInventoryItem?.quantity || componentQuantity : componentQuantity,
                    updatedAt: new Date()
                });
            }

            // Ejecutar batch
            await batch.commit();

            // Mostrar éxito
            setSuccess(`Componente añadido exitosamente a ${unitIds.length} unidad(es)`);

            // Resetear formulario después de 2 segundos
            setTimeout(() => {
                resetForm();
                if (onCancel && typeof onCancel === 'function') {
                    onCancel();
                }
            }, 2000);

        } catch (error) {
            console.error("Error al añadir componente:", error);
            setError("Ocurrió un error al añadir el componente. Intente nuevamente.");
        } finally {
            setSaving(false);
        }
    };

    // Obtener nombre de categoría por ID
    const getCategoryName = (categoryId) => {
        const category = categories.find(cat => cat.id === categoryId);
        return category ? category.name : "No especificada";
    };

    // Obtener nombre de marca por ID
    const getBrandName = (brandId) => {
        const brand = brands.find(b => b.id === brandId);
        return brand ? brand.name : "No especificada";
    };

    // Renderizar selector de inventario - Versión móvil optimizada
    const renderInventorySelector = () => (
        <div className="bg-white border border-gray-300 rounded-lg shadow-md mt-4 p-3">
            <div className="flex justify-between items-center mb-3">
                <h3 className="text-base font-medium text-gray-900">Seleccionar del Inventario</h3>
                <button
                    onClick={() => setShowInventory(false)}
                    className="text-gray-500 hover:text-gray-700 p-1"
                    aria-label="Cerrar"
                >
                    <X className="h-5 w-5" />
                </button>
            </div>

            {/* Filtros en columna para móvil */}
            <div className="space-y-2 mb-3">
                <div className="relative">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                        <Search className="h-4 w-4 text-gray-400" />
                    </div>
                    <input
                        type="text"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        placeholder="Buscar en inventario"
                        className="pl-10 px-3 py-2 w-full border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                    />
                </div>

                <select
                    value={categoryFilter}
                    onChange={(e) => setCategoryFilter(e.target.value)}
                    className="px-3 py-2 w-full border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                >
                    <option value="">Todas las categorías</option>
                    {categories.map((category) => (
                        <option key={category.id} value={category.id}>
                            {category.name}
                        </option>
                    ))}
                </select>

                <select
                    value={brandFilter}
                    onChange={(e) => setBrandFilter(e.target.value)}
                    className="px-3 py-2 w-full border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                >
                    <option value="">Todas las marcas</option>
                    {brands.map((brand) => (
                        <option key={brand.id} value={brand.id}>
                            {brand.name}
                        </option>
                    ))}
                </select>
            </div>

            {/* Lista de elementos con diseño móvil */}
            {loading ? (
                <div className="flex justify-center items-center py-6">
                    <RefreshCcw className="h-6 w-6 text-blue-500 animate-spin" />
                    <span className="ml-2 text-gray-600">Cargando...</span>
                </div>
            ) : filteredItems.length > 0 ? (
                <div className="border border-gray-200 rounded-md overflow-hidden">
                    <div className="bg-gray-50 p-2 border-b border-gray-200 text-xs font-medium text-gray-700 hidden sm:grid sm:grid-cols-3">
                        <div>Nombre</div>
                        <div>Descripción</div>
                        <div>Categoría/Marca</div>
                    </div>
                    <div className="divide-y divide-gray-200 max-h-60 overflow-y-auto">
                        {filteredItems.map((item) => (
                            <div
                                key={item.id}
                                className="p-3 hover:bg-blue-50 cursor-pointer"
                                onClick={() => handleSelectInventoryItem(item)}
                            >
                                {/* Versión móvil - Vista de tarjeta */}
                                <div className="sm:hidden">
                                    <div className="font-medium text-gray-900 mb-1">{item.name}</div>
                                    {item.description && (
                                        <div className="text-xs text-gray-500 mb-1 line-clamp-2">{item.description}</div>
                                    )}
                                    <div className="text-xs text-gray-500 flex justify-between">
                                        <span>{getCategoryName(item.category)}</span>
                                        <span>{getBrandName(item.brand)}</span>
                                    </div>
                                </div>
                                {/* Versión desktop - Grid */}
                                <div className="hidden sm:grid sm:grid-cols-3">
                                    <div className="font-medium text-gray-900">{item.name}</div>
                                    <div className="text-gray-500 truncate">{item.description}</div>
                                    <div className="text-gray-500">
                                        {getCategoryName(item.category)} / {getBrandName(item.brand)}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            ) : (
                <div className="flex flex-col items-center justify-center py-6 text-gray-500">
                    <AlertTriangle className="h-6 w-6 text-yellow-500 mb-2" />
                    <p className="text-sm text-center">No se encontraron elementos</p>
                    {(searchTerm || categoryFilter || brandFilter) && (
                        <button
                            onClick={() => {
                                setSearchTerm("");
                                setCategoryFilter("");
                                setBrandFilter("");
                            }}
                            className="mt-2 text-sm text-blue-500 hover:text-blue-700"
                        >
                            Limpiar filtros
                        </button>
                    )}
                </div>
            )}
        </div>
    );

    // Renderizar selector de unidades optimizado para móvil
    const renderUnitSelector = () => (
        <div className="border border-gray-200 rounded-md overflow-hidden mt-4">
            <div className="bg-gray-50 px-3 py-2 flex flex-col sm:flex-row justify-between items-start sm:items-center border-b border-gray-200">
                <h3 className="font-medium text-gray-700 mb-2 sm:mb-0">Seleccionar Unidades</h3>
                <div className="flex items-center">
                    <input
                        type="checkbox"
                        id="selectAll"
                        checked={selectedUnits.length === units.length}
                        onChange={handleSelectAllUnits}
                        className="mr-2"
                    />
                    <label htmlFor="selectAll" className="text-sm cursor-pointer">
                        {selectedUnits.length === units.length ? "Deseleccionar todo" : "Seleccionar todo"}
                    </label>
                </div>
            </div>
            <div className="max-h-48 overflow-y-auto">
                <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-2 p-3">
                    {units.map((unit) => (
                        <div
                            key={unit.id}
                            className={`px-3 py-2 border rounded-md cursor-pointer flex items-center justify-between
                                ${selectedUnits.includes(unit.id)
                                    ? "border-blue-500 bg-blue-50 text-blue-700"
                                    : "border-gray-300 text-gray-700 hover:bg-gray-50"
                                }`}
                            onClick={() => handleToggleUnit(unit.id)}
                        >
                            <span className="text-sm">Unidad {unit.number}</span>
                            <input
                                type="checkbox"
                                checked={selectedUnits.includes(unit.id)}
                                onChange={() => { }}
                                className="ml-1"
                            />
                        </div>
                    ))}
                </div>
            </div>
            <div className="bg-gray-50 px-3 py-2 border-t border-gray-200 text-xs text-gray-500">
                {selectedUnits.length} unidades seleccionadas
            </div>
        </div>
    );

    return (
        <div className="bg-white rounded-lg shadow-md p-4 sm:p-6 mb-4">
            <div className="flex justify-between items-start mb-4">
                <h2 className="text-lg sm:text-xl font-bold text-gray-800 flex items-center">
                    <Package className="h-5 w-5 text-blue-500 mr-2" />
                    {unitId
                        ? "Agregar Componente"
                        : "Agregar Componentes"}
                </h2>
                <button
                    onClick={onCancel}
                    className="text-gray-400 hover:text-gray-500 p-1"
                    aria-label="Cerrar"
                >
                    <X className="h-5 w-5" />
                </button>
            </div>

            {/* Mensajes de alerta y éxito */}
            {error && (
                <div className="mb-4 bg-red-50 border border-red-200 text-red-700 px-3 py-2 rounded-md flex items-start text-sm">
                    <AlertTriangle className="h-4 w-4 text-red-400 mr-2 mt-0.5 flex-shrink-0" />
                    <span>{error}</span>
                </div>
            )}

            {success && (
                <div className="mb-4 bg-green-50 border border-green-200 text-green-700 px-3 py-2 rounded-md flex items-start text-sm">
                    <Check className="h-4 w-4 text-green-500 mr-2 mt-0.5 flex-shrink-0" />
                    <span>{success}</span>
                </div>
            )}

            {/* Información del componente seleccionado - Versión móvil responsive */}
            {selectedInventoryItem && (
                <div className="mb-4 bg-blue-50 border border-blue-200 rounded-md p-3">
                    <div className="flex justify-between">
                        <h3 className="font-medium text-sm text-gray-900">Componente seleccionado</h3>
                        <button
                            onClick={() => setSelectedInventoryItem(null)}
                            className="text-gray-400 hover:text-gray-500"
                            aria-label="Quitar selección"
                        >
                            <X className="h-4 w-4" />
                        </button>
                    </div>
                    <div className="mt-2 grid grid-cols-1 sm:grid-cols-2 gap-1 text-xs sm:text-sm">
                        <div>
                            <span className="text-gray-500">Nombre:</span> {selectedInventoryItem.name}
                        </div>
                        {selectedInventoryItem.brand && (
                            <div>
                                <span className="text-gray-500">Marca:</span> {getBrandName(selectedInventoryItem.brand)}
                            </div>
                        )}
                        {selectedInventoryItem.model && (
                            <div>
                                <span className="text-gray-500">Modelo:</span> {selectedInventoryItem.model}
                            </div>
                        )}
                        {selectedInventoryItem.stock !== undefined && (
                            <div>
                                <span className="text-gray-500">Stock:</span> {selectedInventoryItem.stock}
                            </div>
                        )}
                    </div>
                </div>
            )}

            <div className="space-y-3">
                {/* Campos de formulario - Responsivos */}
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-3">
                    <div>
                        <label className="block text-sm font-medium text-gray-700 mb-1">
                            Nombre del Componente*
                        </label>
                        <input
                            type="text"
                            value={componentName}
                            onChange={(e) => setComponentName(e.target.value)}
                            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 text-sm"
                            placeholder="Ej: DVR, GPS, Sirena"
                            required
                        />
                    </div>

                    <div>
                        <label className="block text-sm font-medium text-gray-700 mb-1">
                            Cantidad
                        </label>
                        <input
                            type="number"
                            value={componentQuantity}
                            onChange={(e) => setComponentQuantity(parseInt(e.target.value) || 1)}
                            min="1"
                            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 text-sm"
                        />
                    </div>
                </div>

                {/* Enlace de compra */}
                <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                        Enlace de compra (opcional)
                    </label>
                    <div className="flex">
                        <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500">
                            <LinkIcon className="h-4 w-4" />
                        </span>
                        <input
                            type="url"
                            value={componentLink}
                            onChange={(e) => setComponentLink(e.target.value)}
                            className="flex-1 min-w-0 block w-full px-3 py-2 rounded-none rounded-r-md border border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 text-sm"
                            placeholder="https://ejemplo.com/producto"
                        />
                    </div>
                </div>

                {/* Botón de búsqueda en inventario */}
                <div>
                    <button
                        type="button"
                        onClick={() => setShowInventory(!showInventory)}
                        className="w-full sm:w-auto px-3 py-2 border border-gray-300 rounded-md text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 flex items-center justify-center"
                    >
                        <Search className="h-4 w-4 mr-2" />
                        {showInventory ? "Ocultar inventario" : "Buscar en inventario"}
                    </button>
                </div>

                {showInventory && renderInventorySelector()}

                {/* Opción para aplicar a todas o unidad específica */}
                {!unitId && (
                    <div className="border-t border-gray-200 pt-3">
                        <div className="mb-3">
                            <label className="block text-sm font-medium text-gray-700 mb-2">
                                Aplicar a:
                            </label>
                            <div className="flex flex-col sm:flex-row sm:items-center sm:space-x-4 space-y-2 sm:space-y-0">
                                <label className="inline-flex items-center">
                                    <input
                                        type="radio"
                                        className="form-radio h-4 w-4 text-blue-600"
                                        checked={applyToAllUnits}
                                        onChange={() => setApplyToAllUnits(true)}
                                    />
                                    <span className="ml-2 text-sm text-gray-700">Todas las unidades ({units.length})</span>
                                </label>
                                <label className="inline-flex items-center">
                                    <input
                                        type="radio"
                                        className="form-radio h-4 w-4 text-blue-600"
                                        checked={!applyToAllUnits}
                                        onChange={() => setApplyToAllUnits(false)}
                                    />
                                    <span className="ml-2 text-sm text-gray-700">Unidades específicas</span>
                                </label>
                            </div>
                        </div>

                        {!applyToAllUnits && renderUnitSelector()}
                    </div>
                )}

                {/* Botones de acción */}
                <div className="flex flex-col sm:flex-row justify-end space-y-2 sm:space-y-0 sm:space-x-3 pt-3 border-t border-gray-200">
                    <button
                        type="button"
                        onClick={onCancel}
                        className="px-4 py-2 border border-gray-300 rounded-md text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 order-2 sm:order-1"
                        disabled={saving}
                    >
                        Cancelar
                    </button>
                    <button
                        type="button"
                        onClick={handleSaveComponent}
                        disabled={saving}
                        className="px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50 flex items-center justify-center order-1 sm:order-2"
                    >
                        {saving ? (
                            <>
                                <RefreshCcw className="inline-block h-4 w-4 mr-2 animate-spin" />
                                Guardando...
                            </>
                        ) : (
                            <>
                                <Save className="h-4 w-4 mr-2" />
                                Guardar Componente
                            </>
                        )}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ProjectComponents;