import React, { useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import ElectricBoltIcon from "@mui/icons-material/ElectricBolt";
import SettingsIcon from "@mui/icons-material/Settings";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import RadioIcon from "@mui/icons-material/Radio";
import DvrIcon from "@mui/icons-material/Dvr";
import MicIcon from "@mui/icons-material/Mic";
import CableIcon from "@mui/icons-material/Cable";
import AddIcon from "@mui/icons-material/Add";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import CommentIcon from "@mui/icons-material/Comment";
import AssessmentIcon from "@mui/icons-material/Assessment";
import BuildIcon from "@mui/icons-material/Build";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../../firebase";

const Step3EstadoComentarios = ({
  comentario,
  setComentario,
  status,
  setStatus,
  handleNext,
  handleBack,
  generalState,
  setGeneralState,
  fusibles,
  setFusibles,
  inspectionId,
  isEditing = false,
}) => {
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("all");

  const handleCheckboxChange = (field, value) => {
    setGeneralState((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleFusibleChange = (value) => {
    if (!fusibles.includes(value)) {
      setFusibles((prev) => [...prev, value]);
    }
  };

  const handleRemoveFusible = (index) => {
    setFusibles((prev) => prev.filter((_, i) => i !== index));
  };

  const handleNextStep = async () => {
    if (!inspectionId) {
      handleNext();
      return;
    }

    setLoading(true);
    try {
      await updateDoc(doc(db, "inspecciones", inspectionId), {
        Comentario: comentario,
        Status: status,
        EstadoGeneral: generalState,
        FusiblesReemplazados: fusibles,
      });
      handleNext();
    } catch (error) {
      console.error("Error al actualizar la inspección:", error);
      // Aquí se podría mostrar un mensaje de error
    } finally {
      setLoading(false);
    }
  };

  const categories = [
    { id: "all", name: "Todos" },
    { id: "electrical", name: "Eléctricos" },
    { id: "components", name: "Componentes" },
    { id: "cameras", name: "Cámaras" },
    { id: "cables", name: "Cables" },
  ];

  const replacements = [
    {
      label: "Fusible 10 AMP",
      icon: <ElectricBoltIcon />,
      category: "electrical",
    },
    {
      label: "Fusible 30 AMP",
      icon: <ElectricBoltIcon />,
      category: "electrical",
    },
    { label: "Antena", icon: <SettingsIcon />, category: "components" },
    { label: "Diafragma", icon: <SettingsIcon />, category: "components" },
    {
      label: "Control Torreta",
      icon: <SettingsIcon />,
      category: "components",
    },
    {
      label: "Porta Fusibles",
      icon: <ElectricBoltIcon />,
      category: "electrical",
    },
    { label: "Modulo Torreta", icon: <SettingsIcon />, category: "components" },
    { label: "Control Sirena", icon: <SettingsIcon />, category: "components" },
    {
      label: "Reparacion Control Sirena",
      icon: <SettingsIcon />,
      category: "components",
    },
    { label: "Cerebro Sirena", icon: <SettingsIcon />, category: "components" },
    {
      label: "(RJ45) Acoplador Sirena",
      icon: <SettingsIcon />,
      category: "components",
    },
    { label: "Microfono Radio", icon: <MicIcon />, category: "components" },
    { label: "Radio", icon: <RadioIcon />, category: "components" },
    { label: "DVR", icon: <DvrIcon />, category: "components" },
    { label: "Camara Frontal", icon: <CameraAltIcon />, category: "cameras" },
    { label: "Camara Interior", icon: <CameraAltIcon />, category: "cameras" },
    { label: "Camara Trasera", icon: <CameraAltIcon />, category: "cameras" },
    { label: "Cableado", icon: <CableIcon />, category: "cables" },
    { label: "Conector RJ45", icon: <CableIcon />, category: "cables" },
  ];

  const filteredReplacements = replacements.filter((item) => {
    const matchesSearch = item.label
      .toLowerCase()
      .includes(searchTerm.toLowerCase());
    const matchesCategory =
      selectedCategory === "all" || item.category === selectedCategory;
    return matchesSearch && matchesCategory;
  });

  const isReplacementSelected = (label) => {
    return fusibles.includes(label);
  };

  const handleReplacementClick = (label) => {
    if (!isReplacementSelected(label)) {
      setFusibles((prev) => [...prev, label]);
    }
  };

  return (
    <div className="w-full">
      {/* Sección de Comentarios y Estado */}
      <div className="mb-8">
        <h2 className="text-lg font-semibold text-gray-800 flex items-center mb-4">
          <CommentIcon className="mr-2 text-indigo-600" />
          Comentarios y Estado
        </h2>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          <div className="md:col-span-2">
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Comentario
            </label>
            <textarea
              value={comentario}
              onChange={(e) => setComentario(e.target.value)}
              className="w-full p-3 border border-gray-300 rounded-md focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 min-h-[120px]"
              placeholder="Describe el estado y las observaciones de la unidad..."
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Estado
            </label>
            <select
              value={status}
              onChange={(e) => setStatus(e.target.value)}
              className="w-full p-3 border border-gray-300 rounded-md focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 bg-white"
            >
              <option value="Correcto">Correcto</option>
              <option value="Incompleto">Incompleto</option>
              <option value="En Progreso">En Progreso</option>
            </select>

            <div className="mt-4 p-3 bg-gray-50 rounded-md border border-gray-200">
              <div className="flex items-center mb-2">
                <AssessmentIcon
                  className="text-indigo-600 mr-2"
                  fontSize="small"
                />
                <span className="text-sm font-medium text-gray-700">
                  Estado actual
                </span>
              </div>
              <div
                className={`py-1 px-3 rounded-full text-sm font-medium inline-flex items-center ${
                  status === "Correcto"
                    ? "bg-green-100 text-green-800"
                    : status === "Incompleto"
                    ? "bg-red-100 text-red-800"
                    : "bg-yellow-100 text-yellow-800"
                }`}
              >
                {status === "Correcto" ? (
                  <CheckCircleIcon fontSize="small" className="mr-1" />
                ) : status === "Incompleto" ? (
                  <CancelIcon fontSize="small" className="mr-1" />
                ) : (
                  <AssessmentIcon fontSize="small" className="mr-1" />
                )}
                {status}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Sección de Estado General */}
      <div className="mb-8">
        <h2 className="text-lg font-semibold text-gray-800 flex items-center mb-4">
          <AssessmentIcon className="mr-2 text-indigo-600" />
          Estado General de Componentes
        </h2>

        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-3">
          {[
            { label: "Bocina", field: "Bocina", icon: <MicIcon /> },
            { label: "Radio", field: "Radio", icon: <RadioIcon /> },
            { label: "Torreta", field: "Torreta", icon: <SettingsIcon /> },
            { label: "DVR", field: "DVR", icon: <DvrIcon /> },
          ].map(({ label, field, icon }) => {
            const isCorrect = generalState[field] === "Correcto";

            return (
              <div key={field} className="relative">
                <button
                  onClick={() =>
                    handleCheckboxChange(
                      field,
                      isCorrect ? "Incorrecto" : "Correcto"
                    )
                  }
                  className={`w-full p-4 rounded-lg border-2 text-left transition-all hover:shadow-md ${
                    isCorrect
                      ? "border-green-500 bg-green-50"
                      : "border-red-500 bg-red-50"
                  }`}
                >
                  <div className="flex items-center">
                    <div
                      className={`mr-3 ${
                        isCorrect ? "text-green-600" : "text-red-600"
                      }`}
                    >
                      {icon}
                    </div>
                    <div>
                      <div className="font-medium text-gray-800">{label}</div>
                      <div
                        className={`text-sm ${
                          isCorrect ? "text-green-600" : "text-red-600"
                        } font-medium`}
                      >
                        {generalState[field]}
                      </div>
                    </div>
                  </div>

                  <div className="absolute top-2 right-2">
                    {isCorrect ? (
                      <CheckCircleIcon
                        className="text-green-500"
                        fontSize="small"
                      />
                    ) : (
                      <CancelIcon className="text-red-500" fontSize="small" />
                    )}
                  </div>
                </button>
              </div>
            );
          })}
        </div>
      </div>

      {/* Sección de Reemplazos */}
      <div className="mb-8">
        <h2 className="text-lg font-semibold text-gray-800 flex items-center mb-4">
          <BuildIcon className="mr-2 text-indigo-600" />
          Componentes Reemplazados
        </h2>

        {/* Filtro de búsqueda y categorías */}
        <div className="flex flex-wrap gap-2 mb-4">
          <div className="flex-grow">
            <div className="relative">
              <input
                type="text"
                placeholder="Buscar componente..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
              />
              <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                <svg
                  className="w-5 h-5 text-gray-400"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                  />
                </svg>
              </div>
            </div>
          </div>

          <div className="flex space-x-1 overflow-x-auto pb-1 flex-nowrap">
            {categories.map((category) => (
              <button
                key={category.id}
                onClick={() => setSelectedCategory(category.id)}
                className={`px-3 py-2 text-sm font-medium rounded-md whitespace-nowrap transition-colors ${
                  selectedCategory === category.id
                    ? "bg-indigo-100 text-indigo-700"
                    : "bg-gray-100 text-gray-600 hover:bg-gray-200"
                }`}
              >
                {category.name}
              </button>
            ))}
          </div>
        </div>

        {/* Grid de componentes */}
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2 mb-6">
          {filteredReplacements.map(({ label, icon, category }) => {
            const isSelected = isReplacementSelected(label);

            return (
              <button
                key={label}
                onClick={() => handleReplacementClick(label)}
                className={`flex items-center p-2 rounded-md border text-left transition-all ${
                  isSelected
                    ? "border-indigo-500 bg-indigo-50 text-indigo-700"
                    : "border-gray-300 hover:border-indigo-300 hover:bg-gray-50"
                }`}
              >
                <div
                  className={`mr-2 ${
                    isSelected ? "text-indigo-600" : "text-gray-500"
                  }`}
                >
                  {icon}
                </div>
                <div className="flex-grow text-sm">{label}</div>
                {isSelected && (
                  <CheckCircleIcon
                    fontSize="small"
                    className="text-indigo-600 ml-1 flex-shrink-0"
                  />
                )}
              </button>
            );
          })}
        </div>

        {/* Lista de componentes seleccionados */}
        <div className="bg-gray-50 rounded-lg border border-gray-200 p-4">
          <div className="flex justify-between items-center mb-3">
            <h3 className="font-medium text-gray-700 flex items-center">
              <BuildIcon className="mr-2 text-indigo-600" fontSize="small" />
              Componentes Seleccionados
            </h3>
            <span className="bg-indigo-100 text-indigo-800 text-xs font-medium py-1 px-2 rounded-full">
              {fusibles.length} elementos
            </span>
          </div>

          {fusibles.length === 0 ? (
            <div className="text-center py-8 text-gray-500">
              <p>No hay componentes seleccionados</p>
              <p className="text-sm mt-1">
                Haz clic en los componentes de arriba para agregarlos
              </p>
            </div>
          ) : (
            <div className="space-y-2 max-h-60 overflow-y-auto pr-1">
              {fusibles.map((item, index) => (
                <div
                  key={index}
                  className="flex items-center justify-between bg-white rounded-md border border-gray-200 p-2 hover:shadow-sm"
                >
                  <div className="flex items-center">
                    <span className="w-6 h-6 flex items-center justify-center rounded-full bg-indigo-100 text-indigo-700 mr-2 text-xs font-bold">
                      {index + 1}
                    </span>
                    <span className="text-gray-800">{item}</span>
                  </div>
                  <button
                    onClick={() => handleRemoveFusible(index)}
                    className="text-gray-400 hover:text-red-500 p-1 rounded-full hover:bg-red-50"
                  >
                    <DeleteIcon fontSize="small" />
                  </button>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>

      {/* Botones de navegación */}
      <div className="flex justify-between mt-8">
        <button
          type="button"
          onClick={handleBack}
          className="px-5 py-2.5 rounded-md border border-gray-300 bg-white text-gray-700 hover:bg-gray-50 flex items-center"
        >
          <ArrowBackIcon className="mr-1" fontSize="small" />
          Anterior
        </button>

        <button
          type="button"
          onClick={handleNextStep}
          disabled={loading}
          className="px-5 py-2.5 rounded-md bg-indigo-600 text-white hover:bg-indigo-700 flex items-center"
        >
          {loading ? (
            <>
              <div className="w-5 h-5 border-t-2 border-b-2 border-white rounded-full animate-spin mr-2"></div>
              Guardando...
            </>
          ) : (
            <>
              Siguiente
              <ArrowForwardIcon className="ml-1" fontSize="small" />
            </>
          )}
        </button>
      </div>
    </div>
  );
};

export default Step3EstadoComentarios;
