import React, { useState, useEffect, useCallback } from "react";
import { addDoc, collection, doc, getDocs, query, where, updateDoc } from "firebase/firestore";
import { db } from "../../firebase";
import DatosPersonales from "./Steps/DatosPersonales";
import Documentacion from "./Steps/Documentacion";
import EstadoSalud from "./Steps/EstadoSalud";
import DatosFamiliares from "./Steps/DatosFamiliares";
import Escolaridad from "./Steps/Escolaridad";
import ConocimientosGenerales from "./Steps/ConocimientosGenerales";
import HistorialEmpleos from "./Steps/HistorialEmpleos";
import ReferenciasPersonales from "./Steps/ReferenciasPersonales";
import DatosGenerales from "./Steps/DatosGenerales";
import { Mail, Phone, ChevronRight, ChevronLeft, CheckCircle, AlertCircle, Loader2, Save, Briefcase, HelpCircle } from "lucide-react";

const NuevaSolicitud = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [contactInfo, setContactInfo] = useState("");
  const [solicitudId, setSolicitudId] = useState(null);
  const [datosPersonales, setDatosPersonales] = useState({});
  const [documentacion, setDocumentacion] = useState({});
  const [estadoSalud, setEstadoSalud] = useState({});
  const [datosFamiliares, setDatosFamiliares] = useState({});
  const [escolaridad, setEscolaridad] = useState({});
  const [conocimientosGenerales, setConocimientosGenerales] = useState({});
  const [loading, setLoading] = useState(false);
  const [referenciasPersonales, setReferenciasPersonales] = useState([]);
  const [datosGenerales, setDatosGenerales] = useState({});
  const [notification, setNotification] = useState({ show: false, type: "", message: "" });
  const [saveStatus, setSaveStatus] = useState("");
  const [historialEmpleos, setHistorialEmpleos] = useState([]);
  const [debounceTimers, setDebounceTimers] = useState({
    datosPersonales: null,
    documentacion: null,
    estadoSalud: null,
    datosFamiliares: null,
    escolaridad: null,
    conocimientosGenerales: null,
    historialEmpleos: null,
    referenciasPersonales: null,
    datosGenerales: null
  });

  const steps = [
    { name: "Información de contacto", description: "Verificación de identidad" },
    { name: "Datos personales", description: "Información básica del solicitante" },
    { name: "Documentación", description: "Archivos y documentos requeridos" },
    { name: "Estado de Salud", description: "Hábitos y condición física" },
    { name: "Datos Familiares", description: "Información familiar" },
    { name: "Escolaridad", description: "Formación académica" },
    { name: "Conocimientos", description: "Habilidades y competencias" },
    { name: "Historial de Empleos", description: "Experiencia laboral previa" },
    { name: "Referencias Personales", description: "Referencias no familiares" },
    { name: "Datos Generales", description: "Información adicional" },
    { name: "Finalizado", description: "Confirmación de solicitud" }
  ];

  // Mostrar notificación
  const showNotification = (type, message) => {
    setNotification({ show: true, type, message });
    setTimeout(() => {
      setNotification({ show: false, type: "", message: "" });
    }, 4000);
  };

  // Verificar si el contacto ya existe
  const checkExistingSolicitud = async () => {
    setLoading(true);

    try {
      const q = query(collection(db, "Solicitudes"), where("contactInfo", "==", contactInfo));
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        const existingDoc = querySnapshot.docs[0];
        setSolicitudId(existingDoc.id);
        setDatosPersonales(existingDoc.data().datosPersonales || {});
        setDocumentacion(existingDoc.data().documentacion || {});
        setEstadoSalud(existingDoc.data().estadoSalud || {});
        setDatosFamiliares(existingDoc.data().datosFamiliares || {});
        setEscolaridad(existingDoc.data().escolaridad || {});
        setConocimientosGenerales(existingDoc.data().conocimientosGenerales || {});
        setHistorialEmpleos(existingDoc.data().historialEmpleos || []);
        setReferenciasPersonales(existingDoc.data().referenciasPersonales || []);
        setDatosGenerales(existingDoc.data().datosGenerales || {}); // Nuevo
        showNotification("success", "Solicitud existente recuperada");
      } else {
        setSolicitudId(null);
        setDatosPersonales({});
        setDocumentacion({});
        setEstadoSalud({});
        setDatosFamiliares({});
        setEscolaridad({});
        setConocimientosGenerales({});
        setHistorialEmpleos([]);
        setReferenciasPersonales([]);
        setDatosGenerales({}); // Nuevo
      }
    } catch (err) {
      console.error("Error verificando el contacto:", err);
      showNotification("error", "Error al verificar la información de contacto");
    } finally {
      setLoading(false);
    }
  };

  const handleNext = async () => {
    if (activeStep === 0) {
      if (!contactInfo) {
        showNotification("error", "Por favor ingrese un correo o teléfono válido");
        return;
      }

      // Crear nueva solicitud si no existe
      if (!solicitudId) {
        setLoading(true);
        try {
          const docRef = await addDoc(collection(db, "Solicitudes"), {
            contactInfo,
            createdAt: new Date(),
            datosPersonales: {},
            documentacion: {},
            estadoSalud: {},
            datosFamiliares: {},
            escolaridad: {},
            conocimientosGenerales: {},
            historialEmpleos: [],
            referenciasPersonales: [],
            datosGenerales: {}
          });

          setSolicitudId(docRef.id);
          setActiveStep((prevStep) => prevStep + 1);
          showNotification("success", "Solicitud creada correctamente");
        } catch (err) {
          console.error("Error creando la solicitud:", err);
          showNotification("error", "Error al crear la solicitud");
        } finally {
          setLoading(false);
        }
      } else {
        setActiveStep((prevStep) => prevStep + 1);
      }
    } else {
      setActiveStep((prevStep) => prevStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  // Función genérica para actualizar los datos con debounce
  const debouncedUpdate = useCallback((section, updatedData, updateFunction) => {
    // Limpiar el temporizador anterior si existe
    if (debounceTimers[section]) {
      clearTimeout(debounceTimers[section]);
    }

    // Si este es el primer cambio, mostrar estado guardando
    if (saveStatus === "") {
      setSaveStatus("saving");
    }

    // Configurar un nuevo temporizador
    const timer = setTimeout(async () => {
      try {
        await updateFunction(updatedData);
        setSaveStatus("saved");

        // Quitar el mensaje "guardado" después de 2 segundos
        setTimeout(() => {
          setSaveStatus("");
        }, 2000);
      } catch (error) {
        showNotification("error", `Error al guardar: ${error.message}`);
        setSaveStatus("");
      }
    }, 3000); // Esperar 3 segundos después de la última tecla

    // Actualizar el temporizador en el estado
    setDebounceTimers(prev => ({
      ...prev,
      [section]: timer
    }));
  }, [debounceTimers, saveStatus]);

  // Funciones de actualización con debounce
  const handleUpdateDatosPersonales = (updatedData) => {
    // No actualizar el estado inmediatamente para evitar re-renders que causen pérdida de foco
    // setDatosPersonales(updatedData);

    if (!solicitudId) return;

    const actualUpdate = async (data) => {
      const docRef = doc(db, "Solicitudes", solicitudId);
      await updateDoc(docRef, { datosPersonales: data });
      // Solo actualizar el estado después de guardar en Firebase
      setDatosPersonales(data);
    };

    debouncedUpdate('datosPersonales', updatedData, actualUpdate);
  };

  const handleUpdateDocumentacion = (updatedData) => {
    // No actualizar el estado inmediatamente para evitar re-renders que causen pérdida de foco
    // setDocumentacion(updatedData);

    if (!solicitudId) return;

    const actualUpdate = async (data) => {
      const docRef = doc(db, "Solicitudes", solicitudId);
      await updateDoc(docRef, { documentacion: data });
      // Solo actualizar el estado después de guardar en Firebase
      setDocumentacion(data);
    };

    debouncedUpdate('documentacion', updatedData, actualUpdate);
  };

  const handleUpdateEstadoSalud = (updatedData) => {
    // No actualizar el estado inmediatamente para evitar re-renders que causen pérdida de foco
    // setEstadoSalud(updatedData);

    if (!solicitudId) return;

    const actualUpdate = async (data) => {
      const docRef = doc(db, "Solicitudes", solicitudId);
      await updateDoc(docRef, { estadoSalud: data });
      // Solo actualizar el estado después de guardar en Firebase
      setEstadoSalud(data);
    };

    debouncedUpdate('estadoSalud', updatedData, actualUpdate);
  };

  const handleUpdateDatosFamiliares = (updatedData) => {
    // No actualizar el estado inmediatamente para evitar re-renders que causen pérdida de foco
    // setDatosFamiliares(updatedData);

    if (!solicitudId) return;

    const actualUpdate = async (data) => {
      const docRef = doc(db, "Solicitudes", solicitudId);
      await updateDoc(docRef, { datosFamiliares: data });
      // Solo actualizar el estado después de guardar en Firebase
      setDatosFamiliares(data);
    };

    debouncedUpdate('datosFamiliares', updatedData, actualUpdate);
  };

  const handleUpdateEscolaridad = (updatedData) => {
    // NO actualizar el estado inmediatamente - esto es lo que causa la pérdida de foco
    // setEscolaridad(updatedData);

    if (!solicitudId) return;

    const actualUpdate = async (data) => {
      const docRef = doc(db, "Solicitudes", solicitudId);
      await updateDoc(docRef, { escolaridad: data });
      // Solo actualizar el estado DESPUÉS de guardar en Firebase
      setEscolaridad(data);
    };

    debouncedUpdate('escolaridad', updatedData, actualUpdate);
  };

  const handleUpdateConocimientosGenerales = (updatedData) => {
    // NO actualizar el estado inmediatamente - esto es lo que causa la pérdida de foco
    // setConocimientosGenerales(updatedData);

    if (!solicitudId) return;

    const actualUpdate = async (data) => {
      const docRef = doc(db, "Solicitudes", solicitudId);
      await updateDoc(docRef, { conocimientosGenerales: data });
      // Solo actualizar el estado DESPUÉS de guardar en Firebase
      setConocimientosGenerales(data);
    };

    debouncedUpdate('conocimientosGenerales', updatedData, actualUpdate);
  };

  const handleUpdateHistorialEmpleos = (updatedData) => {
    if (!solicitudId) return;

    const actualUpdate = async (data) => {
      const docRef = doc(db, "Solicitudes", solicitudId);
      await updateDoc(docRef, { historialEmpleos: data });
      setHistorialEmpleos(data);
    };

    debouncedUpdate('historialEmpleos', updatedData, actualUpdate);
  };

  const handleUpdateReferenciasPersonales = (updatedData) => {
    if (!solicitudId) return;

    const actualUpdate = async (data) => {
      const docRef = doc(db, "Solicitudes", solicitudId);
      await updateDoc(docRef, { referenciasPersonales: data });
      setReferenciasPersonales(data);
    };

    debouncedUpdate('referenciasPersonales', updatedData, actualUpdate);
  };

  const handleUpdateDatosGenerales = (updatedData) => {
    if (!solicitudId) return;

    const actualUpdate = async (data) => {
      const docRef = doc(db, "Solicitudes", solicitudId);
      await updateDoc(docRef, { datosGenerales: data });
      setDatosGenerales(data);
    };

    debouncedUpdate('datosGenerales', updatedData, actualUpdate);
  };

  useEffect(() => {
    if (contactInfo) {
      const timer = setTimeout(() => {
        checkExistingSolicitud();
      }, 500);
      return () => clearTimeout(timer);
    }
  }, [contactInfo]);

  // Limpiar temporizadores al desmontar el componente
  useEffect(() => {
    return () => {
      Object.values(debounceTimers).forEach(timer => {
        if (timer) clearTimeout(timer);
      });
    };
  }, [debounceTimers]);

  return (
    <div className="max-w-4xl mx-auto px-4 py-8">
      <div className="mb-8">
        <div className="flex items-center mb-4">
          <Briefcase className="h-6 w-6 text-gray-600 mr-2" />
          <h1 className="text-xl font-bold text-gray-800 sm:text-2xl">Solicitud de empleo</h1>
        </div>
        <div>
          <p className="text-gray-600 mb-4">
            Complete todos los pasos para enviar su solicitud.
          </p>
          <div className="flex flex-col sm:flex-row sm:items-center text-gray-600">
            <div className="flex items-center mb-2 sm:mb-0 sm:mr-4">
              <HelpCircle className="h-4 w-4 mr-1" />
              <span>Cualquier duda no olvide marcar al</span>
            </div>
            <div className="flex items-center">
              <Phone className="h-4 w-4 mr-1" />
              <a href="tel:6623003515" className="font-medium">
                662 300 3515
              </a>
            </div>
          </div>
        </div>
      </div>

      {/* Stepper */}
      <div className="mb-8">
        <nav aria-label="Progress">
          <ol className="space-y-4 md:flex md:space-y-0 md:space-x-8">
            {steps.map((step, index) => (
              <li key={index} className="md:flex-1">
                <div className={`group flex flex-col border-l-4 py-2 pl-4 md:border-l-0 md:border-t-4 md:pb-0 md:pl-0 md:pt-4 ${activeStep > index
                  ? "border-blue-600 md:border-blue-600"
                  : activeStep === index
                    ? "border-blue-600 md:border-blue-600"
                    : "border-gray-200 md:border-gray-200"
                  }`}>
                  <span className={`text-xs font-semibold uppercase tracking-wide ${activeStep >= index ? "text-blue-600" : "text-gray-500"
                    }`}>
                    {`Paso ${index + 1}`}
                  </span>
                  <span className={`text-sm font-medium ${activeStep >= index ? "text-gray-900" : "text-gray-500"
                    }`}>
                    {step.name}
                  </span>
                </div>
              </li>
            ))}
          </ol>
        </nav>
      </div>

      {/* Content */}
      <div className="bg-white p-6 rounded-lg shadow-md">
        {activeStep === 0 && (
          <div>
            <h2 className="text-xl font-semibold text-gray-800 mb-6">Comienza agregando tu información de contacto</h2>
            <div className="mb-6">
              <label htmlFor="contactInfo" className="block text-sm font-medium text-gray-700 mb-1">
                Correo Electrónico o Teléfono <span className="text-red-500">*</span>
              </label>
              <div className="relative mt-1 rounded-md shadow-sm">
                <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                  {contactInfo.includes("@") ? (
                    <Mail className="h-5 w-5 text-gray-400" />
                  ) : (
                    <Phone className="h-5 w-5 text-gray-400" />
                  )}
                </div>
                <input
                  type="text"
                  id="contactInfo"
                  className="block w-full rounded-md border-gray-300 pl-10 py-3 focus:border-blue-500 focus:ring-blue-500 shadow-sm"
                  placeholder="correo@ejemplo.com o (662) 123-4567"
                  value={contactInfo}
                  onChange={(e) => setContactInfo(e.target.value)}
                />
              </div>
              <p className="mt-2 text-sm text-gray-500">
                Esto se utilizará para identificar y recuperar su solicitud en el futuro.
              </p>
            </div>
            <div className="flex justify-end">
              <button
                type="button"
                onClick={handleNext}
                disabled={loading}
                className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50 disabled:cursor-not-allowed"
              >
                {loading ? (
                  <Loader2 className="h-4 w-4 mr-2 animate-spin" />
                ) : (
                  <>
                    Continuar <ChevronRight className="ml-2 h-4 w-4" />
                  </>
                )}
              </button>
            </div>
          </div>
        )}

        {activeStep === 1 && solicitudId && (
          <div>
            <h2 className="text-xl font-semibold text-gray-800 mb-6">Datos Personales</h2>
            <DatosPersonales
              solicitudId={solicitudId}
              datosIniciales={datosPersonales}
              onUpdate={handleUpdateDatosPersonales}
            />
            <div className="flex justify-between mt-6">
              <button
                type="button"
                onClick={handleBack}
                className="inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md shadow-sm text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                <ChevronLeft className="mr-2 h-4 w-4" /> Atrás
              </button>
              <button
                type="button"
                onClick={handleNext}
                className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                Continuar <ChevronRight className="ml-2 h-4 w-4" />
              </button>
            </div>
          </div>
        )}

        {activeStep === 2 && solicitudId && (
          <div>
            <h2 className="text-xl font-semibold text-gray-800 mb-6">Documentación</h2>
            <Documentacion
              solicitudId={solicitudId}
              datosIniciales={documentacion}
              onUpdate={handleUpdateDocumentacion}
            />
            <div className="flex justify-between mt-6">
              <button
                type="button"
                onClick={handleBack}
                className="inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md shadow-sm text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                <ChevronLeft className="mr-2 h-4 w-4" /> Atrás
              </button>
              <button
                type="button"
                onClick={handleNext}
                className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                Continuar <ChevronRight className="ml-2 h-4 w-4" />
              </button>
            </div>
          </div>
        )}

        {activeStep === 3 && solicitudId && (
          <div>
            <h2 className="text-xl font-semibold text-gray-800 mb-6">Estado de Salud y Hábitos Personales</h2>
            <EstadoSalud
              solicitudId={solicitudId}
              datosIniciales={estadoSalud}
              onUpdate={handleUpdateEstadoSalud}
            />
            <div className="flex justify-between mt-6">
              <button
                type="button"
                onClick={handleBack}
                className="inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md shadow-sm text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                <ChevronLeft className="mr-2 h-4 w-4" /> Atrás
              </button>
              <button
                type="button"
                onClick={handleNext}
                className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                Continuar <ChevronRight className="ml-2 h-4 w-4" />
              </button>
            </div>
          </div>
        )}

        {activeStep === 4 && solicitudId && (
          <div>
            <h2 className="text-xl font-semibold text-gray-800 mb-6">Datos Familiares</h2>
            <DatosFamiliares
              solicitudId={solicitudId}
              datosIniciales={datosFamiliares}
              onUpdate={handleUpdateDatosFamiliares}
            />
            <div className="flex justify-between mt-6">
              <button
                type="button"
                onClick={handleBack}
                className="inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md shadow-sm text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                <ChevronLeft className="mr-2 h-4 w-4" /> Atrás
              </button>
              <button
                type="button"
                onClick={handleNext}
                className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                Continuar <ChevronRight className="ml-2 h-4 w-4" />
              </button>
            </div>
          </div>
        )}

        {activeStep === 5 && solicitudId && (
          <div>
            <h2 className="text-xl font-semibold text-gray-800 mb-6">Escolaridad</h2>
            <Escolaridad
              solicitudId={solicitudId}
              datosIniciales={escolaridad}
              onUpdate={handleUpdateEscolaridad}
            />
            <div className="flex justify-between mt-6">
              <button
                type="button"
                onClick={handleBack}
                className="inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md shadow-sm text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                <ChevronLeft className="mr-2 h-4 w-4" /> Atrás
              </button>
              <button
                type="button"
                onClick={handleNext}
                className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                Continuar <ChevronRight className="ml-2 h-4 w-4" />
              </button>
            </div>
          </div>
        )}

        {activeStep === 6 && solicitudId && (
          <div>
            <h2 className="text-xl font-semibold text-gray-800 mb-6">Conocimientos Generales</h2>
            <ConocimientosGenerales
              solicitudId={solicitudId}
              datosIniciales={conocimientosGenerales}
              onUpdate={handleUpdateConocimientosGenerales}
            />
            <div className="flex justify-between mt-6">
              <button
                type="button"
                onClick={handleBack}
                className="inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md shadow-sm text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                <ChevronLeft className="mr-2 h-4 w-4" /> Atrás
              </button>
              <button
                type="button"
                onClick={handleNext}
                className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                Continuar <ChevronRight className="ml-2 h-4 w-4" />
              </button>
            </div>
          </div>
        )}

        {activeStep === 7 && solicitudId && (
          <div>
            <h2 className="text-xl font-semibold text-gray-800 mb-6">Historial de Empleos</h2>
            <HistorialEmpleos
              solicitudId={solicitudId}
              datosIniciales={historialEmpleos}
              onUpdate={handleUpdateHistorialEmpleos}
            />
            <div className="flex justify-between mt-6">
              <button
                type="button"
                onClick={handleBack}
                className="inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md shadow-sm text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                <ChevronLeft className="mr-2 h-4 w-4" /> Atrás
              </button>
              <button
                type="button"
                onClick={handleNext}
                className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                Continuar <ChevronRight className="ml-2 h-4 w-4" />
              </button>
            </div>
          </div>
        )}
        {activeStep === 8 && solicitudId && (
          <div>
            <h2 className="text-xl font-semibold text-gray-800 mb-6">Referencias Personales</h2>
            <ReferenciasPersonales
              solicitudId={solicitudId}
              datosIniciales={referenciasPersonales}
              onUpdate={handleUpdateReferenciasPersonales}
            />
            <div className="flex justify-between mt-6">
              <button
                type="button"
                onClick={handleBack}
                className="inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md shadow-sm text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                <ChevronLeft className="mr-2 h-4 w-4" /> Atrás
              </button>
              <button
                type="button"
                onClick={handleNext}
                className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                Continuar <ChevronRight className="ml-2 h-4 w-4" />
              </button>
            </div>
          </div>
        )}
        {activeStep === 9 && solicitudId && (
          <div>
            <h2 className="text-xl font-semibold text-gray-800 mb-6">Datos Generales</h2>
            <DatosGenerales
              solicitudId={solicitudId}
              datosIniciales={datosGenerales}
              onUpdate={handleUpdateDatosGenerales}
            />
            <div className="flex justify-between mt-6">
              <button
                type="button"
                onClick={handleBack}
                className="inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md shadow-sm text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                <ChevronLeft className="mr-2 h-4 w-4" /> Atrás
              </button>
              <button
                type="button"
                onClick={handleNext}
                className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                Continuar <ChevronRight className="ml-2 h-4 w-4" />
              </button>
            </div>
          </div>
        )}
        {activeStep === 10 && (
          <div className="text-center py-10">
            <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
              <CheckCircle className="h-6 w-6 text-green-600" />
            </div>
            <h2 className="mt-3 text-lg font-medium text-gray-900">¡Solicitud enviada!</h2>
            <p className="mt-2 text-sm text-gray-500">
              Su solicitud ha sido recibida y será procesada a la brevedad.
            </p>
            <div className="mt-6">
              <button
                type="button"
                onClick={() => window.location.href = '/'}
                className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                Volver al inicio
              </button>
            </div>
          </div>
        )}

        {saveStatus && (
          <div className={`mt-4 p-3 rounded-md flex items-center ${saveStatus === "saving"
            ? "bg-blue-50 text-blue-700"
            : "bg-green-50 text-green-700"
            }`}>
            {saveStatus === "saving" ? (
              <>
                <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-blue-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
                Guardando cambios...
              </>
            ) : (
              <>
                <Save className="h-5 w-5 mr-2 text-green-500" />
                Cambios guardados
              </>
            )}
          </div>
        )}
      </div>

      {/* Notification */}
      {notification.show && (
        <div className={`fixed bottom-4 right-4 flex items-center p-4 rounded-lg shadow-lg max-w-md ${notification.type === "success" ? "bg-green-50 text-green-800 border border-green-200" : "bg-red-50 text-red-800 border border-red-200"
          }`}>
          {notification.type === "success" ? (
            <CheckCircle className="h-6 w-6 mr-3 text-green-500" />
          ) : (
            <AlertCircle className="h-6 w-6 mr-3 text-red-500" />
          )}
          <span>{notification.message}</span>
        </div>
      )}
    </div>
  );
};

export default NuevaSolicitud;