import React from "react";
import { FileText } from "lucide-react";

const SeccionDocumentacion = ({ candidato }) => (
    <div className="bg-white shadow-md rounded-lg p-6">
        <h3 className="text-lg font-bold text-gray-900 mb-4 flex items-center">
            <FileText className="h-5 w-5 text-blue-500 mr-2" />
            Documentación
        </h3>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-y-3 gap-x-6">
            <div className="flex flex-col">
                <span className="text-sm text-gray-500">CURP</span>
                <span className="font-medium">{candidato.documentacion?.curp || "No especificado"}</span>
            </div>
            <div className="flex flex-col">
                <span className="text-sm text-gray-500">RFC</span>
                <span className="font-medium">{candidato.documentacion?.rfc || "No especificado"}</span>
            </div>
            <div className="flex flex-col">
                <span className="text-sm text-gray-500">NSS</span>
                <span className="font-medium">{candidato.documentacion?.nss || "No especificado"}</span>
            </div>
            <div className="flex flex-col">
                <span className="text-sm text-gray-500">AFORE</span>
                <span className="font-medium">{candidato.documentacion?.afore || "No especificado"}</span>
            </div>
            <div className="flex flex-col">
                <span className="text-sm text-gray-500">Tipo de Licencia</span>
                <span className="font-medium">{candidato.documentacion?.licencia || "No especificado"}</span>
            </div>
            <div className="flex flex-col">
                <span className="text-sm text-gray-500">Pasaporte</span>
                <span className="font-medium">{candidato.documentacion?.pasaporte || "No especificado"}</span>
            </div>
        </div>
    </div>
);

export default SeccionDocumentacion;