import React, { useState } from "react";
import emailjs from "emailjs-com";
import {
  User,
  Mail,
  Phone,
  Briefcase,
  MessageSquare,
  Send,
  CheckCircle,
  AlertCircle,
} from "lucide-react";

const Contact = () => {
  const [service, setService] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [notification, setNotification] = useState({
    show: false,
    type: "",
    message: "",
  });
  const [formData, setFormData] = useState({
    user_name: "",
    user_email: "",
    user_phone: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleServiceChange = (e) => {
    setService(e.target.value);
  };

  const showNotification = (type, message) => {
    setNotification({ show: true, type, message });
    setTimeout(() => {
      setNotification({ show: false, type: "", message: "" });
    }, 5000);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      // Enviar el formulario al correo de administración
      await emailjs.sendForm(
        "service_nntdh2l",
        "template_uhmwxcz",
        e.target,
        "YBNR2ke3iLef3YK4o"
      );

      // Enviar un correo de confirmación al usuario
      await emailjs.send(
        "service_nntdh2l",
        "template_4bi7xbp",
        {
          user_name: formData.user_name,
          user_email: formData.user_email,
          service: service,
        },
        "YBNR2ke3iLef3YK4o"
      );

      showNotification("success", "¡Mensaje enviado con éxito!");
      setFormData({
        user_name: "",
        user_email: "",
        user_phone: "",
        message: "",
      });
      setService("");
    } catch (error) {
      console.error("Error al enviar el correo:", error);
      showNotification(
        "error",
        "Error al enviar el mensaje. Intente nuevamente."
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <section
      id="contact"
      className="py-20 px-4 bg-gradient-to-b from-gray-50 to-gray-100"
    >
      <div className="max-w-4xl mx-auto">
        <div className="text-center mb-12">
          <h2 className="text-3xl font-bold text-gray-900 mb-4">Contáctanos</h2>
          <div className="w-20 h-1 bg-blue-700 mx-auto mb-6"></div>
          <p className="text-gray-600 max-w-2xl mx-auto">
            Complete el formulario y nuestro equipo se pondrá en contacto con
            usted a la brevedad.
          </p>
        </div>

        <div className="bg-white rounded-lg shadow-lg p-8 md:p-10">
          <form onSubmit={handleSubmit} className="space-y-6">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              {/* Nombre */}
              <div className="relative">
                <label
                  htmlFor="user_name"
                  className="block text-sm font-medium text-gray-700 mb-1"
                >
                  Nombre completo <span className="text-red-500">*</span>
                </label>
                <div className="relative">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <User className="h-5 w-5 text-gray-400" />
                  </div>
                  <input
                    type="text"
                    name="user_name"
                    id="user_name"
                    value={formData.user_name}
                    onChange={handleChange}
                    required
                    className="block w-full pl-10 pr-3 py-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 text-sm"
                    placeholder="Ingrese su nombre"
                  />
                </div>
              </div>

              {/* Email */}
              <div className="relative">
                <label
                  htmlFor="user_email"
                  className="block text-sm font-medium text-gray-700 mb-1"
                >
                  Correo Electrónico <span className="text-red-500">*</span>
                </label>
                <div className="relative">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <Mail className="h-5 w-5 text-gray-400" />
                  </div>
                  <input
                    type="email"
                    name="user_email"
                    id="user_email"
                    value={formData.user_email}
                    onChange={handleChange}
                    required
                    className="block w-full pl-10 pr-3 py-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 text-sm"
                    placeholder="correo@ejemplo.com"
                  />
                </div>
              </div>

              {/* Teléfono */}
              <div className="relative">
                <label
                  htmlFor="user_phone"
                  className="block text-sm font-medium text-gray-700 mb-1"
                >
                  Número de Teléfono <span className="text-red-500">*</span>
                </label>
                <div className="relative">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <Phone className="h-5 w-5 text-gray-400" />
                  </div>
                  <input
                    type="tel"
                    name="user_phone"
                    id="user_phone"
                    value={formData.user_phone}
                    onChange={handleChange}
                    required
                    className="block w-full pl-10 pr-3 py-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 text-sm"
                    placeholder="(662) 123-4567"
                  />
                </div>
              </div>

              {/* Servicio */}
              <div className="relative">
                <label
                  htmlFor="service"
                  className="block text-sm font-medium text-gray-700 mb-1"
                >
                  Servicio de interés <span className="text-red-500">*</span>
                </label>
                <div className="relative">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <Briefcase className="h-5 w-5 text-gray-400" />
                  </div>
                  <select
                    name="service"
                    id="service"
                    value={service}
                    onChange={handleServiceChange}
                    required
                    className="block w-full pl-10 pr-3 py-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 text-sm appearance-none bg-none"
                  >
                    <option value="" disabled>
                      Seleccione un servicio
                    </option>
                    <option value="Radio comunicación">
                      Radio comunicación
                    </option>
                    <option value="Seguridad Privada">Seguridad Privada</option>
                    <option value="Seguridad Publica">Seguridad Pública</option>
                    <option value="Busqueda y Rescate">
                      Búsqueda y Rescate
                    </option>
                    <option value="Venta de Drones y Accesorios">
                      Venta de Drones y Accesorios
                    </option>
                    <option value="Equipamiento de Vehículos">
                      Equipamiento de Vehículos
                    </option>
                    <option value="Puntos de repeticion">
                      Puntos de repetición
                    </option>
                    <option value="Venta de Equipos">Venta de Equipos</option>
                  </select>
                </div>
              </div>
            </div>

            {/* Mensaje */}
            <div className="relative">
              <label
                htmlFor="message"
                className="block text-sm font-medium text-gray-700 mb-1"
              >
                Mensaje <span className="text-red-500">*</span>
              </label>
              <div className="relative">
                <div className="absolute top-3 left-3 flex items-start pointer-events-none">
                  <MessageSquare className="h-5 w-5 text-gray-400" />
                </div>
                <textarea
                  name="message"
                  id="message"
                  rows="4"
                  value={formData.message}
                  onChange={handleChange}
                  required
                  className="block w-full pl-10 pr-3 py-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 text-sm"
                  placeholder="Describa su consulta o requerimiento..."
                ></textarea>
              </div>
            </div>

            {/* Botón enviar */}
            <div className="flex justify-center">
              <button
                type="submit"
                disabled={isSubmitting}
                className={`inline-flex items-center justify-center px-6 py-3 border border-transparent text-base font-medium rounded-md text-white bg-blue-700 hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-colors duration-200 ${
                  isSubmitting ? "opacity-70 cursor-not-allowed" : ""
                }`}
              >
                {isSubmitting ? (
                  <>
                    <svg
                      className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                    Enviando...
                  </>
                ) : (
                  <>
                    Enviar mensaje <Send className="ml-2 h-5 w-5" />
                  </>
                )}
              </button>
            </div>

            {/* Texto de confidencialidad */}
            <p className="text-xs text-gray-500 text-center mt-4">
              Sus datos personales serán tratados con confidencialidad de
              acuerdo a nuestra política de privacidad.
            </p>
          </form>
        </div>

        {/* Notificación */}
        {notification.show && (
          <div
            className={`fixed bottom-6 right-6 flex items-center p-4 rounded-lg shadow-lg max-w-md ${
              notification.type === "success"
                ? "bg-green-50 text-green-800 border border-green-200"
                : "bg-red-50 text-red-800 border border-red-200"
            }`}
          >
            {notification.type === "success" ? (
              <CheckCircle className="h-6 w-6 mr-3 text-green-500" />
            ) : (
              <AlertCircle className="h-6 w-6 mr-3 text-red-500" />
            )}
            <span>{notification.message}</span>
          </div>
        )}
      </div>
    </section>
  );
};

export default Contact;
