import React from "react";
import { Clipboard } from "lucide-react";

const SeccionDatosGenerales = ({ candidato }) => (
    <div className="bg-white shadow-md rounded-lg p-6">
        <h3 className="text-lg font-bold text-gray-900 mb-4 flex items-center">
            <Clipboard className="h-5 w-5 text-blue-500 mr-2" />
            Datos Generales
        </h3>

        <div className="space-y-6">
            <div>
                <h4 className="font-semibold text-gray-800 mb-2">Información Económica</h4>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-y-2 gap-x-6 pl-4">
                    <div className="flex flex-col">
                        <span className="text-sm text-gray-500">Gastos Mensuales</span>
                        <span className="font-medium">${candidato.datosGenerales?.gastosMensuales || "No especificado"}</span>
                    </div>
                    <div className="flex flex-col">
                        <span className="text-sm text-gray-500">¿Tiene otros ingresos?</span>
                        <span className="font-medium">{candidato.datosGenerales?.otrosIngresos || "No especificado"}</span>
                    </div>
                    {candidato.datosGenerales?.otrosIngresos === "si" && (
                        <div className="flex flex-col">
                            <span className="text-sm text-gray-500">Importe de otros ingresos</span>
                            <span className="font-medium">${candidato.datosGenerales?.otrosIngresosImporte || "No especificado"}</span>
                        </div>
                    )}
                    <div className="flex flex-col">
                        <span className="text-sm text-gray-500">¿Tiene deudas?</span>
                        <span className="font-medium">{candidato.datosGenerales?.deudas || "No especificado"}</span>
                    </div>
                    {candidato.datosGenerales?.deudas === "si" && (
                        <>
                            <div className="flex flex-col">
                                <span className="text-sm text-gray-500">Descripción de deudas</span>
                                <span className="font-medium">{candidato.datosGenerales?.deudasDescripcion || "No especificado"}</span>
                            </div>
                            <div className="flex flex-col">
                                <span className="text-sm text-gray-500">Importe de deudas</span>
                                <span className="font-medium">${candidato.datosGenerales?.deudasImporte || "No especificado"}</span>
                            </div>
                            <div className="flex flex-col">
                                <span className="text-sm text-gray-500">Abono Mensual</span>
                                <span className="font-medium">${candidato.datosGenerales?.abonaMensual || "No especificado"}</span>
                            </div>
                        </>
                    )}
                </div>
            </div>

            <div>
                <h4 className="font-semibold text-gray-800 mb-2">Vivienda</h4>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-y-2 gap-x-6 pl-4">
                    <div className="flex flex-col">
                        <span className="text-sm text-gray-500">¿Casa Propia?</span>
                        <span className="font-medium">{candidato.datosGenerales?.casaPropia || "No especificado"}</span>
                    </div>
                    {candidato.datosGenerales?.casaPropia === "si" && (
                        <div className="flex flex-col">
                            <span className="text-sm text-gray-500">Valor de la casa</span>
                            <span className="font-medium">${candidato.datosGenerales?.casaPropiaValor || "No especificado"}</span>
                        </div>
                    )}
                    <div className="flex flex-col">
                        <span className="text-sm text-gray-500">¿Paga renta?</span>
                        <span className="font-medium">{candidato.datosGenerales?.pagaRenta || "No especificado"}</span>
                    </div>
                    {candidato.datosGenerales?.pagaRenta === "si" && (
                        <div className="flex flex-col">
                            <span className="text-sm text-gray-500">Renta mensual</span>
                            <span className="font-medium">${candidato.datosGenerales?.rentaMensual || "No especificado"}</span>
                        </div>
                    )}
                </div>
            </div>

            <div>
                <h4 className="font-semibold text-gray-800 mb-2">Información Adicional</h4>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-y-2 gap-x-6 pl-4">
                    <div className="flex flex-col">
                        <span className="text-sm text-gray-500">¿Automóvil propio?</span>
                        <span className="font-medium">{candidato.datosGenerales?.automovilPropio || "No especificado"}</span>
                    </div>
                    {candidato.datosGenerales?.automovilPropio === "si" && (
                        <>
                            <div className="flex flex-col">
                                <span className="text-sm text-gray-500">Marca</span>
                                <span className="font-medium">{candidato.datosGenerales?.automovilMarca || "No especificado"}</span>
                            </div>
                            <div className="flex flex-col">
                                <span className="text-sm text-gray-500">Modelo</span>
                                <span className="font-medium">{candidato.datosGenerales?.automovilModelo || "No especificado"}</span>
                            </div>
                        </>
                    )}
                    <div className="flex flex-col">
                        <span className="text-sm text-gray-500">¿Puede viajar?</span>
                        <span className="font-medium">{candidato.datosGenerales?.puedeViajar || "No especificado"}</span>
                    </div>
                    {candidato.datosGenerales?.puedeViajar === "no" && (
                        <div className="flex flex-col">
                            <span className="text-sm text-gray-500">Razones</span>
                            <span className="font-medium">{candidato.datosGenerales?.puedeViajarRazones || "No especificado"}</span>
                        </div>
                    )}
                    <div className="flex flex-col">
                        <span className="text-sm text-gray-500">¿Cómo se enteró de la vacante?</span>
                        <span className="font-medium">{candidato.datosGenerales?.medioConocimiento || "No especificado"}</span>
                    </div>
                    <div className="flex flex-col">
                        <span className="text-sm text-gray-500">Fecha de presentación</span>
                        <span className="font-medium">{candidato.datosGenerales?.fechaPresentacion || "No especificado"}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
);

export default SeccionDatosGenerales;