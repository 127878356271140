import React, { useState, useEffect } from "react";
import { collection, getDocs, query, where, orderBy, onSnapshot, doc, updateDoc } from "firebase/firestore";
import { db } from "../../../firebase";
import {
    Search, Phone, Mail, Calendar, User, Filter,
    ChevronDown, ChevronUp, RefreshCcw, Eye, Briefcase,
    Check, Clock, X
} from "lucide-react";
import { Link } from "react-router-dom";

const Candidatos = () => {
    const [candidatos, setCandidatos] = useState([]);
    const [filteredCandidatos, setFilteredCandidatos] = useState([]);
    const [loading, setLoading] = useState(true);
    const [searchTerm, setSearchTerm] = useState("");
    const [sortField, setSortField] = useState("createdAt");
    const [sortDirection, setSortDirection] = useState("desc");
    const [filterStatus, setFilterStatus] = useState("todos");
    const [filterSeleccion, setFilterSeleccion] = useState("todos");
    const [updatingId, setUpdatingId] = useState(null);

    // Efecto para cargar los candidatos y configurar la escucha en tiempo real
    useEffect(() => {
        setLoading(true);

        // Configurar la consulta base
        const candidatosRef = collection(db, "Solicitudes");
        const q = query(candidatosRef, orderBy(sortField, sortDirection));

        // Configurar la escucha en tiempo real
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const candidatosData = [];
            querySnapshot.forEach((doc) => {
                const data = doc.data();

                // Extraer información relevante del candidato
                const nombreCompleto =
                    `${data.datosPersonales?.nombres || ""} ${data.datosPersonales?.apellidoPaterno || ""} ${data.datosPersonales?.apellidoMaterno || ""}`.trim();

                // Extraer teléfono o correo según el formato del contactInfo
                let telefono = "";
                let correo = "";

                if (data.contactInfo) {
                    if (data.contactInfo.includes("@")) {
                        correo = data.contactInfo;
                    } else {
                        telefono = data.contactInfo;
                    }
                }

                // También se puede extraer del objeto datosPersonales si está disponible
                if (!telefono && data.datosPersonales?.telefono) {
                    telefono = data.datosPersonales.telefono;
                }

                // Calcular el progreso de la solicitud
                let progreso = 0;
                const totalSecciones = 9; // Número total de secciones en la solicitud
                let seccionesCompletadas = 0;

                if (Object.keys(data.datosPersonales || {}).length > 0) seccionesCompletadas++;
                if (Object.keys(data.documentacion || {}).length > 0) seccionesCompletadas++;
                if (Object.keys(data.estadoSalud || {}).length > 0) seccionesCompletadas++;
                if (Object.keys(data.datosFamiliares || {}).length > 0) seccionesCompletadas++;
                if (Object.keys(data.escolaridad || {}).length > 0) seccionesCompletadas++;
                if (Object.keys(data.conocimientosGenerales || {}).length > 0) seccionesCompletadas++;
                if ((data.historialEmpleos || []).length > 0) seccionesCompletadas++;
                if ((data.referenciasPersonales || []).length > 0) seccionesCompletadas++;
                if (Object.keys(data.datosGenerales || {}).length > 0) seccionesCompletadas++;

                progreso = Math.round((seccionesCompletadas / totalSecciones) * 100);

                // Determinar el estado de la solicitud
                let status = "pendiente";
                if (progreso === 100) {
                    status = "completo";
                } else if (progreso > 50) {
                    status = "en_proceso";
                }

                // Agregar el candidato a la lista
                candidatosData.push({
                    id: doc.id,
                    nombreCompleto,
                    telefono,
                    correo,
                    contactInfo: data.contactInfo,
                    createdAt: data.createdAt?.toDate() || new Date(),
                    progreso,
                    status,
                    seleccion: data.status || "pendiente", // Estado de selección (entrevista, pendiente, descartado)
                    raw: data // Mantener los datos originales para referencia
                });
            });

            setCandidatos(candidatosData);
            setLoading(false);
        }, (error) => {
            console.error("Error al obtener candidatos:", error);
            setLoading(false);
        });

        // Limpiar la suscripción cuando se desmonte el componente
        return () => unsubscribe();
    }, [sortField, sortDirection]);

    // Efecto para filtrar candidatos cuando cambia el término de búsqueda o los filtros
    useEffect(() => {
        if (searchTerm === "" && filterStatus === "todos" && filterSeleccion === "todos") {
            setFilteredCandidatos(candidatos);
            return;
        }

        const searchTermLower = searchTerm.toLowerCase();

        const filtered = candidatos.filter(candidato => {
            // Filtro por término de búsqueda
            const matchesSearch = searchTerm === "" ||
                candidato.nombreCompleto.toLowerCase().includes(searchTermLower) ||
                candidato.telefono.toLowerCase().includes(searchTermLower) ||
                candidato.correo.toLowerCase().includes(searchTermLower) ||
                candidato.contactInfo.toLowerCase().includes(searchTermLower);

            // Filtro por estado de la solicitud
            const matchesStatus = filterStatus === "todos" ||
                candidato.status === filterStatus;

            // Filtro por estado de selección
            const matchesSeleccion = filterSeleccion === "todos" ||
                candidato.seleccion === filterSeleccion;

            return matchesSearch && matchesStatus && matchesSeleccion;
        });

        setFilteredCandidatos(filtered);
    }, [candidatos, searchTerm, filterStatus, filterSeleccion]);

    // Función para cambiar el campo de ordenación
    const handleSort = (field) => {
        if (sortField === field) {
            // Si ya estamos ordenando por este campo, invertir la dirección
            setSortDirection(sortDirection === "asc" ? "desc" : "asc");
        } else {
            // Si es un nuevo campo, establecerlo y usar dirección descendente por defecto
            setSortField(field);
            setSortDirection("desc");
        }
    };

    // Función para actualizar el estado de selección de un candidato
    const handleUpdateSeleccion = async (candidatoId, nuevoEstado) => {
        if (updatingId) return; // Evitar múltiples actualizaciones simultáneas

        setUpdatingId(candidatoId);

        try {
            const docRef = doc(db, "Solicitudes", candidatoId);
            await updateDoc(docRef, { status: nuevoEstado });
            console.log(`Candidato ${candidatoId} actualizado a: ${nuevoEstado}`);
        } catch (error) {
            console.error("Error al actualizar estado:", error);
        } finally {
            setUpdatingId(null);
        }
    };

    // Función para formatear la fecha
    const formatDate = (date) => {
        return new Date(date).toLocaleDateString('es-MX', {
            year: 'numeric',
            month: 'short',
            day: 'numeric'
        });
    };

    // Función para obtener el color del botón según el estado de selección
    const getSeleccionColor = (seleccion) => {
        switch (seleccion) {
            case "entrevista": return "bg-green-100 text-green-800 hover:bg-green-200";
            case "pendiente": return "bg-yellow-100 text-yellow-800 hover:bg-yellow-200";
            case "descartado": return "bg-red-100 text-red-800 hover:bg-red-200";
            default: return "bg-gray-100 text-gray-800 hover:bg-gray-200";
        }
    };

    // Función para obtener el icono del estado de selección
    const getSeleccionIcon = (seleccion) => {
        switch (seleccion) {
            case "entrevista": return <Check className="h-4 w-4" />;
            case "pendiente": return <Clock className="h-4 w-4" />;
            case "descartado": return <X className="h-4 w-4" />;
            default: return null;
        }
    };

    // Renderización del componente de carga
    const renderLoading = () => (
        <div className="flex justify-center items-center h-48 sm:h-64">
            <div className="flex flex-col items-center">
                <RefreshCcw className="h-6 w-6 sm:h-8 sm:w-8 text-blue-500 animate-spin" />
                <p className="mt-3 text-sm sm:text-base text-gray-600">Cargando candidatos...</p>
            </div>
        </div>
    );

    // Renderización del mensaje de no se encontraron resultados
    const renderNoResults = () => (
        <div className="flex justify-center items-center h-48 sm:h-64">
            <div className="flex flex-col items-center">
                <Search className="h-6 w-6 sm:h-8 sm:w-8 text-gray-400" />
                <p className="mt-3 text-sm sm:text-base text-gray-600">No se encontraron candidatos</p>
                {searchTerm && (
                    <p className="text-xs sm:text-sm text-gray-500 mt-2">
                        Prueba con otro término de búsqueda
                    </p>
                )}
            </div>
        </div>
    );

    // Renderización de la tabla de candidatos
    const renderCandidatosTable = () => (
        <div className="overflow-x-auto -mx-2 sm:mx-0">
            <table className="min-w-full divide-y divide-gray-200 border-collapse">
                <thead className="bg-gray-50">
                    <tr>
                        <th
                            scope="col"
                            className="px-2 sm:px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                            onClick={() => handleSort("nombreCompleto")}
                        >
                            <div className="flex items-center">
                                <span>Candidato</span>
                                {sortField === "nombreCompleto" && (
                                    sortDirection === "asc" ?
                                        <ChevronUp className="ml-1 h-3 w-3" /> :
                                        <ChevronDown className="ml-1 h-3 w-3" />
                                )}
                            </div>
                        </th>
                        <th
                            scope="col"
                            className="px-2 sm:px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                            Contacto
                        </th>
                        <th
                            scope="col"
                            className="px-2 sm:px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                            onClick={() => handleSort("createdAt")}
                        >
                            <div className="flex items-center">
                                <span>Fecha</span>
                                {sortField === "createdAt" && (
                                    sortDirection === "asc" ?
                                        <ChevronUp className="ml-1 h-3 w-3" /> :
                                        <ChevronDown className="ml-1 h-3 w-3" />
                                )}
                            </div>
                        </th>
                        <th
                            scope="col"
                            className="px-2 sm:px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                            onClick={() => handleSort("progreso")}
                        >
                            <div className="flex items-center">
                                <span>Progreso</span>
                                {sortField === "progreso" && (
                                    sortDirection === "asc" ?
                                        <ChevronUp className="ml-1 h-3 w-3" /> :
                                        <ChevronDown className="ml-1 h-3 w-3" />
                                )}
                            </div>
                        </th>
                        <th
                            scope="col"
                            className="px-2 sm:px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                            onClick={() => handleSort("seleccion")}
                        >
                            <div className="flex items-center">
                                <span>Estado</span>
                                {sortField === "seleccion" && (
                                    sortDirection === "asc" ?
                                        <ChevronUp className="ml-1 h-3 w-3" /> :
                                        <ChevronDown className="ml-1 h-3 w-3" />
                                )}
                            </div>
                        </th>
                        <th
                            scope="col"
                            className="px-2 sm:px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                            Acciones
                        </th>
                    </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                    {filteredCandidatos.map((candidato) => (
                        <tr key={candidato.id} className="hover:bg-gray-50">
                            <td className="px-2 sm:px-4 py-2 sm:py-3 whitespace-nowrap">
                                <div className="flex items-center">
                                    <div className="flex-shrink-0 h-8 w-8 sm:h-9 sm:w-9 bg-blue-100 rounded-full flex items-center justify-center">
                                        <User className="h-4 w-4 sm:h-5 sm:w-5 text-blue-500" />
                                    </div>
                                    <div className="ml-2 sm:ml-3">
                                        <div className="text-xs sm:text-sm font-medium text-gray-900 line-clamp-1">
                                            {candidato.nombreCompleto || 'Sin nombre'}
                                        </div>
                                        <div className="text-xs text-gray-500">
                                            ID: {candidato.id.slice(0, 6)}...
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td className="px-2 sm:px-4 py-2 sm:py-3 whitespace-nowrap">
                                <div className="text-xs sm:text-sm text-gray-900">
                                    {candidato.telefono && (
                                        <div className="flex items-center">
                                            <Phone className="h-3 w-3 sm:h-4 sm:w-4 text-gray-400 mr-1" />
                                            <span className="truncate max-w-[120px] sm:max-w-none">{candidato.telefono}</span>
                                        </div>
                                    )}
                                    {candidato.correo && (
                                        <div className="flex items-center mt-1">
                                            <Mail className="h-3 w-3 sm:h-4 sm:w-4 text-gray-400 mr-1" />
                                            <span className="truncate max-w-[120px] sm:max-w-none">{candidato.correo}</span>
                                        </div>
                                    )}
                                    {!candidato.telefono && !candidato.correo && (
                                        <div className="flex items-center">
                                            <span className="text-xs text-gray-500 truncate max-w-[120px] sm:max-w-none">{candidato.contactInfo}</span>
                                        </div>
                                    )}
                                </div>
                            </td>
                            <td className="px-2 sm:px-4 py-2 sm:py-3 whitespace-nowrap">
                                <div className="flex items-center text-xs sm:text-sm text-gray-500">
                                    <Calendar className="h-3 w-3 sm:h-4 sm:w-4 text-gray-400 mr-1" />
                                    <span>{formatDate(candidato.createdAt)}</span>
                                </div>
                            </td>
                            <td className="px-2 sm:px-4 py-2 sm:py-3 whitespace-nowrap">
                                <div>
                                    <div className="flex items-center">
                                        <span className="text-xs sm:text-sm text-gray-500 mr-1 sm:mr-2 w-7">{candidato.progreso}%</span>
                                        <div className="w-16 sm:w-full bg-gray-200 rounded-full h-1.5 sm:h-2">
                                            <div
                                                className={`h-1.5 sm:h-2 rounded-full ${candidato.progreso === 100
                                                    ? 'bg-green-500'
                                                    : candidato.progreso > 50
                                                        ? 'bg-blue-500'
                                                        : 'bg-yellow-500'
                                                    }`}
                                                style={{ width: `${candidato.progreso}%` }}
                                            ></div>
                                        </div>
                                    </div>
                                    <div className="text-xs text-gray-500 mt-0.5 hidden sm:block">
                                        {candidato.status === 'completo' && 'Solicitud completa'}
                                        {candidato.status === 'en_proceso' && 'En proceso'}
                                        {candidato.status === 'pendiente' && 'Pendiente'}
                                    </div>
                                </div>
                            </td>
                            <td className="px-2 sm:px-4 py-2 sm:py-3 whitespace-nowrap">
                                <div>
                                    <span className={`inline-flex items-center px-1.5 py-0.5 rounded-full text-xs font-medium ${getSeleccionColor(candidato.seleccion)}`}>
                                        {getSeleccionIcon(candidato.seleccion)}
                                        <span className="ml-1 capitalize hidden sm:inline">
                                            {candidato.seleccion === "entrevista" ? "Entrevista" :
                                                candidato.seleccion === "pendiente" ? "Pendiente" :
                                                    candidato.seleccion === "descartado" ? "Descartado" : "Sin estado"}
                                        </span>
                                    </span>
                                </div>
                            </td>
                            <td className="px-2 sm:px-4 py-2 sm:py-3 whitespace-nowrap text-xs sm:text-sm font-medium">
                                <div className="flex items-center space-x-1">
                                    <Link
                                        to={`/candidato/${candidato.id}`}
                                        className="inline-flex items-center p-1 text-blue-600 hover:text-blue-900"
                                    >
                                        <Eye className="h-4 w-4" />
                                    </Link>

                                    <div className="flex border divide-x rounded-md overflow-hidden">
                                        <button
                                            disabled={updatingId === candidato.id}
                                            onClick={() => handleUpdateSeleccion(candidato.id, "entrevista")}
                                            className={`p-1 ${candidato.seleccion === "entrevista"
                                                    ? "bg-green-100 text-green-700"
                                                    : "hover:bg-green-50 text-green-600 hover:text-green-700"
                                                } transition-colors duration-150 ease-in-out`}
                                            title="Marcar para entrevista"
                                        >
                                            <Check className="h-4 w-4" />
                                        </button>
                                        <button
                                            disabled={updatingId === candidato.id}
                                            onClick={() => handleUpdateSeleccion(candidato.id, "pendiente")}
                                            className={`p-1 ${candidato.seleccion === "pendiente"
                                                    ? "bg-yellow-100 text-yellow-700"
                                                    : "hover:bg-yellow-50 text-yellow-600 hover:text-yellow-700"
                                                } transition-colors duration-150 ease-in-out`}
                                            title="Marcar como pendiente"
                                        >
                                            <Clock className="h-4 w-4" />
                                        </button>
                                        <button
                                            disabled={updatingId === candidato.id}
                                            onClick={() => handleUpdateSeleccion(candidato.id, "descartado")}
                                            className={`p-1 ${candidato.seleccion === "descartado"
                                                    ? "bg-red-100 text-red-700"
                                                    : "hover:bg-red-50 text-red-600 hover:text-red-700"
                                                } transition-colors duration-150 ease-in-out`}
                                            title="Descartar candidato"
                                        >
                                            <X className="h-4 w-4" />
                                        </button>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );

    return (
        <div className="w-full px-2 sm:px-4 lg:px-6 py-4 sm:py-6">
            <div className="mb-4 sm:mb-6">
                <div className="flex items-center mb-2 sm:mb-3">
                    <Briefcase className="h-5 w-5 sm:h-6 sm:w-6 text-gray-600 mr-2" />
                    <h1 className="text-lg font-bold text-gray-800 sm:text-xl md:text-2xl">Candidatos</h1>
                </div>
                <p className="text-sm sm:text-base text-gray-600">
                    Consulta y gestiona todas las solicitudes de empleo recibidas
                </p>
            </div>

            {/* Filtros y búsqueda - Diseño optimizado */}
            <div className="grid grid-cols-1 md:grid-cols-12 gap-3 mb-4">
                <div className="md:col-span-5 lg:col-span-4">
                    <div className="relative rounded-md shadow-sm">
                        <div className="absolute inset-y-0 left-0 pl-2 flex items-center pointer-events-none">
                            <Search className="h-4 w-4 text-gray-400" />
                        </div>
                        <input
                            type="text"
                            className="block w-full pl-8 pr-8 py-1.5 border border-gray-300 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500"
                            placeholder="Buscar por nombre o teléfono..."
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                        {searchTerm && (
                            <button
                                className="absolute inset-y-0 right-0 pr-2 flex items-center"
                                onClick={() => setSearchTerm("")}
                            >
                                <span className="text-gray-400 hover:text-gray-500">
                                    &times;
                                </span>
                            </button>
                        )}
                    </div>
                </div>

                <div className="md:col-span-3 lg:col-span-3">
                    <div className="flex items-center h-full">
                        <Filter className="h-4 w-4 text-gray-400 mr-1 flex-shrink-0" />
                        <select
                            className="block w-full text-sm border-gray-300 py-1.5 focus:outline-none focus:ring-blue-500 focus:border-blue-500 rounded-md"
                            value={filterStatus}
                            onChange={(e) => setFilterStatus(e.target.value)}
                        >
                            <option value="todos">Todos los estados</option>
                            <option value="completo">Completados</option>
                            <option value="en_proceso">En proceso</option>
                            <option value="pendiente">Pendientes</option>
                        </select>
                    </div>
                </div>

                <div className="md:col-span-3 lg:col-span-3">
                    <div className="flex items-center h-full">
                        <User className="h-4 w-4 text-gray-400 mr-1 flex-shrink-0" />
                        <select
                            className="block w-full text-sm border-gray-300 py-1.5 focus:outline-none focus:ring-blue-500 focus:border-blue-500 rounded-md"
                            value={filterSeleccion}
                            onChange={(e) => setFilterSeleccion(e.target.value)}
                        >
                            <option value="todos">Todas las selecciones</option>
                            <option value="entrevista">Entrevista</option>
                            <option value="pendiente">Pendiente</option>
                            <option value="descartado">Descartado</option>
                        </select>
                    </div>
                </div>

                <div className="md:col-span-1 lg:col-span-2 flex items-center justify-end">
                    <button
                        type="button"
                        onClick={() => {
                            setSearchTerm("");
                            setFilterStatus("todos");
                            setFilterSeleccion("todos");
                        }}
                        className="inline-flex items-center px-2 py-1.5 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-1 focus:ring-blue-500"
                    >
                        <RefreshCcw className="h-3.5 w-3.5 mr-1" />
                        Reiniciar
                    </button>
                </div>
            </div>

            {/* Información de resultados y leyenda */}
            <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center space-y-2 sm:space-y-0 mb-3">
                <p className="text-xs sm:text-sm text-gray-500">
                    Mostrando {filteredCandidatos.length} de {candidatos.length} candidatos
                </p>
                <div className="flex flex-wrap items-center gap-x-2 gap-y-1">
                    <div className="flex items-center">
                        <span className="inline-flex items-center p-0.5 rounded-full bg-green-100">
                            <Check className="h-3 w-3 text-green-600" />
                        </span>
                        <span className="text-xs ml-1 text-gray-600">Entrevista</span>
                    </div>

                    <div className="flex items-center">
                        <span className="inline-flex items-center p-0.5 rounded-full bg-yellow-100">
                            <Clock className="h-3 w-3 text-yellow-600" />
                        </span>
                        <span className="text-xs ml-1 text-gray-600">Pendiente</span>
                    </div>

                    <div className="flex items-center">
                        <span className="inline-flex items-center p-0.5 rounded-full bg-red-100">
                            <X className="h-3 w-3 text-red-600" />
                        </span>
                        <span className="text-xs ml-1 text-gray-600">Descartado</span>
                    </div>
                </div>
            </div>

            {/* Tabla de candidatos o mensajes de estado */}
            <div className="bg-white rounded-lg shadow-sm border border-gray-200">
                {loading ? (
                    renderLoading()
                ) : filteredCandidatos.length === 0 ? (
                    renderNoResults()
                ) : (
                    renderCandidatosTable()
                )}
            </div>

            {/* Indicador de actualización */}
            {updatingId && (
                <div className="fixed bottom-4 right-4 bg-blue-600 text-white py-2 px-4 rounded-md shadow-lg flex items-center">
                    <RefreshCcw className="h-4 w-4 mr-2 animate-spin" />
                    <span className="text-sm">Actualizando...</span>
                </div>
            )}
        </div>
    );
};

export default Candidatos;