import React from "react";
import { User, Phone, Mail, Calendar, Home, Globe } from "lucide-react";

const SeccionGeneral = ({ candidato, formatearFecha }) => (
    <div className="bg-white shadow-md rounded-lg p-6">
        <div className="flex items-center mb-6">
            <div className="h-16 w-16 bg-blue-100 rounded-full flex items-center justify-center">
                <User className="h-8 w-8 text-blue-500" />
            </div>
            <div className="ml-4">
                <h2 className="text-xl font-bold text-gray-900">
                    {candidato.datosPersonales?.nombres || ""} {candidato.datosPersonales?.apellidoPaterno || ""} {candidato.datosPersonales?.apellidoMaterno || ""}
                </h2>
                <p className="text-sm text-gray-500">
                    {candidato.datosPersonales?.edad ? `${candidato.datosPersonales.edad} años` : ""}
                    {candidato.datosPersonales?.nacionalidad ? ` • ${candidato.datosPersonales.nacionalidad}` : ""}
                </p>
            </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
            <div className="space-y-4">
                <div className="flex items-center text-gray-700">
                    <Phone className="h-5 w-5 text-gray-500 mr-2" />
                    <span>{candidato.datosPersonales?.telefono || "Sin teléfono"}</span>
                </div>
                <div className="flex items-center text-gray-700">
                    <Mail className="h-5 w-5 text-gray-500 mr-2" />
                    <span>{candidato.contactInfo || "Sin correo"}</span>
                </div>
                <div className="flex items-center text-gray-700">
                    <Calendar className="h-5 w-5 text-gray-500 mr-2" />
                    <span>Solicitud creada: {formatearFecha(candidato.createdAt)}</span>
                </div>
            </div>
            <div className="space-y-4">
                <div className="flex items-center text-gray-700">
                    <Home className="h-5 w-5 text-gray-500 mr-2" />
                    <span>{candidato.datosPersonales?.domicilio || "Sin domicilio"}, {candidato.datosPersonales?.colonia || ""}</span>
                </div>
                <div className="flex items-center text-gray-700">
                    <Globe className="h-5 w-5 text-gray-500 mr-2" />
                    <span>{candidato.datosPersonales?.nacionalidad || "Sin nacionalidad"}</span>
                </div>
                <div className="flex items-center text-gray-700">
                    <Calendar className="h-5 w-5 text-gray-500 mr-2" />
                    <span>Fecha de nacimiento: {candidato.datosPersonales?.fechaNacimiento || "No disponible"}</span>
                </div>
            </div>
        </div>
    </div>
);

export default SeccionGeneral;