import React from "react";
import { Heart } from "lucide-react";

const SeccionEstadoSalud = ({ candidato }) => (
    <div className="bg-white shadow-md rounded-lg p-6">
        <h3 className="text-lg font-bold text-gray-900 mb-4 flex items-center">
            <Heart className="h-5 w-5 text-blue-500 mr-2" />
            Estado de Salud
        </h3>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-y-3 gap-x-6">
            <div className="flex flex-col">
                <span className="text-sm text-gray-500">Estado de Salud</span>
                <span className="font-medium">{candidato.estadoSalud?.estadoSalud || "No especificado"}</span>
            </div>
            <div className="flex flex-col">
                <span className="text-sm text-gray-500">Enfermedad Crónica</span>
                <span className="font-medium">{candidato.estadoSalud?.enfermedadCronica || "No especificado"}</span>
            </div>
            {candidato.estadoSalud?.enfermedadCronica === "Sí" && (
                <div className="flex flex-col">
                    <span className="text-sm text-gray-500">Detalle de Enfermedad</span>
                    <span className="font-medium">{candidato.estadoSalud?.detalleEnfermedad || "No especificado"}</span>
                </div>
            )}
            <div className="flex flex-col">
                <span className="text-sm text-gray-500">Deporte</span>
                <span className="font-medium">{candidato.estadoSalud?.deporte || "No especificado"}</span>
            </div>
            <div className="flex flex-col">
                <span className="text-sm text-gray-500">Pasatiempo</span>
                <span className="font-medium">{candidato.estadoSalud?.pasatiempo || "No especificado"}</span>
            </div>
            <div className="flex flex-col">
                <span className="text-sm text-gray-500">Club Social</span>
                <span className="font-medium">{candidato.estadoSalud?.clubSocial || "No especificado"}</span>
            </div>
            <div className="flex flex-col col-span-2">
                <span className="text-sm text-gray-500">Meta en la Vida</span>
                <span className="font-medium">{candidato.estadoSalud?.metaVida || "No especificado"}</span>
            </div>
        </div>
    </div>
);

export default SeccionEstadoSalud;