import React, { useState, useEffect, useMemo } from "react";
import { db } from "../../firebase";
import { collection, getDocs } from "firebase/firestore";
import {
  Typography,
  Grid,
  CircularProgress,
  Box,
  Paper,
  TextField,
  MenuItem,
  Pagination,
  InputAdornment,
  Card,
  CardContent,
  Container,
  Chip,
  Divider,
  Button,
  Alert,
  Tooltip,
  Fade,
  Skeleton,
  Breadcrumbs,
  Link as MuiLink,
  IconButton,
  Badge,
  Avatar,
  Stack,
  useMediaQuery,
  useTheme,
  Tabs,
  Tab,
  SwipeableDrawer,
  Collapse,
  Fab,
  ButtonGroup,
  Zoom,
  LinearProgress,
  Backdrop,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import FilterListIcon from "@mui/icons-material/FilterList";
import HomeIcon from "@mui/icons-material/Home";
import AssignmentIcon from "@mui/icons-material/Assignment";
import ClearIcon from "@mui/icons-material/Clear";
import AddIcon from "@mui/icons-material/Add";
import DownloadIcon from "@mui/icons-material/Download";
import RefreshIcon from "@mui/icons-material/Refresh";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import ElectricCarIcon from "@mui/icons-material/ElectricCar";
import SortIcon from "@mui/icons-material/Sort";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import TuneIcon from "@mui/icons-material/Tune";
import ViewListIcon from "@mui/icons-material/ViewList";
import ViewModuleIcon from "@mui/icons-material/ViewModule";
import PersonIcon from "@mui/icons-material/Person";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import CloseIcon from "@mui/icons-material/Close";
import SortByAlphaIcon from "@mui/icons-material/SortByAlpha";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import esLocale from "date-fns/locale/es";
import {
  format,
  isToday,
  isYesterday,
  isThisWeek,
  isThisMonth,
} from "date-fns";
import { es } from "date-fns/locale";

const SearchInspection = () => {
  const [inspections, setInspections] = useState([]);
  const [filteredInspections, setFilteredInspections] = useState([]);
  const [loading, setLoading] = useState(true);
  const [refreshing, setRefreshing] = useState(false);
  const [error, setError] = useState(null);
  const [search, setSearch] = useState("");
  const [statusFilter, setStatusFilter] = useState("");
  const [statuses, setStatuses] = useState([]);
  const [page, setPage] = useState(1);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [sortOrder, setSortOrder] = useState("desc"); // desc = newest first
  const [viewMode, setViewMode] = useState("grid"); // grid or list
  const [filterDrawerOpen, setFilterDrawerOpen] = useState(false);
  const [filtersExpanded, setFiltersExpanded] = useState(false);
  const [showFab, setShowFab] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [sortBy, setSortBy] = useState("date"); // date or unit

  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("lg"));

  // Número de inspecciones por página basado en tamaño de pantalla y modo de vista
  const inspectionsPerPage = useMemo(() => {
    if (viewMode === "list") {
      return isLargeScreen ? 10 : isMobile ? 5 : 7;
    } else {
      return isLargeScreen ? 12 : isMobile ? 6 : 9;
    }
  }, [viewMode, isMobile, isTablet, isLargeScreen]);

  // Manejador de scroll para mostrar/ocultar FAB
  useEffect(() => {
    const handleScroll = () => {
      const position = window.pageYOffset;
      setScrollPosition(position);
      setShowFab(position > 300);
    };
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const fetchInspections = async () => {
    setLoading(true);
    try {
      const querySnapshot = await getDocs(collection(db, "inspecciones"));
      const inspectionsData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
        Date: doc.data().Date?.toDate ? doc.data().Date.toDate() : new Date(),
      }));

      // Ordenar según sortBy y sortOrder
      sortInspections(inspectionsData, sortBy, sortOrder);

      const uniqueStatuses = [
        ...new Set(inspectionsData.map((insp) => insp.Status).filter(Boolean)),
      ];

      setInspections(inspectionsData);
      setFilteredInspections(inspectionsData);
      setStatuses(uniqueStatuses);
      setError(null);
    } catch (err) {
      console.error("Error al recuperar las inspecciones:", err);
      setError(
        "Error al recuperar las inspecciones. Por favor, intente nuevamente."
      );
    } finally {
      setLoading(false);
      setRefreshing(false);
    }
  };

  const sortInspections = (data, by = sortBy, order = sortOrder) => {
    if (by === "date") {
      data.sort((a, b) => {
        const dateA = a.Date instanceof Date ? a.Date : new Date(0);
        const dateB = b.Date instanceof Date ? b.Date : new Date(0);
        return order === "desc" ? dateB - dateA : dateA - dateB;
      });
    } else if (by === "unit") {
      data.sort((a, b) => {
        const unitA = (a.Unidad || "").toString().toLowerCase();
        const unitB = (b.Unidad || "").toString().toLowerCase();
        return order === "desc"
          ? unitB.localeCompare(unitA)
          : unitA.localeCompare(unitB);
      });
    }
    return data;
  };

  useEffect(() => {
    fetchInspections();
  }, []);

  // Reordenar cuando cambian los criterios de ordenación
  useEffect(() => {
    if (inspections.length > 0) {
      const sorted = [...inspections];
      sortInspections(sorted, sortBy, sortOrder);
      setInspections(sorted);

      // Aplicar el mismo ordenamiento a las inspecciones filtradas
      const filteredSorted = [...filteredInspections];
      sortInspections(filteredSorted, sortBy, sortOrder);
      setFilteredInspections(filteredSorted);
    }
  }, [sortBy, sortOrder]);

  const handleRefresh = () => {
    setRefreshing(true);
    fetchInspections();
  };

  const handleSearch = (e) => {
    const { value } = e.target;
    setSearch(value);
    filterInspections(value, statusFilter, startDate, endDate);
  };

  const handleStatusFilter = (e) => {
    const { value } = e.target;
    setStatusFilter(value);
    filterInspections(search, value, startDate, endDate);
  };

  const filterInspections = (searchValue, statusValue, start, end) => {
    const filtered = inspections.filter((inspection) => {
      const matchesSearch =
        inspection.Unidad?.toLowerCase().includes(searchValue.toLowerCase()) ||
        inspection.Status?.toLowerCase().includes(searchValue.toLowerCase()) ||
        inspection.Usuario?.toLowerCase().includes(searchValue.toLowerCase()) ||
        inspection.Comentario?.toLowerCase().includes(
          searchValue.toLowerCase()
        ) ||
        !searchValue;

      const matchesStatus = statusValue
        ? inspection.Status === statusValue
        : true;

      const matchesDate =
        (!start || (inspection.Date && inspection.Date >= start)) &&
        (!end || (inspection.Date && inspection.Date <= end));

      return matchesSearch && matchesStatus && matchesDate;
    });

    // Aplicar ordenamiento
    sortInspections(filtered, sortBy, sortOrder);
    setFilteredInspections(filtered);
    setPage(1); // Reset to first page on filter
  };

  const handleStartDateChange = (newStartDate) => {
    if (newStartDate) {
      // Configurar la hora al inicio del día
      newStartDate = new Date(newStartDate);
      newStartDate.setHours(0, 0, 0, 0);
    }

    setStartDate(newStartDate);
    filterInspections(search, statusFilter, newStartDate, endDate);
  };

  const handleEndDateChange = (newEndDate) => {
    if (newEndDate) {
      // Configurar la hora al final del día
      newEndDate = new Date(newEndDate);
      newEndDate.setHours(23, 59, 59, 999);
    }

    setEndDate(newEndDate);
    filterInspections(search, statusFilter, startDate, newEndDate);
  };

  const handlePageChange = (event, value) => {
    setPage(value);
    // Scroll to top of results on page change
    const resultsElement = document.getElementById("results-section");
    if (resultsElement) {
      resultsElement.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  const handleClearFilters = () => {
    setSearch("");
    setStatusFilter("");
    setStartDate(null);
    setEndDate(null);
    setFilteredInspections(
      sortInspections([...inspections], sortBy, sortOrder)
    );
    setPage(1);

    if (isMobile) {
      setFilterDrawerOpen(false);
    }
  };

  const handleToggleSortOrder = () => {
    setSortOrder(sortOrder === "desc" ? "asc" : "desc");
  };

  const handleChangeSortBy = (newSortBy) => {
    setSortBy(newSortBy);
  };

  const toggleViewMode = () => {
    setViewMode(viewMode === "grid" ? "list" : "grid");
    setPage(1); // Reset to first page when changing view
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const getStatusChip = (status) => {
    if (!status)
      return (
        <Chip
          size="small"
          label="Sin estado"
          color="default"
          variant="outlined"
        />
      );

    switch (status.toLowerCase()) {
      case "correcto":
      case "aprobado":
      case "aprobada":
      case "ok":
      case "completo":
      case "completa":
        return (
          <Chip
            size="small"
            icon={<CheckCircleIcon />}
            label="Correcto"
            color="success"
            sx={{ fontWeight: 500 }}
          />
        );
      case "pendiente":
      case "en progreso":
        return (
          <Chip
            size="small"
            icon={<AccessTimeIcon />}
            label="En Progreso"
            color="warning"
            sx={{ fontWeight: 500 }}
          />
        );
      case "incorrecto":
      case "rechazado":
      case "rechazada":
      case "fallo":
      case "error":
        return (
          <Chip
            size="small"
            icon={<ErrorIcon />}
            label="Incorrecto"
            color="error"
            sx={{ fontWeight: 500 }}
          />
        );
      default:
        return (
          <Chip size="small" label={status} color="info" variant="outlined" />
        );
    }
  };

  const formatRelativeDate = (date) => {
    if (!date || !(date instanceof Date)) return "Fecha no disponible";

    try {
      if (isToday(date)) {
        return `Hoy, ${format(date, "HH:mm")}`;
      } else if (isYesterday(date)) {
        return `Ayer, ${format(date, "HH:mm")}`;
      } else if (isThisWeek(date)) {
        return format(date, "EEEE", { locale: es });
      } else if (isThisMonth(date)) {
        return format(date, "d 'de' MMMM", { locale: es });
      } else {
        return format(date, "d MMM yyyy", { locale: es });
      }
    } catch (error) {
      console.error("Error al formatear la fecha:", error);
      return "Fecha no disponible";
    }
  };

  const formatDateDisplay = (date) => {
    if (!date || !(date instanceof Date)) return "Fecha no disponible";

    try {
      return format(date, "dd/MM/yyyy HH:mm");
    } catch (error) {
      console.error("Error al formatear la fecha:", error);
      return "Fecha no disponible";
    }
  };

  // Renderizado condicional del panel de filtros móvil
  const filtersDrawer = (
    <SwipeableDrawer
      anchor="bottom"
      open={filterDrawerOpen}
      onClose={() => setFilterDrawerOpen(false)}
      onOpen={() => setFilterDrawerOpen(true)}
      disableSwipeToOpen={false}
      ModalProps={{
        keepMounted: true, // Mejor rendimiento en móviles
      }}
      PaperProps={{
        sx: {
          borderTopLeftRadius: 16,
          borderTopRightRadius: 16,
          maxHeight: "85vh",
          padding: 0,
        },
      }}
    >
      <Box sx={{ p: 2, pb: 0 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 1,
          }}
        >
          <Typography variant="h6" sx={{ fontWeight: 500 }}>
            Filtros
          </Typography>
          <IconButton onClick={() => setFilterDrawerOpen(false)}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Divider sx={{ mt: 1, mb: 2 }} />
      </Box>

      <Box sx={{ p: 2, pt: 0 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              variant="outlined"
              label="Buscar"
              value={search}
              onChange={handleSearch}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon color="action" />
                  </InputAdornment>
                ),
                endAdornment: search ? (
                  <InputAdornment position="end">
                    <IconButton
                      size="small"
                      onClick={() => {
                        setSearch("");
                        filterInspections("", statusFilter, startDate, endDate);
                      }}
                    >
                      <ClearIcon fontSize="small" />
                    </IconButton>
                  </InputAdornment>
                ) : null,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              select
              fullWidth
              variant="outlined"
              label="Status"
              value={statusFilter}
              onChange={handleStatusFilter}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <FilterListIcon color="action" />
                  </InputAdornment>
                ),
              }}
            >
              <MenuItem value="">
                <em>Todos los estados</em>
              </MenuItem>
              {statuses.map((status) => (
                <MenuItem key={status} value={status}>
                  {status}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
            <Grid item xs={12} sm={6}>
              <DatePicker
                label="Desde"
                value={startDate}
                onChange={handleStartDateChange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    variant="outlined"
                    placeholder="Cualquier fecha"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <DatePicker
                label="Hasta"
                value={endDate}
                onChange={handleEndDateChange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    variant="outlined"
                    placeholder="Cualquier fecha"
                  />
                )}
              />
            </Grid>
          </LocalizationProvider>
        </Grid>

        <Box sx={{ mt: 3, display: "flex", justifyContent: "space-between" }}>
          <Button
            variant="text"
            onClick={handleClearFilters}
            disabled={!search && !statusFilter && !startDate && !endDate}
            startIcon={<ClearIcon />}
          >
            Limpiar
          </Button>
          <Button
            variant="contained"
            onClick={() => setFilterDrawerOpen(false)}
            startIcon={<CheckCircleIcon />}
          >
            Aplicar
          </Button>
        </Box>
      </Box>
    </SwipeableDrawer>
  );

  // Visualización de resultados en modo lista
  const renderListView = (inspectionItem) => (
    <Card
      elevation={1}
      sx={{
        borderRadius: 2,
        transition: "transform 0.2s, box-shadow 0.2s",
        cursor: "pointer",
        "&:hover": {
          transform: "translateX(4px)",
          boxShadow: "0 4px 12px rgba(25, 118, 210, 0.12)",
        },
        position: "relative",
        mb: 1,
      }}
      component={Link}
      to={`/inspeccion/${inspectionItem.id}`}
    >
      <CardContent sx={{ p: 0, display: "flex", alignItems: "stretch" }}>
        {inspectionItem.fotos?.["Foto unidad"] && (
          <Box
            sx={{
              width: { xs: 80, sm: 100 },
              backgroundColor: "#f5f5f5",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              position: "relative",
            }}
          >
            <img
              src={inspectionItem.fotos["Foto unidad"]}
              alt="Foto unidad"
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}
            />
          </Box>
        )}

        <Box
          sx={{
            p: 2,
            flexGrow: 1,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box>
            <Typography
              variant="subtitle1"
              sx={{
                fontWeight: "600",
                color: "#333",
                display: "flex",
                alignItems: "center",
                mb: 0.5,
              }}
            >
              <ElectricCarIcon sx={{ mr: 1, color: "#1976d2", fontSize: 20 }} />
              {inspectionItem.Unidad || "Unidad no especificada"}
            </Typography>

            <Typography
              variant="body2"
              sx={{
                color: "#666",
                display: "flex",
                alignItems: "center",
                mb: 0.5,
              }}
            >
              <CalendarTodayIcon sx={{ mr: 1, color: "#666", fontSize: 16 }} />
              {formatRelativeDate(inspectionItem.Date)}
            </Typography>

            {inspectionItem.Usuario && (
              <Typography
                variant="body2"
                sx={{
                  color: "#666",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <PersonIcon sx={{ mr: 1, color: "#666", fontSize: 16 }} />
                {inspectionItem.Usuario}
              </Typography>
            )}
          </Box>

          <Box sx={{ ml: 2, display: "flex", alignItems: "center" }}>
            {getStatusChip(inspectionItem.Status)}
          </Box>
        </Box>
      </CardContent>
    </Card>
  );

  // Visualización de resultados en modo grid
  const renderGridView = (inspectionItem) => (
    <Grid item xs={12} sm={6} md={4} lg={3} key={inspectionItem.id}>
      <Card
        elevation={2}
        sx={{
          height: "100%",
          borderRadius: 2,
          transition: "transform 0.2s, box-shadow 0.2s",
          cursor: "pointer",
          "&:hover": {
            transform: "translateY(-5px)",
            boxShadow: "0 8px 24px rgba(25, 118, 210, 0.15)",
          },
          position: "relative",
          overflow: "hidden",
        }}
        component={Link}
        to={`/inspeccion/${inspectionItem.id}`}
      >
        <Box sx={{ position: "relative", paddingTop: "56.25%" }}>
          {inspectionItem.fotos?.["Foto unidad"] ? (
            <img
              src={inspectionItem.fotos["Foto unidad"]}
              alt="Foto unidad"
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}
            />
          ) : (
            <Box
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "#f5f5f5",
              }}
            >
              <ElectricCarIcon sx={{ fontSize: 48, color: "#bdbdbd" }} />
            </Box>
          )}

          <Box
            sx={{
              position: "absolute",
              top: 10,
              right: 10,
              zIndex: 1,
            }}
          >
            {getStatusChip(inspectionItem.Status)}
          </Box>
        </Box>

        <CardContent>
          <Typography
            variant="subtitle1"
            sx={{
              fontWeight: "600",
              color: "#333",
              display: "flex",
              alignItems: "center",
              mb: 1,
            }}
          >
            <ElectricCarIcon sx={{ mr: 1, color: "#1976d2", fontSize: 20 }} />
            {inspectionItem.Unidad || "Unidad no especificada"}
          </Typography>

          <Divider sx={{ my: 1 }} />

          <Stack spacing={1}>
            <Typography
              variant="body2"
              sx={{
                display: "flex",
                alignItems: "center",
                color: "#666",
              }}
            >
              <CalendarTodayIcon sx={{ mr: 1, color: "#666", fontSize: 16 }} />
              {formatRelativeDate(inspectionItem.Date)}
            </Typography>

            {inspectionItem.Usuario && (
              <Typography
                variant="body2"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  color: "#666",
                }}
              >
                <PersonIcon sx={{ mr: 1, color: "#666", fontSize: 16 }} />
                {inspectionItem.Usuario}
              </Typography>
            )}
          </Stack>
        </CardContent>
      </Card>
    </Grid>
  );

  if (loading && !refreshing) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
        bgcolor="#f5f7fa"
      >
        <CircularProgress color="primary" />
      </Box>
    );
  }

  const startIndex = (page - 1) * inspectionsPerPage;
  const paginatedInspections = filteredInspections.slice(
    startIndex,
    startIndex + inspectionsPerPage
  );

  const filtersActive = search || statusFilter || startDate || endDate;
  const totalPages = Math.ceil(filteredInspections.length / inspectionsPerPage);

  return (
    <Box
      sx={{
        bgcolor: "#f5f7fa",
        minHeight: "100vh",
        pb: 4,
      }}
    >
      {/* Header fijo con efecto de elevación al hacer scroll */}
      <Paper
        elevation={scrollPosition > 50 ? 4 : 0}
        sx={{
          position: "sticky",
          top: 0,
          zIndex: 10,
          transition: "box-shadow 0.3s",
          borderRadius: 0,
          backgroundColor: scrollPosition > 50 ? "white" : "#f5f7fa",
        }}
      >
        <Container maxWidth="lg">
          <Box
            sx={{
              py: 2,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <AssignmentIcon
                sx={{
                  mr: 1.5,
                  color: "#1976d2",
                  fontSize: { xs: 24, sm: 28 },
                }}
              />
              <Typography
                variant="h5"
                sx={{
                  fontWeight: "600",
                  color: "#333",
                  fontSize: { xs: "1.2rem", sm: "1.5rem" },
                }}
              >
                Inspecciones
              </Typography>
            </Box>

            <Box sx={{ display: "flex", gap: 1 }}>
              {!isMobile && (
                <Tooltip title="Agregar nueva inspección">
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<AddIcon />}
                    onClick={() => navigate("/nuevainspeccion")}
                    size={isMobile ? "small" : "medium"}
                    sx={{ display: { xs: "none", sm: "flex" } }}
                  >
                    Nueva
                  </Button>
                </Tooltip>
              )}

              <Tooltip title={isMobile ? "Filtros" : "Mostrar/ocultar filtros"}>
                <IconButton
                  color="primary"
                  onClick={() =>
                    isMobile
                      ? setFilterDrawerOpen(true)
                      : setFiltersExpanded(!filtersExpanded)
                  }
                  size={isMobile ? "small" : "medium"}
                  sx={{
                    backgroundColor: filtersActive ? "#e3f2fd" : "transparent",
                    "&:hover": {
                      backgroundColor: filtersActive ? "#bbdefb" : undefined,
                    },
                  }}
                >
                  <Badge
                    color="secondary"
                    variant="dot"
                    invisible={!filtersActive}
                  >
                    <TuneIcon />
                  </Badge>
                </IconButton>
              </Tooltip>

              <Tooltip title="Actualizar lista">
                <IconButton
                  color="primary"
                  onClick={handleRefresh}
                  disabled={refreshing}
                  size={isMobile ? "small" : "medium"}
                >
                  <RefreshIcon />
                </IconButton>
              </Tooltip>
            </Box>
          </Box>

          {/* Breadcrumbs, solo visible en pantallas no móviles */}
          {!isMobile && (
            <Breadcrumbs
              aria-label="breadcrumb"
              sx={{
                mb: 2,
                color: "#555",
                display: { xs: "none", sm: "flex" },
              }}
            >
              <MuiLink
                underline="hover"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                color="inherit"
                onClick={() => navigate("/")}
              >
                <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                Inicio
              </MuiLink>
              <Typography
                color="text.primary"
                sx={{ display: "flex", alignItems: "center" }}
              >
                <AssignmentIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                Inspecciones
              </Typography>
            </Breadcrumbs>
          )}

          {/* Panel de filtros colapsable para pantallas no móviles */}
          <Collapse in={filtersExpanded} timeout="auto">
            <Paper
              elevation={1}
              sx={{
                p: 3,
                mb: 2,
                borderRadius: 2,
                backgroundColor: "#f8f9fa",
              }}
            >
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    label="Buscar por Unidad, Status o Técnico"
                    value={search}
                    onChange={handleSearch}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon color="action" />
                        </InputAdornment>
                      ),
                      endAdornment: search ? (
                        <InputAdornment position="end">
                          <IconButton
                            size="small"
                            onClick={() => {
                              setSearch("");
                              filterInspections(
                                "",
                                statusFilter,
                                startDate,
                                endDate
                              );
                            }}
                          >
                            <ClearIcon fontSize="small" />
                          </IconButton>
                        </InputAdornment>
                      ) : null,
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    select
                    fullWidth
                    variant="outlined"
                    label="Filtrar por Status"
                    value={statusFilter}
                    onChange={handleStatusFilter}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <FilterListIcon color="action" />
                        </InputAdornment>
                      ),
                    }}
                  >
                    <MenuItem value="">
                      <em>Todos los estados</em>
                    </MenuItem>
                    {statuses.map((status) => (
                      <MenuItem key={status} value={status}>
                        {status}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>

                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  locale={esLocale}
                >
                  <Grid item xs={12} sm={6}>
                    <DatePicker
                      label="Fecha de inicio"
                      value={startDate}
                      onChange={handleStartDateChange}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          variant="outlined"
                          helperText="Filtrar desde esta fecha"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <DatePicker
                      label="Fecha de fin"
                      value={endDate}
                      onChange={handleEndDateChange}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          variant="outlined"
                          helperText="Filtrar hasta esta fecha"
                        />
                      )}
                    />
                  </Grid>
                </LocalizationProvider>
              </Grid>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  mt: 3,
                }}
              >
                <Typography variant="body2" color="text.secondary">
                  {filteredInspections.length} inspección(es) encontrada(s)
                </Typography>
                <Button
                  variant="text"
                  color="primary"
                  startIcon={<ClearIcon />}
                  onClick={handleClearFilters}
                  disabled={!filtersActive}
                >
                  Limpiar filtros
                </Button>
              </Box>
            </Paper>
          </Collapse>
        </Container>
      </Paper>

      {/* Contenido principal */}
      <Container maxWidth="lg" sx={{ mt: 2, pb: 8 }}>
        {error && (
          <Alert severity="error" sx={{ mb: 3 }}>
            {error}
          </Alert>
        )}

        {/* Barra de herramientas para vista y ordenación */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 2,
            flexWrap: "wrap",
            gap: 1,
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography variant="body2" color="text.secondary" sx={{ mr: 1 }}>
              {filteredInspections.length} resultado
              {filteredInspections.length !== 1 ? "s" : ""}
            </Typography>
            {filtersActive && (
              <Chip
                label="Filtros activos"
                size="small"
                color="primary"
                variant="outlined"
                onDelete={handleClearFilters}
                sx={{ ml: 1 }}
              />
            )}
          </Box>

          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            {/* Selección de criterio de ordenación */}
            <Tooltip title="Cambiar criterio de ordenación">
              <ButtonGroup
                size="small"
                variant="outlined"
                aria-label="ordenar por"
              >
                <Button
                  onClick={() => handleChangeSortBy("date")}
                  variant={sortBy === "date" ? "contained" : "outlined"}
                  sx={{ px: { xs: 1, sm: 2 } }}
                >
                  <CalendarTodayIcon
                    fontSize="small"
                    sx={{ mr: { xs: 0, sm: 0.5 } }}
                  />
                  {!isMobile && "Fecha"}
                </Button>
                <Button
                  onClick={() => handleChangeSortBy("unit")}
                  variant={sortBy === "unit" ? "contained" : "outlined"}
                  sx={{ px: { xs: 1, sm: 2 } }}
                >
                  <SortByAlphaIcon
                    fontSize="small"
                    sx={{ mr: { xs: 0, sm: 0.5 } }}
                  />
                  {!isMobile && "Unidad"}
                </Button>
                <Button
                  onClick={handleToggleSortOrder}
                  sx={{ px: { xs: 1, sm: 2 } }}
                >
                  <SortIcon
                    fontSize="small"
                    sx={{
                      transform:
                        sortOrder === "asc" ? "rotate(0deg)" : "rotate(180deg)",
                      transition: "transform 0.3s",
                    }}
                  />
                </Button>
              </ButtonGroup>
            </Tooltip>

            {/* Toggle entre vista grid/lista */}
            <Tooltip title={`Vista ${viewMode === "grid" ? "lista" : "grid"}`}>
              <IconButton onClick={toggleViewMode} size="small" color="primary">
                {viewMode === "grid" ? (
                  <FormatListBulletedIcon />
                ) : (
                  <ViewModuleIcon />
                )}
              </IconButton>
            </Tooltip>
          </Box>
        </Box>

        {/* Indicador de carga durante actualización */}
        {refreshing && <LinearProgress sx={{ mb: 2, borderRadius: 1 }} />}

        {/* Sección de resultados */}
        <Box id="results-section">
          {filteredInspections.length === 0 ? (
            <Paper
              elevation={0}
              sx={{
                p: 4,
                textAlign: "center",
                borderRadius: 2,
                backgroundColor: "#f8f9fa",
                border: "1px dashed #ccc",
              }}
            >
              <Box sx={{ mb: 2 }}>
                <AssignmentIcon sx={{ fontSize: 60, color: "#bdbdbd" }} />
              </Box>
              <Typography variant="h6" color="text.secondary" gutterBottom>
                No se encontraron inspecciones
              </Typography>
              <Typography variant="body2" color="text.secondary" paragraph>
                {filtersActive
                  ? "Intenta modificar los filtros de búsqueda para ver más resultados."
                  : "No hay inspecciones disponibles en el sistema."}
              </Typography>
              {filtersActive && (
                <Button
                  variant="outlined"
                  startIcon={<ClearIcon />}
                  onClick={handleClearFilters}
                >
                  Limpiar filtros
                </Button>
              )}
            </Paper>
          ) : (
            <Fade in={!loading || refreshing}>
              <div>
                {viewMode === "grid" ? (
                  <Grid container spacing={3}>
                    {paginatedInspections.map((inspection) =>
                      renderGridView(inspection)
                    )}
                  </Grid>
                ) : (
                  <Stack spacing={1.5}>
                    {paginatedInspections.map((inspection) =>
                      renderListView(inspection)
                    )}
                  </Stack>
                )}

                {/* Paginación responsiva y mejorada */}
                {totalPages > 1 && (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      mt: 4,
                      mb: 2,
                    }}
                  >
                    <Pagination
                      count={totalPages}
                      page={page}
                      onChange={handlePageChange}
                      color="primary"
                      size={isMobile ? "small" : "medium"}
                      showFirstButton
                      showLastButton
                      siblingCount={isMobile ? 0 : 1}
                      boundaryCount={isMobile ? 1 : 2}
                      sx={{
                        "& .MuiPaginationItem-root": {
                          borderRadius: "8px",
                        },
                      }}
                    />
                  </Box>
                )}
              </div>
            </Fade>
          )}
        </Box>
      </Container>

      {/* FAB para nueva inspección (visible solo en móvil) y scroll top */}
      <Box
        sx={{
          position: "fixed",
          bottom: 16,
          right: 16,
          zIndex: 1050,
          display: "flex",
          flexDirection: "column",
          gap: 1,
        }}
      >
        {showFab && (
          <Zoom in={showFab}>
            <Fab
              size="small"
              color="secondary"
              aria-label="scroll-top"
              onClick={scrollToTop}
            >
              <ArrowDropUpIcon />
            </Fab>
          </Zoom>
        )}

        {isMobile && (
          <Fab
            color="primary"
            aria-label="add"
            onClick={() => navigate("/nuevainspeccion")}
          >
            <AddIcon />
          </Fab>
        )}
      </Box>

      {/* Drawer de filtros para móvil */}
      {filtersDrawer}

      {/* Backdrop durante carga */}
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading && !refreshing}
      >
        <CircularProgress color="primary" />
      </Backdrop>
    </Box>
  );
};

export default SearchInspection;
