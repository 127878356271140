import React from "react";
import { Users } from "lucide-react";

const SeccionDatosFamiliares = ({ candidato }) => (
    <div className="bg-white shadow-md rounded-lg p-6">
        <h3 className="text-lg font-bold text-gray-900 mb-4 flex items-center">
            <Users className="h-5 w-5 text-blue-500 mr-2" />
            Datos Familiares
        </h3>

        {candidato.datosFamiliares?.padre && (
            <div className="mb-6">
                <h4 className="font-semibold text-gray-800 mb-2">Padre</h4>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-y-2 gap-x-6 pl-4">
                    <div className="flex flex-col">
                        <span className="text-sm text-gray-500">Nombre</span>
                        <span className="font-medium">{candidato.datosFamiliares.padre.nombre || "No especificado"}</span>
                    </div>
                    <div className="flex flex-col">
                        <span className="text-sm text-gray-500">Vive</span>
                        <span className="font-medium">{candidato.datosFamiliares.padre.vive || "No especificado"}</span>
                    </div>
                    <div className="flex flex-col">
                        <span className="text-sm text-gray-500">Ocupación</span>
                        <span className="font-medium">{candidato.datosFamiliares.padre.ocupacion || "No especificado"}</span>
                    </div>
                    <div className="flex flex-col">
                        <span className="text-sm text-gray-500">Domicilio</span>
                        <span className="font-medium">{candidato.datosFamiliares.padre.domicilio || "No especificado"}</span>
                    </div>
                </div>
            </div>
        )}

        {candidato.datosFamiliares?.madre && (
            <div className="mb-6">
                <h4 className="font-semibold text-gray-800 mb-2">Madre</h4>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-y-2 gap-x-6 pl-4">
                    <div className="flex flex-col">
                        <span className="text-sm text-gray-500">Nombre</span>
                        <span className="font-medium">{candidato.datosFamiliares.madre.nombre || "No especificado"}</span>
                    </div>
                    <div className="flex flex-col">
                        <span className="text-sm text-gray-500">Vive</span>
                        <span className="font-medium">{candidato.datosFamiliares.madre.vive || "No especificado"}</span>
                    </div>
                    <div className="flex flex-col">
                        <span className="text-sm text-gray-500">Ocupación</span>
                        <span className="font-medium">{candidato.datosFamiliares.madre.ocupacion || "No especificado"}</span>
                    </div>
                    <div className="flex flex-col">
                        <span className="text-sm text-gray-500">Domicilio</span>
                        <span className="font-medium">{candidato.datosFamiliares.madre.domicilio || "No especificado"}</span>
                    </div>
                </div>
            </div>
        )}

        {candidato.datosFamiliares?.pareja && (
            <div className="mb-6">
                <h4 className="font-semibold text-gray-800 mb-2">Pareja</h4>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-y-2 gap-x-6 pl-4">
                    <div className="flex flex-col">
                        <span className="text-sm text-gray-500">Nombre</span>
                        <span className="font-medium">{candidato.datosFamiliares.pareja.nombre || "No especificado"}</span>
                    </div>
                    <div className="flex flex-col">
                        <span className="text-sm text-gray-500">Vive</span>
                        <span className="font-medium">{candidato.datosFamiliares.pareja.vive || "No especificado"}</span>
                    </div>
                    <div className="flex flex-col">
                        <span className="text-sm text-gray-500">Ocupación</span>
                        <span className="font-medium">{candidato.datosFamiliares.pareja.ocupacion || "No especificado"}</span>
                    </div>
                    <div className="flex flex-col">
                        <span className="text-sm text-gray-500">Domicilio</span>
                        <span className="font-medium">{candidato.datosFamiliares.pareja.domicilio || "No especificado"}</span>
                    </div>
                </div>
            </div>
        )}

        {candidato.datosFamiliares?.hijos && candidato.datosFamiliares.hijos.length > 0 && (
            <div>
                <h4 className="font-semibold text-gray-800 mb-2">Hijos</h4>
                <ul className="pl-4">
                    {candidato.datosFamiliares.hijos.map((hijo, index) => (
                        <li key={index} className="mb-1">{hijo}</li>
                    ))}
                </ul>
            </div>
        )}
    </div>
);

export default SeccionDatosFamiliares;