import React from "react";
import { UserCheck } from "lucide-react";

const SeccionReferencias = ({ candidato }) => (
    <div className="bg-white shadow-md rounded-lg p-6">
        <h3 className="text-lg font-bold text-gray-900 mb-4 flex items-center">
            <UserCheck className="h-5 w-5 text-blue-500 mr-2" />
            Referencias Personales
        </h3>

        {candidato.referenciasPersonales && candidato.referenciasPersonales.length > 0 ? (
            <div className="grid grid-cols-1 gap-4">
                {candidato.referenciasPersonales.map((referencia, index) => (
                    <div key={index} className="border-l-2 border-blue-200 pl-4 py-2">
                        <h4 className="font-semibold text-gray-800">{referencia.nombre || "Referencia no especificada"}</h4>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-y-2 gap-x-6 mt-1">
                            <div className="flex flex-col">
                                <span className="text-sm text-gray-500">Ocupación</span>
                                <span className="font-medium">{referencia.ocupacion || "No especificado"}</span>
                            </div>
                            <div className="flex flex-col">
                                <span className="text-sm text-gray-500">Teléfono</span>
                                <span className="font-medium">{referencia.telefono || "No especificado"}</span>
                            </div>
                            <div className="flex flex-col">
                                <span className="text-sm text-gray-500">Domicilio</span>
                                <span className="font-medium">{referencia.domicilio || "No especificado"}</span>
                            </div>
                            <div className="flex flex-col">
                                <span className="text-sm text-gray-500">Tiempo de Conocerlo</span>
                                <span className="font-medium">{referencia.tiempoConocer ? `${referencia.tiempoConocer} años` : "No especificado"}</span>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        ) : (
            <p className="text-gray-500 italic">No hay referencias personales registradas</p>
        )}
    </div>
);

export default SeccionReferencias;