import React from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import SyncIcon from "@mui/icons-material/Sync";
import BuildIcon from "@mui/icons-material/Build";
import MemoryIcon from "@mui/icons-material/Memory";
import SettingsIcon from "@mui/icons-material/Settings";
import AssessmentIcon from "@mui/icons-material/Assessment";
import SecurityIcon from "@mui/icons-material/Security";

const DiagnosticoInspeccion = ({ estadoGeneral, fusiblesReemplazados }) => {
  // Determina si hay datos para mostrar
  const hasDiagnosticData =
    (estadoGeneral && Object.keys(estadoGeneral).length > 0) ||
    (fusiblesReemplazados && fusiblesReemplazados.length > 0);

  // Función para obtener un icono según el nombre del componente
  const getComponentIcon = (componentName) => {
    const name = componentName.toLowerCase();
    if (
      name.includes("cable") ||
      name.includes("cableado") ||
      name.includes("conexión")
    ) {
      return <MemoryIcon className="text-blue-500" />;
    } else if (name.includes("fusible") || name.includes("fuse")) {
      return <SettingsIcon className="text-blue-500" />;
    } else if (name.includes("seguridad") || name.includes("security")) {
      return <SecurityIcon className="text-blue-500" />;
    } else {
      return <BuildIcon className="text-blue-500" />;
    }
  };

  const renderEstadoGeneral = () => {
    if (!estadoGeneral || Object.keys(estadoGeneral).length === 0) return null;

    return (
      <div className="mb-6">
        <h3 className="flex items-center text-gray-800 font-semibold mb-4 text-lg">
          <AssessmentIcon className="mr-2 text-blue-600" />
          Estado de Componentes
        </h3>

        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
          {Object.entries(estadoGeneral).map(([key, value]) => {
            const isCorrect = value === "Correcto";
            return (
              <div key={key} className="relative">
                <div
                  className={`
                    rounded-lg border p-4 h-full transition-transform duration-200 hover:-translate-y-1
                    ${
                      isCorrect
                        ? "border-green-100 bg-white"
                        : "border-red-100 bg-white"
                    }
                  `}
                >
                  <div className="absolute -top-2 -right-2 z-10">
                    <div className="flex items-center justify-center w-6 h-6 bg-white rounded-full">
                      {isCorrect ? (
                        <CheckCircleIcon className="text-green-500 text-xl" />
                      ) : (
                        <CancelIcon className="text-red-500 text-xl" />
                      )}
                    </div>
                  </div>

                  <div className="flex items-center">
                    <div
                      className={`
                      flex items-center justify-center w-10 h-10 rounded-full mr-3
                      ${
                        isCorrect
                          ? "bg-green-50 text-green-500"
                          : "bg-red-50 text-red-500"
                      }
                    `}
                    >
                      {getComponentIcon(key)}
                    </div>
                    <div>
                      <p className="font-medium text-gray-800">{key}</p>
                      <span
                        className={`
                        inline-flex items-center px-2 py-0.5 mt-1 rounded-full text-xs font-medium
                        ${
                          isCorrect
                            ? "bg-green-100 text-green-800"
                            : "bg-red-100 text-red-800"
                        }
                      `}
                      >
                        {value}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  const renderFusiblesReemplazados = () => {
    if (!fusiblesReemplazados || fusiblesReemplazados.length === 0) return null;

    return (
      <div className="mt-6">
        <h3 className="flex items-center text-gray-800 font-semibold mb-4 text-lg">
          <SyncIcon className="mr-2 text-blue-600" />
          Componentes Reemplazados
        </h3>

        <div className="bg-gray-50 rounded-lg border border-gray-200">
          <ul className="divide-y divide-gray-200">
            {fusiblesReemplazados.map((fusible, index) => (
              <li key={index} className="py-3 px-4">
                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <div className="flex items-center justify-center w-9 h-9 bg-blue-50 rounded-full mr-3 text-blue-600">
                      {getComponentIcon(fusible)}
                    </div>
                    <span className="font-medium text-gray-800">{fusible}</span>
                  </div>
                  <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800">
                    Reemplazado
                  </span>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    );
  };

  // Si no hay datos, no renderizar nada
  if (!hasDiagnosticData) return null;

  return (
    <div className="animate-fade-in">
      <hr className="my-4 border-gray-200" />

      <h2 className="flex items-center text-xl font-semibold text-blue-600 mb-4">
        <BuildIcon className="mr-2" />
        Diagnóstico Técnico
      </h2>

      <div className="p-4 rounded-lg bg-gray-50 border border-gray-200">
        {renderEstadoGeneral()}
        {renderFusiblesReemplazados()}
      </div>
    </div>
  );
};

// Animación de fade-in personalizada para agregar al archivo CSS
const fadeInStyle = `
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}
.animate-fade-in {
  animation: fadeIn 0.3s ease-in-out;
}
`;

export default DiagnosticoInspeccion;
